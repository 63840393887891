/*!
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       

*/
(function (f, define) {
    define('kendo.timezones', ['kendo.core'], f);
}(function () {
    var __meta__ = {
        id: 'timezones',
        name: 'Timezones',
        category: 'framework',
        depends: ['core'],
        hidden: true
    };
    var kendo = window.kendo;
    kendo.timezone.zones = {
        'Africa/Algiers': [
            [
                '-12.2',
                '-',
                'LMT',
                '-2486678340000'
            ],
            [
                '-9.35',
                '-',
                'PMT',
                '-1855958400000'
            ],
            [
                '0',
                'Algeria',
                'WE%sT',
                '-942012000000'
            ],
            [
                '-60',
                'Algeria',
                'CE%sT',
                '-733276800000'
            ],
            [
                '0',
                '-',
                'WET',
                '-439430400000'
            ],
            [
                '-60',
                '-',
                'CET',
                '-212025600000'
            ],
            [
                '0',
                'Algeria',
                'WE%sT',
                '246240000000'
            ],
            [
                '-60',
                'Algeria',
                'CE%sT',
                '309744000000'
            ],
            [
                '0',
                'Algeria',
                'WE%sT',
                '357523200000'
            ],
            [
                '-60',
                '-',
                'CET'
            ]
        ],
        'Atlantic/Cape_Verde': [
            [
                '94.06666666666668',
                '-',
                'LMT',
                '-1830376800000'
            ],
            [
                '120',
                '-',
                '-02',
                '-862617600000'
            ],
            [
                '120',
                '1:00',
                '-01',
                '-764121600000'
            ],
            [
                '120',
                '-',
                '-02',
                '186112800000'
            ],
            [
                '60',
                '-',
                '-01'
            ]
        ],
        'Africa/Ndjamena': [
            [
                '-60.2',
                '-',
                'LMT',
                '-1798848000000'
            ],
            [
                '-60',
                '-',
                'WAT',
                '308707200000'
            ],
            [
                '-60',
                '1:00',
                'WAST',
                '321321600000'
            ],
            [
                '-60',
                '-',
                'WAT'
            ]
        ],
        'Africa/Abidjan': [
            [
                '16.133333333333333',
                '-',
                'LMT',
                '-1798848000000'
            ],
            [
                '0',
                '-',
                'GMT'
            ]
        ],
        'Africa/Bamako': 'Africa/Abidjan',
        'Africa/Banjul': 'Africa/Abidjan',
        'Africa/Conakry': 'Africa/Abidjan',
        'Africa/Dakar': 'Africa/Abidjan',
        'Africa/Freetown': 'Africa/Abidjan',
        'Africa/Lome': 'Africa/Abidjan',
        'Africa/Nouakchott': 'Africa/Abidjan',
        'Africa/Ouagadougou': 'Africa/Abidjan',
        'Atlantic/St_Helena': 'Africa/Abidjan',
        'Africa/Cairo': [
            [
                '-125.15',
                '-',
                'LMT',
                '-2185401600000'
            ],
            [
                '-120',
                'Egypt',
                'EE%sT'
            ]
        ],
        'Africa/Accra': [
            [
                '0.8666666666666666',
                '-',
                'LMT',
                '-1609545600000'
            ],
            [
                '0',
                'Ghana',
                'GMT/+0020'
            ]
        ],
        'Africa/Bissau': [
            [
                '62.333333333333336',
                '-',
                'LMT',
                '-1830380400000'
            ],
            [
                '60',
                '-',
                '-01',
                '189216000000'
            ],
            [
                '0',
                '-',
                'GMT'
            ]
        ],
        'Africa/Nairobi': [
            [
                '-147.26666666666665',
                '-',
                'LMT',
                '-1309737600000'
            ],
            [
                '-180',
                '-',
                'EAT',
                '-1230854400000'
            ],
            [
                '-150',
                '-',
                '+0230',
                '-915235200000'
            ],
            [
                '-165',
                '-',
                '+0245',
                '-284083200000'
            ],
            [
                '-180',
                '-',
                'EAT'
            ]
        ],
        'Africa/Addis_Ababa': 'Africa/Nairobi',
        'Africa/Asmara': 'Africa/Nairobi',
        'Africa/Dar_es_Salaam': 'Africa/Nairobi',
        'Africa/Djibouti': 'Africa/Nairobi',
        'Africa/Kampala': 'Africa/Nairobi',
        'Africa/Mogadishu': 'Africa/Nairobi',
        'Indian/Antananarivo': 'Africa/Nairobi',
        'Indian/Comoro': 'Africa/Nairobi',
        'Indian/Mayotte': 'Africa/Nairobi',
        'Africa/Monrovia': [
            [
                '43.13333333333333',
                '-',
                'LMT',
                '-2745532800000'
            ],
            [
                '43.13333333333333',
                '-',
                'MMT',
                '-1604361600000'
            ],
            [
                '44.5',
                '-',
                'MMT',
                '63590400000'
            ],
            [
                '0',
                '-',
                'GMT'
            ]
        ],
        'Africa/Tripoli': [
            [
                '-52.733333333333334',
                '-',
                'LMT',
                '-1546387200000'
            ],
            [
                '-60',
                'Libya',
                'CE%sT',
                '-315705600000'
            ],
            [
                '-120',
                '-',
                'EET',
                '410140800000'
            ],
            [
                '-60',
                'Libya',
                'CE%sT',
                '641779200000'
            ],
            [
                '-120',
                '-',
                'EET',
                '844041600000'
            ],
            [
                '-60',
                'Libya',
                'CE%sT',
                '875923200000'
            ],
            [
                '-120',
                '-',
                'EET',
                '1352512800000'
            ],
            [
                '-60',
                'Libya',
                'CE%sT',
                '1382666400000'
            ],
            [
                '-120',
                '-',
                'EET'
            ]
        ],
        'Indian/Mauritius': [
            [
                '-230',
                '-',
                'LMT',
                '-1956700800000'
            ],
            [
                '-240',
                'Mauritius',
                '+04/+05'
            ]
        ],
        'Africa/Casablanca': [
            [
                '30.333333333333332',
                '-',
                'LMT',
                '-1773014400000'
            ],
            [
                '0',
                'Morocco',
                '+00/+01',
                '448243200000'
            ],
            [
                '-60',
                '-',
                '+01',
                '536371200000'
            ],
            [
                '0',
                'Morocco',
                '+00/+01',
                '1540695600000'
            ],
            [
                '-60',
                'Morocco',
                '+01/+00'
            ]
        ],
        'Africa/El_Aaiun': [
            [
                '52.8',
                '-',
                'LMT',
                '-1136073600000'
            ],
            [
                '60',
                '-',
                '-01',
                '198288000000'
            ],
            [
                '0',
                'Morocco',
                '+00/+01',
                '1540695600000'
            ],
            [
                '-60',
                'Morocco',
                '+01/+00'
            ]
        ],
        'Africa/Maputo': [
            [
                '-130.33333333333331',
                '-',
                'LMT',
                '-2109283200000'
            ],
            [
                '-120',
                '-',
                'CAT'
            ]
        ],
        'Africa/Blantyre': 'Africa/Maputo',
        'Africa/Bujumbura': 'Africa/Maputo',
        'Africa/Gaborone': 'Africa/Maputo',
        'Africa/Harare': 'Africa/Maputo',
        'Africa/Kigali': 'Africa/Maputo',
        'Africa/Lubumbashi': 'Africa/Maputo',
        'Africa/Lusaka': 'Africa/Maputo',
        'Africa/Windhoek': [
            [
                '-68.4',
                '-',
                'LMT',
                '-2458166400000'
            ],
            [
                '-90',
                '-',
                '+0130',
                '-2109283200000'
            ],
            [
                '-120',
                '-',
                'SAST',
                '-860968800000'
            ],
            [
                '-120',
                '1:00',
                'SAST',
                '-845244000000'
            ],
            [
                '-120',
                '-',
                'SAST',
                '637977600000'
            ],
            [
                '-120',
                'Namibia',
                '%s'
            ]
        ],
        'Africa/Lagos': [
            [
                '-13.6',
                '-',
                'LMT',
                '-1588464000000'
            ],
            [
                '-60',
                '-',
                'WAT'
            ]
        ],
        'Africa/Bangui': 'Africa/Lagos',
        'Africa/Brazzaville': 'Africa/Lagos',
        'Africa/Douala': 'Africa/Lagos',
        'Africa/Kinshasa': 'Africa/Lagos',
        'Africa/Libreville': 'Africa/Lagos',
        'Africa/Luanda': 'Africa/Lagos',
        'Africa/Malabo': 'Africa/Lagos',
        'Africa/Niamey': 'Africa/Lagos',
        'Africa/Porto-Novo': 'Africa/Lagos',
        'Indian/Reunion': [
            [
                '-221.86666666666665',
                '-',
                'LMT',
                '-1848873600000'
            ],
            [
                '-240',
                '-',
                '+04'
            ]
        ],
        'Africa/Sao_Tome': [
            [
                '-26.933333333333334',
                '-',
                'LMT',
                '-2682374400000'
            ],
            [
                '36.75',
                '-',
                'LMT',
                '-1830384000000'
            ],
            [
                '0',
                '-',
                'GMT',
                '1514768400000'
            ],
            [
                '-60',
                '-',
                'WAT',
                '1546308000000'
            ],
            [
                '0',
                '-',
                'GMT'
            ]
        ],
        'Indian/Mahe': [
            [
                '-221.8',
                '-',
                'LMT',
                '-2006640000000'
            ],
            [
                '-240',
                '-',
                '+04'
            ]
        ],
        'Africa/Johannesburg': [
            [
                '-112',
                '-',
                'LMT',
                '-2458166400000'
            ],
            [
                '-90',
                '-',
                'SAST',
                '-2109283200000'
            ],
            [
                '-120',
                'SA',
                'SAST'
            ]
        ],
        'Africa/Maseru': 'Africa/Johannesburg',
        'Africa/Mbabane': 'Africa/Johannesburg',
        'Africa/Khartoum': [
            [
                '-130.13333333333333',
                '-',
                'LMT',
                '-1199318400000'
            ],
            [
                '-120',
                'Sudan',
                'CA%sT',
                '947937600000'
            ],
            [
                '-180',
                '-',
                'EAT',
                '1509494400000'
            ],
            [
                '-120',
                '-',
                'CAT'
            ]
        ],
        'Africa/Juba': [
            [
                '-126.46666666666667',
                '-',
                'LMT',
                '-1199318400000'
            ],
            [
                '-120',
                'Sudan',
                'CA%sT',
                '947937600000'
            ],
            [
                '-180',
                '-',
                'EAT'
            ]
        ],
        'Africa/Tunis': [
            [
                '-40.733333333333334',
                '-',
                'LMT',
                '-2797200000000'
            ],
            [
                '-9.35',
                '-',
                'PMT',
                '-1855958400000'
            ],
            [
                '-60',
                'Tunisia',
                'CE%sT'
            ]
        ],
        'Antarctica/Casey': [
            [
                '0',
                '-',
                '-00',
                '-86400000'
            ],
            [
                '-480',
                '-',
                '+08',
                '1255831200000'
            ],
            [
                '-660',
                '-',
                '+11',
                '1267754400000'
            ],
            [
                '-480',
                '-',
                '+08',
                '1319767200000'
            ],
            [
                '-660',
                '-',
                '+11',
                '1329843600000'
            ],
            [
                '-480',
                '-',
                '+08',
                '1477094400000'
            ],
            [
                '-660',
                '-',
                '+11',
                '1520740800000'
            ],
            [
                '-480',
                '-',
                '+08'
            ]
        ],
        'Antarctica/Davis': [
            [
                '0',
                '-',
                '-00',
                '-409190400000'
            ],
            [
                '-420',
                '-',
                '+07',
                '-163036800000'
            ],
            [
                '0',
                '-',
                '-00',
                '-28857600000'
            ],
            [
                '-420',
                '-',
                '+07',
                '1255831200000'
            ],
            [
                '-300',
                '-',
                '+05',
                '1268251200000'
            ],
            [
                '-420',
                '-',
                '+07',
                '1319767200000'
            ],
            [
                '-300',
                '-',
                '+05',
                '1329854400000'
            ],
            [
                '-420',
                '-',
                '+07'
            ]
        ],
        'Antarctica/Mawson': [
            [
                '0',
                '-',
                '-00',
                '-501206400000'
            ],
            [
                '-360',
                '-',
                '+06',
                '1255831200000'
            ],
            [
                '-300',
                '-',
                '+05'
            ]
        ],
        'Indian/Kerguelen': [
            [
                '0',
                '-',
                '-00',
                '-599702400000'
            ],
            [
                '-300',
                '-',
                '+05'
            ]
        ],
        'Antarctica/DumontDUrville': [
            [
                '0',
                '-',
                '-00',
                '-694396800000'
            ],
            [
                '-600',
                '-',
                '+10',
                '-566956800000'
            ],
            [
                '0',
                '-',
                '-00',
                '-415497600000'
            ],
            [
                '-600',
                '-',
                '+10'
            ]
        ],
        'Antarctica/Syowa': [
            [
                '0',
                '-',
                '-00',
                '-407808000000'
            ],
            [
                '-180',
                '-',
                '+03'
            ]
        ],
        'Antarctica/Troll': [
            [
                '0',
                '-',
                '-00',
                '1108166400000'
            ],
            [
                '0',
                'Troll',
                '%s'
            ]
        ],
        'Antarctica/Vostok': [
            [
                '0',
                '-',
                '-00',
                '-380073600000'
            ],
            [
                '-360',
                '-',
                '+06'
            ]
        ],
        'Antarctica/Rothera': [
            [
                '0',
                '-',
                '-00',
                '218246400000'
            ],
            [
                '180',
                '-',
                '-03'
            ]
        ],
        'Asia/Kabul': [
            [
                '-276.8',
                '-',
                'LMT',
                '-2493072000000'
            ],
            [
                '-240',
                '-',
                '+04',
                '-757468800000'
            ],
            [
                '-270',
                '-',
                '+0430'
            ]
        ],
        'Asia/Yerevan': [
            [
                '-178',
                '-',
                'LMT',
                '-1441152000000'
            ],
            [
                '-180',
                '-',
                '+03',
                '-405129600000'
            ],
            [
                '-240',
                'RussiaAsia',
                '+04/+05',
                '670384800000'
            ],
            [
                '-180',
                'RussiaAsia',
                '+03/+04',
                '811908000000'
            ],
            [
                '-240',
                '-',
                '+04',
                '883526400000'
            ],
            [
                '-240',
                'RussiaAsia',
                '+04/+05',
                '1325289600000'
            ],
            [
                '-240',
                'Armenia',
                '+04/+05'
            ]
        ],
        'Asia/Baku': [
            [
                '-199.4',
                '-',
                'LMT',
                '-1441152000000'
            ],
            [
                '-180',
                '-',
                '+03',
                '-405129600000'
            ],
            [
                '-240',
                'RussiaAsia',
                '+04/+05',
                '670384800000'
            ],
            [
                '-180',
                'RussiaAsia',
                '+03/+04',
                '715312800000'
            ],
            [
                '-240',
                '-',
                '+04',
                '851990400000'
            ],
            [
                '-240',
                'EUAsia',
                '+04/+05',
                '883526400000'
            ],
            [
                '-240',
                'Azer',
                '+04/+05'
            ]
        ],
        'Asia/Dhaka': [
            [
                '-361.6666666666667',
                '-',
                'LMT',
                '-2493072000000'
            ],
            [
                '-353.3333333333333',
                '-',
                'HMT',
                '-891561600000'
            ],
            [
                '-390',
                '-',
                '+0630',
                '-872035200000'
            ],
            [
                '-330',
                '-',
                '+0530',
                '-862617600000'
            ],
            [
                '-390',
                '-',
                '+0630',
                '-576115200000'
            ],
            [
                '-360',
                '-',
                '+06',
                '1262217600000'
            ],
            [
                '-360',
                'Dhaka',
                '+06/+07'
            ]
        ],
        'Asia/Thimphu': [
            [
                '-358.6',
                '-',
                'LMT',
                '-706320000000'
            ],
            [
                '-330',
                '-',
                '+0530',
                '560044800000'
            ],
            [
                '-360',
                '-',
                '+06'
            ]
        ],
        'Indian/Chagos': [
            [
                '-289.6666666666667',
                '-',
                'LMT',
                '-1956700800000'
            ],
            [
                '-300',
                '-',
                '+05',
                '851990400000'
            ],
            [
                '-360',
                '-',
                '+06'
            ]
        ],
        'Asia/Brunei': [
            [
                '-459.6666666666667',
                '-',
                'LMT',
                '-1383436800000'
            ],
            [
                '-450',
                '-',
                '+0730',
                '-1136160000000'
            ],
            [
                '-480',
                '-',
                '+08'
            ]
        ],
        'Asia/Yangon': [
            [
                '-384.7833333333333',
                '-',
                'LMT',
                '-2808604800000'
            ],
            [
                '-384.7833333333333',
                '-',
                'RMT',
                '-1546387200000'
            ],
            [
                '-390',
                '-',
                '+0630',
                '-873244800000'
            ],
            [
                '-540',
                '-',
                '+09',
                '-778377600000'
            ],
            [
                '-390',
                '-',
                '+0630'
            ]
        ],
        'Asia/Shanghai': [
            [
                '-485.7166666666667',
                '-',
                'LMT',
                '-2146003200000'
            ],
            [
                '-480',
                'Shang',
                'C%sT',
                '-649987200000'
            ],
            [
                '-480',
                'PRC',
                'C%sT'
            ]
        ],
        'Asia/Urumqi': [
            [
                '-350.3333333333333',
                '-',
                'LMT',
                '-1293926400000'
            ],
            [
                '-360',
                '-',
                '+06'
            ]
        ],
        'Asia/Hong_Kong': [
            [
                '-456.7',
                '-',
                'LMT',
                '-2056663398000'
            ],
            [
                '-480',
                '-',
                'HKT',
                '-900882000000'
            ],
            [
                '-480',
                '1:00',
                'HKST',
                '-891547200000'
            ],
            [
                '-480',
                '0:30',
                'HKWT',
                '-884217600000'
            ],
            [
                '-540',
                '-',
                'JST',
                '-761176800000'
            ],
            [
                '-480',
                'HK',
                'HK%sT'
            ]
        ],
        'Asia/Taipei': [
            [
                '-486',
                '-',
                'LMT',
                '-2335219200000'
            ],
            [
                '-480',
                '-',
                'CST',
                '-1017792000000'
            ],
            [
                '-540',
                '-',
                'JST',
                '-766191600000'
            ],
            [
                '-480',
                'Taiwan',
                'C%sT'
            ]
        ],
        'Asia/Macau': [
            [
                '-454.1666666666667',
                '-',
                'LMT',
                '-2056665600000'
            ],
            [
                '-480',
                '-',
                'CST',
                '-884480400000'
            ],
            [
                '-540',
                'Macau',
                '+09/+10',
                '-765331200000'
            ],
            [
                '-480',
                'Macau',
                'C%sT'
            ]
        ],
        'Asia/Nicosia': [
            [
                '-133.46666666666667',
                '-',
                'LMT',
                '-1518912000000'
            ],
            [
                '-120',
                'Cyprus',
                'EE%sT',
                '904608000000'
            ],
            [
                '-120',
                'EUAsia',
                'EE%sT'
            ]
        ],
        'Asia/Famagusta': [
            [
                '-135.8',
                '-',
                'LMT',
                '-1518912000000'
            ],
            [
                '-120',
                'Cyprus',
                'EE%sT',
                '904608000000'
            ],
            [
                '-120',
                'EUAsia',
                'EE%sT',
                '1473292800000'
            ],
            [
                '-180',
                '-',
                '+03',
                '1509238800000'
            ],
            [
                '-120',
                'EUAsia',
                'EE%sT'
            ]
        ],
        'Europe/Nicosia': 'Asia/Nicosia',
        'Asia/Tbilisi': [
            [
                '-179.18333333333334',
                '-',
                'LMT',
                '-2808604800000'
            ],
            [
                '-179.18333333333334',
                '-',
                'TBMT',
                '-1441152000000'
            ],
            [
                '-180',
                '-',
                '+03',
                '-405129600000'
            ],
            [
                '-240',
                'RussiaAsia',
                '+04/+05',
                '670384800000'
            ],
            [
                '-180',
                'RussiaAsia',
                '+03/+04',
                '725760000000'
            ],
            [
                '-180',
                'E-EurAsia',
                '+03/+04',
                '778377600000'
            ],
            [
                '-240',
                'E-EurAsia',
                '+04/+05',
                '844128000000'
            ],
            [
                '-240',
                '1:00',
                '+05',
                '857174400000'
            ],
            [
                '-240',
                'E-EurAsia',
                '+04/+05',
                '1088294400000'
            ],
            [
                '-180',
                'RussiaAsia',
                '+03/+04',
                '1109642400000'
            ],
            [
                '-240',
                '-',
                '+04'
            ]
        ],
        'Asia/Dili': [
            [
                '-502.3333333333333',
                '-',
                'LMT',
                '-1830384000000'
            ],
            [
                '-480',
                '-',
                '+08',
                '-879123600000'
            ],
            [
                '-540',
                '-',
                '+09',
                '199929600000'
            ],
            [
                '-480',
                '-',
                '+08',
                '969148800000'
            ],
            [
                '-540',
                '-',
                '+09'
            ]
        ],
        'Asia/Kolkata': [
            [
                '-353.4666666666667',
                '-',
                'LMT',
                '-3645216000000'
            ],
            [
                '-353.3333333333333',
                '-',
                'HMT',
                '-3124224000000'
            ],
            [
                '-321.1666666666667',
                '-',
                'MMT',
                '-2019686400000'
            ],
            [
                '-330',
                '-',
                'IST',
                '-891561600000'
            ],
            [
                '-330',
                '1:00',
                '+0630',
                '-872035200000'
            ],
            [
                '-330',
                '-',
                'IST',
                '-862617600000'
            ],
            [
                '-330',
                '1:00',
                '+0630',
                '-764121600000'
            ],
            [
                '-330',
                '-',
                'IST'
            ]
        ],
        'Asia/Jakarta': [
            [
                '-427.2',
                '-',
                'LMT',
                '-3231273600000'
            ],
            [
                '-427.2',
                '-',
                'BMT',
                '-1451693568000'
            ],
            [
                '-440',
                '-',
                '+0720',
                '-1172880000000'
            ],
            [
                '-450',
                '-',
                '+0730',
                '-876614400000'
            ],
            [
                '-540',
                '-',
                '+09',
                '-766022400000'
            ],
            [
                '-450',
                '-',
                '+0730',
                '-683856000000'
            ],
            [
                '-480',
                '-',
                '+08',
                '-620784000000'
            ],
            [
                '-450',
                '-',
                '+0730',
                '-157852800000'
            ],
            [
                '-420',
                '-',
                'WIB'
            ]
        ],
        'Asia/Pontianak': [
            [
                '-437.3333333333333',
                '-',
                'LMT',
                '-1946160000000'
            ],
            [
                '-437.3333333333333',
                '-',
                'PMT',
                '-1172880000000'
            ],
            [
                '-450',
                '-',
                '+0730',
                '-881193600000'
            ],
            [
                '-540',
                '-',
                '+09',
                '-766022400000'
            ],
            [
                '-450',
                '-',
                '+0730',
                '-683856000000'
            ],
            [
                '-480',
                '-',
                '+08',
                '-620784000000'
            ],
            [
                '-450',
                '-',
                '+0730',
                '-157852800000'
            ],
            [
                '-480',
                '-',
                'WITA',
                '567993600000'
            ],
            [
                '-420',
                '-',
                'WIB'
            ]
        ],
        'Asia/Makassar': [
            [
                '-477.6',
                '-',
                'LMT',
                '-1546387200000'
            ],
            [
                '-477.6',
                '-',
                'MMT',
                '-1172880000000'
            ],
            [
                '-480',
                '-',
                '+08',
                '-880243200000'
            ],
            [
                '-540',
                '-',
                '+09',
                '-766022400000'
            ],
            [
                '-480',
                '-',
                'WITA'
            ]
        ],
        'Asia/Jayapura': [
            [
                '-562.8',
                '-',
                'LMT',
                '-1172880000000'
            ],
            [
                '-540',
                '-',
                '+09',
                '-799459200000'
            ],
            [
                '-570',
                '-',
                '+0930',
                '-157852800000'
            ],
            [
                '-540',
                '-',
                'WIT'
            ]
        ],
        'Asia/Tehran': [
            [
                '-205.73333333333335',
                '-',
                'LMT',
                '-1672617600000'
            ],
            [
                '-205.73333333333335',
                '-',
                'TMT',
                '-725932800000'
            ],
            [
                '-210',
                '-',
                '+0330',
                '247190400000'
            ],
            [
                '-240',
                'Iran',
                '+04/+05',
                '315446400000'
            ],
            [
                '-210',
                'Iran',
                '+0330/+0430'
            ]
        ],
        'Asia/Baghdad': [
            [
                '-177.66666666666666',
                '-',
                'LMT',
                '-2493072000000'
            ],
            [
                '-177.6',
                '-',
                'BMT',
                '-1609545600000'
            ],
            [
                '-180',
                '-',
                '+03',
                '389059200000'
            ],
            [
                '-180',
                'Iraq',
                '+03/+04'
            ]
        ],
        'Asia/Jerusalem': [
            [
                '-140.9',
                '-',
                'LMT',
                '-2808604800000'
            ],
            [
                '-140.66666666666666',
                '-',
                'JMT',
                '-1609545600000'
            ],
            [
                '-120',
                'Zion',
                'I%sT'
            ]
        ],
        'Asia/Tokyo': [
            [
                '-558.9833333333333',
                '-',
                'LMT',
                '-2587712400000'
            ],
            [
                '-540',
                'Japan',
                'J%sT'
            ]
        ],
        'Asia/Amman': [
            [
                '-143.73333333333335',
                '-',
                'LMT',
                '-1199318400000'
            ],
            [
                '-120',
                'Jordan',
                'EE%sT'
            ]
        ],
        'Asia/Almaty': [
            [
                '-307.8',
                '-',
                'LMT',
                '-1441152000000'
            ],
            [
                '-300',
                '-',
                '+05',
                '-1247529600000'
            ],
            [
                '-360',
                'RussiaAsia',
                '+06/+07',
                '670384800000'
            ],
            [
                '-300',
                'RussiaAsia',
                '+05/+06',
                '695786400000'
            ],
            [
                '-360',
                'RussiaAsia',
                '+06/+07',
                '1099188000000'
            ],
            [
                '-360',
                '-',
                '+06'
            ]
        ],
        'Asia/Qyzylorda': [
            [
                '-261.8666666666667',
                '-',
                'LMT',
                '-1441152000000'
            ],
            [
                '-240',
                '-',
                '+04',
                '-1247529600000'
            ],
            [
                '-300',
                '-',
                '+05',
                '354931200000'
            ],
            [
                '-300',
                '1:00',
                '+06',
                '370742400000'
            ],
            [
                '-360',
                '-',
                '+06',
                '386467200000'
            ],
            [
                '-300',
                'RussiaAsia',
                '+05/+06',
                '670384800000'
            ],
            [
                '-240',
                'RussiaAsia',
                '+04/+05',
                '686109600000'
            ],
            [
                '-300',
                'RussiaAsia',
                '+05/+06',
                '695786400000'
            ],
            [
                '-360',
                'RussiaAsia',
                '+06/+07',
                '701834400000'
            ],
            [
                '-300',
                'RussiaAsia',
                '+05/+06',
                '1099188000000'
            ],
            [
                '-360',
                '-',
                '+06',
                '1545350400000'
            ],
            [
                '-300',
                '-',
                '+05'
            ]
        ],
        'Asia/Qostanay': [
            [
                '-254.46666666666667',
                '-',
                'LMT',
                '-1441152000000'
            ],
            [
                '-240',
                '-',
                '+04',
                '-1247529600000'
            ],
            [
                '-300',
                '-',
                '+05',
                '354931200000'
            ],
            [
                '-300',
                '1:00',
                '+06',
                '370742400000'
            ],
            [
                '-360',
                '-',
                '+06',
                '386467200000'
            ],
            [
                '-300',
                'RussiaAsia',
                '+05/+06',
                '670384800000'
            ],
            [
                '-240',
                'RussiaAsia',
                '+04/+05',
                '695786400000'
            ],
            [
                '-300',
                'RussiaAsia',
                '+05/+06',
                '1099188000000'
            ],
            [
                '-360',
                '-',
                '+06'
            ]
        ],
        'Asia/Aqtobe': [
            [
                '-228.66666666666666',
                '-',
                'LMT',
                '-1441152000000'
            ],
            [
                '-240',
                '-',
                '+04',
                '-1247529600000'
            ],
            [
                '-300',
                '-',
                '+05',
                '354931200000'
            ],
            [
                '-300',
                '1:00',
                '+06',
                '370742400000'
            ],
            [
                '-360',
                '-',
                '+06',
                '386467200000'
            ],
            [
                '-300',
                'RussiaAsia',
                '+05/+06',
                '670384800000'
            ],
            [
                '-240',
                'RussiaAsia',
                '+04/+05',
                '695786400000'
            ],
            [
                '-300',
                'RussiaAsia',
                '+05/+06',
                '1099188000000'
            ],
            [
                '-300',
                '-',
                '+05'
            ]
        ],
        'Asia/Aqtau': [
            [
                '-201.06666666666666',
                '-',
                'LMT',
                '-1441152000000'
            ],
            [
                '-240',
                '-',
                '+04',
                '-1247529600000'
            ],
            [
                '-300',
                '-',
                '+05',
                '370742400000'
            ],
            [
                '-360',
                '-',
                '+06',
                '386467200000'
            ],
            [
                '-300',
                'RussiaAsia',
                '+05/+06',
                '670384800000'
            ],
            [
                '-240',
                'RussiaAsia',
                '+04/+05',
                '695786400000'
            ],
            [
                '-300',
                'RussiaAsia',
                '+05/+06',
                '780458400000'
            ],
            [
                '-240',
                'RussiaAsia',
                '+04/+05',
                '1099188000000'
            ],
            [
                '-300',
                '-',
                '+05'
            ]
        ],
        'Asia/Atyrau': [
            [
                '-207.73333333333335',
                '-',
                'LMT',
                '-1441152000000'
            ],
            [
                '-180',
                '-',
                '+03',
                '-1247529600000'
            ],
            [
                '-300',
                '-',
                '+05',
                '370742400000'
            ],
            [
                '-360',
                '-',
                '+06',
                '386467200000'
            ],
            [
                '-300',
                'RussiaAsia',
                '+05/+06',
                '670384800000'
            ],
            [
                '-240',
                'RussiaAsia',
                '+04/+05',
                '695786400000'
            ],
            [
                '-300',
                'RussiaAsia',
                '+05/+06',
                '922586400000'
            ],
            [
                '-240',
                'RussiaAsia',
                '+04/+05',
                '1099188000000'
            ],
            [
                '-300',
                '-',
                '+05'
            ]
        ],
        'Asia/Oral': [
            [
                '-205.4',
                '-',
                'LMT',
                '-1441152000000'
            ],
            [
                '-180',
                '-',
                '+03',
                '-1247529600000'
            ],
            [
                '-300',
                '-',
                '+05',
                '354931200000'
            ],
            [
                '-300',
                '1:00',
                '+06',
                '370742400000'
            ],
            [
                '-360',
                '-',
                '+06',
                '386467200000'
            ],
            [
                '-300',
                'RussiaAsia',
                '+05/+06',
                '606880800000'
            ],
            [
                '-240',
                'RussiaAsia',
                '+04/+05',
                '695786400000'
            ],
            [
                '-300',
                'RussiaAsia',
                '+05/+06',
                '701834400000'
            ],
            [
                '-240',
                'RussiaAsia',
                '+04/+05',
                '1099188000000'
            ],
            [
                '-300',
                '-',
                '+05'
            ]
        ],
        'Asia/Bishkek': [
            [
                '-298.4',
                '-',
                'LMT',
                '-1441152000000'
            ],
            [
                '-300',
                '-',
                '+05',
                '-1247529600000'
            ],
            [
                '-360',
                'RussiaAsia',
                '+06/+07',
                '670384800000'
            ],
            [
                '-300',
                'RussiaAsia',
                '+05/+06',
                '683604000000'
            ],
            [
                '-300',
                'Kyrgyz',
                '+05/+06',
                '1123804800000'
            ],
            [
                '-360',
                '-',
                '+06'
            ]
        ],
        'Asia/Seoul': [
            [
                '-507.8666666666667',
                '-',
                'LMT',
                '-1948752000000'
            ],
            [
                '-510',
                '-',
                'KST',
                '-1830384000000'
            ],
            [
                '-540',
                '-',
                'JST',
                '-767318400000'
            ],
            [
                '-540',
                'ROK',
                'K%sT',
                '-498096000000'
            ],
            [
                '-510',
                'ROK',
                'K%sT',
                '-264902400000'
            ],
            [
                '-540',
                'ROK',
                'K%sT'
            ]
        ],
        'Asia/Pyongyang': [
            [
                '-503',
                '-',
                'LMT',
                '-1948752000000'
            ],
            [
                '-510',
                '-',
                'KST',
                '-1830384000000'
            ],
            [
                '-540',
                '-',
                'JST',
                '-768614400000'
            ],
            [
                '-540',
                '-',
                'KST',
                '1439596800000'
            ],
            [
                '-510',
                '-',
                'KST',
                '1525476600000'
            ],
            [
                '-540',
                '-',
                'KST'
            ]
        ],
        'Asia/Beirut': [
            [
                '-142',
                '-',
                'LMT',
                '-2808604800000'
            ],
            [
                '-120',
                'Lebanon',
                'EE%sT'
            ]
        ],
        'Asia/Kuala_Lumpur': [
            [
                '-406.7666666666667',
                '-',
                'LMT',
                '-2177452800000'
            ],
            [
                '-415.4166666666667',
                '-',
                'SMT',
                '-2038176000000'
            ],
            [
                '-420',
                '-',
                '+07',
                '-1167609600000'
            ],
            [
                '-420',
                '0:20',
                '+0720',
                '-1073001600000'
            ],
            [
                '-440',
                '-',
                '+0720',
                '-894153600000'
            ],
            [
                '-450',
                '-',
                '+0730',
                '-879638400000'
            ],
            [
                '-540',
                '-',
                '+09',
                '-766972800000'
            ],
            [
                '-450',
                '-',
                '+0730',
                '378691200000'
            ],
            [
                '-480',
                '-',
                '+08'
            ]
        ],
        'Asia/Kuching': [
            [
                '-441.3333333333333',
                '-',
                'LMT',
                '-1383436800000'
            ],
            [
                '-450',
                '-',
                '+0730',
                '-1136160000000'
            ],
            [
                '-480',
                'NBorneo',
                '+08/+0820',
                '-879638400000'
            ],
            [
                '-540',
                '-',
                '+09',
                '-766972800000'
            ],
            [
                '-480',
                '-',
                '+08'
            ]
        ],
        'Indian/Maldives': [
            [
                '-294',
                '-',
                'LMT',
                '-2808604800000'
            ],
            [
                '-294',
                '-',
                'MMT',
                '-284083200000'
            ],
            [
                '-300',
                '-',
                '+05'
            ]
        ],
        'Asia/Hovd': [
            [
                '-366.6',
                '-',
                'LMT',
                '-2032905600000'
            ],
            [
                '-360',
                '-',
                '+06',
                '283910400000'
            ],
            [
                '-420',
                'Mongol',
                '+07/+08'
            ]
        ],
        'Asia/Ulaanbaatar': [
            [
                '-427.5333333333333',
                '-',
                'LMT',
                '-2032905600000'
            ],
            [
                '-420',
                '-',
                '+07',
                '283910400000'
            ],
            [
                '-480',
                'Mongol',
                '+08/+09'
            ]
        ],
        'Asia/Choibalsan': [
            [
                '-458',
                '-',
                'LMT',
                '-2032905600000'
            ],
            [
                '-420',
                '-',
                '+07',
                '283910400000'
            ],
            [
                '-480',
                '-',
                '+08',
                '418003200000'
            ],
            [
                '-540',
                'Mongol',
                '+09/+10',
                '1206921600000'
            ],
            [
                '-480',
                'Mongol',
                '+08/+09'
            ]
        ],
        'Asia/Kathmandu': [
            [
                '-341.2666666666667',
                '-',
                'LMT',
                '-1546387200000'
            ],
            [
                '-330',
                '-',
                '+0530',
                '536371200000'
            ],
            [
                '-345',
                '-',
                '+0545'
            ]
        ],
        'Asia/Karachi': [
            [
                '-268.2',
                '-',
                'LMT',
                '-1956700800000'
            ],
            [
                '-330',
                '-',
                '+0530',
                '-862617600000'
            ],
            [
                '-330',
                '1:00',
                '+0630',
                '-764121600000'
            ],
            [
                '-330',
                '-',
                '+0530',
                '-576115200000'
            ],
            [
                '-300',
                '-',
                '+05',
                '38793600000'
            ],
            [
                '-300',
                'Pakistan',
                'PK%sT'
            ]
        ],
        'Asia/Gaza': [
            [
                '-137.86666666666665',
                '-',
                'LMT',
                '-2185401600000'
            ],
            [
                '-120',
                'Zion',
                'EET/EEST',
                '-682646400000'
            ],
            [
                '-120',
                'EgyptAsia',
                'EE%sT',
                '-81302400000'
            ],
            [
                '-120',
                'Zion',
                'I%sT',
                '851990400000'
            ],
            [
                '-120',
                'Jordan',
                'EE%sT',
                '946598400000'
            ],
            [
                '-120',
                'Palestine',
                'EE%sT',
                '1219968000000'
            ],
            [
                '-120',
                '-',
                'EET',
                '1220227200000'
            ],
            [
                '-120',
                'Palestine',
                'EE%sT',
                '1293753600000'
            ],
            [
                '-120',
                '-',
                'EET',
                '1269648060000'
            ],
            [
                '-120',
                'Palestine',
                'EE%sT',
                '1312156800000'
            ],
            [
                '-120',
                '-',
                'EET',
                '1356912000000'
            ],
            [
                '-120',
                'Palestine',
                'EE%sT'
            ]
        ],
        'Asia/Hebron': [
            [
                '-140.38333333333335',
                '-',
                'LMT',
                '-2185401600000'
            ],
            [
                '-120',
                'Zion',
                'EET/EEST',
                '-682646400000'
            ],
            [
                '-120',
                'EgyptAsia',
                'EE%sT',
                '-81302400000'
            ],
            [
                '-120',
                'Zion',
                'I%sT',
                '851990400000'
            ],
            [
                '-120',
                'Jordan',
                'EE%sT',
                '946598400000'
            ],
            [
                '-120',
                'Palestine',
                'EE%sT'
            ]
        ],
        'Asia/Manila': [
            [
                '956',
                '-',
                'LMT',
                '-3944678400000'
            ],
            [
                '-484',
                '-',
                'LMT',
                '-2229292800000'
            ],
            [
                '-480',
                'Phil',
                'P%sT',
                '-873244800000'
            ],
            [
                '-540',
                '-',
                'JST',
                '-794188800000'
            ],
            [
                '-480',
                'Phil',
                'P%sT'
            ]
        ],
        'Asia/Qatar': [
            [
                '-206.13333333333335',
                '-',
                'LMT',
                '-1546387200000'
            ],
            [
                '-240',
                '-',
                '+04',
                '76204800000'
            ],
            [
                '-180',
                '-',
                '+03'
            ]
        ],
        'Asia/Bahrain': 'Asia/Qatar',
        'Asia/Riyadh': [
            [
                '-186.86666666666665',
                '-',
                'LMT',
                '-719625600000'
            ],
            [
                '-180',
                '-',
                '+03'
            ]
        ],
        'Asia/Aden': 'Asia/Riyadh',
        'Asia/Kuwait': 'Asia/Riyadh',
        'Asia/Singapore': [
            [
                '-415.4166666666667',
                '-',
                'LMT',
                '-2177452800000'
            ],
            [
                '-415.4166666666667',
                '-',
                'SMT',
                '-2038176000000'
            ],
            [
                '-420',
                '-',
                '+07',
                '-1167609600000'
            ],
            [
                '-420',
                '0:20',
                '+0720',
                '-1073001600000'
            ],
            [
                '-440',
                '-',
                '+0720',
                '-894153600000'
            ],
            [
                '-450',
                '-',
                '+0730',
                '-879638400000'
            ],
            [
                '-540',
                '-',
                '+09',
                '-766972800000'
            ],
            [
                '-450',
                '-',
                '+0730',
                '378691200000'
            ],
            [
                '-480',
                '-',
                '+08'
            ]
        ],
        'Asia/Colombo': [
            [
                '-319.4',
                '-',
                'LMT',
                '-2808604800000'
            ],
            [
                '-319.5333333333333',
                '-',
                'MMT',
                '-1988236800000'
            ],
            [
                '-330',
                '-',
                '+0530',
                '-883267200000'
            ],
            [
                '-330',
                '0:30',
                '+06',
                '-862617600000'
            ],
            [
                '-330',
                '1:00',
                '+0630',
                '-764028000000'
            ],
            [
                '-330',
                '-',
                '+0530',
                '832982400000'
            ],
            [
                '-390',
                '-',
                '+0630',
                '846289800000'
            ],
            [
                '-360',
                '-',
                '+06',
                '1145061000000'
            ],
            [
                '-330',
                '-',
                '+0530'
            ]
        ],
        'Asia/Damascus': [
            [
                '-145.2',
                '-',
                'LMT',
                '-1546387200000'
            ],
            [
                '-120',
                'Syria',
                'EE%sT'
            ]
        ],
        'Asia/Dushanbe': [
            [
                '-275.2',
                '-',
                'LMT',
                '-1441152000000'
            ],
            [
                '-300',
                '-',
                '+05',
                '-1247529600000'
            ],
            [
                '-360',
                'RussiaAsia',
                '+06/+07',
                '670384800000'
            ],
            [
                '-300',
                '1:00',
                '+05/+06',
                '684381600000'
            ],
            [
                '-300',
                '-',
                '+05'
            ]
        ],
        'Asia/Bangkok': [
            [
                '-402.06666666666666',
                '-',
                'LMT',
                '-2808604800000'
            ],
            [
                '-402.06666666666666',
                '-',
                'BMT',
                '-1570060800000'
            ],
            [
                '-420',
                '-',
                '+07'
            ]
        ],
        'Asia/Phnom_Penh': 'Asia/Bangkok',
        'Asia/Vientiane': 'Asia/Bangkok',
        'Asia/Ashgabat': [
            [
                '-233.53333333333333',
                '-',
                'LMT',
                '-1441152000000'
            ],
            [
                '-240',
                '-',
                '+04',
                '-1247529600000'
            ],
            [
                '-300',
                'RussiaAsia',
                '+05/+06',
                '670384800000'
            ],
            [
                '-240',
                'RussiaAsia',
                '+04/+05',
                '695786400000'
            ],
            [
                '-300',
                '-',
                '+05'
            ]
        ],
        'Asia/Dubai': [
            [
                '-221.2',
                '-',
                'LMT',
                '-1546387200000'
            ],
            [
                '-240',
                '-',
                '+04'
            ]
        ],
        'Asia/Muscat': 'Asia/Dubai',
        'Asia/Samarkand': [
            [
                '-267.8833333333333',
                '-',
                'LMT',
                '-1441152000000'
            ],
            [
                '-240',
                '-',
                '+04',
                '-1247529600000'
            ],
            [
                '-300',
                '-',
                '+05',
                '354931200000'
            ],
            [
                '-300',
                '1:00',
                '+06',
                '370742400000'
            ],
            [
                '-360',
                '-',
                '+06',
                '386467200000'
            ],
            [
                '-300',
                'RussiaAsia',
                '+05/+06',
                '725760000000'
            ],
            [
                '-300',
                '-',
                '+05'
            ]
        ],
        'Asia/Tashkent': [
            [
                '-277.18333333333334',
                '-',
                'LMT',
                '-1441152000000'
            ],
            [
                '-300',
                '-',
                '+05',
                '-1247529600000'
            ],
            [
                '-360',
                'RussiaAsia',
                '+06/+07',
                '670384800000'
            ],
            [
                '-300',
                'RussiaAsia',
                '+05/+06',
                '725760000000'
            ],
            [
                '-300',
                '-',
                '+05'
            ]
        ],
        'Asia/Ho_Chi_Minh': [
            [
                '-426.6666666666667',
                '-',
                'LMT',
                '-2004048000000'
            ],
            [
                '-426.5',
                '-',
                'PLMT',
                '-1851552000000'
            ],
            [
                '-420',
                '-',
                '+07',
                '-852080400000'
            ],
            [
                '-480',
                '-',
                '+08',
                '-782614800000'
            ],
            [
                '-540',
                '-',
                '+09',
                '-767836800000'
            ],
            [
                '-420',
                '-',
                '+07',
                '-718070400000'
            ],
            [
                '-480',
                '-',
                '+08',
                '-457747200000'
            ],
            [
                '-420',
                '-',
                '+07',
                '-315622800000'
            ],
            [
                '-480',
                '-',
                '+08',
                '171849600000'
            ],
            [
                '-420',
                '-',
                '+07'
            ]
        ],
        'Australia/Darwin': [
            [
                '-523.3333333333333',
                '-',
                'LMT',
                '-2364076800000'
            ],
            [
                '-540',
                '-',
                'ACST',
                '-2230156800000'
            ],
            [
                '-570',
                'Aus',
                'AC%sT'
            ]
        ],
        'Australia/Perth': [
            [
                '-463.4',
                '-',
                'LMT',
                '-2337897600000'
            ],
            [
                '-480',
                'Aus',
                'AW%sT',
                '-836438400000'
            ],
            [
                '-480',
                'AW',
                'AW%sT'
            ]
        ],
        'Australia/Eucla': [
            [
                '-515.4666666666667',
                '-',
                'LMT',
                '-2337897600000'
            ],
            [
                '-525',
                'Aus',
                '+0845/+0945',
                '-836438400000'
            ],
            [
                '-525',
                'AW',
                '+0845/+0945'
            ]
        ],
        'Australia/Brisbane': [
            [
                '-612.1333333333333',
                '-',
                'LMT',
                '-2335305600000'
            ],
            [
                '-600',
                'Aus',
                'AE%sT',
                '62985600000'
            ],
            [
                '-600',
                'AQ',
                'AE%sT'
            ]
        ],
        'Australia/Lindeman': [
            [
                '-595.9333333333334',
                '-',
                'LMT',
                '-2335305600000'
            ],
            [
                '-600',
                'Aus',
                'AE%sT',
                '62985600000'
            ],
            [
                '-600',
                'AQ',
                'AE%sT',
                '709948800000'
            ],
            [
                '-600',
                'Holiday',
                'AE%sT'
            ]
        ],
        'Australia/Adelaide': [
            [
                '-554.3333333333334',
                '-',
                'LMT',
                '-2364076800000'
            ],
            [
                '-540',
                '-',
                'ACST',
                '-2230156800000'
            ],
            [
                '-570',
                'Aus',
                'AC%sT',
                '62985600000'
            ],
            [
                '-570',
                'AS',
                'AC%sT'
            ]
        ],
        'Australia/Hobart': [
            [
                '-589.2666666666667',
                '-',
                'LMT',
                '-2345760000000'
            ],
            [
                '-600',
                '-',
                'AEST',
                '-1680472800000'
            ],
            [
                '-600',
                '1:00',
                'AEDT',
                '-1669852800000'
            ],
            [
                '-600',
                'Aus',
                'AE%sT',
                '-63244800000'
            ],
            [
                '-600',
                'AT',
                'AE%sT'
            ]
        ],
        'Australia/Currie': [
            [
                '-575.4666666666666',
                '-',
                'LMT',
                '-2345760000000'
            ],
            [
                '-600',
                '-',
                'AEST',
                '-1680472800000'
            ],
            [
                '-600',
                '1:00',
                'AEDT',
                '-1669852800000'
            ],
            [
                '-600',
                'Aus',
                'AE%sT',
                '47174400000'
            ],
            [
                '-600',
                'AT',
                'AE%sT'
            ]
        ],
        'Australia/Melbourne': [
            [
                '-579.8666666666667',
                '-',
                'LMT',
                '-2364076800000'
            ],
            [
                '-600',
                'Aus',
                'AE%sT',
                '62985600000'
            ],
            [
                '-600',
                'AV',
                'AE%sT'
            ]
        ],
        'Australia/Sydney': [
            [
                '-604.8666666666667',
                '-',
                'LMT',
                '-2364076800000'
            ],
            [
                '-600',
                'Aus',
                'AE%sT',
                '62985600000'
            ],
            [
                '-600',
                'AN',
                'AE%sT'
            ]
        ],
        'Australia/Broken_Hill': [
            [
                '-565.8',
                '-',
                'LMT',
                '-2364076800000'
            ],
            [
                '-600',
                '-',
                'AEST',
                '-2314915200000'
            ],
            [
                '-540',
                '-',
                'ACST',
                '-2230156800000'
            ],
            [
                '-570',
                'Aus',
                'AC%sT',
                '62985600000'
            ],
            [
                '-570',
                'AN',
                'AC%sT',
                '978220800000'
            ],
            [
                '-570',
                'AS',
                'AC%sT'
            ]
        ],
        'Australia/Lord_Howe': [
            [
                '-636.3333333333334',
                '-',
                'LMT',
                '-2364076800000'
            ],
            [
                '-600',
                '-',
                'AEST',
                '352252800000'
            ],
            [
                '-630',
                'LH',
                '+1030/+1130',
                '489024000000'
            ],
            [
                '-630',
                'LH',
                '+1030/+11'
            ]
        ],
        'Antarctica/Macquarie': [
            [
                '0',
                '-',
                '-00',
                '-2214259200000'
            ],
            [
                '-600',
                '-',
                'AEST',
                '-1680472800000'
            ],
            [
                '-600',
                '1:00',
                'AEDT',
                '-1669852800000'
            ],
            [
                '-600',
                'Aus',
                'AE%sT',
                '-1601683200000'
            ],
            [
                '0',
                '-',
                '-00',
                '-687052800000'
            ],
            [
                '-600',
                'Aus',
                'AE%sT',
                '-63244800000'
            ],
            [
                '-600',
                'AT',
                'AE%sT',
                '1270350000000'
            ],
            [
                '-660',
                '-',
                '+11'
            ]
        ],
        'Indian/Christmas': [
            [
                '-422.8666666666667',
                '-',
                'LMT',
                '-2364076800000'
            ],
            [
                '-420',
                '-',
                '+07'
            ]
        ],
        'Indian/Cocos': [
            [
                '-387.6666666666667',
                '-',
                'LMT',
                '-2177539200000'
            ],
            [
                '-390',
                '-',
                '+0630'
            ]
        ],
        'Pacific/Fiji': [
            [
                '-715.7333333333333',
                '-',
                'LMT',
                '-1709942400000'
            ],
            [
                '-720',
                'Fiji',
                '+12/+13'
            ]
        ],
        'Pacific/Gambier': [
            [
                '539.8',
                '-',
                'LMT',
                '-1806710400000'
            ],
            [
                '540',
                '-',
                '-09'
            ]
        ],
        'Pacific/Marquesas': [
            [
                '558',
                '-',
                'LMT',
                '-1806710400000'
            ],
            [
                '570',
                '-',
                '-0930'
            ]
        ],
        'Pacific/Tahiti': [
            [
                '598.2666666666667',
                '-',
                'LMT',
                '-1806710400000'
            ],
            [
                '600',
                '-',
                '-10'
            ]
        ],
        'Pacific/Guam': [
            [
                '861',
                '-',
                'LMT',
                '-3944678400000'
            ],
            [
                '-579',
                '-',
                'LMT',
                '-2146003200000'
            ],
            [
                '-600',
                '-',
                'GST',
                '-885513600000'
            ],
            [
                '-540',
                '-',
                '+09',
                '-802224000000'
            ],
            [
                '-600',
                'Guam',
                'G%sT',
                '977529600000'
            ],
            [
                '-600',
                '-',
                'ChST'
            ]
        ],
        'Pacific/Saipan': 'Pacific/Guam',
        'Pacific/Tarawa': [
            [
                '-692.0666666666666',
                '-',
                'LMT',
                '-2146003200000'
            ],
            [
                '-720',
                '-',
                '+12'
            ]
        ],
        'Pacific/Enderbury': [
            [
                '684.3333333333334',
                '-',
                'LMT',
                '-2146003200000'
            ],
            [
                '720',
                '-',
                '-12',
                '307584000000'
            ],
            [
                '660',
                '-',
                '-11',
                '788832000000'
            ],
            [
                '-780',
                '-',
                '+13'
            ]
        ],
        'Pacific/Kiritimati': [
            [
                '629.3333333333334',
                '-',
                'LMT',
                '-2146003200000'
            ],
            [
                '640',
                '-',
                '-1040',
                '307584000000'
            ],
            [
                '600',
                '-',
                '-10',
                '788832000000'
            ],
            [
                '-840',
                '-',
                '+14'
            ]
        ],
        'Pacific/Majuro': [
            [
                '-684.8',
                '-',
                'LMT',
                '-2146003200000'
            ],
            [
                '-660',
                '-',
                '+11',
                '-1743638400000'
            ],
            [
                '-540',
                '-',
                '+09',
                '-1606780800000'
            ],
            [
                '-660',
                '-',
                '+11',
                '-1009929600000'
            ],
            [
                '-600',
                '-',
                '+10',
                '-907372800000'
            ],
            [
                '-540',
                '-',
                '+09',
                '-818035200000'
            ],
            [
                '-660',
                '-',
                '+11',
                '-7948800000'
            ],
            [
                '-720',
                '-',
                '+12'
            ]
        ],
        'Pacific/Kwajalein': [
            [
                '-669.3333333333334',
                '-',
                'LMT',
                '-2146003200000'
            ],
            [
                '-660',
                '-',
                '+11',
                '-1009929600000'
            ],
            [
                '-600',
                '-',
                '+10',
                '-907372800000'
            ],
            [
                '-540',
                '-',
                '+09',
                '-817430400000'
            ],
            [
                '-660',
                '-',
                '+11',
                '-7948800000'
            ],
            [
                '720',
                '-',
                '-12',
                '745891200000'
            ],
            [
                '-720',
                '-',
                '+12'
            ]
        ],
        'Pacific/Chuuk': [
            [
                '832.8666666666667',
                '-',
                'LMT',
                '-3944678400000'
            ],
            [
                '-607.1333333333333',
                '-',
                'LMT',
                '-2146003200000'
            ],
            [
                '-600',
                '-',
                '+10',
                '-1743638400000'
            ],
            [
                '-540',
                '-',
                '+09',
                '-1606780800000'
            ],
            [
                '-600',
                '-',
                '+10',
                '-907372800000'
            ],
            [
                '-540',
                '-',
                '+09',
                '-770601600000'
            ],
            [
                '-600',
                '-',
                '+10'
            ]
        ],
        'Pacific/Pohnpei': [
            [
                '807.1333333333333',
                '-',
                'LMT',
                '-3944678400000'
            ],
            [
                '-632.8666666666667',
                '-',
                'LMT',
                '-2146003200000'
            ],
            [
                '-660',
                '-',
                '+11',
                '-1743638400000'
            ],
            [
                '-540',
                '-',
                '+09',
                '-1606780800000'
            ],
            [
                '-660',
                '-',
                '+11',
                '-1009929600000'
            ],
            [
                '-600',
                '-',
                '+10',
                '-907372800000'
            ],
            [
                '-540',
                '-',
                '+09',
                '-770601600000'
            ],
            [
                '-660',
                '-',
                '+11'
            ]
        ],
        'Pacific/Kosrae': [
            [
                '788.0666666666666',
                '-',
                'LMT',
                '-3944678400000'
            ],
            [
                '-651.9333333333334',
                '-',
                'LMT',
                '-2146003200000'
            ],
            [
                '-660',
                '-',
                '+11',
                '-1743638400000'
            ],
            [
                '-540',
                '-',
                '+09',
                '-1606780800000'
            ],
            [
                '-660',
                '-',
                '+11',
                '-1009929600000'
            ],
            [
                '-600',
                '-',
                '+10',
                '-907372800000'
            ],
            [
                '-540',
                '-',
                '+09',
                '-770601600000'
            ],
            [
                '-660',
                '-',
                '+11',
                '-7948800000'
            ],
            [
                '-720',
                '-',
                '+12',
                '946598400000'
            ],
            [
                '-660',
                '-',
                '+11'
            ]
        ],
        'Pacific/Nauru': [
            [
                '-667.6666666666666',
                '-',
                'LMT',
                '-1545091200000'
            ],
            [
                '-690',
                '-',
                '+1130',
                '-862876800000'
            ],
            [
                '-540',
                '-',
                '+09',
                '-767318400000'
            ],
            [
                '-690',
                '-',
                '+1130',
                '287460000000'
            ],
            [
                '-720',
                '-',
                '+12'
            ]
        ],
        'Pacific/Noumea': [
            [
                '-665.8',
                '-',
                'LMT',
                '-1829347200000'
            ],
            [
                '-660',
                'NC',
                '+11/+12'
            ]
        ],
        'Pacific/Auckland': [
            [
                '-699.0666666666666',
                '-',
                'LMT',
                '-3192393600000'
            ],
            [
                '-690',
                'NZ',
                'NZ%sT',
                '-757382400000'
            ],
            [
                '-720',
                'NZ',
                'NZ%sT'
            ]
        ],
        'Pacific/Chatham': [
            [
                '-733.8',
                '-',
                'LMT',
                '-3192393600000'
            ],
            [
                '-735',
                '-',
                '+1215',
                '-757382400000'
            ],
            [
                '-765',
                'Chatham',
                '+1245/+1345'
            ]
        ],
        'Antarctica/McMurdo': 'Pacific/Auckland',
        'Pacific/Rarotonga': [
            [
                '639.0666666666666',
                '-',
                'LMT',
                '-2146003200000'
            ],
            [
                '630',
                '-',
                '-1030',
                '279676800000'
            ],
            [
                '600',
                'Cook',
                '-10/-0930'
            ]
        ],
        'Pacific/Niue': [
            [
                '679.6666666666666',
                '-',
                'LMT',
                '-2146003200000'
            ],
            [
                '680',
                '-',
                '-1120',
                '-568166400000'
            ],
            [
                '690',
                '-',
                '-1130',
                '276048000000'
            ],
            [
                '660',
                '-',
                '-11'
            ]
        ],
        'Pacific/Norfolk': [
            [
                '-671.8666666666667',
                '-',
                'LMT',
                '-2146003200000'
            ],
            [
                '-672',
                '-',
                '+1112',
                '-568166400000'
            ],
            [
                '-690',
                '-',
                '+1130',
                '152071200000'
            ],
            [
                '-690',
                '1:00',
                '+1230',
                '162957600000'
            ],
            [
                '-690',
                '-',
                '+1130',
                '1443924000000'
            ],
            [
                '-660',
                '-',
                '+11',
                '1561939200000'
            ],
            [
                '-660',
                'AN',
                '+11/+12'
            ]
        ],
        'Pacific/Palau': [
            [
                '902.0666666666666',
                '-',
                'LMT',
                '-3944678400000'
            ],
            [
                '-537.9333333333334',
                '-',
                'LMT',
                '-2146003200000'
            ],
            [
                '-540',
                '-',
                '+09'
            ]
        ],
        'Pacific/Port_Moresby': [
            [
                '-588.6666666666666',
                '-',
                'LMT',
                '-2808604800000'
            ],
            [
                '-588.5333333333334',
                '-',
                'PMMT',
                '-2335305600000'
            ],
            [
                '-600',
                '-',
                '+10'
            ]
        ],
        'Pacific/Bougainville': [
            [
                '-622.2666666666667',
                '-',
                'LMT',
                '-2808604800000'
            ],
            [
                '-588.5333333333334',
                '-',
                'PMMT',
                '-2335305600000'
            ],
            [
                '-600',
                '-',
                '+10',
                '-867974400000'
            ],
            [
                '-540',
                '-',
                '+09',
                '-768873600000'
            ],
            [
                '-600',
                '-',
                '+10',
                '1419732000000'
            ],
            [
                '-660',
                '-',
                '+11'
            ]
        ],
        'Pacific/Pitcairn': [
            [
                '520.3333333333333',
                '-',
                'LMT',
                '-2146003200000'
            ],
            [
                '510',
                '-',
                '-0830',
                '893635200000'
            ],
            [
                '480',
                '-',
                '-08'
            ]
        ],
        'Pacific/Pago_Pago': [
            [
                '-757.2',
                '-',
                'LMT',
                '-2445379200000'
            ],
            [
                '682.8',
                '-',
                'LMT',
                '-1830470400000'
            ],
            [
                '660',
                '-',
                'SST'
            ]
        ],
        'Pacific/Midway': 'Pacific/Pago_Pago',
        'Pacific/Apia': [
            [
                '-753.0666666666666',
                '-',
                'LMT',
                '-2445379200000'
            ],
            [
                '686.9333333333334',
                '-',
                'LMT',
                '-1830470400000'
            ],
            [
                '690',
                '-',
                '-1130',
                '-599702400000'
            ],
            [
                '660',
                'WS',
                '-11/-10',
                '1325203200000'
            ],
            [
                '-780',
                'WS',
                '+13/+14'
            ]
        ],
        'Pacific/Guadalcanal': [
            [
                '-639.8',
                '-',
                'LMT',
                '-1806710400000'
            ],
            [
                '-660',
                '-',
                '+11'
            ]
        ],
        'Pacific/Fakaofo': [
            [
                '684.9333333333334',
                '-',
                'LMT',
                '-2146003200000'
            ],
            [
                '660',
                '-',
                '-11',
                '1325203200000'
            ],
            [
                '-780',
                '-',
                '+13'
            ]
        ],
        'Pacific/Tongatapu': [
            [
                '-739.3333333333334',
                '-',
                'LMT',
                '-2146003200000'
            ],
            [
                '-740',
                '-',
                '+1220',
                '-883699200000'
            ],
            [
                '-780',
                '-',
                '+13',
                '946598400000'
            ],
            [
                '-780',
                'Tonga',
                '+13/+14'
            ]
        ],
        'Pacific/Funafuti': [
            [
                '-716.8666666666667',
                '-',
                'LMT',
                '-2146003200000'
            ],
            [
                '-720',
                '-',
                '+12'
            ]
        ],
        'Pacific/Wake': [
            [
                '-666.4666666666666',
                '-',
                'LMT',
                '-2146003200000'
            ],
            [
                '-720',
                '-',
                '+12'
            ]
        ],
        'Pacific/Efate': [
            [
                '-673.2666666666667',
                '-',
                'LMT',
                '-1829347200000'
            ],
            [
                '-660',
                'Vanuatu',
                '+11/+12'
            ]
        ],
        'Pacific/Wallis': [
            [
                '-735.3333333333334',
                '-',
                'LMT',
                '-2146003200000'
            ],
            [
                '-720',
                '-',
                '+12'
            ]
        ],
        'Africa/Asmera': 'Africa/Nairobi',
        'Africa/Timbuktu': 'Africa/Abidjan',
        'America/Argentina/ComodRivadavia': 'America/Argentina/Catamarca',
        'America/Atka': 'America/Adak',
        'America/Buenos_Aires': 'America/Argentina/Buenos_Aires',
        'America/Catamarca': 'America/Argentina/Catamarca',
        'America/Coral_Harbour': 'America/Atikokan',
        'America/Cordoba': 'America/Argentina/Cordoba',
        'America/Ensenada': 'America/Tijuana',
        'America/Fort_Wayne': 'America/Indiana/Indianapolis',
        'America/Indianapolis': 'America/Indiana/Indianapolis',
        'America/Jujuy': 'America/Argentina/Jujuy',
        'America/Knox_IN': 'America/Indiana/Knox',
        'America/Louisville': 'America/Kentucky/Louisville',
        'America/Mendoza': 'America/Argentina/Mendoza',
        'America/Montreal': 'America/Toronto',
        'America/Porto_Acre': 'America/Rio_Branco',
        'America/Rosario': 'America/Argentina/Cordoba',
        'America/Santa_Isabel': 'America/Tijuana',
        'America/Shiprock': 'America/Denver',
        'America/Virgin': 'America/Port_of_Spain',
        'Antarctica/South_Pole': 'Pacific/Auckland',
        'Asia/Ashkhabad': 'Asia/Ashgabat',
        'Asia/Calcutta': 'Asia/Kolkata',
        'Asia/Chongqing': 'Asia/Shanghai',
        'Asia/Chungking': 'Asia/Shanghai',
        'Asia/Dacca': 'Asia/Dhaka',
        'Asia/Harbin': 'Asia/Shanghai',
        'Asia/Kashgar': 'Asia/Urumqi',
        'Asia/Katmandu': 'Asia/Kathmandu',
        'Asia/Macao': 'Asia/Macau',
        'Asia/Rangoon': 'Asia/Yangon',
        'Asia/Saigon': 'Asia/Ho_Chi_Minh',
        'Asia/Tel_Aviv': 'Asia/Jerusalem',
        'Asia/Thimbu': 'Asia/Thimphu',
        'Asia/Ujung_Pandang': 'Asia/Makassar',
        'Asia/Ulan_Bator': 'Asia/Ulaanbaatar',
        'Atlantic/Faeroe': 'Atlantic/Faroe',
        'Atlantic/Jan_Mayen': 'Europe/Oslo',
        'Australia/ACT': 'Australia/Sydney',
        'Australia/Canberra': 'Australia/Sydney',
        'Australia/LHI': 'Australia/Lord_Howe',
        'Australia/NSW': 'Australia/Sydney',
        'Australia/North': 'Australia/Darwin',
        'Australia/Queensland': 'Australia/Brisbane',
        'Australia/South': 'Australia/Adelaide',
        'Australia/Tasmania': 'Australia/Hobart',
        'Australia/Victoria': 'Australia/Melbourne',
        'Australia/West': 'Australia/Perth',
        'Australia/Yancowinna': 'Australia/Broken_Hill',
        'Brazil/Acre': 'America/Rio_Branco',
        'Brazil/DeNoronha': 'America/Noronha',
        'Brazil/East': 'America/Sao_Paulo',
        'Brazil/West': 'America/Manaus',
        'Canada/Atlantic': 'America/Halifax',
        'Canada/Central': 'America/Winnipeg',
        'Canada/Eastern': 'America/Toronto',
        'Canada/Mountain': 'America/Edmonton',
        'Canada/Newfoundland': 'America/St_Johns',
        'Canada/Pacific': 'America/Vancouver',
        'Canada/Saskatchewan': 'America/Regina',
        'Canada/Yukon': 'America/Whitehorse',
        'Chile/Continental': 'America/Santiago',
        'Chile/EasterIsland': 'Pacific/Easter',
        'Cuba': 'America/Havana',
        'Egypt': 'Africa/Cairo',
        'Eire': 'Europe/Dublin',
        'Etc/UCT': 'Etc/UTC',
        'Europe/Belfast': 'Europe/London',
        'Europe/Tiraspol': 'Europe/Chisinau',
        'GB': 'Europe/London',
        'GB-Eire': 'Europe/London',
        'GMT+0': 'Etc/GMT',
        'GMT-0': 'Etc/GMT',
        'GMT0': 'Etc/GMT',
        'Greenwich': 'Etc/GMT',
        'Hongkong': 'Asia/Hong_Kong',
        'Iceland': 'Atlantic/Reykjavik',
        'Iran': 'Asia/Tehran',
        'Israel': 'Asia/Jerusalem',
        'Jamaica': 'America/Jamaica',
        'Japan': 'Asia/Tokyo',
        'Kwajalein': 'Pacific/Kwajalein',
        'Libya': 'Africa/Tripoli',
        'Mexico/BajaNorte': 'America/Tijuana',
        'Mexico/BajaSur': 'America/Mazatlan',
        'Mexico/General': 'America/Mexico_City',
        'NZ': 'Pacific/Auckland',
        'NZ-CHAT': 'Pacific/Chatham',
        'Navajo': 'America/Denver',
        'PRC': 'Asia/Shanghai',
        'Pacific/Johnston': 'Pacific/Honolulu',
        'Pacific/Ponape': 'Pacific/Pohnpei',
        'Pacific/Samoa': 'Pacific/Pago_Pago',
        'Pacific/Truk': 'Pacific/Chuuk',
        'Pacific/Yap': 'Pacific/Chuuk',
        'Poland': 'Europe/Warsaw',
        'Portugal': 'Europe/Lisbon',
        'ROC': 'Asia/Taipei',
        'ROK': 'Asia/Seoul',
        'Singapore': 'Asia/Singapore',
        'Turkey': 'Europe/Istanbul',
        'UCT': 'Etc/UTC',
        'US/Alaska': 'America/Anchorage',
        'US/Aleutian': 'America/Adak',
        'US/Arizona': 'America/Phoenix',
        'US/Central': 'America/Chicago',
        'US/East-Indiana': 'America/Indiana/Indianapolis',
        'US/Eastern': 'America/New_York',
        'US/Hawaii': 'Pacific/Honolulu',
        'US/Indiana-Starke': 'America/Indiana/Knox',
        'US/Michigan': 'America/Detroit',
        'US/Mountain': 'America/Denver',
        'US/Pacific': 'America/Los_Angeles',
        'US/Samoa': 'Pacific/Pago_Pago',
        'UTC': 'Etc/UTC',
        'Universal': 'Etc/UTC',
        'W-SU': 'Europe/Moscow',
        'Zulu': 'Etc/UTC',
        'Etc/GMT': [[
                '0',
                '-',
                'GMT'
            ]],
        'Etc/UTC': [[
                '0',
                '-',
                'UTC'
            ]],
        'GMT': 'Etc/GMT',
        'Etc/Universal': 'Etc/UTC',
        'Etc/Zulu': 'Etc/UTC',
        'Etc/Greenwich': 'Etc/GMT',
        'Etc/GMT-0': 'Etc/GMT',
        'Etc/GMT+0': 'Etc/GMT',
        'Etc/GMT0': 'Etc/GMT',
        'Etc/GMT-14': [[
                '-840',
                '-',
                '+14'
            ]],
        'Etc/GMT-13': [[
                '-780',
                '-',
                '+13'
            ]],
        'Etc/GMT-12': [[
                '-720',
                '-',
                '+12'
            ]],
        'Etc/GMT-11': [[
                '-660',
                '-',
                '+11'
            ]],
        'Etc/GMT-10': [[
                '-600',
                '-',
                '+10'
            ]],
        'Etc/GMT-9': [[
                '-540',
                '-',
                '+09'
            ]],
        'Etc/GMT-8': [[
                '-480',
                '-',
                '+08'
            ]],
        'Etc/GMT-7': [[
                '-420',
                '-',
                '+07'
            ]],
        'Etc/GMT-6': [[
                '-360',
                '-',
                '+06'
            ]],
        'Etc/GMT-5': [[
                '-300',
                '-',
                '+05'
            ]],
        'Etc/GMT-4': [[
                '-240',
                '-',
                '+04'
            ]],
        'Etc/GMT-3': [[
                '-180',
                '-',
                '+03'
            ]],
        'Etc/GMT-2': [[
                '-120',
                '-',
                '+02'
            ]],
        'Etc/GMT-1': [[
                '-60',
                '-',
                '+01'
            ]],
        'Etc/GMT+1': [[
                '60',
                '-',
                '-01'
            ]],
        'Etc/GMT+2': [[
                '120',
                '-',
                '-02'
            ]],
        'Etc/GMT+3': [[
                '180',
                '-',
                '-03'
            ]],
        'Etc/GMT+4': [[
                '240',
                '-',
                '-04'
            ]],
        'Etc/GMT+5': [[
                '300',
                '-',
                '-05'
            ]],
        'Etc/GMT+6': [[
                '360',
                '-',
                '-06'
            ]],
        'Etc/GMT+7': [[
                '420',
                '-',
                '-07'
            ]],
        'Etc/GMT+8': [[
                '480',
                '-',
                '-08'
            ]],
        'Etc/GMT+9': [[
                '540',
                '-',
                '-09'
            ]],
        'Etc/GMT+10': [[
                '600',
                '-',
                '-10'
            ]],
        'Etc/GMT+11': [[
                '660',
                '-',
                '-11'
            ]],
        'Etc/GMT+12': [[
                '720',
                '-',
                '-12'
            ]],
        'Europe/London': [
            [
                '1.25',
                '-',
                'LMT',
                '-3852662400000'
            ],
            [
                '0',
                'GB-Eire',
                '%s',
                '-37238400000'
            ],
            [
                '-60',
                '-',
                'BST',
                '57722400000'
            ],
            [
                '0',
                'GB-Eire',
                '%s',
                '851990400000'
            ],
            [
                '0',
                'EU',
                'GMT/BST'
            ]
        ],
        'Europe/Jersey': 'Europe/London',
        'Europe/Guernsey': 'Europe/London',
        'Europe/Isle_of_Man': 'Europe/London',
        'Europe/Dublin': [
            [
                '25',
                '-',
                'LMT',
                '-2821651200000'
            ],
            [
                '25.35',
                '-',
                'DMT',
                '-1691964000000'
            ],
            [
                '25.35',
                '1:00',
                'IST',
                '-1680472800000'
            ],
            [
                '0',
                'GB-Eire',
                '%s',
                '-1517011200000'
            ],
            [
                '0',
                'GB-Eire',
                'GMT/IST',
                '-942012000000'
            ],
            [
                '0',
                '1:00',
                'IST',
                '-733356000000'
            ],
            [
                '0',
                '-',
                'GMT',
                '-719445600000'
            ],
            [
                '0',
                '1:00',
                'IST',
                '-699487200000'
            ],
            [
                '0',
                '-',
                'GMT',
                '-684972000000'
            ],
            [
                '0',
                'GB-Eire',
                'GMT/IST',
                '-37238400000'
            ],
            [
                '-60',
                'Eire',
                'IST/GMT'
            ]
        ],
        'WET': [[
                '0',
                'EU',
                'WE%sT'
            ]],
        'CET': [[
                '-60',
                'C-Eur',
                'CE%sT'
            ]],
        'MET': [[
                '-60',
                'C-Eur',
                'ME%sT'
            ]],
        'EET': [[
                '-120',
                'EU',
                'EE%sT'
            ]],
        'Europe/Tirane': [
            [
                '-79.33333333333333',
                '-',
                'LMT',
                '-1735776000000'
            ],
            [
                '-60',
                '-',
                'CET',
                '-932342400000'
            ],
            [
                '-60',
                'Albania',
                'CE%sT',
                '457488000000'
            ],
            [
                '-60',
                'EU',
                'CE%sT'
            ]
        ],
        'Europe/Andorra': [
            [
                '-6.066666666666667',
                '-',
                'LMT',
                '-2146003200000'
            ],
            [
                '0',
                '-',
                'WET',
                '-733881600000'
            ],
            [
                '-60',
                '-',
                'CET',
                '481082400000'
            ],
            [
                '-60',
                'EU',
                'CE%sT'
            ]
        ],
        'Europe/Vienna': [
            [
                '-65.35',
                '-',
                'LMT',
                '-2422051200000'
            ],
            [
                '-60',
                'C-Eur',
                'CE%sT',
                '-1546387200000'
            ],
            [
                '-60',
                'Austria',
                'CE%sT',
                '-938901600000'
            ],
            [
                '-60',
                'C-Eur',
                'CE%sT',
                '-781048800000'
            ],
            [
                '-60',
                '1:00',
                'CEST',
                '-780184800000'
            ],
            [
                '-60',
                '-',
                'CET',
                '-725932800000'
            ],
            [
                '-60',
                'Austria',
                'CE%sT',
                '378604800000'
            ],
            [
                '-60',
                'EU',
                'CE%sT'
            ]
        ],
        'Europe/Minsk': [
            [
                '-110.26666666666667',
                '-',
                'LMT',
                '-2808604800000'
            ],
            [
                '-110',
                '-',
                'MMT',
                '-1441152000000'
            ],
            [
                '-120',
                '-',
                'EET',
                '-1247529600000'
            ],
            [
                '-180',
                '-',
                'MSK',
                '-899769600000'
            ],
            [
                '-60',
                'C-Eur',
                'CE%sT',
                '-804643200000'
            ],
            [
                '-180',
                'Russia',
                'MSK/MSD',
                '662601600000'
            ],
            [
                '-180',
                '-',
                'MSK',
                '670384800000'
            ],
            [
                '-120',
                'Russia',
                'EE%sT',
                '1301191200000'
            ],
            [
                '-180',
                '-',
                '+03'
            ]
        ],
        'Europe/Brussels': [
            [
                '-17.5',
                '-',
                'LMT',
                '-2808604800000'
            ],
            [
                '-17.5',
                '-',
                'BMT',
                '-2450994150000'
            ],
            [
                '0',
                '-',
                'WET',
                '-1740355200000'
            ],
            [
                '-60',
                '-',
                'CET',
                '-1693699200000'
            ],
            [
                '-60',
                'C-Eur',
                'CE%sT',
                '-1613826000000'
            ],
            [
                '0',
                'Belgium',
                'WE%sT',
                '-934668000000'
            ],
            [
                '-60',
                'C-Eur',
                'CE%sT',
                '-799286400000'
            ],
            [
                '-60',
                'Belgium',
                'CE%sT',
                '252374400000'
            ],
            [
                '-60',
                'EU',
                'CE%sT'
            ]
        ],
        'Europe/Sofia': [
            [
                '-93.26666666666667',
                '-',
                'LMT',
                '-2808604800000'
            ],
            [
                '-116.93333333333332',
                '-',
                'IMT',
                '-2369520000000'
            ],
            [
                '-120',
                '-',
                'EET',
                '-857250000000'
            ],
            [
                '-60',
                'C-Eur',
                'CE%sT',
                '-757468800000'
            ],
            [
                '-60',
                '-',
                'CET',
                '-781045200000'
            ],
            [
                '-120',
                '-',
                'EET',
                '291769200000'
            ],
            [
                '-120',
                'Bulg',
                'EE%sT',
                '401857200000'
            ],
            [
                '-120',
                'C-Eur',
                'EE%sT',
                '694137600000'
            ],
            [
                '-120',
                'E-Eur',
                'EE%sT',
                '883526400000'
            ],
            [
                '-120',
                'EU',
                'EE%sT'
            ]
        ],
        'Europe/Prague': [
            [
                '-57.733333333333334',
                '-',
                'LMT',
                '-3755376000000'
            ],
            [
                '-57.733333333333334',
                '-',
                'PMT',
                '-2469398400000'
            ],
            [
                '-60',
                'C-Eur',
                'CE%sT',
                '-777859200000'
            ],
            [
                '-60',
                'Czech',
                'CE%sT',
                '-728514000000'
            ],
            [
                '-60',
                '-1:00',
                'GMT',
                '-721260000000'
            ],
            [
                '-60',
                'Czech',
                'CE%sT',
                '315446400000'
            ],
            [
                '-60',
                'EU',
                'CE%sT'
            ]
        ],
        'Europe/Copenhagen': [
            [
                '-50.333333333333336',
                '-',
                'LMT',
                '-2493072000000'
            ],
            [
                '-50.333333333333336',
                '-',
                'CMT',
                '-2398291200000'
            ],
            [
                '-60',
                'Denmark',
                'CE%sT',
                '-857253600000'
            ],
            [
                '-60',
                'C-Eur',
                'CE%sT',
                '-781048800000'
            ],
            [
                '-60',
                'Denmark',
                'CE%sT',
                '347068800000'
            ],
            [
                '-60',
                'EU',
                'CE%sT'
            ]
        ],
        'Atlantic/Faroe': [
            [
                '27.066666666666666',
                '-',
                'LMT',
                '-1955750400000'
            ],
            [
                '0',
                '-',
                'WET',
                '378604800000'
            ],
            [
                '0',
                'EU',
                'WE%sT'
            ]
        ],
        'America/Danmarkshavn': [
            [
                '74.66666666666667',
                '-',
                'LMT',
                '-1686096000000'
            ],
            [
                '180',
                '-',
                '-03',
                '323834400000'
            ],
            [
                '180',
                'EU',
                '-03/-02',
                '851990400000'
            ],
            [
                '0',
                '-',
                'GMT'
            ]
        ],
        'America/Scoresbysund': [
            [
                '87.86666666666667',
                '-',
                'LMT',
                '-1686096000000'
            ],
            [
                '120',
                '-',
                '-02',
                '323834400000'
            ],
            [
                '120',
                'C-Eur',
                '-02/-01',
                '354672000000'
            ],
            [
                '60',
                'EU',
                '-01/+00'
            ]
        ],
        'America/Godthab': [
            [
                '206.93333333333334',
                '-',
                'LMT',
                '-1686096000000'
            ],
            [
                '180',
                '-',
                '-03',
                '323834400000'
            ],
            [
                '180',
                'EU',
                '-03/-02'
            ]
        ],
        'America/Thule': [
            [
                '275.1333333333333',
                '-',
                'LMT',
                '-1686096000000'
            ],
            [
                '240',
                'Thule',
                'A%sT'
            ]
        ],
        'Europe/Tallinn': [
            [
                '-99',
                '-',
                'LMT',
                '-2808604800000'
            ],
            [
                '-99',
                '-',
                'TMT',
                '-1638316800000'
            ],
            [
                '-60',
                'C-Eur',
                'CE%sT',
                '-1593820800000'
            ],
            [
                '-99',
                '-',
                'TMT',
                '-1535932800000'
            ],
            [
                '-120',
                '-',
                'EET',
                '-927936000000'
            ],
            [
                '-180',
                '-',
                'MSK',
                '-892944000000'
            ],
            [
                '-60',
                'C-Eur',
                'CE%sT',
                '-797644800000'
            ],
            [
                '-180',
                'Russia',
                'MSK/MSD',
                '606880800000'
            ],
            [
                '-120',
                '1:00',
                'EEST',
                '622605600000'
            ],
            [
                '-120',
                'C-Eur',
                'EE%sT',
                '906422400000'
            ],
            [
                '-120',
                'EU',
                'EE%sT',
                '941342400000'
            ],
            [
                '-120',
                '-',
                'EET',
                '1014249600000'
            ],
            [
                '-120',
                'EU',
                'EE%sT'
            ]
        ],
        'Europe/Helsinki': [
            [
                '-99.81666666666668',
                '-',
                'LMT',
                '-2890252800000'
            ],
            [
                '-99.81666666666668',
                '-',
                'HMT',
                '-1535932800000'
            ],
            [
                '-120',
                'Finland',
                'EE%sT',
                '441676800000'
            ],
            [
                '-120',
                'EU',
                'EE%sT'
            ]
        ],
        'Europe/Mariehamn': 'Europe/Helsinki',
        'Europe/Paris': [
            [
                '-9.35',
                '-',
                'LMT',
                '-2486678340000'
            ],
            [
                '-9.35',
                '-',
                'PMT',
                '-1855958340000'
            ],
            [
                '0',
                'France',
                'WE%sT',
                '-932432400000'
            ],
            [
                '-60',
                'C-Eur',
                'CE%sT',
                '-800064000000'
            ],
            [
                '0',
                'France',
                'WE%sT',
                '-766616400000'
            ],
            [
                '-60',
                'France',
                'CE%sT',
                '252374400000'
            ],
            [
                '-60',
                'EU',
                'CE%sT'
            ]
        ],
        'Europe/Berlin': [
            [
                '-53.46666666666666',
                '-',
                'LMT',
                '-2422051200000'
            ],
            [
                '-60',
                'C-Eur',
                'CE%sT',
                '-776556000000'
            ],
            [
                '-60',
                'SovietZone',
                'CE%sT',
                '-725932800000'
            ],
            [
                '-60',
                'Germany',
                'CE%sT',
                '347068800000'
            ],
            [
                '-60',
                'EU',
                'CE%sT'
            ]
        ],
        'Europe/Busingen': 'Europe/Zurich',
        'Europe/Gibraltar': [
            [
                '21.4',
                '-',
                'LMT',
                '-2821651200000'
            ],
            [
                '0',
                'GB-Eire',
                '%s',
                '-401320800000'
            ],
            [
                '-60',
                '-',
                'CET',
                '410140800000'
            ],
            [
                '-60',
                'EU',
                'CE%sT'
            ]
        ],
        'Europe/Athens': [
            [
                '-94.86666666666667',
                '-',
                'LMT',
                '-2344636800000'
            ],
            [
                '-94.86666666666667',
                '-',
                'AMT',
                '-1686095940000'
            ],
            [
                '-120',
                'Greece',
                'EE%sT',
                '-904867200000'
            ],
            [
                '-60',
                'Greece',
                'CE%sT',
                '-812419200000'
            ],
            [
                '-120',
                'Greece',
                'EE%sT',
                '378604800000'
            ],
            [
                '-120',
                'EU',
                'EE%sT'
            ]
        ],
        'Europe/Budapest': [
            [
                '-76.33333333333333',
                '-',
                'LMT',
                '-2500934400000'
            ],
            [
                '-60',
                'C-Eur',
                'CE%sT',
                '-1609545600000'
            ],
            [
                '-60',
                'Hungary',
                'CE%sT',
                '-906768000000'
            ],
            [
                '-60',
                'C-Eur',
                'CE%sT',
                '-757468800000'
            ],
            [
                '-60',
                'Hungary',
                'CE%sT',
                '338954400000'
            ],
            [
                '-60',
                'EU',
                'CE%sT'
            ]
        ],
        'Atlantic/Reykjavik': [
            [
                '88',
                '-',
                'LMT',
                '-1925078400000'
            ],
            [
                '60',
                'Iceland',
                '-01/+00',
                '-54774000000'
            ],
            [
                '0',
                '-',
                'GMT'
            ]
        ],
        'Europe/Rome': [
            [
                '-49.93333333333334',
                '-',
                'LMT',
                '-3252096000000'
            ],
            [
                '-49.93333333333334',
                '-',
                'RMT',
                '-2403562204000'
            ],
            [
                '-60',
                'Italy',
                'CE%sT',
                '-830304000000'
            ],
            [
                '-60',
                'C-Eur',
                'CE%sT',
                '-807148800000'
            ],
            [
                '-60',
                'Italy',
                'CE%sT',
                '347068800000'
            ],
            [
                '-60',
                'EU',
                'CE%sT'
            ]
        ],
        'Europe/Vatican': 'Europe/Rome',
        'Europe/San_Marino': 'Europe/Rome',
        'Europe/Riga': [
            [
                '-96.56666666666668',
                '-',
                'LMT',
                '-2808604800000'
            ],
            [
                '-96.56666666666668',
                '-',
                'RMT',
                '-1632002400000'
            ],
            [
                '-96.56666666666668',
                '1:00',
                'LST',
                '-1618693200000'
            ],
            [
                '-96.56666666666668',
                '-',
                'RMT',
                '-1601676000000'
            ],
            [
                '-96.56666666666668',
                '1:00',
                'LST',
                '-1597266000000'
            ],
            [
                '-96.56666666666668',
                '-',
                'RMT',
                '-1377302400000'
            ],
            [
                '-120',
                '-',
                'EET',
                '-928022400000'
            ],
            [
                '-180',
                '-',
                'MSK',
                '-899510400000'
            ],
            [
                '-60',
                'C-Eur',
                'CE%sT',
                '-795830400000'
            ],
            [
                '-180',
                'Russia',
                'MSK/MSD',
                '604720800000'
            ],
            [
                '-120',
                '1:00',
                'EEST',
                '620618400000'
            ],
            [
                '-120',
                'Latvia',
                'EE%sT',
                '853804800000'
            ],
            [
                '-120',
                'EU',
                'EE%sT',
                '951782400000'
            ],
            [
                '-120',
                '-',
                'EET',
                '978393600000'
            ],
            [
                '-120',
                'EU',
                'EE%sT'
            ]
        ],
        'Europe/Vaduz': 'Europe/Zurich',
        'Europe/Vilnius': [
            [
                '-101.26666666666667',
                '-',
                'LMT',
                '-2808604800000'
            ],
            [
                '-84',
                '-',
                'WMT',
                '-1641081600000'
            ],
            [
                '-95.6',
                '-',
                'KMT',
                '-1585094400000'
            ],
            [
                '-60',
                '-',
                'CET',
                '-1561248000000'
            ],
            [
                '-120',
                '-',
                'EET',
                '-1553558400000'
            ],
            [
                '-60',
                '-',
                'CET',
                '-928195200000'
            ],
            [
                '-180',
                '-',
                'MSK',
                '-900115200000'
            ],
            [
                '-60',
                'C-Eur',
                'CE%sT',
                '-802137600000'
            ],
            [
                '-180',
                'Russia',
                'MSK/MSD',
                '606880800000'
            ],
            [
                '-120',
                'Russia',
                'EE%sT',
                '686109600000'
            ],
            [
                '-120',
                'C-Eur',
                'EE%sT',
                '915062400000'
            ],
            [
                '-120',
                '-',
                'EET',
                '891133200000'
            ],
            [
                '-60',
                'EU',
                'CE%sT',
                '941331600000'
            ],
            [
                '-120',
                '-',
                'EET',
                '1041379200000'
            ],
            [
                '-120',
                'EU',
                'EE%sT'
            ]
        ],
        'Europe/Luxembourg': [
            [
                '-24.6',
                '-',
                'LMT',
                '-2069712000000'
            ],
            [
                '-60',
                'Lux',
                'CE%sT',
                '-1612656000000'
            ],
            [
                '0',
                'Lux',
                'WE%sT',
                '-1269813600000'
            ],
            [
                '0',
                'Belgium',
                'WE%sT',
                '-935182800000'
            ],
            [
                '-60',
                'C-Eur',
                'WE%sT',
                '-797979600000'
            ],
            [
                '-60',
                'Belgium',
                'CE%sT',
                '252374400000'
            ],
            [
                '-60',
                'EU',
                'CE%sT'
            ]
        ],
        'Europe/Malta': [
            [
                '-58.06666666666666',
                '-',
                'LMT',
                '-2403475200000'
            ],
            [
                '-60',
                'Italy',
                'CE%sT',
                '102384000000'
            ],
            [
                '-60',
                'Malta',
                'CE%sT',
                '378604800000'
            ],
            [
                '-60',
                'EU',
                'CE%sT'
            ]
        ],
        'Europe/Chisinau': [
            [
                '-115.33333333333333',
                '-',
                'LMT',
                '-2808604800000'
            ],
            [
                '-115',
                '-',
                'CMT',
                '-1637107200000'
            ],
            [
                '-104.4',
                '-',
                'BMT',
                '-1213142400000'
            ],
            [
                '-120',
                'Romania',
                'EE%sT',
                '-927158400000'
            ],
            [
                '-120',
                '1:00',
                'EEST',
                '-898128000000'
            ],
            [
                '-60',
                'C-Eur',
                'CE%sT',
                '-800150400000'
            ],
            [
                '-180',
                'Russia',
                'MSK/MSD',
                '641959200000'
            ],
            [
                '-120',
                'Russia',
                'EE%sT',
                '725760000000'
            ],
            [
                '-120',
                'E-Eur',
                'EE%sT',
                '883526400000'
            ],
            [
                '-120',
                'Moldova',
                'EE%sT'
            ]
        ],
        'Europe/Monaco': [
            [
                '-29.53333333333333',
                '-',
                'LMT',
                '-2486678400000'
            ],
            [
                '-9.35',
                '-',
                'PMT',
                '-1855958400000'
            ],
            [
                '0',
                'France',
                'WE%sT',
                '-766616400000'
            ],
            [
                '-60',
                'France',
                'CE%sT',
                '252374400000'
            ],
            [
                '-60',
                'EU',
                'CE%sT'
            ]
        ],
        'Europe/Amsterdam': [
            [
                '-19.53333333333333',
                '-',
                'LMT',
                '-4228761600000'
            ],
            [
                '-19.53333333333333',
                'Neth',
                '%s',
                '-1025740800000'
            ],
            [
                '-20',
                'Neth',
                '+0020/+0120',
                '-935020800000'
            ],
            [
                '-60',
                'C-Eur',
                'CE%sT',
                '-781048800000'
            ],
            [
                '-60',
                'Neth',
                'CE%sT',
                '252374400000'
            ],
            [
                '-60',
                'EU',
                'CE%sT'
            ]
        ],
        'Europe/Oslo': [
            [
                '-43',
                '-',
                'LMT',
                '-2366755200000'
            ],
            [
                '-60',
                'Norway',
                'CE%sT',
                '-927507600000'
            ],
            [
                '-60',
                'C-Eur',
                'CE%sT',
                '-781048800000'
            ],
            [
                '-60',
                'Norway',
                'CE%sT',
                '347068800000'
            ],
            [
                '-60',
                'EU',
                'CE%sT'
            ]
        ],
        'Arctic/Longyearbyen': 'Europe/Oslo',
        'Europe/Warsaw': [
            [
                '-84',
                '-',
                'LMT',
                '-2808604800000'
            ],
            [
                '-84',
                '-',
                'WMT',
                '-1717027200000'
            ],
            [
                '-60',
                'C-Eur',
                'CE%sT',
                '-1618693200000'
            ],
            [
                '-120',
                'Poland',
                'EE%sT',
                '-1501718400000'
            ],
            [
                '-60',
                'Poland',
                'CE%sT',
                '-931730400000'
            ],
            [
                '-60',
                'C-Eur',
                'CE%sT',
                '-796867200000'
            ],
            [
                '-60',
                'Poland',
                'CE%sT',
                '252374400000'
            ],
            [
                '-60',
                'W-Eur',
                'CE%sT',
                '599529600000'
            ],
            [
                '-60',
                'EU',
                'CE%sT'
            ]
        ],
        'Europe/Lisbon': [
            [
                '36.75',
                '-',
                'LMT',
                '-2682374400000'
            ],
            [
                '36.75',
                '-',
                'LMT',
                '-1830384000000'
            ],
            [
                '0',
                'Port',
                'WE%sT',
                '-118274400000'
            ],
            [
                '-60',
                '-',
                'CET',
                '212547600000'
            ],
            [
                '0',
                'Port',
                'WE%sT',
                '433299600000'
            ],
            [
                '0',
                'W-Eur',
                'WE%sT',
                '717555600000'
            ],
            [
                '-60',
                'EU',
                'CE%sT',
                '828234000000'
            ],
            [
                '0',
                'EU',
                'WE%sT'
            ]
        ],
        'Atlantic/Azores': [
            [
                '102.66666666666667',
                '-',
                'LMT',
                '-2682374400000'
            ],
            [
                '114.53333333333333',
                '-',
                'HMT',
                '-1830376800000'
            ],
            [
                '120',
                'Port',
                '-02/-01',
                '-873684000000'
            ],
            [
                '120',
                'Port',
                '+00',
                '-864007200000'
            ],
            [
                '120',
                'Port',
                '-02/-01',
                '-842839200000'
            ],
            [
                '120',
                'Port',
                '+00',
                '-831348000000'
            ],
            [
                '120',
                'Port',
                '-02/-01',
                '-810784800000'
            ],
            [
                '120',
                'Port',
                '+00',
                '-799898400000'
            ],
            [
                '120',
                'Port',
                '-02/-01',
                '-779335200000'
            ],
            [
                '120',
                'Port',
                '+00',
                '-768448800000'
            ],
            [
                '120',
                'Port',
                '-02/-01',
                '-118274400000'
            ],
            [
                '60',
                'Port',
                '-01/+00',
                '433299600000'
            ],
            [
                '60',
                'W-Eur',
                '-01/+00',
                '717555600000'
            ],
            [
                '0',
                'EU',
                'WE%sT',
                '733280400000'
            ],
            [
                '60',
                'EU',
                '-01/+00'
            ]
        ],
        'Atlantic/Madeira': [
            [
                '67.6',
                '-',
                'LMT',
                '-2682374400000'
            ],
            [
                '67.6',
                '-',
                'FMT',
                '-1830380400000'
            ],
            [
                '60',
                'Port',
                '-01/+00',
                '-873684000000'
            ],
            [
                '60',
                'Port',
                '+01',
                '-864007200000'
            ],
            [
                '60',
                'Port',
                '-01/+00',
                '-842839200000'
            ],
            [
                '60',
                'Port',
                '+01',
                '-831348000000'
            ],
            [
                '60',
                'Port',
                '-01/+00',
                '-810784800000'
            ],
            [
                '60',
                'Port',
                '+01',
                '-799898400000'
            ],
            [
                '60',
                'Port',
                '-01/+00',
                '-779335200000'
            ],
            [
                '60',
                'Port',
                '+01',
                '-768448800000'
            ],
            [
                '60',
                'Port',
                '-01/+00',
                '-118274400000'
            ],
            [
                '0',
                'Port',
                'WE%sT',
                '433299600000'
            ],
            [
                '0',
                'EU',
                'WE%sT'
            ]
        ],
        'Europe/Bucharest': [
            [
                '-104.4',
                '-',
                'LMT',
                '-2469398400000'
            ],
            [
                '-104.4',
                '-',
                'BMT',
                '-1213142400000'
            ],
            [
                '-120',
                'Romania',
                'EE%sT',
                '354679200000'
            ],
            [
                '-120',
                'C-Eur',
                'EE%sT',
                '694137600000'
            ],
            [
                '-120',
                'Romania',
                'EE%sT',
                '788832000000'
            ],
            [
                '-120',
                'E-Eur',
                'EE%sT',
                '883526400000'
            ],
            [
                '-120',
                'EU',
                'EE%sT'
            ]
        ],
        'Europe/Kaliningrad': [
            [
                '-82',
                '-',
                'LMT',
                '-2422051200000'
            ],
            [
                '-60',
                'C-Eur',
                'CE%sT',
                '-780364800000'
            ],
            [
                '-120',
                'Poland',
                'EE%sT',
                '-749088000000'
            ],
            [
                '-180',
                'Russia',
                'MSK/MSD',
                '606880800000'
            ],
            [
                '-120',
                'Russia',
                'EE%sT',
                '1301191200000'
            ],
            [
                '-180',
                '-',
                '+03',
                '1414288800000'
            ],
            [
                '-120',
                '-',
                'EET'
            ]
        ],
        'Europe/Moscow': [
            [
                '-150.28333333333333',
                '-',
                'LMT',
                '-2808604800000'
            ],
            [
                '-150.28333333333333',
                '-',
                'MMT',
                '-1688256000000'
            ],
            [
                '-151.31666666666666',
                'Russia',
                '%s',
                '-1593820800000'
            ],
            [
                '-180',
                'Russia',
                '%s',
                '-1522713600000'
            ],
            [
                '-180',
                'Russia',
                'MSK/MSD',
                '-1491177600000'
            ],
            [
                '-120',
                '-',
                'EET',
                '-1247529600000'
            ],
            [
                '-180',
                'Russia',
                'MSK/MSD',
                '670384800000'
            ],
            [
                '-120',
                'Russia',
                'EE%sT',
                '695786400000'
            ],
            [
                '-180',
                'Russia',
                'MSK/MSD',
                '1301191200000'
            ],
            [
                '-240',
                '-',
                'MSK',
                '1414288800000'
            ],
            [
                '-180',
                '-',
                'MSK'
            ]
        ],
        'Europe/Simferopol': [
            [
                '-136.4',
                '-',
                'LMT',
                '-2808604800000'
            ],
            [
                '-136',
                '-',
                'SMT',
                '-1441152000000'
            ],
            [
                '-120',
                '-',
                'EET',
                '-1247529600000'
            ],
            [
                '-180',
                '-',
                'MSK',
                '-888883200000'
            ],
            [
                '-60',
                'C-Eur',
                'CE%sT',
                '-811641600000'
            ],
            [
                '-180',
                'Russia',
                'MSK/MSD',
                '662601600000'
            ],
            [
                '-180',
                '-',
                'MSK',
                '646797600000'
            ],
            [
                '-120',
                '-',
                'EET',
                '725760000000'
            ],
            [
                '-120',
                'E-Eur',
                'EE%sT',
                '767750400000'
            ],
            [
                '-180',
                'E-Eur',
                'MSK/MSD',
                '828230400000'
            ],
            [
                '-180',
                '1:00',
                'MSD',
                '846385200000'
            ],
            [
                '-180',
                'Russia',
                'MSK/MSD',
                '883526400000'
            ],
            [
                '-180',
                '-',
                'MSK',
                '857178000000'
            ],
            [
                '-120',
                'EU',
                'EE%sT',
                '1396144800000'
            ],
            [
                '-240',
                '-',
                'MSK',
                '1414288800000'
            ],
            [
                '-180',
                '-',
                'MSK'
            ]
        ],
        'Europe/Astrakhan': [
            [
                '-192.2',
                '-',
                'LMT',
                '-1441238400000'
            ],
            [
                '-180',
                '-',
                '+03',
                '-1247529600000'
            ],
            [
                '-240',
                'Russia',
                '+04/+05',
                '606880800000'
            ],
            [
                '-180',
                'Russia',
                '+03/+04',
                '670384800000'
            ],
            [
                '-240',
                '-',
                '+04',
                '701834400000'
            ],
            [
                '-180',
                'Russia',
                '+03/+04',
                '1301191200000'
            ],
            [
                '-240',
                '-',
                '+04',
                '1414288800000'
            ],
            [
                '-180',
                '-',
                '+03',
                '1459044000000'
            ],
            [
                '-240',
                '-',
                '+04'
            ]
        ],
        'Europe/Volgograd': [
            [
                '-177.66666666666666',
                '-',
                'LMT',
                '-1577750400000'
            ],
            [
                '-180',
                '-',
                '+03',
                '-1247529600000'
            ],
            [
                '-240',
                '-',
                '+04',
                '-256867200000'
            ],
            [
                '-240',
                'Russia',
                '+04/+05',
                '575431200000'
            ],
            [
                '-180',
                'Russia',
                '+03/+04',
                '670384800000'
            ],
            [
                '-240',
                '-',
                '+04',
                '701834400000'
            ],
            [
                '-180',
                'Russia',
                '+03/+04',
                '1301191200000'
            ],
            [
                '-240',
                '-',
                '+04',
                '1414288800000'
            ],
            [
                '-180',
                '-',
                '+03',
                '1540692000000'
            ],
            [
                '-240',
                '-',
                '+04'
            ]
        ],
        'Europe/Saratov': [
            [
                '-184.3',
                '-',
                'LMT',
                '-1593820800000'
            ],
            [
                '-180',
                '-',
                '+03',
                '-1247529600000'
            ],
            [
                '-240',
                'Russia',
                '+04/+05',
                '575431200000'
            ],
            [
                '-180',
                'Russia',
                '+03/+04',
                '670384800000'
            ],
            [
                '-240',
                '-',
                '+04',
                '701834400000'
            ],
            [
                '-180',
                'Russia',
                '+03/+04',
                '1301191200000'
            ],
            [
                '-240',
                '-',
                '+04',
                '1414288800000'
            ],
            [
                '-180',
                '-',
                '+03',
                '1480816800000'
            ],
            [
                '-240',
                '-',
                '+04'
            ]
        ],
        'Europe/Kirov': [
            [
                '-198.8',
                '-',
                'LMT',
                '-1593820800000'
            ],
            [
                '-180',
                '-',
                '+03',
                '-1247529600000'
            ],
            [
                '-240',
                'Russia',
                '+04/+05',
                '606880800000'
            ],
            [
                '-180',
                'Russia',
                '+03/+04',
                '670384800000'
            ],
            [
                '-240',
                '-',
                '+04',
                '701834400000'
            ],
            [
                '-180',
                'Russia',
                '+03/+04',
                '1301191200000'
            ],
            [
                '-240',
                '-',
                '+04',
                '1414288800000'
            ],
            [
                '-180',
                '-',
                '+03'
            ]
        ],
        'Europe/Samara': [
            [
                '-200.33333333333334',
                '-',
                'LMT',
                '-1593820800000'
            ],
            [
                '-180',
                '-',
                '+03',
                '-1247529600000'
            ],
            [
                '-240',
                '-',
                '+04',
                '-1102291200000'
            ],
            [
                '-240',
                'Russia',
                '+04/+05',
                '606880800000'
            ],
            [
                '-180',
                'Russia',
                '+03/+04',
                '670384800000'
            ],
            [
                '-120',
                'Russia',
                '+02/+03',
                '686109600000'
            ],
            [
                '-180',
                '-',
                '+03',
                '687927600000'
            ],
            [
                '-240',
                'Russia',
                '+04/+05',
                '1269741600000'
            ],
            [
                '-180',
                'Russia',
                '+03/+04',
                '1301191200000'
            ],
            [
                '-240',
                '-',
                '+04'
            ]
        ],
        'Europe/Ulyanovsk': [
            [
                '-193.6',
                '-',
                'LMT',
                '-1593820800000'
            ],
            [
                '-180',
                '-',
                '+03',
                '-1247529600000'
            ],
            [
                '-240',
                'Russia',
                '+04/+05',
                '606880800000'
            ],
            [
                '-180',
                'Russia',
                '+03/+04',
                '670384800000'
            ],
            [
                '-120',
                'Russia',
                '+02/+03',
                '695786400000'
            ],
            [
                '-180',
                'Russia',
                '+03/+04',
                '1301191200000'
            ],
            [
                '-240',
                '-',
                '+04',
                '1414288800000'
            ],
            [
                '-180',
                '-',
                '+03',
                '1459044000000'
            ],
            [
                '-240',
                '-',
                '+04'
            ]
        ],
        'Asia/Yekaterinburg': [
            [
                '-242.55',
                '-',
                'LMT',
                '-1688256000000'
            ],
            [
                '-225.08333333333334',
                '-',
                'PMT',
                '-1592596800000'
            ],
            [
                '-240',
                '-',
                '+04',
                '-1247529600000'
            ],
            [
                '-300',
                'Russia',
                '+05/+06',
                '670384800000'
            ],
            [
                '-240',
                'Russia',
                '+04/+05',
                '695786400000'
            ],
            [
                '-300',
                'Russia',
                '+05/+06',
                '1301191200000'
            ],
            [
                '-360',
                '-',
                '+06',
                '1414288800000'
            ],
            [
                '-300',
                '-',
                '+05'
            ]
        ],
        'Asia/Omsk': [
            [
                '-293.5',
                '-',
                'LMT',
                '-1582070400000'
            ],
            [
                '-300',
                '-',
                '+05',
                '-1247529600000'
            ],
            [
                '-360',
                'Russia',
                '+06/+07',
                '670384800000'
            ],
            [
                '-300',
                'Russia',
                '+05/+06',
                '695786400000'
            ],
            [
                '-360',
                'Russia',
                '+06/+07',
                '1301191200000'
            ],
            [
                '-420',
                '-',
                '+07',
                '1414288800000'
            ],
            [
                '-360',
                '-',
                '+06'
            ]
        ],
        'Asia/Barnaul': [
            [
                '-335',
                '-',
                'LMT',
                '-1579824000000'
            ],
            [
                '-360',
                '-',
                '+06',
                '-1247529600000'
            ],
            [
                '-420',
                'Russia',
                '+07/+08',
                '670384800000'
            ],
            [
                '-360',
                'Russia',
                '+06/+07',
                '695786400000'
            ],
            [
                '-420',
                'Russia',
                '+07/+08',
                '801619200000'
            ],
            [
                '-360',
                'Russia',
                '+06/+07',
                '1301191200000'
            ],
            [
                '-420',
                '-',
                '+07',
                '1414288800000'
            ],
            [
                '-360',
                '-',
                '+06',
                '1459044000000'
            ],
            [
                '-420',
                '-',
                '+07'
            ]
        ],
        'Asia/Novosibirsk': [
            [
                '-331.6666666666667',
                '-',
                'LMT',
                '-1579456800000'
            ],
            [
                '-360',
                '-',
                '+06',
                '-1247529600000'
            ],
            [
                '-420',
                'Russia',
                '+07/+08',
                '670384800000'
            ],
            [
                '-360',
                'Russia',
                '+06/+07',
                '695786400000'
            ],
            [
                '-420',
                'Russia',
                '+07/+08',
                '738115200000'
            ],
            [
                '-360',
                'Russia',
                '+06/+07',
                '1301191200000'
            ],
            [
                '-420',
                '-',
                '+07',
                '1414288800000'
            ],
            [
                '-360',
                '-',
                '+06',
                '1469325600000'
            ],
            [
                '-420',
                '-',
                '+07'
            ]
        ],
        'Asia/Tomsk': [
            [
                '-339.85',
                '-',
                'LMT',
                '-1578787200000'
            ],
            [
                '-360',
                '-',
                '+06',
                '-1247529600000'
            ],
            [
                '-420',
                'Russia',
                '+07/+08',
                '670384800000'
            ],
            [
                '-360',
                'Russia',
                '+06/+07',
                '695786400000'
            ],
            [
                '-420',
                'Russia',
                '+07/+08',
                '1020222000000'
            ],
            [
                '-360',
                'Russia',
                '+06/+07',
                '1301191200000'
            ],
            [
                '-420',
                '-',
                '+07',
                '1414288800000'
            ],
            [
                '-360',
                '-',
                '+06',
                '1464487200000'
            ],
            [
                '-420',
                '-',
                '+07'
            ]
        ],
        'Asia/Novokuznetsk': [
            [
                '-348.8',
                '-',
                'LMT',
                '-1441238400000'
            ],
            [
                '-360',
                '-',
                '+06',
                '-1247529600000'
            ],
            [
                '-420',
                'Russia',
                '+07/+08',
                '670384800000'
            ],
            [
                '-360',
                'Russia',
                '+06/+07',
                '695786400000'
            ],
            [
                '-420',
                'Russia',
                '+07/+08',
                '1269741600000'
            ],
            [
                '-360',
                'Russia',
                '+06/+07',
                '1301191200000'
            ],
            [
                '-420',
                '-',
                '+07'
            ]
        ],
        'Asia/Krasnoyarsk': [
            [
                '-371.43333333333334',
                '-',
                'LMT',
                '-1577491200000'
            ],
            [
                '-360',
                '-',
                '+06',
                '-1247529600000'
            ],
            [
                '-420',
                'Russia',
                '+07/+08',
                '670384800000'
            ],
            [
                '-360',
                'Russia',
                '+06/+07',
                '695786400000'
            ],
            [
                '-420',
                'Russia',
                '+07/+08',
                '1301191200000'
            ],
            [
                '-480',
                '-',
                '+08',
                '1414288800000'
            ],
            [
                '-420',
                '-',
                '+07'
            ]
        ],
        'Asia/Irkutsk': [
            [
                '-417.0833333333333',
                '-',
                'LMT',
                '-2808604800000'
            ],
            [
                '-417.0833333333333',
                '-',
                'IMT',
                '-1575849600000'
            ],
            [
                '-420',
                '-',
                '+07',
                '-1247529600000'
            ],
            [
                '-480',
                'Russia',
                '+08/+09',
                '670384800000'
            ],
            [
                '-420',
                'Russia',
                '+07/+08',
                '695786400000'
            ],
            [
                '-480',
                'Russia',
                '+08/+09',
                '1301191200000'
            ],
            [
                '-540',
                '-',
                '+09',
                '1414288800000'
            ],
            [
                '-480',
                '-',
                '+08'
            ]
        ],
        'Asia/Chita': [
            [
                '-453.8666666666667',
                '-',
                'LMT',
                '-1579392000000'
            ],
            [
                '-480',
                '-',
                '+08',
                '-1247529600000'
            ],
            [
                '-540',
                'Russia',
                '+09/+10',
                '670384800000'
            ],
            [
                '-480',
                'Russia',
                '+08/+09',
                '695786400000'
            ],
            [
                '-540',
                'Russia',
                '+09/+10',
                '1301191200000'
            ],
            [
                '-600',
                '-',
                '+10',
                '1414288800000'
            ],
            [
                '-480',
                '-',
                '+08',
                '1459044000000'
            ],
            [
                '-540',
                '-',
                '+09'
            ]
        ],
        'Asia/Yakutsk': [
            [
                '-518.9666666666667',
                '-',
                'LMT',
                '-1579392000000'
            ],
            [
                '-480',
                '-',
                '+08',
                '-1247529600000'
            ],
            [
                '-540',
                'Russia',
                '+09/+10',
                '670384800000'
            ],
            [
                '-480',
                'Russia',
                '+08/+09',
                '695786400000'
            ],
            [
                '-540',
                'Russia',
                '+09/+10',
                '1301191200000'
            ],
            [
                '-600',
                '-',
                '+10',
                '1414288800000'
            ],
            [
                '-540',
                '-',
                '+09'
            ]
        ],
        'Asia/Vladivostok': [
            [
                '-527.5166666666667',
                '-',
                'LMT',
                '-1487289600000'
            ],
            [
                '-540',
                '-',
                '+09',
                '-1247529600000'
            ],
            [
                '-600',
                'Russia',
                '+10/+11',
                '670384800000'
            ],
            [
                '-540',
                'Russia',
                '+09/+10',
                '695786400000'
            ],
            [
                '-600',
                'Russia',
                '+10/+11',
                '1301191200000'
            ],
            [
                '-660',
                '-',
                '+11',
                '1414288800000'
            ],
            [
                '-600',
                '-',
                '+10'
            ]
        ],
        'Asia/Khandyga': [
            [
                '-542.2166666666666',
                '-',
                'LMT',
                '-1579392000000'
            ],
            [
                '-480',
                '-',
                '+08',
                '-1247529600000'
            ],
            [
                '-540',
                'Russia',
                '+09/+10',
                '670384800000'
            ],
            [
                '-480',
                'Russia',
                '+08/+09',
                '695786400000'
            ],
            [
                '-540',
                'Russia',
                '+09/+10',
                '1104451200000'
            ],
            [
                '-600',
                'Russia',
                '+10/+11',
                '1301191200000'
            ],
            [
                '-660',
                '-',
                '+11',
                '1315872000000'
            ],
            [
                '-600',
                '-',
                '+10',
                '1414288800000'
            ],
            [
                '-540',
                '-',
                '+09'
            ]
        ],
        'Asia/Sakhalin': [
            [
                '-570.8',
                '-',
                'LMT',
                '-2031004800000'
            ],
            [
                '-540',
                '-',
                '+09',
                '-768528000000'
            ],
            [
                '-660',
                'Russia',
                '+11/+12',
                '670384800000'
            ],
            [
                '-600',
                'Russia',
                '+10/+11',
                '695786400000'
            ],
            [
                '-660',
                'Russia',
                '+11/+12',
                '857181600000'
            ],
            [
                '-600',
                'Russia',
                '+10/+11',
                '1301191200000'
            ],
            [
                '-660',
                '-',
                '+11',
                '1414288800000'
            ],
            [
                '-600',
                '-',
                '+10',
                '1459044000000'
            ],
            [
                '-660',
                '-',
                '+11'
            ]
        ],
        'Asia/Magadan': [
            [
                '-603.2',
                '-',
                'LMT',
                '-1441152000000'
            ],
            [
                '-600',
                '-',
                '+10',
                '-1247529600000'
            ],
            [
                '-660',
                'Russia',
                '+11/+12',
                '670384800000'
            ],
            [
                '-600',
                'Russia',
                '+10/+11',
                '695786400000'
            ],
            [
                '-660',
                'Russia',
                '+11/+12',
                '1301191200000'
            ],
            [
                '-720',
                '-',
                '+12',
                '1414288800000'
            ],
            [
                '-600',
                '-',
                '+10',
                '1461463200000'
            ],
            [
                '-660',
                '-',
                '+11'
            ]
        ],
        'Asia/Srednekolymsk': [
            [
                '-614.8666666666667',
                '-',
                'LMT',
                '-1441152000000'
            ],
            [
                '-600',
                '-',
                '+10',
                '-1247529600000'
            ],
            [
                '-660',
                'Russia',
                '+11/+12',
                '670384800000'
            ],
            [
                '-600',
                'Russia',
                '+10/+11',
                '695786400000'
            ],
            [
                '-660',
                'Russia',
                '+11/+12',
                '1301191200000'
            ],
            [
                '-720',
                '-',
                '+12',
                '1414288800000'
            ],
            [
                '-660',
                '-',
                '+11'
            ]
        ],
        'Asia/Ust-Nera': [
            [
                '-572.9',
                '-',
                'LMT',
                '-1579392000000'
            ],
            [
                '-480',
                '-',
                '+08',
                '-1247529600000'
            ],
            [
                '-540',
                'Russia',
                '+09/+10',
                '354931200000'
            ],
            [
                '-660',
                'Russia',
                '+11/+12',
                '670384800000'
            ],
            [
                '-600',
                'Russia',
                '+10/+11',
                '695786400000'
            ],
            [
                '-660',
                'Russia',
                '+11/+12',
                '1301191200000'
            ],
            [
                '-720',
                '-',
                '+12',
                '1315872000000'
            ],
            [
                '-660',
                '-',
                '+11',
                '1414288800000'
            ],
            [
                '-600',
                '-',
                '+10'
            ]
        ],
        'Asia/Kamchatka': [
            [
                '-634.6',
                '-',
                'LMT',
                '-1487721600000'
            ],
            [
                '-660',
                '-',
                '+11',
                '-1247529600000'
            ],
            [
                '-720',
                'Russia',
                '+12/+13',
                '670384800000'
            ],
            [
                '-660',
                'Russia',
                '+11/+12',
                '695786400000'
            ],
            [
                '-720',
                'Russia',
                '+12/+13',
                '1269741600000'
            ],
            [
                '-660',
                'Russia',
                '+11/+12',
                '1301191200000'
            ],
            [
                '-720',
                '-',
                '+12'
            ]
        ],
        'Asia/Anadyr': [
            [
                '-709.9333333333334',
                '-',
                'LMT',
                '-1441152000000'
            ],
            [
                '-720',
                '-',
                '+12',
                '-1247529600000'
            ],
            [
                '-780',
                'Russia',
                '+13/+14',
                '386467200000'
            ],
            [
                '-720',
                'Russia',
                '+12/+13',
                '670384800000'
            ],
            [
                '-660',
                'Russia',
                '+11/+12',
                '695786400000'
            ],
            [
                '-720',
                'Russia',
                '+12/+13',
                '1269741600000'
            ],
            [
                '-660',
                'Russia',
                '+11/+12',
                '1301191200000'
            ],
            [
                '-720',
                '-',
                '+12'
            ]
        ],
        'Europe/Belgrade': [
            [
                '-82',
                '-',
                'LMT',
                '-2682374400000'
            ],
            [
                '-60',
                '-',
                'CET',
                '-905821200000'
            ],
            [
                '-60',
                'C-Eur',
                'CE%sT',
                '-757468800000'
            ],
            [
                '-60',
                '-',
                'CET',
                '-777938400000'
            ],
            [
                '-60',
                '1:00',
                'CEST',
                '-766620000000'
            ],
            [
                '-60',
                '-',
                'CET',
                '407203200000'
            ],
            [
                '-60',
                'EU',
                'CE%sT'
            ]
        ],
        'Europe/Ljubljana': 'Europe/Belgrade',
        'Europe/Podgorica': 'Europe/Belgrade',
        'Europe/Sarajevo': 'Europe/Belgrade',
        'Europe/Skopje': 'Europe/Belgrade',
        'Europe/Zagreb': 'Europe/Belgrade',
        'Europe/Bratislava': 'Europe/Prague',
        'Europe/Madrid': [
            [
                '14.733333333333334',
                '-',
                'LMT',
                '-2177453684000'
            ],
            [
                '0',
                'Spain',
                'WE%sT',
                '-940208400000'
            ],
            [
                '-60',
                'Spain',
                'CE%sT',
                '315446400000'
            ],
            [
                '-60',
                'EU',
                'CE%sT'
            ]
        ],
        'Africa/Ceuta': [
            [
                '21.26666666666667',
                '-',
                'LMT',
                '-2177454076000'
            ],
            [
                '0',
                '-',
                'WET',
                '-1630112400000'
            ],
            [
                '0',
                '1:00',
                'WEST',
                '-1616806800000'
            ],
            [
                '0',
                '-',
                'WET',
                '-1420156800000'
            ],
            [
                '0',
                'Spain',
                'WE%sT',
                '-1262390400000'
            ],
            [
                '0',
                '-',
                'WET',
                '-63244800000'
            ],
            [
                '0',
                'SpainAfrica',
                'WE%sT',
                '448243200000'
            ],
            [
                '-60',
                '-',
                'CET',
                '536371200000'
            ],
            [
                '-60',
                'EU',
                'CE%sT'
            ]
        ],
        'Atlantic/Canary': [
            [
                '61.6',
                '-',
                'LMT',
                '-1509667200000'
            ],
            [
                '60',
                '-',
                '-01',
                '-733878000000'
            ],
            [
                '0',
                '-',
                'WET',
                '323827200000'
            ],
            [
                '0',
                '1:00',
                'WEST',
                '338950800000'
            ],
            [
                '0',
                'EU',
                'WE%sT'
            ]
        ],
        'Europe/Stockholm': [
            [
                '-72.2',
                '-',
                'LMT',
                '-2871676800000'
            ],
            [
                '-60.233333333333334',
                '-',
                'SET',
                '-2208988800000'
            ],
            [
                '-60',
                '-',
                'CET',
                '-1692493200000'
            ],
            [
                '-60',
                '1:00',
                'CEST',
                '-1680476400000'
            ],
            [
                '-60',
                '-',
                'CET',
                '347068800000'
            ],
            [
                '-60',
                'EU',
                'CE%sT'
            ]
        ],
        'Europe/Zurich': [
            [
                '-34.13333333333333',
                '-',
                'LMT',
                '-3675196800000'
            ],
            [
                '-29.76666666666667',
                '-',
                'BMT',
                '-2385244800000'
            ],
            [
                '-60',
                'Swiss',
                'CE%sT',
                '378604800000'
            ],
            [
                '-60',
                'EU',
                'CE%sT'
            ]
        ],
        'Europe/Istanbul': [
            [
                '-115.86666666666667',
                '-',
                'LMT',
                '-2808604800000'
            ],
            [
                '-116.93333333333332',
                '-',
                'IMT',
                '-1869868800000'
            ],
            [
                '-120',
                'Turkey',
                'EE%sT',
                '267926400000'
            ],
            [
                '-180',
                'Turkey',
                '+03/+04',
                '468122400000'
            ],
            [
                '-120',
                'Turkey',
                'EE%sT',
                '1199059200000'
            ],
            [
                '-120',
                'EU',
                'EE%sT',
                '1301187600000'
            ],
            [
                '-120',
                '-',
                'EET',
                '1301274000000'
            ],
            [
                '-120',
                'EU',
                'EE%sT',
                '1396141200000'
            ],
            [
                '-120',
                '-',
                'EET',
                '1396227600000'
            ],
            [
                '-120',
                'EU',
                'EE%sT',
                '1445734800000'
            ],
            [
                '-120',
                '1:00',
                'EEST',
                '1446944400000'
            ],
            [
                '-120',
                'EU',
                'EE%sT',
                '1473206400000'
            ],
            [
                '-180',
                '-',
                '+03'
            ]
        ],
        'Asia/Istanbul': 'Europe/Istanbul',
        'Europe/Kiev': [
            [
                '-122.06666666666668',
                '-',
                'LMT',
                '-2808604800000'
            ],
            [
                '-122.06666666666668',
                '-',
                'KMT',
                '-1441152000000'
            ],
            [
                '-120',
                '-',
                'EET',
                '-1247529600000'
            ],
            [
                '-180',
                '-',
                'MSK',
                '-892512000000'
            ],
            [
                '-60',
                'C-Eur',
                'CE%sT',
                '-825379200000'
            ],
            [
                '-180',
                'Russia',
                'MSK/MSD',
                '646797600000'
            ],
            [
                '-120',
                '1:00',
                'EEST',
                '686113200000'
            ],
            [
                '-120',
                'E-Eur',
                'EE%sT',
                '820368000000'
            ],
            [
                '-120',
                'EU',
                'EE%sT'
            ]
        ],
        'Europe/Uzhgorod': [
            [
                '-89.2',
                '-',
                'LMT',
                '-2500934400000'
            ],
            [
                '-60',
                '-',
                'CET',
                '-915235200000'
            ],
            [
                '-60',
                'C-Eur',
                'CE%sT',
                '-796867200000'
            ],
            [
                '-60',
                '1:00',
                'CEST',
                '-794707200000'
            ],
            [
                '-60',
                '-',
                'CET',
                '-773452800000'
            ],
            [
                '-180',
                'Russia',
                'MSK/MSD',
                '662601600000'
            ],
            [
                '-180',
                '-',
                'MSK',
                '646797600000'
            ],
            [
                '-60',
                '-',
                'CET',
                '670388400000'
            ],
            [
                '-120',
                '-',
                'EET',
                '725760000000'
            ],
            [
                '-120',
                'E-Eur',
                'EE%sT',
                '820368000000'
            ],
            [
                '-120',
                'EU',
                'EE%sT'
            ]
        ],
        'Europe/Zaporozhye': [
            [
                '-140.66666666666666',
                '-',
                'LMT',
                '-2808604800000'
            ],
            [
                '-140',
                '-',
                '+0220',
                '-1441152000000'
            ],
            [
                '-120',
                '-',
                'EET',
                '-1247529600000'
            ],
            [
                '-180',
                '-',
                'MSK',
                '-894758400000'
            ],
            [
                '-60',
                'C-Eur',
                'CE%sT',
                '-826416000000'
            ],
            [
                '-180',
                'Russia',
                'MSK/MSD',
                '670384800000'
            ],
            [
                '-120',
                'E-Eur',
                'EE%sT',
                '820368000000'
            ],
            [
                '-120',
                'EU',
                'EE%sT'
            ]
        ],
        'EST': [[
                '300',
                '-',
                'EST'
            ]],
        'MST': [[
                '420',
                '-',
                'MST'
            ]],
        'HST': [[
                '600',
                '-',
                'HST'
            ]],
        'EST5EDT': [[
                '300',
                'US',
                'E%sT'
            ]],
        'CST6CDT': [[
                '360',
                'US',
                'C%sT'
            ]],
        'MST7MDT': [[
                '420',
                'US',
                'M%sT'
            ]],
        'PST8PDT': [[
                '480',
                'US',
                'P%sT'
            ]],
        'America/New_York': [
            [
                '296.0333333333333',
                '-',
                'LMT',
                '-2717668562000'
            ],
            [
                '300',
                'US',
                'E%sT',
                '-1546387200000'
            ],
            [
                '300',
                'NYC',
                'E%sT',
                '-852163200000'
            ],
            [
                '300',
                'US',
                'E%sT',
                '-725932800000'
            ],
            [
                '300',
                'NYC',
                'E%sT',
                '-63244800000'
            ],
            [
                '300',
                'US',
                'E%sT'
            ]
        ],
        'America/Chicago': [
            [
                '350.6',
                '-',
                'LMT',
                '-2717668236000'
            ],
            [
                '360',
                'US',
                'C%sT',
                '-1546387200000'
            ],
            [
                '360',
                'Chicago',
                'C%sT',
                '-1067810400000'
            ],
            [
                '300',
                '-',
                'EST',
                '-1045432800000'
            ],
            [
                '360',
                'Chicago',
                'C%sT',
                '-852163200000'
            ],
            [
                '360',
                'US',
                'C%sT',
                '-725932800000'
            ],
            [
                '360',
                'Chicago',
                'C%sT',
                '-63244800000'
            ],
            [
                '360',
                'US',
                'C%sT'
            ]
        ],
        'America/North_Dakota/Center': [
            [
                '405.2',
                '-',
                'LMT',
                '-2717667912000'
            ],
            [
                '420',
                'US',
                'M%sT',
                '719978400000'
            ],
            [
                '360',
                'US',
                'C%sT'
            ]
        ],
        'America/North_Dakota/New_Salem': [
            [
                '405.65',
                '-',
                'LMT',
                '-2717667939000'
            ],
            [
                '420',
                'US',
                'M%sT',
                '1067133600000'
            ],
            [
                '360',
                'US',
                'C%sT'
            ]
        ],
        'America/North_Dakota/Beulah': [
            [
                '407.1166666666667',
                '-',
                'LMT',
                '-2717668027000'
            ],
            [
                '420',
                'US',
                'M%sT',
                '1289095200000'
            ],
            [
                '360',
                'US',
                'C%sT'
            ]
        ],
        'America/Denver': [
            [
                '419.93333333333334',
                '-',
                'LMT',
                '-2717668796000'
            ],
            [
                '420',
                'US',
                'M%sT',
                '-1546387200000'
            ],
            [
                '420',
                'Denver',
                'M%sT',
                '-852163200000'
            ],
            [
                '420',
                'US',
                'M%sT',
                '-725932800000'
            ],
            [
                '420',
                'Denver',
                'M%sT',
                '-63244800000'
            ],
            [
                '420',
                'US',
                'M%sT'
            ]
        ],
        'America/Los_Angeles': [
            [
                '472.9666666666667',
                '-',
                'LMT',
                '-2717668378000'
            ],
            [
                '480',
                'US',
                'P%sT',
                '-725932800000'
            ],
            [
                '480',
                'CA',
                'P%sT',
                '-63244800000'
            ],
            [
                '480',
                'US',
                'P%sT'
            ]
        ],
        'America/Juneau': [
            [
                '-902.3166666666666',
                '-',
                'LMT',
                '-3225169588000'
            ],
            [
                '537.6833333333334',
                '-',
                'LMT',
                '-2188987200000'
            ],
            [
                '480',
                '-',
                'PST',
                '-852163200000'
            ],
            [
                '480',
                'US',
                'P%sT',
                '-725932800000'
            ],
            [
                '480',
                '-',
                'PST',
                '-86400000'
            ],
            [
                '480',
                'US',
                'P%sT',
                '325648800000'
            ],
            [
                '540',
                'US',
                'Y%sT',
                '341373600000'
            ],
            [
                '480',
                'US',
                'P%sT',
                '436327200000'
            ],
            [
                '540',
                'US',
                'Y%sT',
                '438998400000'
            ],
            [
                '540',
                'US',
                'AK%sT'
            ]
        ],
        'America/Sitka': [
            [
                '-898.7833333333334',
                '-',
                'LMT',
                '-3225169800000'
            ],
            [
                '541.2166666666666',
                '-',
                'LMT',
                '-2188987200000'
            ],
            [
                '480',
                '-',
                'PST',
                '-852163200000'
            ],
            [
                '480',
                'US',
                'P%sT',
                '-725932800000'
            ],
            [
                '480',
                '-',
                'PST',
                '-86400000'
            ],
            [
                '480',
                'US',
                'P%sT',
                '436327200000'
            ],
            [
                '540',
                'US',
                'Y%sT',
                '438998400000'
            ],
            [
                '540',
                'US',
                'AK%sT'
            ]
        ],
        'America/Metlakatla': [
            [
                '-913.7',
                '-',
                'LMT',
                '-3225168905000'
            ],
            [
                '526.3',
                '-',
                'LMT',
                '-2188987200000'
            ],
            [
                '480',
                '-',
                'PST',
                '-852163200000'
            ],
            [
                '480',
                'US',
                'P%sT',
                '-725932800000'
            ],
            [
                '480',
                '-',
                'PST',
                '-86400000'
            ],
            [
                '480',
                'US',
                'P%sT',
                '436327200000'
            ],
            [
                '480',
                '-',
                'PST',
                '1446343200000'
            ],
            [
                '540',
                'US',
                'AK%sT',
                '1541296800000'
            ],
            [
                '480',
                '-',
                'PST',
                '1547949600000'
            ],
            [
                '540',
                'US',
                'AK%sT'
            ]
        ],
        'America/Yakutat': [
            [
                '-881.0833333333334',
                '-',
                'LMT',
                '-3225170862000'
            ],
            [
                '558.9166666666666',
                '-',
                'LMT',
                '-2188987200000'
            ],
            [
                '540',
                '-',
                'YST',
                '-852163200000'
            ],
            [
                '540',
                'US',
                'Y%sT',
                '-725932800000'
            ],
            [
                '540',
                '-',
                'YST',
                '-86400000'
            ],
            [
                '540',
                'US',
                'Y%sT',
                '438998400000'
            ],
            [
                '540',
                'US',
                'AK%sT'
            ]
        ],
        'America/Anchorage': [
            [
                '-840.4',
                '-',
                'LMT',
                '-3225173303000'
            ],
            [
                '599.6',
                '-',
                'LMT',
                '-2188987200000'
            ],
            [
                '600',
                '-',
                'AST',
                '-852163200000'
            ],
            [
                '600',
                'US',
                'A%sT',
                '-86918400000'
            ],
            [
                '600',
                '-',
                'AHST',
                '-86400000'
            ],
            [
                '600',
                'US',
                'AH%sT',
                '436327200000'
            ],
            [
                '540',
                'US',
                'Y%sT',
                '438998400000'
            ],
            [
                '540',
                'US',
                'AK%sT'
            ]
        ],
        'America/Nome': [
            [
                '-778.3666666666667',
                '-',
                'LMT',
                '-3225177025000'
            ],
            [
                '661.6333333333333',
                '-',
                'LMT',
                '-2188987200000'
            ],
            [
                '660',
                '-',
                'NST',
                '-852163200000'
            ],
            [
                '660',
                'US',
                'N%sT',
                '-725932800000'
            ],
            [
                '660',
                '-',
                'NST',
                '-86918400000'
            ],
            [
                '660',
                '-',
                'BST',
                '-86400000'
            ],
            [
                '660',
                'US',
                'B%sT',
                '436327200000'
            ],
            [
                '540',
                'US',
                'Y%sT',
                '438998400000'
            ],
            [
                '540',
                'US',
                'AK%sT'
            ]
        ],
        'America/Adak': [
            [
                '-733.3666666666667',
                '-',
                'LMT',
                '-3225179725000'
            ],
            [
                '706.6333333333333',
                '-',
                'LMT',
                '-2188987200000'
            ],
            [
                '660',
                '-',
                'NST',
                '-852163200000'
            ],
            [
                '660',
                'US',
                'N%sT',
                '-725932800000'
            ],
            [
                '660',
                '-',
                'NST',
                '-86918400000'
            ],
            [
                '660',
                '-',
                'BST',
                '-86400000'
            ],
            [
                '660',
                'US',
                'B%sT',
                '436327200000'
            ],
            [
                '600',
                'US',
                'AH%sT',
                '438998400000'
            ],
            [
                '600',
                'US',
                'H%sT'
            ]
        ],
        'Pacific/Honolulu': [
            [
                '631.4333333333334',
                '-',
                'LMT',
                '-2334139200000'
            ],
            [
                '630',
                '-',
                'HST',
                '-1157320800000'
            ],
            [
                '630',
                '1:00',
                'HDT',
                '-1155470400000'
            ],
            [
                '630',
                'US',
                'H%sT',
                '-712188000000'
            ],
            [
                '600',
                '-',
                'HST'
            ]
        ],
        'America/Phoenix': [
            [
                '448.3',
                '-',
                'LMT',
                '-2717670498000'
            ],
            [
                '420',
                'US',
                'M%sT',
                '-820540740000'
            ],
            [
                '420',
                '-',
                'MST',
                '-812678340000'
            ],
            [
                '420',
                'US',
                'M%sT',
                '-796867140000'
            ],
            [
                '420',
                '-',
                'MST',
                '-63244800000'
            ],
            [
                '420',
                'US',
                'M%sT',
                '-56246400000'
            ],
            [
                '420',
                '-',
                'MST'
            ]
        ],
        'America/Boise': [
            [
                '464.81666666666666',
                '-',
                'LMT',
                '-2717667889000'
            ],
            [
                '480',
                'US',
                'P%sT',
                '-1471816800000'
            ],
            [
                '420',
                'US',
                'M%sT',
                '157680000000'
            ],
            [
                '420',
                '-',
                'MST',
                '129088800000'
            ],
            [
                '420',
                'US',
                'M%sT'
            ]
        ],
        'America/Indiana/Indianapolis': [
            [
                '344.6333333333333',
                '-',
                'LMT',
                '-2717667878000'
            ],
            [
                '360',
                'US',
                'C%sT',
                '-1546387200000'
            ],
            [
                '360',
                'Indianapolis',
                'C%sT',
                '-852163200000'
            ],
            [
                '360',
                'US',
                'C%sT',
                '-725932800000'
            ],
            [
                '360',
                'Indianapolis',
                'C%sT',
                '-463615200000'
            ],
            [
                '300',
                '-',
                'EST',
                '-386805600000'
            ],
            [
                '360',
                '-',
                'CST',
                '-368661600000'
            ],
            [
                '300',
                '-',
                'EST',
                '-86400000'
            ],
            [
                '300',
                'US',
                'E%sT',
                '62985600000'
            ],
            [
                '300',
                '-',
                'EST',
                '1167523200000'
            ],
            [
                '300',
                'US',
                'E%sT'
            ]
        ],
        'America/Indiana/Marengo': [
            [
                '345.3833333333333',
                '-',
                'LMT',
                '-2717667923000'
            ],
            [
                '360',
                'US',
                'C%sT',
                '-568166400000'
            ],
            [
                '360',
                'Marengo',
                'C%sT',
                '-273708000000'
            ],
            [
                '300',
                '-',
                'EST',
                '-86400000'
            ],
            [
                '300',
                'US',
                'E%sT',
                '126669600000'
            ],
            [
                '360',
                '1:00',
                'CDT',
                '152071200000'
            ],
            [
                '300',
                'US',
                'E%sT',
                '220838400000'
            ],
            [
                '300',
                '-',
                'EST',
                '1167523200000'
            ],
            [
                '300',
                'US',
                'E%sT'
            ]
        ],
        'America/Indiana/Vincennes': [
            [
                '350.1166666666667',
                '-',
                'LMT',
                '-2717668207000'
            ],
            [
                '360',
                'US',
                'C%sT',
                '-725932800000'
            ],
            [
                '360',
                'Vincennes',
                'C%sT',
                '-179359200000'
            ],
            [
                '300',
                '-',
                'EST',
                '-86400000'
            ],
            [
                '300',
                'US',
                'E%sT',
                '62985600000'
            ],
            [
                '300',
                '-',
                'EST',
                '1143943200000'
            ],
            [
                '360',
                'US',
                'C%sT',
                '1194141600000'
            ],
            [
                '300',
                'US',
                'E%sT'
            ]
        ],
        'America/Indiana/Tell_City': [
            [
                '347.05',
                '-',
                'LMT',
                '-2717668023000'
            ],
            [
                '360',
                'US',
                'C%sT',
                '-725932800000'
            ],
            [
                '360',
                'Perry',
                'C%sT',
                '-179359200000'
            ],
            [
                '300',
                '-',
                'EST',
                '-68680800000'
            ],
            [
                '360',
                'US',
                'C%sT',
                '-21506400000'
            ],
            [
                '300',
                'US',
                'E%sT',
                '62985600000'
            ],
            [
                '300',
                '-',
                'EST',
                '1143943200000'
            ],
            [
                '360',
                'US',
                'C%sT'
            ]
        ],
        'America/Indiana/Petersburg': [
            [
                '349.1166666666667',
                '-',
                'LMT',
                '-2717668147000'
            ],
            [
                '360',
                'US',
                'C%sT',
                '-441936000000'
            ],
            [
                '360',
                'Pike',
                'C%sT',
                '-147909600000'
            ],
            [
                '300',
                '-',
                'EST',
                '-100130400000'
            ],
            [
                '360',
                'US',
                'C%sT',
                '247024800000'
            ],
            [
                '300',
                '-',
                'EST',
                '1143943200000'
            ],
            [
                '360',
                'US',
                'C%sT',
                '1194141600000'
            ],
            [
                '300',
                'US',
                'E%sT'
            ]
        ],
        'America/Indiana/Knox': [
            [
                '346.5',
                '-',
                'LMT',
                '-2717667990000'
            ],
            [
                '360',
                'US',
                'C%sT',
                '-694396800000'
            ],
            [
                '360',
                'Starke',
                'C%sT',
                '-242258400000'
            ],
            [
                '300',
                '-',
                'EST',
                '-195084000000'
            ],
            [
                '360',
                'US',
                'C%sT',
                '688528800000'
            ],
            [
                '300',
                '-',
                'EST',
                '1143943200000'
            ],
            [
                '360',
                'US',
                'C%sT'
            ]
        ],
        'America/Indiana/Winamac': [
            [
                '346.4166666666667',
                '-',
                'LMT',
                '-2717667985000'
            ],
            [
                '360',
                'US',
                'C%sT',
                '-725932800000'
            ],
            [
                '360',
                'Pulaski',
                'C%sT',
                '-273708000000'
            ],
            [
                '300',
                '-',
                'EST',
                '-86400000'
            ],
            [
                '300',
                'US',
                'E%sT',
                '62985600000'
            ],
            [
                '300',
                '-',
                'EST',
                '1143943200000'
            ],
            [
                '360',
                'US',
                'C%sT',
                '1173578400000'
            ],
            [
                '300',
                'US',
                'E%sT'
            ]
        ],
        'America/Indiana/Vevay': [
            [
                '340.2666666666667',
                '-',
                'LMT',
                '-2717667616000'
            ],
            [
                '360',
                'US',
                'C%sT',
                '-495064800000'
            ],
            [
                '300',
                '-',
                'EST',
                '-86400000'
            ],
            [
                '300',
                'US',
                'E%sT',
                '126144000000'
            ],
            [
                '300',
                '-',
                'EST',
                '1167523200000'
            ],
            [
                '300',
                'US',
                'E%sT'
            ]
        ],
        'America/Kentucky/Louisville': [
            [
                '343.0333333333333',
                '-',
                'LMT',
                '-2717667782000'
            ],
            [
                '360',
                'US',
                'C%sT',
                '-1514851200000'
            ],
            [
                '360',
                'Louisville',
                'C%sT',
                '-852163200000'
            ],
            [
                '360',
                'US',
                'C%sT',
                '-725932800000'
            ],
            [
                '360',
                'Louisville',
                'C%sT',
                '-266450400000'
            ],
            [
                '300',
                '-',
                'EST',
                '-31622400000'
            ],
            [
                '300',
                'US',
                'E%sT',
                '126669600000'
            ],
            [
                '360',
                '1:00',
                'CDT',
                '152071200000'
            ],
            [
                '300',
                'US',
                'E%sT'
            ]
        ],
        'America/Kentucky/Monticello': [
            [
                '339.4',
                '-',
                'LMT',
                '-2717667564000'
            ],
            [
                '360',
                'US',
                'C%sT',
                '-725932800000'
            ],
            [
                '360',
                '-',
                'CST',
                '-31622400000'
            ],
            [
                '360',
                'US',
                'C%sT',
                '972784800000'
            ],
            [
                '300',
                'US',
                'E%sT'
            ]
        ],
        'America/Detroit': [
            [
                '332.18333333333334',
                '-',
                'LMT',
                '-2019772800000'
            ],
            [
                '360',
                '-',
                'CST',
                '-1724104800000'
            ],
            [
                '300',
                '-',
                'EST',
                '-852163200000'
            ],
            [
                '300',
                'US',
                'E%sT',
                '-725932800000'
            ],
            [
                '300',
                'Detroit',
                'E%sT',
                '-80524740000'
            ],
            [
                '300',
                'US',
                'E%sT',
                '-86400000'
            ],
            [
                '300',
                '-',
                'EST',
                '126144000000'
            ],
            [
                '300',
                'US',
                'E%sT',
                '189216000000'
            ],
            [
                '300',
                '-',
                'EST',
                '167796000000'
            ],
            [
                '300',
                'US',
                'E%sT'
            ]
        ],
        'America/Menominee': [
            [
                '350.45',
                '-',
                'LMT',
                '-2659780800000'
            ],
            [
                '360',
                'US',
                'C%sT',
                '-725932800000'
            ],
            [
                '360',
                'Menominee',
                'C%sT',
                '-21506400000'
            ],
            [
                '300',
                '-',
                'EST',
                '104896800000'
            ],
            [
                '360',
                'US',
                'C%sT'
            ]
        ],
        'America/St_Johns': [
            [
                '210.86666666666665',
                '-',
                'LMT',
                '-2682374400000'
            ],
            [
                '210.86666666666665',
                'StJohns',
                'N%sT',
                '-1609545600000'
            ],
            [
                '210.86666666666665',
                'Canada',
                'N%sT',
                '-1578009600000'
            ],
            [
                '210.86666666666665',
                'StJohns',
                'N%sT',
                '-1096934400000'
            ],
            [
                '210',
                'StJohns',
                'N%sT',
                '-872380800000'
            ],
            [
                '210',
                'Canada',
                'N%sT',
                '-725932800000'
            ],
            [
                '210',
                'StJohns',
                'N%sT',
                '1320105600000'
            ],
            [
                '210',
                'Canada',
                'N%sT'
            ]
        ],
        'America/Goose_Bay': [
            [
                '241.66666666666666',
                '-',
                'LMT',
                '-2682374400000'
            ],
            [
                '210.86666666666665',
                '-',
                'NST',
                '-1609545600000'
            ],
            [
                '210.86666666666665',
                'Canada',
                'N%sT',
                '-1578009600000'
            ],
            [
                '210.86666666666665',
                '-',
                'NST',
                '-1096934400000'
            ],
            [
                '210',
                '-',
                'NST',
                '-1041465600000'
            ],
            [
                '210',
                'StJohns',
                'N%sT',
                '-872380800000'
            ],
            [
                '210',
                'Canada',
                'N%sT',
                '-725932800000'
            ],
            [
                '210',
                'StJohns',
                'N%sT',
                '-119916000000'
            ],
            [
                '240',
                'StJohns',
                'A%sT',
                '1320105600000'
            ],
            [
                '240',
                'Canada',
                'A%sT'
            ]
        ],
        'America/Halifax': [
            [
                '254.4',
                '-',
                'LMT',
                '-2131660800000'
            ],
            [
                '240',
                'Halifax',
                'A%sT',
                '-1609545600000'
            ],
            [
                '240',
                'Canada',
                'A%sT',
                '-1578009600000'
            ],
            [
                '240',
                'Halifax',
                'A%sT',
                '-880236000000'
            ],
            [
                '240',
                'Canada',
                'A%sT',
                '-725932800000'
            ],
            [
                '240',
                'Halifax',
                'A%sT',
                '157680000000'
            ],
            [
                '240',
                'Canada',
                'A%sT'
            ]
        ],
        'America/Glace_Bay': [
            [
                '239.8',
                '-',
                'LMT',
                '-2131660800000'
            ],
            [
                '240',
                'Canada',
                'A%sT',
                '-505008000000'
            ],
            [
                '240',
                'Halifax',
                'A%sT',
                '-473472000000'
            ],
            [
                '240',
                '-',
                'AST',
                '94608000000'
            ],
            [
                '240',
                'Halifax',
                'A%sT',
                '157680000000'
            ],
            [
                '240',
                'Canada',
                'A%sT'
            ]
        ],
        'America/Moncton': [
            [
                '259.1333333333333',
                '-',
                'LMT',
                '-2715897600000'
            ],
            [
                '300',
                '-',
                'EST',
                '-2131660800000'
            ],
            [
                '240',
                'Canada',
                'A%sT',
                '-1136160000000'
            ],
            [
                '240',
                'Moncton',
                'A%sT',
                '-852163200000'
            ],
            [
                '240',
                'Canada',
                'A%sT',
                '-725932800000'
            ],
            [
                '240',
                'Moncton',
                'A%sT',
                '126144000000'
            ],
            [
                '240',
                'Canada',
                'A%sT',
                '757296000000'
            ],
            [
                '240',
                'Moncton',
                'A%sT',
                '1199059200000'
            ],
            [
                '240',
                'Canada',
                'A%sT'
            ]
        ],
        'America/Blanc-Sablon': [
            [
                '228.46666666666667',
                '-',
                'LMT',
                '-2682374400000'
            ],
            [
                '240',
                'Canada',
                'A%sT',
                '31449600000'
            ],
            [
                '240',
                '-',
                'AST'
            ]
        ],
        'America/Toronto': [
            [
                '317.5333333333333',
                '-',
                'LMT',
                '-2335305600000'
            ],
            [
                '300',
                'Canada',
                'E%sT',
                '-1578009600000'
            ],
            [
                '300',
                'Toronto',
                'E%sT',
                '-880236000000'
            ],
            [
                '300',
                'Canada',
                'E%sT',
                '-725932800000'
            ],
            [
                '300',
                'Toronto',
                'E%sT',
                '157680000000'
            ],
            [
                '300',
                'Canada',
                'E%sT'
            ]
        ],
        'America/Thunder_Bay': [
            [
                '357',
                '-',
                'LMT',
                '-2335305600000'
            ],
            [
                '360',
                '-',
                'CST',
                '-1862006400000'
            ],
            [
                '300',
                '-',
                'EST',
                '-852163200000'
            ],
            [
                '300',
                'Canada',
                'E%sT',
                '31449600000'
            ],
            [
                '300',
                'Toronto',
                'E%sT',
                '126144000000'
            ],
            [
                '300',
                '-',
                'EST',
                '157680000000'
            ],
            [
                '300',
                'Canada',
                'E%sT'
            ]
        ],
        'America/Nipigon': [
            [
                '353.06666666666666',
                '-',
                'LMT',
                '-2335305600000'
            ],
            [
                '300',
                'Canada',
                'E%sT',
                '-923270400000'
            ],
            [
                '300',
                '1:00',
                'EDT',
                '-880236000000'
            ],
            [
                '300',
                'Canada',
                'E%sT'
            ]
        ],
        'America/Rainy_River': [
            [
                '378.2666666666667',
                '-',
                'LMT',
                '-2335305600000'
            ],
            [
                '360',
                'Canada',
                'C%sT',
                '-923270400000'
            ],
            [
                '360',
                '1:00',
                'CDT',
                '-880236000000'
            ],
            [
                '360',
                'Canada',
                'C%sT'
            ]
        ],
        'America/Atikokan': [
            [
                '366.4666666666667',
                '-',
                'LMT',
                '-2335305600000'
            ],
            [
                '360',
                'Canada',
                'C%sT',
                '-923270400000'
            ],
            [
                '360',
                '1:00',
                'CDT',
                '-880236000000'
            ],
            [
                '360',
                'Canada',
                'C%sT',
                '-765410400000'
            ],
            [
                '300',
                '-',
                'EST'
            ]
        ],
        'America/Winnipeg': [
            [
                '388.6',
                '-',
                'LMT',
                '-2602281600000'
            ],
            [
                '360',
                'Winn',
                'C%sT',
                '1167523200000'
            ],
            [
                '360',
                'Canada',
                'C%sT'
            ]
        ],
        'America/Regina': [
            [
                '418.6',
                '-',
                'LMT',
                '-2030227200000'
            ],
            [
                '420',
                'Regina',
                'M%sT',
                '-307749600000'
            ],
            [
                '360',
                '-',
                'CST'
            ]
        ],
        'America/Swift_Current': [
            [
                '431.3333333333333',
                '-',
                'LMT',
                '-2030227200000'
            ],
            [
                '420',
                'Canada',
                'M%sT',
                '-749599200000'
            ],
            [
                '420',
                'Regina',
                'M%sT',
                '-599702400000'
            ],
            [
                '420',
                'Swift',
                'M%sT',
                '70941600000'
            ],
            [
                '360',
                '-',
                'CST'
            ]
        ],
        'America/Edmonton': [
            [
                '453.8666666666667',
                '-',
                'LMT',
                '-1998691200000'
            ],
            [
                '420',
                'Edm',
                'M%sT',
                '567907200000'
            ],
            [
                '420',
                'Canada',
                'M%sT'
            ]
        ],
        'America/Vancouver': [
            [
                '492.4666666666667',
                '-',
                'LMT',
                '-2682374400000'
            ],
            [
                '480',
                'Vanc',
                'P%sT',
                '567907200000'
            ],
            [
                '480',
                'Canada',
                'P%sT'
            ]
        ],
        'America/Dawson_Creek': [
            [
                '480.93333333333334',
                '-',
                'LMT',
                '-2682374400000'
            ],
            [
                '480',
                'Canada',
                'P%sT',
                '-694396800000'
            ],
            [
                '480',
                'Vanc',
                'P%sT',
                '83988000000'
            ],
            [
                '420',
                '-',
                'MST'
            ]
        ],
        'America/Fort_Nelson': [
            [
                '490.7833333333333',
                '-',
                'LMT',
                '-2682374400000'
            ],
            [
                '480',
                'Vanc',
                'P%sT',
                '-725932800000'
            ],
            [
                '480',
                '-',
                'PST',
                '-694396800000'
            ],
            [
                '480',
                'Vanc',
                'P%sT',
                '567907200000'
            ],
            [
                '480',
                'Canada',
                'P%sT',
                '1425780000000'
            ],
            [
                '420',
                '-',
                'MST'
            ]
        ],
        'America/Creston': [
            [
                '466.06666666666666',
                '-',
                'LMT',
                '-2682374400000'
            ],
            [
                '420',
                '-',
                'MST',
                '-1680480000000'
            ],
            [
                '480',
                '-',
                'PST',
                '-1627862400000'
            ],
            [
                '420',
                '-',
                'MST'
            ]
        ],
        'America/Pangnirtung': [
            [
                '0',
                '-',
                '-00',
                '-1514851200000'
            ],
            [
                '240',
                'NT_YK',
                'A%sT',
                '796701600000'
            ],
            [
                '300',
                'Canada',
                'E%sT',
                '941335200000'
            ],
            [
                '360',
                'Canada',
                'C%sT',
                '972784800000'
            ],
            [
                '300',
                'Canada',
                'E%sT'
            ]
        ],
        'America/Iqaluit': [
            [
                '0',
                '-',
                '-00',
                '-865296000000'
            ],
            [
                '300',
                'NT_YK',
                'E%sT',
                '941335200000'
            ],
            [
                '360',
                'Canada',
                'C%sT',
                '972784800000'
            ],
            [
                '300',
                'Canada',
                'E%sT'
            ]
        ],
        'America/Resolute': [
            [
                '0',
                '-',
                '-00',
                '-704937600000'
            ],
            [
                '360',
                'NT_YK',
                'C%sT',
                '972784800000'
            ],
            [
                '300',
                '-',
                'EST',
                '986094000000'
            ],
            [
                '360',
                'Canada',
                'C%sT',
                '1162087200000'
            ],
            [
                '300',
                '-',
                'EST',
                '1173582000000'
            ],
            [
                '360',
                'Canada',
                'C%sT'
            ]
        ],
        'America/Rankin_Inlet': [
            [
                '0',
                '-',
                '-00',
                '-378777600000'
            ],
            [
                '360',
                'NT_YK',
                'C%sT',
                '972784800000'
            ],
            [
                '300',
                '-',
                'EST',
                '986094000000'
            ],
            [
                '360',
                'Canada',
                'C%sT'
            ]
        ],
        'America/Cambridge_Bay': [
            [
                '0',
                '-',
                '-00',
                '-1546387200000'
            ],
            [
                '420',
                'NT_YK',
                'M%sT',
                '941335200000'
            ],
            [
                '360',
                'Canada',
                'C%sT',
                '972784800000'
            ],
            [
                '300',
                '-',
                'EST',
                '973382400000'
            ],
            [
                '360',
                '-',
                'CST',
                '986094000000'
            ],
            [
                '420',
                'Canada',
                'M%sT'
            ]
        ],
        'America/Yellowknife': [
            [
                '0',
                '-',
                '-00',
                '-1073088000000'
            ],
            [
                '420',
                'NT_YK',
                'M%sT',
                '347068800000'
            ],
            [
                '420',
                'Canada',
                'M%sT'
            ]
        ],
        'America/Inuvik': [
            [
                '0',
                '-',
                '-00',
                '-505008000000'
            ],
            [
                '480',
                'NT_YK',
                'P%sT',
                '291780000000'
            ],
            [
                '420',
                'NT_YK',
                'M%sT',
                '347068800000'
            ],
            [
                '420',
                'Canada',
                'M%sT'
            ]
        ],
        'America/Whitehorse': [
            [
                '540.2',
                '-',
                'LMT',
                '-2189030400000'
            ],
            [
                '540',
                'NT_YK',
                'Y%sT',
                '-81993600000'
            ],
            [
                '480',
                'NT_YK',
                'P%sT',
                '347068800000'
            ],
            [
                '480',
                'Canada',
                'P%sT'
            ]
        ],
        'America/Dawson': [
            [
                '557.6666666666666',
                '-',
                'LMT',
                '-2189030400000'
            ],
            [
                '540',
                'NT_YK',
                'Y%sT',
                '120614400000'
            ],
            [
                '480',
                'NT_YK',
                'P%sT',
                '347068800000'
            ],
            [
                '480',
                'Canada',
                'P%sT'
            ]
        ],
        'America/Cancun': [
            [
                '347.06666666666666',
                '-',
                'LMT',
                '-1514764024000'
            ],
            [
                '360',
                '-',
                'CST',
                '377913600000'
            ],
            [
                '300',
                'Mexico',
                'E%sT',
                '902023200000'
            ],
            [
                '360',
                'Mexico',
                'C%sT',
                '1422756000000'
            ],
            [
                '300',
                '-',
                'EST'
            ]
        ],
        'America/Merida': [
            [
                '358.4666666666667',
                '-',
                'LMT',
                '-1514764708000'
            ],
            [
                '360',
                '-',
                'CST',
                '377913600000'
            ],
            [
                '300',
                '-',
                'EST',
                '407635200000'
            ],
            [
                '360',
                'Mexico',
                'C%sT'
            ]
        ],
        'America/Matamoros': [
            [
                '400',
                '-',
                'LMT',
                '-1514767200000'
            ],
            [
                '360',
                '-',
                'CST',
                '599529600000'
            ],
            [
                '360',
                'US',
                'C%sT',
                '631065600000'
            ],
            [
                '360',
                'Mexico',
                'C%sT',
                '1293753600000'
            ],
            [
                '360',
                'US',
                'C%sT'
            ]
        ],
        'America/Monterrey': [
            [
                '401.2666666666667',
                '-',
                'LMT',
                '-1514767276000'
            ],
            [
                '360',
                '-',
                'CST',
                '599529600000'
            ],
            [
                '360',
                'US',
                'C%sT',
                '631065600000'
            ],
            [
                '360',
                'Mexico',
                'C%sT'
            ]
        ],
        'America/Mexico_City': [
            [
                '396.6',
                '-',
                'LMT',
                '-1514763396000'
            ],
            [
                '420',
                '-',
                'MST',
                '-1343091600000'
            ],
            [
                '360',
                '-',
                'CST',
                '-1234828800000'
            ],
            [
                '420',
                '-',
                'MST',
                '-1220317200000'
            ],
            [
                '360',
                '-',
                'CST',
                '-1207180800000'
            ],
            [
                '420',
                '-',
                'MST',
                '-1191369600000'
            ],
            [
                '360',
                'Mexico',
                'C%sT',
                '1001815200000'
            ],
            [
                '360',
                '-',
                'CST',
                '1014163200000'
            ],
            [
                '360',
                'Mexico',
                'C%sT'
            ]
        ],
        'America/Ojinaga': [
            [
                '417.6666666666667',
                '-',
                'LMT',
                '-1514764660000'
            ],
            [
                '420',
                '-',
                'MST',
                '-1343091600000'
            ],
            [
                '360',
                '-',
                'CST',
                '-1234828800000'
            ],
            [
                '420',
                '-',
                'MST',
                '-1220317200000'
            ],
            [
                '360',
                '-',
                'CST',
                '-1207180800000'
            ],
            [
                '420',
                '-',
                'MST',
                '-1191369600000'
            ],
            [
                '360',
                '-',
                'CST',
                '851990400000'
            ],
            [
                '360',
                'Mexico',
                'C%sT',
                '915062400000'
            ],
            [
                '360',
                '-',
                'CST',
                '891399600000'
            ],
            [
                '420',
                'Mexico',
                'M%sT',
                '1293753600000'
            ],
            [
                '420',
                'US',
                'M%sT'
            ]
        ],
        'America/Chihuahua': [
            [
                '424.3333333333333',
                '-',
                'LMT',
                '-1514765060000'
            ],
            [
                '420',
                '-',
                'MST',
                '-1343091600000'
            ],
            [
                '360',
                '-',
                'CST',
                '-1234828800000'
            ],
            [
                '420',
                '-',
                'MST',
                '-1220317200000'
            ],
            [
                '360',
                '-',
                'CST',
                '-1207180800000'
            ],
            [
                '420',
                '-',
                'MST',
                '-1191369600000'
            ],
            [
                '360',
                '-',
                'CST',
                '851990400000'
            ],
            [
                '360',
                'Mexico',
                'C%sT',
                '915062400000'
            ],
            [
                '360',
                '-',
                'CST',
                '891399600000'
            ],
            [
                '420',
                'Mexico',
                'M%sT'
            ]
        ],
        'America/Hermosillo': [
            [
                '443.8666666666667',
                '-',
                'LMT',
                '-1514766232000'
            ],
            [
                '420',
                '-',
                'MST',
                '-1343091600000'
            ],
            [
                '360',
                '-',
                'CST',
                '-1234828800000'
            ],
            [
                '420',
                '-',
                'MST',
                '-1220317200000'
            ],
            [
                '360',
                '-',
                'CST',
                '-1207180800000'
            ],
            [
                '420',
                '-',
                'MST',
                '-1191369600000'
            ],
            [
                '360',
                '-',
                'CST',
                '-873849600000'
            ],
            [
                '420',
                '-',
                'MST',
                '-661564800000'
            ],
            [
                '480',
                '-',
                'PST',
                '31449600000'
            ],
            [
                '420',
                'Mexico',
                'M%sT',
                '946598400000'
            ],
            [
                '420',
                '-',
                'MST'
            ]
        ],
        'America/Mazatlan': [
            [
                '425.6666666666667',
                '-',
                'LMT',
                '-1514765140000'
            ],
            [
                '420',
                '-',
                'MST',
                '-1343091600000'
            ],
            [
                '360',
                '-',
                'CST',
                '-1234828800000'
            ],
            [
                '420',
                '-',
                'MST',
                '-1220317200000'
            ],
            [
                '360',
                '-',
                'CST',
                '-1207180800000'
            ],
            [
                '420',
                '-',
                'MST',
                '-1191369600000'
            ],
            [
                '360',
                '-',
                'CST',
                '-873849600000'
            ],
            [
                '420',
                '-',
                'MST',
                '-661564800000'
            ],
            [
                '480',
                '-',
                'PST',
                '31449600000'
            ],
            [
                '420',
                'Mexico',
                'M%sT'
            ]
        ],
        'America/Bahia_Banderas': [
            [
                '421',
                '-',
                'LMT',
                '-1514764860000'
            ],
            [
                '420',
                '-',
                'MST',
                '-1343091600000'
            ],
            [
                '360',
                '-',
                'CST',
                '-1234828800000'
            ],
            [
                '420',
                '-',
                'MST',
                '-1220317200000'
            ],
            [
                '360',
                '-',
                'CST',
                '-1207180800000'
            ],
            [
                '420',
                '-',
                'MST',
                '-1191369600000'
            ],
            [
                '360',
                '-',
                'CST',
                '-873849600000'
            ],
            [
                '420',
                '-',
                'MST',
                '-661564800000'
            ],
            [
                '480',
                '-',
                'PST',
                '31449600000'
            ],
            [
                '420',
                'Mexico',
                'M%sT',
                '1270346400000'
            ],
            [
                '360',
                'Mexico',
                'C%sT'
            ]
        ],
        'America/Tijuana': [
            [
                '468.06666666666666',
                '-',
                'LMT',
                '-1514764084000'
            ],
            [
                '420',
                '-',
                'MST',
                '-1420156800000'
            ],
            [
                '480',
                '-',
                'PST',
                '-1343091600000'
            ],
            [
                '420',
                '-',
                'MST',
                '-1234828800000'
            ],
            [
                '480',
                '-',
                'PST',
                '-1222992000000'
            ],
            [
                '480',
                '1:00',
                'PDT',
                '-1207267200000'
            ],
            [
                '480',
                '-',
                'PST',
                '-873849600000'
            ],
            [
                '480',
                '1:00',
                'PWT',
                '-769395600000'
            ],
            [
                '480',
                '1:00',
                'PPT',
                '-761702400000'
            ],
            [
                '480',
                '-',
                'PST',
                '-686102400000'
            ],
            [
                '480',
                '1:00',
                'PDT',
                '-661564800000'
            ],
            [
                '480',
                '-',
                'PST',
                '-473472000000'
            ],
            [
                '480',
                'CA',
                'P%sT',
                '-252547200000'
            ],
            [
                '480',
                '-',
                'PST',
                '220838400000'
            ],
            [
                '480',
                'US',
                'P%sT',
                '851990400000'
            ],
            [
                '480',
                'Mexico',
                'P%sT',
                '1009756800000'
            ],
            [
                '480',
                'US',
                'P%sT',
                '1014163200000'
            ],
            [
                '480',
                'Mexico',
                'P%sT',
                '1293753600000'
            ],
            [
                '480',
                'US',
                'P%sT'
            ]
        ],
        'America/Nassau': [
            [
                '309.5',
                '-',
                'LMT',
                '-1825113600000'
            ],
            [
                '300',
                'Bahamas',
                'E%sT',
                '220838400000'
            ],
            [
                '300',
                'US',
                'E%sT'
            ]
        ],
        'America/Barbados': [
            [
                '238.48333333333335',
                '-',
                'LMT',
                '-1420156800000'
            ],
            [
                '238.48333333333335',
                '-',
                'BMT',
                '-1167696000000'
            ],
            [
                '240',
                'Barb',
                'A%sT'
            ]
        ],
        'America/Belize': [
            [
                '352.8',
                '-',
                'LMT',
                '-1822521600000'
            ],
            [
                '360',
                'Belize',
                '%s'
            ]
        ],
        'Atlantic/Bermuda': [
            [
                '259.3',
                '-',
                'LMT',
                '-1262296800000'
            ],
            [
                '240',
                '-',
                'AST',
                '136346400000'
            ],
            [
                '240',
                'Canada',
                'A%sT',
                '220838400000'
            ],
            [
                '240',
                'US',
                'A%sT'
            ]
        ],
        'America/Costa_Rica': [
            [
                '336.2166666666667',
                '-',
                'LMT',
                '-2493072000000'
            ],
            [
                '336.2166666666667',
                '-',
                'SJMT',
                '-1545091200000'
            ],
            [
                '360',
                'CR',
                'C%sT'
            ]
        ],
        'America/Havana': [
            [
                '329.4666666666667',
                '-',
                'LMT',
                '-2493072000000'
            ],
            [
                '329.6',
                '-',
                'HMT',
                '-1402833600000'
            ],
            [
                '300',
                'Cuba',
                'C%sT'
            ]
        ],
        'America/Santo_Domingo': [
            [
                '279.6',
                '-',
                'LMT',
                '-2493072000000'
            ],
            [
                '280',
                '-',
                'SDMT',
                '-1159790400000'
            ],
            [
                '300',
                'DR',
                '%s',
                '152064000000'
            ],
            [
                '240',
                '-',
                'AST',
                '972784800000'
            ],
            [
                '300',
                'US',
                'E%sT',
                '975805200000'
            ],
            [
                '240',
                '-',
                'AST'
            ]
        ],
        'America/El_Salvador': [
            [
                '356.8',
                '-',
                'LMT',
                '-1514851200000'
            ],
            [
                '360',
                'Salv',
                'C%sT'
            ]
        ],
        'America/Guatemala': [
            [
                '362.06666666666666',
                '-',
                'LMT',
                '-1617062400000'
            ],
            [
                '360',
                'Guat',
                'C%sT'
            ]
        ],
        'America/Port-au-Prince': [
            [
                '289.3333333333333',
                '-',
                'LMT',
                '-2493072000000'
            ],
            [
                '289',
                '-',
                'PPMT',
                '-1670500800000'
            ],
            [
                '300',
                'Haiti',
                'E%sT'
            ]
        ],
        'America/Tegucigalpa': [
            [
                '348.8666666666667',
                '-',
                'LMT',
                '-1538524800000'
            ],
            [
                '360',
                'Hond',
                'C%sT'
            ]
        ],
        'America/Jamaica': [
            [
                '307.1666666666667',
                '-',
                'LMT',
                '-2493072000000'
            ],
            [
                '307.1666666666667',
                '-',
                'KMT',
                '-1827705600000'
            ],
            [
                '300',
                '-',
                'EST',
                '157680000000'
            ],
            [
                '300',
                'US',
                'E%sT',
                '473299200000'
            ],
            [
                '300',
                '-',
                'EST'
            ]
        ],
        'America/Martinique': [
            [
                '244.33333333333334',
                '-',
                'LMT',
                '-2493072000000'
            ],
            [
                '244.33333333333334',
                '-',
                'FFMT',
                '-1851552000000'
            ],
            [
                '240',
                '-',
                'AST',
                '323827200000'
            ],
            [
                '240',
                '1:00',
                'ADT',
                '338947200000'
            ],
            [
                '240',
                '-',
                'AST'
            ]
        ],
        'America/Managua': [
            [
                '345.1333333333333',
                '-',
                'LMT',
                '-2493072000000'
            ],
            [
                '345.2',
                '-',
                'MMT',
                '-1121126400000'
            ],
            [
                '360',
                '-',
                'CST',
                '105062400000'
            ],
            [
                '300',
                '-',
                'EST',
                '161740800000'
            ],
            [
                '360',
                'Nic',
                'C%sT',
                '694238400000'
            ],
            [
                '300',
                '-',
                'EST',
                '717292800000'
            ],
            [
                '360',
                '-',
                'CST',
                '757296000000'
            ],
            [
                '300',
                '-',
                'EST',
                '883526400000'
            ],
            [
                '360',
                'Nic',
                'C%sT'
            ]
        ],
        'America/Panama': [
            [
                '318.1333333333333',
                '-',
                'LMT',
                '-2493072000000'
            ],
            [
                '319.6',
                '-',
                'CMT',
                '-1946937600000'
            ],
            [
                '300',
                '-',
                'EST'
            ]
        ],
        'America/Cayman': 'America/Panama',
        'America/Puerto_Rico': [
            [
                '264.4166666666667',
                '-',
                'LMT',
                '-2233051200000'
            ],
            [
                '240',
                '-',
                'AST',
                '-873072000000'
            ],
            [
                '240',
                'US',
                'A%sT',
                '-725932800000'
            ],
            [
                '240',
                '-',
                'AST'
            ]
        ],
        'America/Miquelon': [
            [
                '224.66666666666666',
                '-',
                'LMT',
                '-1850342400000'
            ],
            [
                '240',
                '-',
                'AST',
                '325987200000'
            ],
            [
                '180',
                '-',
                '-03',
                '567907200000'
            ],
            [
                '180',
                'Canada',
                '-03/-02'
            ]
        ],
        'America/Grand_Turk': [
            [
                '284.5333333333333',
                '-',
                'LMT',
                '-2493072000000'
            ],
            [
                '307.1666666666667',
                '-',
                'KMT',
                '-1827705600000'
            ],
            [
                '300',
                '-',
                'EST',
                '315446400000'
            ],
            [
                '300',
                'US',
                'E%sT',
                '1446343200000'
            ],
            [
                '240',
                '-',
                'AST',
                '1520737200000'
            ],
            [
                '300',
                'US',
                'E%sT'
            ]
        ],
        'US/Pacific-New': 'America/Los_Angeles',
        'America/Argentina/Buenos_Aires': [
            [
                '233.8',
                '-',
                'LMT',
                '-2372112000000'
            ],
            [
                '256.8',
                '-',
                'CMT',
                '-1567468800000'
            ],
            [
                '240',
                '-',
                '-04',
                '-1233446400000'
            ],
            [
                '240',
                'Arg',
                '-04/-03',
                '-7603200000'
            ],
            [
                '180',
                'Arg',
                '-03/-02',
                '938908800000'
            ],
            [
                '240',
                'Arg',
                '-04/-03',
                '952041600000'
            ],
            [
                '180',
                'Arg',
                '-03/-02'
            ]
        ],
        'America/Argentina/Cordoba': [
            [
                '256.8',
                '-',
                'LMT',
                '-2372112000000'
            ],
            [
                '256.8',
                '-',
                'CMT',
                '-1567468800000'
            ],
            [
                '240',
                '-',
                '-04',
                '-1233446400000'
            ],
            [
                '240',
                'Arg',
                '-04/-03',
                '-7603200000'
            ],
            [
                '180',
                'Arg',
                '-03/-02',
                '667958400000'
            ],
            [
                '240',
                '-',
                '-04',
                '687916800000'
            ],
            [
                '180',
                'Arg',
                '-03/-02',
                '938908800000'
            ],
            [
                '240',
                'Arg',
                '-04/-03',
                '952041600000'
            ],
            [
                '180',
                'Arg',
                '-03/-02'
            ]
        ],
        'America/Argentina/Salta': [
            [
                '261.66666666666663',
                '-',
                'LMT',
                '-2372112000000'
            ],
            [
                '256.8',
                '-',
                'CMT',
                '-1567468800000'
            ],
            [
                '240',
                '-',
                '-04',
                '-1233446400000'
            ],
            [
                '240',
                'Arg',
                '-04/-03',
                '-7603200000'
            ],
            [
                '180',
                'Arg',
                '-03/-02',
                '667958400000'
            ],
            [
                '240',
                '-',
                '-04',
                '687916800000'
            ],
            [
                '180',
                'Arg',
                '-03/-02',
                '938908800000'
            ],
            [
                '240',
                'Arg',
                '-04/-03',
                '952041600000'
            ],
            [
                '180',
                'Arg',
                '-03/-02',
                '1224288000000'
            ],
            [
                '180',
                '-',
                '-03'
            ]
        ],
        'America/Argentina/Tucuman': [
            [
                '260.8666666666667',
                '-',
                'LMT',
                '-2372112000000'
            ],
            [
                '256.8',
                '-',
                'CMT',
                '-1567468800000'
            ],
            [
                '240',
                '-',
                '-04',
                '-1233446400000'
            ],
            [
                '240',
                'Arg',
                '-04/-03',
                '-7603200000'
            ],
            [
                '180',
                'Arg',
                '-03/-02',
                '667958400000'
            ],
            [
                '240',
                '-',
                '-04',
                '687916800000'
            ],
            [
                '180',
                'Arg',
                '-03/-02',
                '938908800000'
            ],
            [
                '240',
                'Arg',
                '-04/-03',
                '952041600000'
            ],
            [
                '180',
                '-',
                '-03',
                '1086048000000'
            ],
            [
                '240',
                '-',
                '-04',
                '1087084800000'
            ],
            [
                '180',
                'Arg',
                '-03/-02'
            ]
        ],
        'America/Argentina/La_Rioja': [
            [
                '267.4',
                '-',
                'LMT',
                '-2372112000000'
            ],
            [
                '256.8',
                '-',
                'CMT',
                '-1567468800000'
            ],
            [
                '240',
                '-',
                '-04',
                '-1233446400000'
            ],
            [
                '240',
                'Arg',
                '-04/-03',
                '-7603200000'
            ],
            [
                '180',
                'Arg',
                '-03/-02',
                '667785600000'
            ],
            [
                '240',
                '-',
                '-04',
                '673574400000'
            ],
            [
                '180',
                'Arg',
                '-03/-02',
                '938908800000'
            ],
            [
                '240',
                'Arg',
                '-04/-03',
                '952041600000'
            ],
            [
                '180',
                '-',
                '-03',
                '1086048000000'
            ],
            [
                '240',
                '-',
                '-04',
                '1087689600000'
            ],
            [
                '180',
                'Arg',
                '-03/-02',
                '1224288000000'
            ],
            [
                '180',
                '-',
                '-03'
            ]
        ],
        'America/Argentina/San_Juan': [
            [
                '274.06666666666666',
                '-',
                'LMT',
                '-2372112000000'
            ],
            [
                '256.8',
                '-',
                'CMT',
                '-1567468800000'
            ],
            [
                '240',
                '-',
                '-04',
                '-1233446400000'
            ],
            [
                '240',
                'Arg',
                '-04/-03',
                '-7603200000'
            ],
            [
                '180',
                'Arg',
                '-03/-02',
                '667785600000'
            ],
            [
                '240',
                '-',
                '-04',
                '673574400000'
            ],
            [
                '180',
                'Arg',
                '-03/-02',
                '938908800000'
            ],
            [
                '240',
                'Arg',
                '-04/-03',
                '952041600000'
            ],
            [
                '180',
                '-',
                '-03',
                '1085961600000'
            ],
            [
                '240',
                '-',
                '-04',
                '1090713600000'
            ],
            [
                '180',
                'Arg',
                '-03/-02',
                '1224288000000'
            ],
            [
                '180',
                '-',
                '-03'
            ]
        ],
        'America/Argentina/Jujuy': [
            [
                '261.2',
                '-',
                'LMT',
                '-2372112000000'
            ],
            [
                '256.8',
                '-',
                'CMT',
                '-1567468800000'
            ],
            [
                '240',
                '-',
                '-04',
                '-1233446400000'
            ],
            [
                '240',
                'Arg',
                '-04/-03',
                '-7603200000'
            ],
            [
                '180',
                'Arg',
                '-03/-02',
                '636508800000'
            ],
            [
                '240',
                '-',
                '-04',
                '657072000000'
            ],
            [
                '240',
                '1:00',
                '-03',
                '669168000000'
            ],
            [
                '240',
                '-',
                '-04',
                '686707200000'
            ],
            [
                '180',
                '1:00',
                '-02',
                '725760000000'
            ],
            [
                '180',
                'Arg',
                '-03/-02',
                '938908800000'
            ],
            [
                '240',
                'Arg',
                '-04/-03',
                '952041600000'
            ],
            [
                '180',
                'Arg',
                '-03/-02',
                '1224288000000'
            ],
            [
                '180',
                '-',
                '-03'
            ]
        ],
        'America/Argentina/Catamarca': [
            [
                '263.1333333333333',
                '-',
                'LMT',
                '-2372112000000'
            ],
            [
                '256.8',
                '-',
                'CMT',
                '-1567468800000'
            ],
            [
                '240',
                '-',
                '-04',
                '-1233446400000'
            ],
            [
                '240',
                'Arg',
                '-04/-03',
                '-7603200000'
            ],
            [
                '180',
                'Arg',
                '-03/-02',
                '667958400000'
            ],
            [
                '240',
                '-',
                '-04',
                '687916800000'
            ],
            [
                '180',
                'Arg',
                '-03/-02',
                '938908800000'
            ],
            [
                '240',
                'Arg',
                '-04/-03',
                '952041600000'
            ],
            [
                '180',
                '-',
                '-03',
                '1086048000000'
            ],
            [
                '240',
                '-',
                '-04',
                '1087689600000'
            ],
            [
                '180',
                'Arg',
                '-03/-02',
                '1224288000000'
            ],
            [
                '180',
                '-',
                '-03'
            ]
        ],
        'America/Argentina/Mendoza': [
            [
                '275.2666666666667',
                '-',
                'LMT',
                '-2372112000000'
            ],
            [
                '256.8',
                '-',
                'CMT',
                '-1567468800000'
            ],
            [
                '240',
                '-',
                '-04',
                '-1233446400000'
            ],
            [
                '240',
                'Arg',
                '-04/-03',
                '-7603200000'
            ],
            [
                '180',
                'Arg',
                '-03/-02',
                '636508800000'
            ],
            [
                '240',
                '-',
                '-04',
                '655948800000'
            ],
            [
                '240',
                '1:00',
                '-03',
                '667785600000'
            ],
            [
                '240',
                '-',
                '-04',
                '687484800000'
            ],
            [
                '240',
                '1:00',
                '-03',
                '699408000000'
            ],
            [
                '240',
                '-',
                '-04',
                '719366400000'
            ],
            [
                '180',
                'Arg',
                '-03/-02',
                '938908800000'
            ],
            [
                '240',
                'Arg',
                '-04/-03',
                '952041600000'
            ],
            [
                '180',
                '-',
                '-03',
                '1085270400000'
            ],
            [
                '240',
                '-',
                '-04',
                '1096156800000'
            ],
            [
                '180',
                'Arg',
                '-03/-02',
                '1224288000000'
            ],
            [
                '180',
                '-',
                '-03'
            ]
        ],
        'America/Argentina/San_Luis': [
            [
                '265.4',
                '-',
                'LMT',
                '-2372112000000'
            ],
            [
                '256.8',
                '-',
                'CMT',
                '-1567468800000'
            ],
            [
                '240',
                '-',
                '-04',
                '-1233446400000'
            ],
            [
                '240',
                'Arg',
                '-04/-03',
                '-7603200000'
            ],
            [
                '180',
                'Arg',
                '-03/-02',
                '662601600000'
            ],
            [
                '180',
                '1:00',
                '-02',
                '637372800000'
            ],
            [
                '240',
                '-',
                '-04',
                '655948800000'
            ],
            [
                '240',
                '1:00',
                '-03',
                '667785600000'
            ],
            [
                '240',
                '-',
                '-04',
                '675734400000'
            ],
            [
                '180',
                '-',
                '-03',
                '938908800000'
            ],
            [
                '240',
                '1:00',
                '-03',
                '952041600000'
            ],
            [
                '180',
                '-',
                '-03',
                '1085961600000'
            ],
            [
                '240',
                '-',
                '-04',
                '1090713600000'
            ],
            [
                '180',
                'Arg',
                '-03/-02',
                '1200873600000'
            ],
            [
                '240',
                'SanLuis',
                '-04/-03',
                '1255219200000'
            ],
            [
                '180',
                '-',
                '-03'
            ]
        ],
        'America/Argentina/Rio_Gallegos': [
            [
                '276.8666666666667',
                '-',
                'LMT',
                '-2372112000000'
            ],
            [
                '256.8',
                '-',
                'CMT',
                '-1567468800000'
            ],
            [
                '240',
                '-',
                '-04',
                '-1233446400000'
            ],
            [
                '240',
                'Arg',
                '-04/-03',
                '-7603200000'
            ],
            [
                '180',
                'Arg',
                '-03/-02',
                '938908800000'
            ],
            [
                '240',
                'Arg',
                '-04/-03',
                '952041600000'
            ],
            [
                '180',
                '-',
                '-03',
                '1086048000000'
            ],
            [
                '240',
                '-',
                '-04',
                '1087689600000'
            ],
            [
                '180',
                'Arg',
                '-03/-02',
                '1224288000000'
            ],
            [
                '180',
                '-',
                '-03'
            ]
        ],
        'America/Argentina/Ushuaia': [
            [
                '273.2',
                '-',
                'LMT',
                '-2372112000000'
            ],
            [
                '256.8',
                '-',
                'CMT',
                '-1567468800000'
            ],
            [
                '240',
                '-',
                '-04',
                '-1233446400000'
            ],
            [
                '240',
                'Arg',
                '-04/-03',
                '-7603200000'
            ],
            [
                '180',
                'Arg',
                '-03/-02',
                '938908800000'
            ],
            [
                '240',
                'Arg',
                '-04/-03',
                '952041600000'
            ],
            [
                '180',
                '-',
                '-03',
                '1085875200000'
            ],
            [
                '240',
                '-',
                '-04',
                '1087689600000'
            ],
            [
                '180',
                'Arg',
                '-03/-02',
                '1224288000000'
            ],
            [
                '180',
                '-',
                '-03'
            ]
        ],
        'America/Aruba': 'America/Curacao',
        'America/La_Paz': [
            [
                '272.6',
                '-',
                'LMT',
                '-2493072000000'
            ],
            [
                '272.6',
                '-',
                'CMT',
                '-1205971200000'
            ],
            [
                '272.6',
                '1:00',
                'BST',
                '-1192320000000'
            ],
            [
                '240',
                '-',
                '-04'
            ]
        ],
        'America/Noronha': [
            [
                '129.66666666666669',
                '-',
                'LMT',
                '-1735776000000'
            ],
            [
                '120',
                'Brazil',
                '-02/-01',
                '653529600000'
            ],
            [
                '120',
                '-',
                '-02',
                '938649600000'
            ],
            [
                '120',
                'Brazil',
                '-02/-01',
                '971568000000'
            ],
            [
                '120',
                '-',
                '-02',
                '1000339200000'
            ],
            [
                '120',
                'Brazil',
                '-02/-01',
                '1033430400000'
            ],
            [
                '120',
                '-',
                '-02'
            ]
        ],
        'America/Belem': [
            [
                '193.93333333333334',
                '-',
                'LMT',
                '-1735776000000'
            ],
            [
                '180',
                'Brazil',
                '-03/-02',
                '590025600000'
            ],
            [
                '180',
                '-',
                '-03'
            ]
        ],
        'America/Santarem': [
            [
                '218.8',
                '-',
                'LMT',
                '-1735776000000'
            ],
            [
                '240',
                'Brazil',
                '-04/-03',
                '590025600000'
            ],
            [
                '240',
                '-',
                '-04',
                '1214265600000'
            ],
            [
                '180',
                '-',
                '-03'
            ]
        ],
        'America/Fortaleza': [
            [
                '154',
                '-',
                'LMT',
                '-1735776000000'
            ],
            [
                '180',
                'Brazil',
                '-03/-02',
                '653529600000'
            ],
            [
                '180',
                '-',
                '-03',
                '938649600000'
            ],
            [
                '180',
                'Brazil',
                '-03/-02',
                '972172800000'
            ],
            [
                '180',
                '-',
                '-03',
                '1000339200000'
            ],
            [
                '180',
                'Brazil',
                '-03/-02',
                '1033430400000'
            ],
            [
                '180',
                '-',
                '-03'
            ]
        ],
        'America/Recife': [
            [
                '139.6',
                '-',
                'LMT',
                '-1735776000000'
            ],
            [
                '180',
                'Brazil',
                '-03/-02',
                '653529600000'
            ],
            [
                '180',
                '-',
                '-03',
                '938649600000'
            ],
            [
                '180',
                'Brazil',
                '-03/-02',
                '971568000000'
            ],
            [
                '180',
                '-',
                '-03',
                '1000339200000'
            ],
            [
                '180',
                'Brazil',
                '-03/-02',
                '1033430400000'
            ],
            [
                '180',
                '-',
                '-03'
            ]
        ],
        'America/Araguaina': [
            [
                '192.8',
                '-',
                'LMT',
                '-1735776000000'
            ],
            [
                '180',
                'Brazil',
                '-03/-02',
                '653529600000'
            ],
            [
                '180',
                '-',
                '-03',
                '811036800000'
            ],
            [
                '180',
                'Brazil',
                '-03/-02',
                '1064361600000'
            ],
            [
                '180',
                '-',
                '-03',
                '1350777600000'
            ],
            [
                '180',
                'Brazil',
                '-03/-02',
                '1377993600000'
            ],
            [
                '180',
                '-',
                '-03'
            ]
        ],
        'America/Maceio': [
            [
                '142.86666666666665',
                '-',
                'LMT',
                '-1735776000000'
            ],
            [
                '180',
                'Brazil',
                '-03/-02',
                '653529600000'
            ],
            [
                '180',
                '-',
                '-03',
                '813542400000'
            ],
            [
                '180',
                'Brazil',
                '-03/-02',
                '841795200000'
            ],
            [
                '180',
                '-',
                '-03',
                '938649600000'
            ],
            [
                '180',
                'Brazil',
                '-03/-02',
                '972172800000'
            ],
            [
                '180',
                '-',
                '-03',
                '1000339200000'
            ],
            [
                '180',
                'Brazil',
                '-03/-02',
                '1033430400000'
            ],
            [
                '180',
                '-',
                '-03'
            ]
        ],
        'America/Bahia': [
            [
                '154.06666666666666',
                '-',
                'LMT',
                '-1735776000000'
            ],
            [
                '180',
                'Brazil',
                '-03/-02',
                '1064361600000'
            ],
            [
                '180',
                '-',
                '-03',
                '1318723200000'
            ],
            [
                '180',
                'Brazil',
                '-03/-02',
                '1350777600000'
            ],
            [
                '180',
                '-',
                '-03'
            ]
        ],
        'America/Sao_Paulo': [
            [
                '186.46666666666667',
                '-',
                'LMT',
                '-1735776000000'
            ],
            [
                '180',
                'Brazil',
                '-03/-02',
                '-195436800000'
            ],
            [
                '180',
                '1:00',
                '-02',
                '-157852800000'
            ],
            [
                '180',
                'Brazil',
                '-03/-02'
            ]
        ],
        'America/Campo_Grande': [
            [
                '218.46666666666667',
                '-',
                'LMT',
                '-1735776000000'
            ],
            [
                '240',
                'Brazil',
                '-04/-03'
            ]
        ],
        'America/Cuiaba': [
            [
                '224.33333333333334',
                '-',
                'LMT',
                '-1735776000000'
            ],
            [
                '240',
                'Brazil',
                '-04/-03',
                '1064361600000'
            ],
            [
                '240',
                '-',
                '-04',
                '1096588800000'
            ],
            [
                '240',
                'Brazil',
                '-04/-03'
            ]
        ],
        'America/Porto_Velho': [
            [
                '255.6',
                '-',
                'LMT',
                '-1735776000000'
            ],
            [
                '240',
                'Brazil',
                '-04/-03',
                '590025600000'
            ],
            [
                '240',
                '-',
                '-04'
            ]
        ],
        'America/Boa_Vista': [
            [
                '242.66666666666666',
                '-',
                'LMT',
                '-1735776000000'
            ],
            [
                '240',
                'Brazil',
                '-04/-03',
                '590025600000'
            ],
            [
                '240',
                '-',
                '-04',
                '938649600000'
            ],
            [
                '240',
                'Brazil',
                '-04/-03',
                '971568000000'
            ],
            [
                '240',
                '-',
                '-04'
            ]
        ],
        'America/Manaus': [
            [
                '240.06666666666666',
                '-',
                'LMT',
                '-1735776000000'
            ],
            [
                '240',
                'Brazil',
                '-04/-03',
                '590025600000'
            ],
            [
                '240',
                '-',
                '-04',
                '749174400000'
            ],
            [
                '240',
                'Brazil',
                '-04/-03',
                '780192000000'
            ],
            [
                '240',
                '-',
                '-04'
            ]
        ],
        'America/Eirunepe': [
            [
                '279.4666666666667',
                '-',
                'LMT',
                '-1735776000000'
            ],
            [
                '300',
                'Brazil',
                '-05/-04',
                '590025600000'
            ],
            [
                '300',
                '-',
                '-05',
                '749174400000'
            ],
            [
                '300',
                'Brazil',
                '-05/-04',
                '780192000000'
            ],
            [
                '300',
                '-',
                '-05',
                '1214265600000'
            ],
            [
                '240',
                '-',
                '-04',
                '1384041600000'
            ],
            [
                '300',
                '-',
                '-05'
            ]
        ],
        'America/Rio_Branco': [
            [
                '271.2',
                '-',
                'LMT',
                '-1735776000000'
            ],
            [
                '300',
                'Brazil',
                '-05/-04',
                '590025600000'
            ],
            [
                '300',
                '-',
                '-05',
                '1214265600000'
            ],
            [
                '240',
                '-',
                '-04',
                '1384041600000'
            ],
            [
                '300',
                '-',
                '-05'
            ]
        ],
        'America/Santiago': [
            [
                '282.7666666666667',
                '-',
                'LMT',
                '-2493072000000'
            ],
            [
                '282.7666666666667',
                '-',
                'SMT',
                '-1892678400000'
            ],
            [
                '300',
                '-',
                '-05',
                '-1688428800000'
            ],
            [
                '282.7666666666667',
                '-',
                'SMT',
                '-1619222400000'
            ],
            [
                '240',
                '-',
                '-04',
                '-1593820800000'
            ],
            [
                '282.7666666666667',
                '-',
                'SMT',
                '-1336003200000'
            ],
            [
                '300',
                'Chile',
                '-05/-04',
                '-1178150400000'
            ],
            [
                '240',
                '-',
                '-04',
                '-870566400000'
            ],
            [
                '300',
                '-',
                '-05',
                '-865296000000'
            ],
            [
                '240',
                '-',
                '-04',
                '-740534400000'
            ],
            [
                '240',
                '1:00',
                '-03',
                '-736387200000'
            ],
            [
                '240',
                '-',
                '-04',
                '-718070400000'
            ],
            [
                '300',
                '-',
                '-05',
                '-713667600000'
            ],
            [
                '240',
                'Chile',
                '-04/-03'
            ]
        ],
        'America/Punta_Arenas': [
            [
                '283.6666666666667',
                '-',
                'LMT',
                '-2493072000000'
            ],
            [
                '282.7666666666667',
                '-',
                'SMT',
                '-1892678400000'
            ],
            [
                '300',
                '-',
                '-05',
                '-1688428800000'
            ],
            [
                '282.7666666666667',
                '-',
                'SMT',
                '-1619222400000'
            ],
            [
                '240',
                '-',
                '-04',
                '-1593820800000'
            ],
            [
                '282.7666666666667',
                '-',
                'SMT',
                '-1336003200000'
            ],
            [
                '300',
                'Chile',
                '-05/-04',
                '-1178150400000'
            ],
            [
                '240',
                '-',
                '-04',
                '-870566400000'
            ],
            [
                '300',
                '-',
                '-05',
                '-865296000000'
            ],
            [
                '240',
                '-',
                '-04',
                '-718070400000'
            ],
            [
                '300',
                '-',
                '-05',
                '-713667600000'
            ],
            [
                '240',
                'Chile',
                '-04/-03',
                '1480809600000'
            ],
            [
                '180',
                '-',
                '-03'
            ]
        ],
        'Pacific/Easter': [
            [
                '437.4666666666667',
                '-',
                'LMT',
                '-2493072000000'
            ],
            [
                '437.4666666666667',
                '-',
                'EMT',
                '-1178150400000'
            ],
            [
                '420',
                'Chile',
                '-07/-06',
                '384922800000'
            ],
            [
                '360',
                'Chile',
                '-06/-05'
            ]
        ],
        'Antarctica/Palmer': [
            [
                '0',
                '-',
                '-00',
                '-126316800000'
            ],
            [
                '240',
                'Arg',
                '-04/-03',
                '-7603200000'
            ],
            [
                '180',
                'Arg',
                '-03/-02',
                '389059200000'
            ],
            [
                '240',
                'Chile',
                '-04/-03',
                '1480809600000'
            ],
            [
                '180',
                '-',
                '-03'
            ]
        ],
        'America/Bogota': [
            [
                '296.2666666666667',
                '-',
                'LMT',
                '-2707689600000'
            ],
            [
                '296.2666666666667',
                '-',
                'BMT',
                '-1739059200000'
            ],
            [
                '300',
                'CO',
                '-05/-04'
            ]
        ],
        'America/Curacao': [
            [
                '275.7833333333333',
                '-',
                'LMT',
                '-1826755200000'
            ],
            [
                '270',
                '-',
                '-0430',
                '-126316800000'
            ],
            [
                '240',
                '-',
                'AST'
            ]
        ],
        'America/Lower_Princes': 'America/Curacao',
        'America/Kralendijk': 'America/Curacao',
        'America/Guayaquil': [
            [
                '319.3333333333333',
                '-',
                'LMT',
                '-2493072000000'
            ],
            [
                '314',
                '-',
                'QMT',
                '-1199318400000'
            ],
            [
                '300',
                'Ecuador',
                '-05/-04'
            ]
        ],
        'Pacific/Galapagos': [
            [
                '358.4',
                '-',
                'LMT',
                '-1199318400000'
            ],
            [
                '300',
                '-',
                '-05',
                '536371200000'
            ],
            [
                '360',
                'Ecuador',
                '-06/-05'
            ]
        ],
        'Atlantic/Stanley': [
            [
                '231.4',
                '-',
                'LMT',
                '-2493072000000'
            ],
            [
                '231.4',
                '-',
                'SMT',
                '-1824249600000'
            ],
            [
                '240',
                'Falk',
                '-04/-03',
                '420595200000'
            ],
            [
                '180',
                'Falk',
                '-03/-02',
                '495590400000'
            ],
            [
                '240',
                'Falk',
                '-04/-03',
                '1283652000000'
            ],
            [
                '180',
                '-',
                '-03'
            ]
        ],
        'America/Cayenne': [
            [
                '209.33333333333334',
                '-',
                'LMT',
                '-1846281600000'
            ],
            [
                '240',
                '-',
                '-04',
                '-71107200000'
            ],
            [
                '180',
                '-',
                '-03'
            ]
        ],
        'America/Guyana': [
            [
                '232.66666666666666',
                '-',
                'LMT',
                '-1730592000000'
            ],
            [
                '225',
                '-',
                '-0345',
                '175996800000'
            ],
            [
                '180',
                '-',
                '-03',
                '694137600000'
            ],
            [
                '240',
                '-',
                '-04'
            ]
        ],
        'America/Asuncion': [
            [
                '230.66666666666666',
                '-',
                'LMT',
                '-2493072000000'
            ],
            [
                '230.66666666666666',
                '-',
                'AMT',
                '-1206403200000'
            ],
            [
                '240',
                '-',
                '-04',
                '86745600000'
            ],
            [
                '180',
                '-',
                '-03',
                '134006400000'
            ],
            [
                '240',
                'Para',
                '-04/-03'
            ]
        ],
        'America/Lima': [
            [
                '308.2',
                '-',
                'LMT',
                '-2493072000000'
            ],
            [
                '308.6',
                '-',
                'LMT',
                '-1938556800000'
            ],
            [
                '300',
                'Peru',
                '-05/-04'
            ]
        ],
        'Atlantic/South_Georgia': [
            [
                '146.13333333333335',
                '-',
                'LMT',
                '-2493072000000'
            ],
            [
                '120',
                '-',
                '-02'
            ]
        ],
        'America/Paramaribo': [
            [
                '220.66666666666666',
                '-',
                'LMT',
                '-1830470400000'
            ],
            [
                '220.86666666666665',
                '-',
                'PMT',
                '-1073088000000'
            ],
            [
                '220.6',
                '-',
                'PMT',
                '-765331200000'
            ],
            [
                '210',
                '-',
                '-0330',
                '465436800000'
            ],
            [
                '180',
                '-',
                '-03'
            ]
        ],
        'America/Port_of_Spain': [
            [
                '246.06666666666666',
                '-',
                'LMT',
                '-1825113600000'
            ],
            [
                '240',
                '-',
                'AST'
            ]
        ],
        'America/Anguilla': 'America/Port_of_Spain',
        'America/Antigua': 'America/Port_of_Spain',
        'America/Dominica': 'America/Port_of_Spain',
        'America/Grenada': 'America/Port_of_Spain',
        'America/Guadeloupe': 'America/Port_of_Spain',
        'America/Marigot': 'America/Port_of_Spain',
        'America/Montserrat': 'America/Port_of_Spain',
        'America/St_Barthelemy': 'America/Port_of_Spain',
        'America/St_Kitts': 'America/Port_of_Spain',
        'America/St_Lucia': 'America/Port_of_Spain',
        'America/St_Thomas': 'America/Port_of_Spain',
        'America/St_Vincent': 'America/Port_of_Spain',
        'America/Tortola': 'America/Port_of_Spain',
        'America/Montevideo': [
            [
                '224.85',
                '-',
                'LMT',
                '-1942704000000'
            ],
            [
                '224.85',
                '-',
                'MMT',
                '-1567468800000'
            ],
            [
                '240',
                '-',
                '-04',
                '-1459641600000'
            ],
            [
                '210',
                'Uruguay',
                '-0330/-03',
                '-853632000000'
            ],
            [
                '180',
                'Uruguay',
                '-03/-0230',
                '-284083200000'
            ],
            [
                '180',
                'Uruguay',
                '-03/-02',
                '-31622400000'
            ],
            [
                '180',
                'Uruguay',
                '-03/-0230',
                '31449600000'
            ],
            [
                '180',
                'Uruguay',
                '-03/-02',
                '157680000000'
            ],
            [
                '180',
                'Uruguay',
                '-03/-0130',
                '132105600000'
            ],
            [
                '180',
                'Uruguay',
                '-03/-0230',
                '156902400000'
            ],
            [
                '180',
                'Uruguay',
                '-03/-02'
            ]
        ],
        'America/Caracas': [
            [
                '267.7333333333333',
                '-',
                'LMT',
                '-2493072000000'
            ],
            [
                '267.6666666666667',
                '-',
                'CMT',
                '-1826755200000'
            ],
            [
                '270',
                '-',
                '-0430',
                '-157766400000'
            ],
            [
                '240',
                '-',
                '-04',
                '1197169200000'
            ],
            [
                '270',
                '-',
                '-0430',
                '1462069800000'
            ],
            [
                '240',
                '-',
                '-04'
            ]
        ]
    };
    kendo.timezone.rules = {
        'Algeria': [
            [
                '1916',
                'only',
                '-',
                'Jun',
                '14',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1916',
                '1919',
                '-',
                'Oct',
                'Sun>=1',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1917',
                'only',
                '-',
                'Mar',
                '24',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1918',
                'only',
                '-',
                'Mar',
                '9',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1919',
                'only',
                '-',
                'Mar',
                '1',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1920',
                'only',
                '-',
                'Feb',
                '14',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1920',
                'only',
                '-',
                'Oct',
                '23',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1921',
                'only',
                '-',
                'Mar',
                '14',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1921',
                'only',
                '-',
                'Jun',
                '21',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1939',
                'only',
                '-',
                'Sep',
                '11',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1939',
                'only',
                '-',
                'Nov',
                '19',
                [
                    '1',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1944',
                '1945',
                '-',
                'Apr',
                'Mon>=1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1944',
                'only',
                '-',
                'Oct',
                '8',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1945',
                'only',
                '-',
                'Sep',
                '16',
                [
                    '1',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1971',
                'only',
                '-',
                'Apr',
                '25',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1971',
                'only',
                '-',
                'Sep',
                '26',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1977',
                'only',
                '-',
                'May',
                '6',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1977',
                'only',
                '-',
                'Oct',
                '21',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1978',
                'only',
                '-',
                'Mar',
                '24',
                [
                    '1',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1978',
                'only',
                '-',
                'Sep',
                '22',
                [
                    '3',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1980',
                'only',
                '-',
                'Apr',
                '25',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1980',
                'only',
                '-',
                'Oct',
                '31',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ]
        ],
        'Egypt': [
            [
                '1940',
                'only',
                '-',
                'Jul',
                '15',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1940',
                'only',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1941',
                'only',
                '-',
                'Apr',
                '15',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1941',
                'only',
                '-',
                'Sep',
                '16',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1942',
                '1944',
                '-',
                'Apr',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1942',
                'only',
                '-',
                'Oct',
                '27',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1943',
                '1945',
                '-',
                'Nov',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1945',
                'only',
                '-',
                'Apr',
                '16',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1957',
                'only',
                '-',
                'May',
                '10',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1957',
                '1958',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1958',
                'only',
                '-',
                'May',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1959',
                '1981',
                '-',
                'May',
                '1',
                [
                    '1',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1959',
                '1965',
                '-',
                'Sep',
                '30',
                [
                    '3',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1966',
                '1994',
                '-',
                'Oct',
                '1',
                [
                    '3',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1982',
                'only',
                '-',
                'Jul',
                '25',
                [
                    '1',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1983',
                'only',
                '-',
                'Jul',
                '12',
                [
                    '1',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1984',
                '1988',
                '-',
                'May',
                '1',
                [
                    '1',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1989',
                'only',
                '-',
                'May',
                '6',
                [
                    '1',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1990',
                '1994',
                '-',
                'May',
                '1',
                [
                    '1',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1995',
                '2010',
                '-',
                'Apr',
                'lastFri',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1995',
                '2005',
                '-',
                'Sep',
                'lastThu',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2006',
                'only',
                '-',
                'Sep',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2007',
                'only',
                '-',
                'Sep',
                'Thu>=1',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2008',
                'only',
                '-',
                'Aug',
                'lastThu',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2009',
                'only',
                '-',
                'Aug',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2010',
                'only',
                '-',
                'Aug',
                '10',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2010',
                'only',
                '-',
                'Sep',
                '9',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '2010',
                'only',
                '-',
                'Sep',
                'lastThu',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2014',
                'only',
                '-',
                'May',
                '15',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '2014',
                'only',
                '-',
                'Jun',
                '26',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2014',
                'only',
                '-',
                'Jul',
                '31',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '2014',
                'only',
                '-',
                'Sep',
                'lastThu',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ]
        ],
        'Ghana': [
            [
                '1920',
                '1942',
                '-',
                'Sep',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '20',
                '-'
            ],
            [
                '1920',
                '1942',
                '-',
                'Dec',
                '31',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ]
        ],
        'Libya': [
            [
                '1951',
                'only',
                '-',
                'Oct',
                '14',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1952',
                'only',
                '-',
                'Jan',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1953',
                'only',
                '-',
                'Oct',
                '9',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1954',
                'only',
                '-',
                'Jan',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1955',
                'only',
                '-',
                'Sep',
                '30',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1956',
                'only',
                '-',
                'Jan',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1982',
                '1984',
                '-',
                'Apr',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1982',
                '1985',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1985',
                'only',
                '-',
                'Apr',
                '6',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1986',
                'only',
                '-',
                'Apr',
                '4',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1986',
                'only',
                '-',
                'Oct',
                '3',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1987',
                '1989',
                '-',
                'Apr',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1987',
                '1989',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1997',
                'only',
                '-',
                'Apr',
                '4',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1997',
                'only',
                '-',
                'Oct',
                '4',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2013',
                'only',
                '-',
                'Mar',
                'lastFri',
                [
                    '1',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '2013',
                'only',
                '-',
                'Oct',
                'lastFri',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ]
        ],
        'Mauritius': [
            [
                '1982',
                'only',
                '-',
                'Oct',
                '10',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1983',
                'only',
                '-',
                'Mar',
                '21',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2008',
                'only',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2009',
                'only',
                '-',
                'Mar',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ]
        ],
        'Morocco': [
            [
                '1939',
                'only',
                '-',
                'Sep',
                '12',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1939',
                'only',
                '-',
                'Nov',
                '19',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1940',
                'only',
                '-',
                'Feb',
                '25',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1945',
                'only',
                '-',
                'Nov',
                '18',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1950',
                'only',
                '-',
                'Jun',
                '11',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1950',
                'only',
                '-',
                'Oct',
                '29',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1967',
                'only',
                '-',
                'Jun',
                '3',
                [
                    '12',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1967',
                'only',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1974',
                'only',
                '-',
                'Jun',
                '24',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1974',
                'only',
                '-',
                'Sep',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1976',
                '1977',
                '-',
                'May',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1976',
                'only',
                '-',
                'Aug',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1977',
                'only',
                '-',
                'Sep',
                '28',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1978',
                'only',
                '-',
                'Jun',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1978',
                'only',
                '-',
                'Aug',
                '4',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2008',
                'only',
                '-',
                'Jun',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2008',
                'only',
                '-',
                'Sep',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2009',
                'only',
                '-',
                'Jun',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2009',
                'only',
                '-',
                'Aug',
                '21',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2010',
                'only',
                '-',
                'May',
                '2',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2010',
                'only',
                '-',
                'Aug',
                '8',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2011',
                'only',
                '-',
                'Apr',
                '3',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2011',
                'only',
                '-',
                'Jul',
                '31',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2012',
                '2013',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2012',
                'only',
                '-',
                'Jul',
                '20',
                [
                    '3',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2012',
                'only',
                '-',
                'Aug',
                '20',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2012',
                'only',
                '-',
                'Sep',
                '30',
                [
                    '3',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2013',
                'only',
                '-',
                'Jul',
                '7',
                [
                    '3',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2013',
                'only',
                '-',
                'Aug',
                '10',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2013',
                '2018',
                '-',
                'Oct',
                'lastSun',
                [
                    '3',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2014',
                '2018',
                '-',
                'Mar',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2014',
                'only',
                '-',
                'Jun',
                '28',
                [
                    '3',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2014',
                'only',
                '-',
                'Aug',
                '2',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2015',
                'only',
                '-',
                'Jun',
                '14',
                [
                    '3',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2015',
                'only',
                '-',
                'Jul',
                '19',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2016',
                'only',
                '-',
                'Jun',
                '5',
                [
                    '3',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2016',
                'only',
                '-',
                'Jul',
                '10',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2017',
                'only',
                '-',
                'May',
                '21',
                [
                    '3',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2017',
                'only',
                '-',
                'Jul',
                '2',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2018',
                'only',
                '-',
                'May',
                '13',
                [
                    '3',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2018',
                'only',
                '-',
                'Jun',
                '17',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2019',
                'only',
                '-',
                'May',
                '5',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2019',
                'only',
                '-',
                'Jun',
                '9',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2020',
                'only',
                '-',
                'Apr',
                '19',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2020',
                'only',
                '-',
                'May',
                '24',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2021',
                'only',
                '-',
                'Apr',
                '11',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2021',
                'only',
                '-',
                'May',
                '16',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2022',
                'only',
                '-',
                'Mar',
                '27',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2022',
                'only',
                '-',
                'May',
                '8',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2023',
                'only',
                '-',
                'Mar',
                '19',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2023',
                'only',
                '-',
                'Apr',
                '23',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2024',
                'only',
                '-',
                'Mar',
                '10',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2024',
                'only',
                '-',
                'Apr',
                '14',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2025',
                'only',
                '-',
                'Feb',
                '23',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2025',
                'only',
                '-',
                'Apr',
                '6',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2026',
                'only',
                '-',
                'Feb',
                '15',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2026',
                'only',
                '-',
                'Mar',
                '22',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2027',
                'only',
                '-',
                'Feb',
                '7',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2027',
                'only',
                '-',
                'Mar',
                '14',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2028',
                'only',
                '-',
                'Jan',
                '23',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2028',
                'only',
                '-',
                'Feb',
                '27',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2029',
                'only',
                '-',
                'Jan',
                '14',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2029',
                'only',
                '-',
                'Feb',
                '18',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2029',
                'only',
                '-',
                'Dec',
                '30',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2030',
                'only',
                '-',
                'Feb',
                '10',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2030',
                'only',
                '-',
                'Dec',
                '22',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2031',
                'only',
                '-',
                'Jan',
                '26',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2031',
                'only',
                '-',
                'Dec',
                '14',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2032',
                'only',
                '-',
                'Jan',
                '18',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2032',
                'only',
                '-',
                'Nov',
                '28',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2033',
                'only',
                '-',
                'Jan',
                '9',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2033',
                'only',
                '-',
                'Nov',
                '20',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2033',
                'only',
                '-',
                'Dec',
                '25',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2034',
                'only',
                '-',
                'Nov',
                '5',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2034',
                'only',
                '-',
                'Dec',
                '17',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2035',
                'only',
                '-',
                'Oct',
                '28',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2035',
                'only',
                '-',
                'Dec',
                '2',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2036',
                'only',
                '-',
                'Oct',
                '19',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2036',
                'only',
                '-',
                'Nov',
                '23',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2037',
                'only',
                '-',
                'Oct',
                '4',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2037',
                'only',
                '-',
                'Nov',
                '15',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2038',
                'only',
                '-',
                'Sep',
                '26',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2038',
                'only',
                '-',
                'Oct',
                '31',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2039',
                'only',
                '-',
                'Sep',
                '18',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2039',
                'only',
                '-',
                'Oct',
                '23',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2040',
                'only',
                '-',
                'Sep',
                '2',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2040',
                'only',
                '-',
                'Oct',
                '14',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2041',
                'only',
                '-',
                'Aug',
                '25',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2041',
                'only',
                '-',
                'Sep',
                '29',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2042',
                'only',
                '-',
                'Aug',
                '10',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2042',
                'only',
                '-',
                'Sep',
                '21',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2043',
                'only',
                '-',
                'Aug',
                '2',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2043',
                'only',
                '-',
                'Sep',
                '6',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2044',
                'only',
                '-',
                'Jul',
                '24',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2044',
                'only',
                '-',
                'Aug',
                '28',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2045',
                'only',
                '-',
                'Jul',
                '9',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2045',
                'only',
                '-',
                'Aug',
                '20',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2046',
                'only',
                '-',
                'Jul',
                '1',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2046',
                'only',
                '-',
                'Aug',
                '5',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2047',
                'only',
                '-',
                'Jun',
                '23',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2047',
                'only',
                '-',
                'Jul',
                '28',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2048',
                'only',
                '-',
                'Jun',
                '7',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2048',
                'only',
                '-',
                'Jul',
                '19',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2049',
                'only',
                '-',
                'May',
                '30',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2049',
                'only',
                '-',
                'Jul',
                '4',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2050',
                'only',
                '-',
                'May',
                '15',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2050',
                'only',
                '-',
                'Jun',
                '26',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2051',
                'only',
                '-',
                'May',
                '7',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2051',
                'only',
                '-',
                'Jun',
                '11',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2052',
                'only',
                '-',
                'Apr',
                '28',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2052',
                'only',
                '-',
                'Jun',
                '2',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2053',
                'only',
                '-',
                'Apr',
                '13',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2053',
                'only',
                '-',
                'May',
                '25',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2054',
                'only',
                '-',
                'Apr',
                '5',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2054',
                'only',
                '-',
                'May',
                '10',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2055',
                'only',
                '-',
                'Mar',
                '28',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2055',
                'only',
                '-',
                'May',
                '2',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2056',
                'only',
                '-',
                'Mar',
                '12',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2056',
                'only',
                '-',
                'Apr',
                '23',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2057',
                'only',
                '-',
                'Mar',
                '4',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2057',
                'only',
                '-',
                'Apr',
                '8',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2058',
                'only',
                '-',
                'Feb',
                '17',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2058',
                'only',
                '-',
                'Mar',
                '31',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2059',
                'only',
                '-',
                'Feb',
                '9',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2059',
                'only',
                '-',
                'Mar',
                '16',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2060',
                'only',
                '-',
                'Feb',
                '1',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2060',
                'only',
                '-',
                'Mar',
                '7',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2061',
                'only',
                '-',
                'Jan',
                '16',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2061',
                'only',
                '-',
                'Feb',
                '27',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2062',
                'only',
                '-',
                'Jan',
                '8',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2062',
                'only',
                '-',
                'Feb',
                '12',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2062',
                'only',
                '-',
                'Dec',
                '31',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2063',
                'only',
                '-',
                'Feb',
                '4',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2063',
                'only',
                '-',
                'Dec',
                '16',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2064',
                'only',
                '-',
                'Jan',
                '20',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2064',
                'only',
                '-',
                'Dec',
                '7',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2065',
                'only',
                '-',
                'Jan',
                '11',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2065',
                'only',
                '-',
                'Nov',
                '22',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2066',
                'only',
                '-',
                'Jan',
                '3',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2066',
                'only',
                '-',
                'Nov',
                '14',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2066',
                'only',
                '-',
                'Dec',
                '19',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2067',
                'only',
                '-',
                'Nov',
                '6',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2067',
                'only',
                '-',
                'Dec',
                '11',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2068',
                'only',
                '-',
                'Oct',
                '21',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2068',
                'only',
                '-',
                'Dec',
                '2',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2069',
                'only',
                '-',
                'Oct',
                '13',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2069',
                'only',
                '-',
                'Nov',
                '17',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2070',
                'only',
                '-',
                'Oct',
                '5',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2070',
                'only',
                '-',
                'Nov',
                '9',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2071',
                'only',
                '-',
                'Sep',
                '20',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2071',
                'only',
                '-',
                'Oct',
                '25',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2072',
                'only',
                '-',
                'Sep',
                '11',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2072',
                'only',
                '-',
                'Oct',
                '16',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2073',
                'only',
                '-',
                'Aug',
                '27',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2073',
                'only',
                '-',
                'Oct',
                '8',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2074',
                'only',
                '-',
                'Aug',
                '19',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2074',
                'only',
                '-',
                'Sep',
                '23',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2075',
                'only',
                '-',
                'Aug',
                '11',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2075',
                'only',
                '-',
                'Sep',
                '15',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2076',
                'only',
                '-',
                'Jul',
                '26',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2076',
                'only',
                '-',
                'Sep',
                '6',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2077',
                'only',
                '-',
                'Jul',
                '18',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2077',
                'only',
                '-',
                'Aug',
                '22',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2078',
                'only',
                '-',
                'Jul',
                '10',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2078',
                'only',
                '-',
                'Aug',
                '14',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2079',
                'only',
                '-',
                'Jun',
                '25',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2079',
                'only',
                '-',
                'Jul',
                '30',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2080',
                'only',
                '-',
                'Jun',
                '16',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2080',
                'only',
                '-',
                'Jul',
                '21',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2081',
                'only',
                '-',
                'Jun',
                '1',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2081',
                'only',
                '-',
                'Jul',
                '13',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2082',
                'only',
                '-',
                'May',
                '24',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2082',
                'only',
                '-',
                'Jun',
                '28',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2083',
                'only',
                '-',
                'May',
                '16',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2083',
                'only',
                '-',
                'Jun',
                '20',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2084',
                'only',
                '-',
                'Apr',
                '30',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2084',
                'only',
                '-',
                'Jun',
                '11',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2085',
                'only',
                '-',
                'Apr',
                '22',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2085',
                'only',
                '-',
                'May',
                '27',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2086',
                'only',
                '-',
                'Apr',
                '14',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2086',
                'only',
                '-',
                'May',
                '19',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2087',
                'only',
                '-',
                'Mar',
                '30',
                [
                    '3',
                    '0',
                    '0'
                ],
                '-60',
                '-'
            ],
            [
                '2087',
                'only',
                '-',
                'May',
                '4',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ]
        ],
        'Namibia': [
            [
                '1994',
                'only',
                '-',
                'Mar',
                '21',
                [
                    '0',
                    '0',
                    '0'
                ],
                '-60',
                'WAT'
            ],
            [
                '1994',
                '2017',
                '-',
                'Sep',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'CAT'
            ],
            [
                '1995',
                '2017',
                '-',
                'Apr',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '-60',
                'WAT'
            ]
        ],
        'SA': [
            [
                '1942',
                '1943',
                '-',
                'Sep',
                'Sun>=15',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1943',
                '1944',
                '-',
                'Mar',
                'Sun>=15',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ]
        ],
        'Sudan': [
            [
                '1970',
                'only',
                '-',
                'May',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1970',
                '1985',
                '-',
                'Oct',
                '15',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1971',
                'only',
                '-',
                'Apr',
                '30',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1972',
                '1985',
                '-',
                'Apr',
                'lastSun',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ]
        ],
        'Tunisia': [
            [
                '1939',
                'only',
                '-',
                'Apr',
                '15',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1939',
                'only',
                '-',
                'Nov',
                '18',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1940',
                'only',
                '-',
                'Feb',
                '25',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1941',
                'only',
                '-',
                'Oct',
                '6',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1942',
                'only',
                '-',
                'Mar',
                '9',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1942',
                'only',
                '-',
                'Nov',
                '2',
                [
                    '3',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1943',
                'only',
                '-',
                'Mar',
                '29',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1943',
                'only',
                '-',
                'Apr',
                '17',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1943',
                'only',
                '-',
                'Apr',
                '25',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1943',
                'only',
                '-',
                'Oct',
                '4',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1944',
                '1945',
                '-',
                'Apr',
                'Mon>=1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1944',
                'only',
                '-',
                'Oct',
                '8',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1945',
                'only',
                '-',
                'Sep',
                '16',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1977',
                'only',
                '-',
                'Apr',
                '30',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1977',
                'only',
                '-',
                'Sep',
                '24',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1978',
                'only',
                '-',
                'May',
                '1',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1978',
                'only',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1988',
                'only',
                '-',
                'Jun',
                '1',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1988',
                '1990',
                '-',
                'Sep',
                'lastSun',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1989',
                'only',
                '-',
                'Mar',
                '26',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1990',
                'only',
                '-',
                'May',
                '1',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '2005',
                'only',
                '-',
                'May',
                '1',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '2005',
                'only',
                '-',
                'Sep',
                '30',
                [
                    '1',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '2006',
                '2008',
                '-',
                'Mar',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '2006',
                '2008',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ]
        ],
        'Troll': [
            [
                '2005',
                'max',
                '-',
                'Mar',
                'lastSun',
                [
                    '1',
                    '0',
                    '0',
                    'u'
                ],
                '120',
                '+02'
            ],
            [
                '2004',
                'max',
                '-',
                'Oct',
                'lastSun',
                [
                    '1',
                    '0',
                    '0',
                    'u'
                ],
                '0',
                '+00'
            ]
        ],
        'EUAsia': [
            [
                '1981',
                'max',
                '-',
                'Mar',
                'lastSun',
                [
                    '1',
                    '0',
                    '0',
                    'u'
                ],
                '60',
                'S'
            ],
            [
                '1979',
                '1995',
                '-',
                'Sep',
                'lastSun',
                [
                    '1',
                    '0',
                    '0',
                    'u'
                ],
                '0',
                '-'
            ],
            [
                '1996',
                'max',
                '-',
                'Oct',
                'lastSun',
                [
                    '1',
                    '0',
                    '0',
                    'u'
                ],
                '0',
                '-'
            ]
        ],
        'E-EurAsia': [
            [
                '1981',
                'max',
                '-',
                'Mar',
                'lastSun',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1979',
                '1995',
                '-',
                'Sep',
                'lastSun',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1996',
                'max',
                '-',
                'Oct',
                'lastSun',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ]
        ],
        'RussiaAsia': [
            [
                '1981',
                '1984',
                '-',
                'Apr',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1981',
                '1983',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1984',
                '1995',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1985',
                '2010',
                '-',
                'Mar',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                '-'
            ],
            [
                '1996',
                '2010',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ]
        ],
        'Armenia': [
            [
                '2011',
                'only',
                '-',
                'Mar',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                '-'
            ],
            [
                '2011',
                'only',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ]
        ],
        'Azer': [
            [
                '1997',
                '2015',
                '-',
                'Mar',
                'lastSun',
                [
                    '4',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1997',
                '2015',
                '-',
                'Oct',
                'lastSun',
                [
                    '5',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ]
        ],
        'Dhaka': [
            [
                '2009',
                'only',
                '-',
                'Jun',
                '19',
                [
                    '23',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2009',
                'only',
                '-',
                'Dec',
                '31',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ]
        ],
        'Shang': [
            [
                '1940',
                'only',
                '-',
                'Jun',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1940',
                'only',
                '-',
                'Oct',
                '12',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1941',
                'only',
                '-',
                'Mar',
                '15',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1941',
                'only',
                '-',
                'Nov',
                '1',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1942',
                'only',
                '-',
                'Jan',
                '31',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1945',
                'only',
                '-',
                'Sep',
                '1',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1946',
                'only',
                '-',
                'May',
                '15',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1946',
                'only',
                '-',
                'Sep',
                '30',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1947',
                'only',
                '-',
                'Apr',
                '15',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1947',
                'only',
                '-',
                'Oct',
                '31',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1948',
                '1949',
                '-',
                'May',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1948',
                '1949',
                '-',
                'Sep',
                '30',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                'S',
                ''
            ]
        ],
        'PRC': [
            [
                '1986',
                'only',
                '-',
                'May',
                '4',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1986',
                '1991',
                '-',
                'Sep',
                'Sun>=11',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1987',
                '1991',
                '-',
                'Apr',
                'Sun>=11',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ]
        ],
        'HK': [
            [
                '1946',
                'only',
                '-',
                'Apr',
                '21',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1946',
                'only',
                '-',
                'Dec',
                '1',
                [
                    '3',
                    '30',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1947',
                'only',
                '-',
                'Apr',
                '13',
                [
                    '3',
                    '30',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1947',
                'only',
                '-',
                'Nov',
                '30',
                [
                    '3',
                    '30',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1948',
                'only',
                '-',
                'May',
                '2',
                [
                    '3',
                    '30',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1948',
                '1952',
                '-',
                'Oct',
                'Sun>=28',
                [
                    '3',
                    '30',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1949',
                '1953',
                '-',
                'Apr',
                'Sun>=1',
                [
                    '3',
                    '30',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1953',
                '1964',
                '-',
                'Oct',
                'Sun>=31',
                [
                    '3',
                    '30',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1954',
                '1964',
                '-',
                'Mar',
                'Sun>=18',
                [
                    '3',
                    '30',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1965',
                '1976',
                '-',
                'Apr',
                'Sun>=16',
                [
                    '3',
                    '30',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1965',
                '1976',
                '-',
                'Oct',
                'Sun>=16',
                [
                    '3',
                    '30',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1973',
                'only',
                '-',
                'Dec',
                '30',
                [
                    '3',
                    '30',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1979',
                'only',
                '-',
                'May',
                '13',
                [
                    '3',
                    '30',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1979',
                'only',
                '-',
                'Oct',
                '21',
                [
                    '3',
                    '30',
                    '0'
                ],
                '0',
                '-'
            ]
        ],
        'Taiwan': [
            [
                '1946',
                'only',
                '-',
                'May',
                '15',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1946',
                'only',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1947',
                'only',
                '-',
                'Apr',
                '15',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1947',
                'only',
                '-',
                'Nov',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1948',
                '1951',
                '-',
                'May',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1948',
                '1951',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1952',
                'only',
                '-',
                'Mar',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1952',
                '1954',
                '-',
                'Nov',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1953',
                '1959',
                '-',
                'Apr',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1955',
                '1961',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1960',
                '1961',
                '-',
                'Jun',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1974',
                '1975',
                '-',
                'Apr',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1974',
                '1975',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1979',
                'only',
                '-',
                'Jul',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1979',
                'only',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ]
        ],
        'Macau': [
            [
                '1942',
                '1943',
                '-',
                'Apr',
                '30',
                [
                    '23',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1942',
                'only',
                '-',
                'Nov',
                '17',
                [
                    '23',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1943',
                'only',
                '-',
                'Sep',
                '30',
                [
                    '23',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1946',
                'only',
                '-',
                'Apr',
                '30',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '1946',
                'only',
                '-',
                'Sep',
                '30',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '1947',
                'only',
                '-',
                'Apr',
                '19',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '1947',
                'only',
                '-',
                'Nov',
                '30',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '1948',
                'only',
                '-',
                'May',
                '2',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '1948',
                'only',
                '-',
                'Oct',
                '31',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '1949',
                '1950',
                '-',
                'Apr',
                'Sat>=1',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '1949',
                '1950',
                '-',
                'Oct',
                'lastSat',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '1951',
                'only',
                '-',
                'Mar',
                '31',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '1951',
                'only',
                '-',
                'Oct',
                '28',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '1952',
                '1953',
                '-',
                'Apr',
                'Sat>=1',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '1952',
                'only',
                '-',
                'Nov',
                '1',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '1953',
                '1954',
                '-',
                'Oct',
                'lastSat',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '1954',
                '1956',
                '-',
                'Mar',
                'Sat>=17',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '1955',
                'only',
                '-',
                'Nov',
                '5',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '1956',
                '1964',
                '-',
                'Nov',
                'Sun>=1',
                [
                    '3',
                    '30',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1957',
                '1964',
                '-',
                'Mar',
                'Sun>=18',
                [
                    '3',
                    '30',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1965',
                '1973',
                '-',
                'Apr',
                'Sun>=16',
                [
                    '3',
                    '30',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1965',
                '1966',
                '-',
                'Oct',
                'Sun>=16',
                [
                    '2',
                    '30',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1967',
                '1976',
                '-',
                'Oct',
                'Sun>=16',
                [
                    '3',
                    '30',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1973',
                'only',
                '-',
                'Dec',
                '30',
                [
                    '3',
                    '30',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1975',
                '1976',
                '-',
                'Apr',
                'Sun>=16',
                [
                    '3',
                    '30',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1979',
                'only',
                '-',
                'May',
                '13',
                [
                    '3',
                    '30',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1979',
                'only',
                '-',
                'Oct',
                'Sun>=16',
                [
                    '3',
                    '30',
                    '0'
                ],
                '0',
                'S'
            ]
        ],
        'Cyprus': [
            [
                '1975',
                'only',
                '-',
                'Apr',
                '13',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1975',
                'only',
                '-',
                'Oct',
                '12',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1976',
                'only',
                '-',
                'May',
                '15',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1976',
                'only',
                '-',
                'Oct',
                '11',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1977',
                '1980',
                '-',
                'Apr',
                'Sun>=1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1977',
                'only',
                '-',
                'Sep',
                '25',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1978',
                'only',
                '-',
                'Oct',
                '2',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1979',
                '1997',
                '-',
                'Sep',
                'lastSun',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1981',
                '1998',
                '-',
                'Mar',
                'lastSun',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ]
        ],
        'Iran': [
            [
                '1978',
                '1980',
                '-',
                'Mar',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1978',
                'only',
                '-',
                'Oct',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1979',
                'only',
                '-',
                'Sep',
                '18',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1980',
                'only',
                '-',
                'Sep',
                '22',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1991',
                'only',
                '-',
                'May',
                '2',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1992',
                '1995',
                '-',
                'Mar',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1991',
                '1995',
                '-',
                'Sep',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1996',
                'only',
                '-',
                'Mar',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1996',
                'only',
                '-',
                'Sep',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1997',
                '1999',
                '-',
                'Mar',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1997',
                '1999',
                '-',
                'Sep',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2000',
                'only',
                '-',
                'Mar',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2000',
                'only',
                '-',
                'Sep',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2001',
                '2003',
                '-',
                'Mar',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2001',
                '2003',
                '-',
                'Sep',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2004',
                'only',
                '-',
                'Mar',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2004',
                'only',
                '-',
                'Sep',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2005',
                'only',
                '-',
                'Mar',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2005',
                'only',
                '-',
                'Sep',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2008',
                'only',
                '-',
                'Mar',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2008',
                'only',
                '-',
                'Sep',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2009',
                '2011',
                '-',
                'Mar',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2009',
                '2011',
                '-',
                'Sep',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2012',
                'only',
                '-',
                'Mar',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2012',
                'only',
                '-',
                'Sep',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2013',
                '2015',
                '-',
                'Mar',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2013',
                '2015',
                '-',
                'Sep',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2016',
                'only',
                '-',
                'Mar',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2016',
                'only',
                '-',
                'Sep',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2017',
                '2019',
                '-',
                'Mar',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2017',
                '2019',
                '-',
                'Sep',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2020',
                'only',
                '-',
                'Mar',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2020',
                'only',
                '-',
                'Sep',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2021',
                '2023',
                '-',
                'Mar',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2021',
                '2023',
                '-',
                'Sep',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2024',
                'only',
                '-',
                'Mar',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2024',
                'only',
                '-',
                'Sep',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2025',
                '2027',
                '-',
                'Mar',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2025',
                '2027',
                '-',
                'Sep',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2028',
                '2029',
                '-',
                'Mar',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2028',
                '2029',
                '-',
                'Sep',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2030',
                '2031',
                '-',
                'Mar',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2030',
                '2031',
                '-',
                'Sep',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2032',
                '2033',
                '-',
                'Mar',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2032',
                '2033',
                '-',
                'Sep',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2034',
                '2035',
                '-',
                'Mar',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2034',
                '2035',
                '-',
                'Sep',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2036',
                '2037',
                '-',
                'Mar',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2036',
                '2037',
                '-',
                'Sep',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2038',
                '2039',
                '-',
                'Mar',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2038',
                '2039',
                '-',
                'Sep',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2040',
                '2041',
                '-',
                'Mar',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2040',
                '2041',
                '-',
                'Sep',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2042',
                '2043',
                '-',
                'Mar',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2042',
                '2043',
                '-',
                'Sep',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2044',
                '2045',
                '-',
                'Mar',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2044',
                '2045',
                '-',
                'Sep',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2046',
                '2047',
                '-',
                'Mar',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2046',
                '2047',
                '-',
                'Sep',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2048',
                '2049',
                '-',
                'Mar',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2048',
                '2049',
                '-',
                'Sep',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2050',
                '2051',
                '-',
                'Mar',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2050',
                '2051',
                '-',
                'Sep',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2052',
                '2053',
                '-',
                'Mar',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2052',
                '2053',
                '-',
                'Sep',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2054',
                '2055',
                '-',
                'Mar',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2054',
                '2055',
                '-',
                'Sep',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2056',
                '2057',
                '-',
                'Mar',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2056',
                '2057',
                '-',
                'Sep',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2058',
                '2059',
                '-',
                'Mar',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2058',
                '2059',
                '-',
                'Sep',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2060',
                '2062',
                '-',
                'Mar',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2060',
                '2062',
                '-',
                'Sep',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2063',
                'only',
                '-',
                'Mar',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2063',
                'only',
                '-',
                'Sep',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2064',
                '2066',
                '-',
                'Mar',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2064',
                '2066',
                '-',
                'Sep',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2067',
                'only',
                '-',
                'Mar',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2067',
                'only',
                '-',
                'Sep',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2068',
                '2070',
                '-',
                'Mar',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2068',
                '2070',
                '-',
                'Sep',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2071',
                'only',
                '-',
                'Mar',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2071',
                'only',
                '-',
                'Sep',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2072',
                '2074',
                '-',
                'Mar',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2072',
                '2074',
                '-',
                'Sep',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2075',
                'only',
                '-',
                'Mar',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2075',
                'only',
                '-',
                'Sep',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2076',
                '2078',
                '-',
                'Mar',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2076',
                '2078',
                '-',
                'Sep',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2079',
                'only',
                '-',
                'Mar',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2079',
                'only',
                '-',
                'Sep',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2080',
                '2082',
                '-',
                'Mar',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2080',
                '2082',
                '-',
                'Sep',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2083',
                'only',
                '-',
                'Mar',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2083',
                'only',
                '-',
                'Sep',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2084',
                '2086',
                '-',
                'Mar',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2084',
                '2086',
                '-',
                'Sep',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2087',
                'only',
                '-',
                'Mar',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2087',
                'only',
                '-',
                'Sep',
                '21',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2088',
                'max',
                '-',
                'Mar',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2088',
                'max',
                '-',
                'Sep',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ]
        ],
        'Iraq': [
            [
                '1982',
                'only',
                '-',
                'May',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1982',
                '1984',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1983',
                'only',
                '-',
                'Mar',
                '31',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1984',
                '1985',
                '-',
                'Apr',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1985',
                '1990',
                '-',
                'Sep',
                'lastSun',
                [
                    '1',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1986',
                '1990',
                '-',
                'Mar',
                'lastSun',
                [
                    '1',
                    '0',
                    '0',
                    's'
                ],
                '60',
                '-'
            ],
            [
                '1991',
                '2007',
                '-',
                'Apr',
                '1',
                [
                    '3',
                    '0',
                    '0',
                    's'
                ],
                '60',
                '-'
            ],
            [
                '1991',
                '2007',
                '-',
                'Oct',
                '1',
                [
                    '3',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ]
        ],
        'Zion': [
            [
                '1940',
                'only',
                '-',
                'Jun',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1942',
                '1944',
                '-',
                'Nov',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1943',
                'only',
                '-',
                'Apr',
                '1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1944',
                'only',
                '-',
                'Apr',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1945',
                'only',
                '-',
                'Apr',
                '16',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1945',
                'only',
                '-',
                'Nov',
                '1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1946',
                'only',
                '-',
                'Apr',
                '16',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1946',
                'only',
                '-',
                'Nov',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1948',
                'only',
                '-',
                'May',
                '23',
                [
                    '0',
                    '0',
                    '0'
                ],
                '120',
                'DD'
            ],
            [
                '1948',
                'only',
                '-',
                'Sep',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1948',
                '1949',
                '-',
                'Nov',
                '1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1949',
                'only',
                '-',
                'May',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1950',
                'only',
                '-',
                'Apr',
                '16',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1950',
                'only',
                '-',
                'Sep',
                '15',
                [
                    '3',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1951',
                'only',
                '-',
                'Apr',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1951',
                'only',
                '-',
                'Nov',
                '11',
                [
                    '3',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1952',
                'only',
                '-',
                'Apr',
                '20',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1952',
                'only',
                '-',
                'Oct',
                '19',
                [
                    '3',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1953',
                'only',
                '-',
                'Apr',
                '12',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1953',
                'only',
                '-',
                'Sep',
                '13',
                [
                    '3',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1954',
                'only',
                '-',
                'Jun',
                '13',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1954',
                'only',
                '-',
                'Sep',
                '12',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1955',
                'only',
                '-',
                'Jun',
                '11',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1955',
                'only',
                '-',
                'Sep',
                '11',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1956',
                'only',
                '-',
                'Jun',
                '3',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1956',
                'only',
                '-',
                'Sep',
                '30',
                [
                    '3',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1957',
                'only',
                '-',
                'Apr',
                '29',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1957',
                'only',
                '-',
                'Sep',
                '22',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1974',
                'only',
                '-',
                'Jul',
                '7',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1974',
                'only',
                '-',
                'Oct',
                '13',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1975',
                'only',
                '-',
                'Apr',
                '20',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1975',
                'only',
                '-',
                'Aug',
                '31',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1980',
                'only',
                '-',
                'Aug',
                '2',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1980',
                'only',
                '-',
                'Sep',
                '13',
                [
                    '1',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1984',
                'only',
                '-',
                'May',
                '5',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1984',
                'only',
                '-',
                'Aug',
                '25',
                [
                    '1',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1985',
                'only',
                '-',
                'Apr',
                '14',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1985',
                'only',
                '-',
                'Sep',
                '15',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1986',
                'only',
                '-',
                'May',
                '18',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1986',
                'only',
                '-',
                'Sep',
                '7',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1987',
                'only',
                '-',
                'Apr',
                '15',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1987',
                'only',
                '-',
                'Sep',
                '13',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1988',
                'only',
                '-',
                'Apr',
                '10',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1988',
                'only',
                '-',
                'Sep',
                '4',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1989',
                'only',
                '-',
                'Apr',
                '30',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1989',
                'only',
                '-',
                'Sep',
                '3',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1990',
                'only',
                '-',
                'Mar',
                '25',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1990',
                'only',
                '-',
                'Aug',
                '26',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1991',
                'only',
                '-',
                'Mar',
                '24',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1991',
                'only',
                '-',
                'Sep',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1992',
                'only',
                '-',
                'Mar',
                '29',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1992',
                'only',
                '-',
                'Sep',
                '6',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1993',
                'only',
                '-',
                'Apr',
                '2',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1993',
                'only',
                '-',
                'Sep',
                '5',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1994',
                'only',
                '-',
                'Apr',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1994',
                'only',
                '-',
                'Aug',
                '28',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1995',
                'only',
                '-',
                'Mar',
                '31',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1995',
                'only',
                '-',
                'Sep',
                '3',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1996',
                'only',
                '-',
                'Mar',
                '15',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1996',
                'only',
                '-',
                'Sep',
                '16',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1997',
                'only',
                '-',
                'Mar',
                '21',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1997',
                'only',
                '-',
                'Sep',
                '14',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1998',
                'only',
                '-',
                'Mar',
                '20',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1998',
                'only',
                '-',
                'Sep',
                '6',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1999',
                'only',
                '-',
                'Apr',
                '2',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1999',
                'only',
                '-',
                'Sep',
                '3',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '2000',
                'only',
                '-',
                'Apr',
                '14',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '2000',
                'only',
                '-',
                'Oct',
                '6',
                [
                    '1',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '2001',
                'only',
                '-',
                'Apr',
                '9',
                [
                    '1',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '2001',
                'only',
                '-',
                'Sep',
                '24',
                [
                    '1',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '2002',
                'only',
                '-',
                'Mar',
                '29',
                [
                    '1',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '2002',
                'only',
                '-',
                'Oct',
                '7',
                [
                    '1',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '2003',
                'only',
                '-',
                'Mar',
                '28',
                [
                    '1',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '2003',
                'only',
                '-',
                'Oct',
                '3',
                [
                    '1',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '2004',
                'only',
                '-',
                'Apr',
                '7',
                [
                    '1',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '2004',
                'only',
                '-',
                'Sep',
                '22',
                [
                    '1',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '2005',
                '2012',
                '-',
                'Apr',
                'Fri<=1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '2005',
                'only',
                '-',
                'Oct',
                '9',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '2006',
                'only',
                '-',
                'Oct',
                '1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '2007',
                'only',
                '-',
                'Sep',
                '16',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '2008',
                'only',
                '-',
                'Oct',
                '5',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '2009',
                'only',
                '-',
                'Sep',
                '27',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '2010',
                'only',
                '-',
                'Sep',
                '12',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '2011',
                'only',
                '-',
                'Oct',
                '2',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '2012',
                'only',
                '-',
                'Sep',
                '23',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '2013',
                'max',
                '-',
                'Mar',
                'Fri>=23',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '2013',
                'max',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ]
        ],
        'Japan': [
            [
                '1948',
                'only',
                '-',
                'May',
                'Sat>=1',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1948',
                '1951',
                '-',
                'Sep',
                'Sat>=8',
                [
                    '25',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1949',
                'only',
                '-',
                'Apr',
                'Sat>=1',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1950',
                '1951',
                '-',
                'May',
                'Sat>=1',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ]
        ],
        'Jordan': [
            [
                '1973',
                'only',
                '-',
                'Jun',
                '6',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1973',
                '1975',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1974',
                '1977',
                '-',
                'May',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1976',
                'only',
                '-',
                'Nov',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1977',
                'only',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1978',
                'only',
                '-',
                'Apr',
                '30',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1978',
                'only',
                '-',
                'Sep',
                '30',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1985',
                'only',
                '-',
                'Apr',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1985',
                'only',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1986',
                '1988',
                '-',
                'Apr',
                'Fri>=1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1986',
                '1990',
                '-',
                'Oct',
                'Fri>=1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1989',
                'only',
                '-',
                'May',
                '8',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1990',
                'only',
                '-',
                'Apr',
                '27',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1991',
                'only',
                '-',
                'Apr',
                '17',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1991',
                'only',
                '-',
                'Sep',
                '27',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1992',
                'only',
                '-',
                'Apr',
                '10',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1992',
                '1993',
                '-',
                'Oct',
                'Fri>=1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1993',
                '1998',
                '-',
                'Apr',
                'Fri>=1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1994',
                'only',
                '-',
                'Sep',
                'Fri>=15',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1995',
                '1998',
                '-',
                'Sep',
                'Fri>=15',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1999',
                'only',
                '-',
                'Jul',
                '1',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1999',
                '2002',
                '-',
                'Sep',
                'lastFri',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '2000',
                '2001',
                '-',
                'Mar',
                'lastThu',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '2002',
                '2012',
                '-',
                'Mar',
                'lastThu',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '2003',
                'only',
                '-',
                'Oct',
                '24',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '2004',
                'only',
                '-',
                'Oct',
                '15',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '2005',
                'only',
                '-',
                'Sep',
                'lastFri',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '2006',
                '2011',
                '-',
                'Oct',
                'lastFri',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '2013',
                'only',
                '-',
                'Dec',
                '20',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2014',
                'max',
                '-',
                'Mar',
                'lastThu',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '2014',
                'max',
                '-',
                'Oct',
                'lastFri',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ]
        ],
        'Kyrgyz': [
            [
                '1992',
                '1996',
                '-',
                'Apr',
                'Sun>=7',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '60',
                '-'
            ],
            [
                '1992',
                '1996',
                '-',
                'Sep',
                'lastSun',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1997',
                '2005',
                '-',
                'Mar',
                'lastSun',
                [
                    '2',
                    '30',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1997',
                '2004',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '30',
                    '0'
                ],
                '0',
                '-'
            ]
        ],
        'ROK': [
            [
                '1948',
                'only',
                '-',
                'Jun',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1948',
                'only',
                '-',
                'Sep',
                '12',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1949',
                'only',
                '-',
                'Apr',
                '3',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1949',
                '1951',
                '-',
                'Sep',
                'Sat>=7',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1950',
                'only',
                '-',
                'Apr',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1951',
                'only',
                '-',
                'May',
                '6',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1955',
                'only',
                '-',
                'May',
                '5',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1955',
                'only',
                '-',
                'Sep',
                '8',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1956',
                'only',
                '-',
                'May',
                '20',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1956',
                'only',
                '-',
                'Sep',
                '29',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1957',
                '1960',
                '-',
                'May',
                'Sun>=1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1957',
                '1960',
                '-',
                'Sep',
                'Sat>=17',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1987',
                '1988',
                '-',
                'May',
                'Sun>=8',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1987',
                '1988',
                '-',
                'Oct',
                'Sun>=8',
                [
                    '3',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ]
        ],
        'Lebanon': [
            [
                '1920',
                'only',
                '-',
                'Mar',
                '28',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1920',
                'only',
                '-',
                'Oct',
                '25',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1921',
                'only',
                '-',
                'Apr',
                '3',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1921',
                'only',
                '-',
                'Oct',
                '3',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1922',
                'only',
                '-',
                'Mar',
                '26',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1922',
                'only',
                '-',
                'Oct',
                '8',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1923',
                'only',
                '-',
                'Apr',
                '22',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1923',
                'only',
                '-',
                'Sep',
                '16',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1957',
                '1961',
                '-',
                'May',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1957',
                '1961',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1972',
                'only',
                '-',
                'Jun',
                '22',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1972',
                '1977',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1973',
                '1977',
                '-',
                'May',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1978',
                'only',
                '-',
                'Apr',
                '30',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1978',
                'only',
                '-',
                'Sep',
                '30',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1984',
                '1987',
                '-',
                'May',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1984',
                '1991',
                '-',
                'Oct',
                '16',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1988',
                'only',
                '-',
                'Jun',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1989',
                'only',
                '-',
                'May',
                '10',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1990',
                '1992',
                '-',
                'May',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1992',
                'only',
                '-',
                'Oct',
                '4',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1993',
                'max',
                '-',
                'Mar',
                'lastSun',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1993',
                '1998',
                '-',
                'Sep',
                'lastSun',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1999',
                'max',
                '-',
                'Oct',
                'lastSun',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ]
        ],
        'NBorneo': [
            [
                '1935',
                '1941',
                '-',
                'Sep',
                '14',
                [
                    '0',
                    '0',
                    '0'
                ],
                '20',
                '-'
            ],
            [
                '1935',
                '1941',
                '-',
                'Dec',
                '14',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ]
        ],
        'Mongol': [
            [
                '1983',
                '1984',
                '-',
                'Apr',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1983',
                'only',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1985',
                '1998',
                '-',
                'Mar',
                'lastSun',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1984',
                '1998',
                '-',
                'Sep',
                'lastSun',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2001',
                'only',
                '-',
                'Apr',
                'lastSat',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2001',
                '2006',
                '-',
                'Sep',
                'lastSat',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2002',
                '2006',
                '-',
                'Mar',
                'lastSat',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2015',
                '2016',
                '-',
                'Mar',
                'lastSat',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2015',
                '2016',
                '-',
                'Sep',
                'lastSat',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ]
        ],
        'Pakistan': [
            [
                '2002',
                'only',
                '-',
                'Apr',
                'Sun>=2',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '2002',
                'only',
                '-',
                'Oct',
                'Sun>=2',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2008',
                'only',
                '-',
                'Jun',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '2008',
                '2009',
                '-',
                'Nov',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2009',
                'only',
                '-',
                'Apr',
                '15',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ]
        ],
        'EgyptAsia': [
            [
                '1957',
                'only',
                '-',
                'May',
                '10',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1957',
                '1958',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1958',
                'only',
                '-',
                'May',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1959',
                '1967',
                '-',
                'May',
                '1',
                [
                    '1',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1959',
                '1965',
                '-',
                'Sep',
                '30',
                [
                    '3',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1966',
                'only',
                '-',
                'Oct',
                '1',
                [
                    '3',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ]
        ],
        'Palestine': [
            [
                '1999',
                '2005',
                '-',
                'Apr',
                'Fri>=15',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1999',
                '2003',
                '-',
                'Oct',
                'Fri>=15',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2004',
                'only',
                '-',
                'Oct',
                '1',
                [
                    '1',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2005',
                'only',
                '-',
                'Oct',
                '4',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2006',
                '2007',
                '-',
                'Apr',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '2006',
                'only',
                '-',
                'Sep',
                '22',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2007',
                'only',
                '-',
                'Sep',
                'Thu>=8',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2008',
                '2009',
                '-',
                'Mar',
                'lastFri',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '2008',
                'only',
                '-',
                'Sep',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2009',
                'only',
                '-',
                'Sep',
                'Fri>=1',
                [
                    '1',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2010',
                'only',
                '-',
                'Mar',
                '26',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '2010',
                'only',
                '-',
                'Aug',
                '11',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2011',
                'only',
                '-',
                'Apr',
                '1',
                [
                    '0',
                    '1',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '2011',
                'only',
                '-',
                'Aug',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2011',
                'only',
                '-',
                'Aug',
                '30',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '2011',
                'only',
                '-',
                'Sep',
                '30',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2012',
                '2014',
                '-',
                'Mar',
                'lastThu',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '2012',
                'only',
                '-',
                'Sep',
                '21',
                [
                    '1',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2013',
                'only',
                '-',
                'Sep',
                'Fri>=21',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2014',
                '2015',
                '-',
                'Oct',
                'Fri>=21',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2015',
                'only',
                '-',
                'Mar',
                'lastFri',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '2016',
                '2018',
                '-',
                'Mar',
                'Sat>=24',
                [
                    '1',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '2016',
                'max',
                '-',
                'Oct',
                'lastSat',
                [
                    '1',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2019',
                'max',
                '-',
                'Mar',
                'lastFri',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ]
        ],
        'Phil': [
            [
                '1936',
                'only',
                '-',
                'Nov',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1937',
                'only',
                '-',
                'Feb',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1954',
                'only',
                '-',
                'Apr',
                '12',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1954',
                'only',
                '-',
                'Jul',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1978',
                'only',
                '-',
                'Mar',
                '22',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1978',
                'only',
                '-',
                'Sep',
                '21',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ]
        ],
        'Syria': [
            [
                '1920',
                '1923',
                '-',
                'Apr',
                'Sun>=15',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1920',
                '1923',
                '-',
                'Oct',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1962',
                'only',
                '-',
                'Apr',
                '29',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1962',
                'only',
                '-',
                'Oct',
                '1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1963',
                '1965',
                '-',
                'May',
                '1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1963',
                'only',
                '-',
                'Sep',
                '30',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1964',
                'only',
                '-',
                'Oct',
                '1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1965',
                'only',
                '-',
                'Sep',
                '30',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1966',
                'only',
                '-',
                'Apr',
                '24',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1966',
                '1976',
                '-',
                'Oct',
                '1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1967',
                '1978',
                '-',
                'May',
                '1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1977',
                '1978',
                '-',
                'Sep',
                '1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1983',
                '1984',
                '-',
                'Apr',
                '9',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1983',
                '1984',
                '-',
                'Oct',
                '1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1986',
                'only',
                '-',
                'Feb',
                '16',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1986',
                'only',
                '-',
                'Oct',
                '9',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1987',
                'only',
                '-',
                'Mar',
                '1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1987',
                '1988',
                '-',
                'Oct',
                '31',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1988',
                'only',
                '-',
                'Mar',
                '15',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1989',
                'only',
                '-',
                'Mar',
                '31',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1989',
                'only',
                '-',
                'Oct',
                '1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1990',
                'only',
                '-',
                'Apr',
                '1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1990',
                'only',
                '-',
                'Sep',
                '30',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1991',
                'only',
                '-',
                'Apr',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1991',
                '1992',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1992',
                'only',
                '-',
                'Apr',
                '8',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1993',
                'only',
                '-',
                'Mar',
                '26',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1993',
                'only',
                '-',
                'Sep',
                '25',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1994',
                '1996',
                '-',
                'Apr',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1994',
                '2005',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1997',
                '1998',
                '-',
                'Mar',
                'lastMon',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1999',
                '2006',
                '-',
                'Apr',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '2006',
                'only',
                '-',
                'Sep',
                '22',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2007',
                'only',
                '-',
                'Mar',
                'lastFri',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '2007',
                'only',
                '-',
                'Nov',
                'Fri>=1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2008',
                'only',
                '-',
                'Apr',
                'Fri>=1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '2008',
                'only',
                '-',
                'Nov',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2009',
                'only',
                '-',
                'Mar',
                'lastFri',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '2010',
                '2011',
                '-',
                'Apr',
                'Fri>=1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '2012',
                'max',
                '-',
                'Mar',
                'lastFri',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '2009',
                'max',
                '-',
                'Oct',
                'lastFri',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ]
        ],
        'Aus': [
            [
                '1917',
                'only',
                '-',
                'Jan',
                '1',
                [
                    '0',
                    '1',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1917',
                'only',
                '-',
                'Mar',
                '25',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1942',
                'only',
                '-',
                'Jan',
                '1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1942',
                'only',
                '-',
                'Mar',
                '29',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1942',
                'only',
                '-',
                'Sep',
                '27',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1943',
                '1944',
                '-',
                'Mar',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1943',
                'only',
                '-',
                'Oct',
                '3',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ]
        ],
        'AW': [
            [
                '1974',
                'only',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '1975',
                'only',
                '-',
                'Mar',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '1983',
                'only',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '1984',
                'only',
                '-',
                'Mar',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '1991',
                'only',
                '-',
                'Nov',
                '17',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '1992',
                'only',
                '-',
                'Mar',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '2006',
                'only',
                '-',
                'Dec',
                '3',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '2007',
                '2009',
                '-',
                'Mar',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '2007',
                '2008',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ]
        ],
        'AQ': [
            [
                '1971',
                'only',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '1972',
                'only',
                '-',
                'Feb',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '1989',
                '1991',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '1990',
                '1992',
                '-',
                'Mar',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ]
        ],
        'Holiday': [
            [
                '1992',
                '1993',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '1993',
                '1994',
                '-',
                'Mar',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ]
        ],
        'AS': [
            [
                '1971',
                '1985',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '1986',
                'only',
                '-',
                'Oct',
                '19',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '1987',
                '2007',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '1972',
                'only',
                '-',
                'Feb',
                '27',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '1973',
                '1985',
                '-',
                'Mar',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '1986',
                '1990',
                '-',
                'Mar',
                'Sun>=15',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '1991',
                'only',
                '-',
                'Mar',
                '3',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '1992',
                'only',
                '-',
                'Mar',
                '22',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '1993',
                'only',
                '-',
                'Mar',
                '7',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '1994',
                'only',
                '-',
                'Mar',
                '20',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '1995',
                '2005',
                '-',
                'Mar',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '2006',
                'only',
                '-',
                'Apr',
                '2',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '2007',
                'only',
                '-',
                'Mar',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '2008',
                'max',
                '-',
                'Apr',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '2008',
                'max',
                '-',
                'Oct',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ]
        ],
        'AT': [
            [
                '1967',
                'only',
                '-',
                'Oct',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '1968',
                'only',
                '-',
                'Mar',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '1968',
                '1985',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '1969',
                '1971',
                '-',
                'Mar',
                'Sun>=8',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '1972',
                'only',
                '-',
                'Feb',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '1973',
                '1981',
                '-',
                'Mar',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '1982',
                '1983',
                '-',
                'Mar',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '1984',
                '1986',
                '-',
                'Mar',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '1986',
                'only',
                '-',
                'Oct',
                'Sun>=15',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '1987',
                '1990',
                '-',
                'Mar',
                'Sun>=15',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '1987',
                'only',
                '-',
                'Oct',
                'Sun>=22',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '1988',
                '1990',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '1991',
                '1999',
                '-',
                'Oct',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '1991',
                '2005',
                '-',
                'Mar',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '2000',
                'only',
                '-',
                'Aug',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '2001',
                'max',
                '-',
                'Oct',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '2006',
                'only',
                '-',
                'Apr',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '2007',
                'only',
                '-',
                'Mar',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '2008',
                'max',
                '-',
                'Apr',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ]
        ],
        'AV': [
            [
                '1971',
                '1985',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '1972',
                'only',
                '-',
                'Feb',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '1973',
                '1985',
                '-',
                'Mar',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '1986',
                '1990',
                '-',
                'Mar',
                'Sun>=15',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '1986',
                '1987',
                '-',
                'Oct',
                'Sun>=15',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '1988',
                '1999',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '1991',
                '1994',
                '-',
                'Mar',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '1995',
                '2005',
                '-',
                'Mar',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '2000',
                'only',
                '-',
                'Aug',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '2001',
                '2007',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '2006',
                'only',
                '-',
                'Apr',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '2007',
                'only',
                '-',
                'Mar',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '2008',
                'max',
                '-',
                'Apr',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '2008',
                'max',
                '-',
                'Oct',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ]
        ],
        'AN': [
            [
                '1971',
                '1985',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '1972',
                'only',
                '-',
                'Feb',
                '27',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '1973',
                '1981',
                '-',
                'Mar',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '1982',
                'only',
                '-',
                'Apr',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '1983',
                '1985',
                '-',
                'Mar',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '1986',
                '1989',
                '-',
                'Mar',
                'Sun>=15',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '1986',
                'only',
                '-',
                'Oct',
                '19',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '1987',
                '1999',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '1990',
                '1995',
                '-',
                'Mar',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '1996',
                '2005',
                '-',
                'Mar',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '2000',
                'only',
                '-',
                'Aug',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '2001',
                '2007',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '2006',
                'only',
                '-',
                'Apr',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '2007',
                'only',
                '-',
                'Mar',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '2008',
                'max',
                '-',
                'Apr',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '2008',
                'max',
                '-',
                'Oct',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ]
        ],
        'LH': [
            [
                '1981',
                '1984',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1982',
                '1985',
                '-',
                'Mar',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1985',
                'only',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '30',
                '-'
            ],
            [
                '1986',
                '1989',
                '-',
                'Mar',
                'Sun>=15',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1986',
                'only',
                '-',
                'Oct',
                '19',
                [
                    '2',
                    '0',
                    '0'
                ],
                '30',
                '-'
            ],
            [
                '1987',
                '1999',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '30',
                '-'
            ],
            [
                '1990',
                '1995',
                '-',
                'Mar',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1996',
                '2005',
                '-',
                'Mar',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2000',
                'only',
                '-',
                'Aug',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '30',
                '-'
            ],
            [
                '2001',
                '2007',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '30',
                '-'
            ],
            [
                '2006',
                'only',
                '-',
                'Apr',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2007',
                'only',
                '-',
                'Mar',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2008',
                'max',
                '-',
                'Apr',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2008',
                'max',
                '-',
                'Oct',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '30',
                '-'
            ]
        ],
        'Fiji': [
            [
                '1998',
                '1999',
                '-',
                'Nov',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1999',
                '2000',
                '-',
                'Feb',
                'lastSun',
                [
                    '3',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2009',
                'only',
                '-',
                'Nov',
                '29',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2010',
                'only',
                '-',
                'Mar',
                'lastSun',
                [
                    '3',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2010',
                '2013',
                '-',
                'Oct',
                'Sun>=21',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2011',
                'only',
                '-',
                'Mar',
                'Sun>=1',
                [
                    '3',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2012',
                '2013',
                '-',
                'Jan',
                'Sun>=18',
                [
                    '3',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2014',
                'only',
                '-',
                'Jan',
                'Sun>=18',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2014',
                '2018',
                '-',
                'Nov',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2015',
                'max',
                '-',
                'Jan',
                'Sun>=12',
                [
                    '3',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2019',
                'max',
                '-',
                'Nov',
                'Sun>=8',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ]
        ],
        'Guam': [
            [
                '1959',
                'only',
                '-',
                'Jun',
                '27',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1961',
                'only',
                '-',
                'Jan',
                '29',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1967',
                'only',
                '-',
                'Sep',
                '1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1969',
                'only',
                '-',
                'Jan',
                '26',
                [
                    '0',
                    '1',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1969',
                'only',
                '-',
                'Jun',
                '22',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1969',
                'only',
                '-',
                'Aug',
                '31',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1970',
                '1971',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1970',
                '1971',
                '-',
                'Sep',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1973',
                'only',
                '-',
                'Dec',
                '16',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1974',
                'only',
                '-',
                'Feb',
                '24',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1976',
                'only',
                '-',
                'May',
                '26',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1976',
                'only',
                '-',
                'Aug',
                '22',
                [
                    '2',
                    '1',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1977',
                'only',
                '-',
                'Apr',
                '24',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1977',
                'only',
                '-',
                'Aug',
                '28',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ]
        ],
        'NC': [
            [
                '1977',
                '1978',
                '-',
                'Dec',
                'Sun>=1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1978',
                '1979',
                '-',
                'Feb',
                '27',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1996',
                'only',
                '-',
                'Dec',
                '1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                '-'
            ],
            [
                '1997',
                'only',
                '-',
                'Mar',
                '2',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ]
        ],
        'NZ': [
            [
                '1927',
                'only',
                '-',
                'Nov',
                '6',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1928',
                'only',
                '-',
                'Mar',
                '4',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'M'
            ],
            [
                '1928',
                '1933',
                '-',
                'Oct',
                'Sun>=8',
                [
                    '2',
                    '0',
                    '0'
                ],
                '30',
                'S'
            ],
            [
                '1929',
                '1933',
                '-',
                'Mar',
                'Sun>=15',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'M'
            ],
            [
                '1934',
                '1940',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'M'
            ],
            [
                '1934',
                '1940',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '30',
                'S'
            ],
            [
                '1946',
                'only',
                '-',
                'Jan',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1974',
                'only',
                '-',
                'Nov',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '1975',
                'only',
                '-',
                'Feb',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '1975',
                '1988',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '1976',
                '1989',
                '-',
                'Mar',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '1989',
                'only',
                '-',
                'Oct',
                'Sun>=8',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '1990',
                '2006',
                '-',
                'Oct',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '1990',
                '2007',
                '-',
                'Mar',
                'Sun>=15',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '2007',
                'max',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '2008',
                'max',
                '-',
                'Apr',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ]
        ],
        'Chatham': [
            [
                '1974',
                'only',
                '-',
                'Nov',
                'Sun>=1',
                [
                    '2',
                    '45',
                    '0',
                    's'
                ],
                '60',
                '-'
            ],
            [
                '1975',
                'only',
                '-',
                'Feb',
                'lastSun',
                [
                    '2',
                    '45',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1975',
                '1988',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '45',
                    '0',
                    's'
                ],
                '60',
                '-'
            ],
            [
                '1976',
                '1989',
                '-',
                'Mar',
                'Sun>=1',
                [
                    '2',
                    '45',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1989',
                'only',
                '-',
                'Oct',
                'Sun>=8',
                [
                    '2',
                    '45',
                    '0',
                    's'
                ],
                '60',
                '-'
            ],
            [
                '1990',
                '2006',
                '-',
                'Oct',
                'Sun>=1',
                [
                    '2',
                    '45',
                    '0',
                    's'
                ],
                '60',
                '-'
            ],
            [
                '1990',
                '2007',
                '-',
                'Mar',
                'Sun>=15',
                [
                    '2',
                    '45',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '2007',
                'max',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '45',
                    '0',
                    's'
                ],
                '60',
                '-'
            ],
            [
                '2008',
                'max',
                '-',
                'Apr',
                'Sun>=1',
                [
                    '2',
                    '45',
                    '0',
                    's'
                ],
                '0',
                '-'
            ]
        ],
        'Cook': [
            [
                '1978',
                'only',
                '-',
                'Nov',
                '12',
                [
                    '0',
                    '0',
                    '0'
                ],
                '30',
                '-'
            ],
            [
                '1979',
                '1991',
                '-',
                'Mar',
                'Sun>=1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1979',
                '1990',
                '-',
                'Oct',
                'lastSun',
                [
                    '0',
                    '0',
                    '0'
                ],
                '30',
                '-'
            ]
        ],
        'WS': [
            [
                '2010',
                'only',
                '-',
                'Sep',
                'lastSun',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2011',
                'only',
                '-',
                'Apr',
                'Sat>=1',
                [
                    '4',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2011',
                'only',
                '-',
                'Sep',
                'lastSat',
                [
                    '3',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2012',
                'max',
                '-',
                'Apr',
                'Sun>=1',
                [
                    '4',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2012',
                'max',
                '-',
                'Sep',
                'lastSun',
                [
                    '3',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ]
        ],
        'Tonga': [
            [
                '1999',
                'only',
                '-',
                'Oct',
                '7',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                '-'
            ],
            [
                '2000',
                'only',
                '-',
                'Mar',
                '19',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '2000',
                '2001',
                '-',
                'Nov',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2001',
                '2002',
                '-',
                'Jan',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2016',
                'only',
                '-',
                'Nov',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2017',
                'only',
                '-',
                'Jan',
                'Sun>=15',
                [
                    '3',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ]
        ],
        'Vanuatu': [
            [
                '1983',
                'only',
                '-',
                'Sep',
                '25',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1984',
                '1991',
                '-',
                'Mar',
                'Sun>=23',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1984',
                'only',
                '-',
                'Oct',
                '23',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1985',
                '1991',
                '-',
                'Sep',
                'Sun>=23',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1992',
                '1993',
                '-',
                'Jan',
                'Sun>=23',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1992',
                'only',
                '-',
                'Oct',
                'Sun>=23',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ]
        ],
        'GB-Eire': [
            [
                '1916',
                'only',
                '-',
                'May',
                '21',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'BST'
            ],
            [
                '1916',
                'only',
                '-',
                'Oct',
                '1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'GMT'
            ],
            [
                '1917',
                'only',
                '-',
                'Apr',
                '8',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'BST'
            ],
            [
                '1917',
                'only',
                '-',
                'Sep',
                '17',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'GMT'
            ],
            [
                '1918',
                'only',
                '-',
                'Mar',
                '24',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'BST'
            ],
            [
                '1918',
                'only',
                '-',
                'Sep',
                '30',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'GMT'
            ],
            [
                '1919',
                'only',
                '-',
                'Mar',
                '30',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'BST'
            ],
            [
                '1919',
                'only',
                '-',
                'Sep',
                '29',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'GMT'
            ],
            [
                '1920',
                'only',
                '-',
                'Mar',
                '28',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'BST'
            ],
            [
                '1920',
                'only',
                '-',
                'Oct',
                '25',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'GMT'
            ],
            [
                '1921',
                'only',
                '-',
                'Apr',
                '3',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'BST'
            ],
            [
                '1921',
                'only',
                '-',
                'Oct',
                '3',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'GMT'
            ],
            [
                '1922',
                'only',
                '-',
                'Mar',
                '26',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'BST'
            ],
            [
                '1922',
                'only',
                '-',
                'Oct',
                '8',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'GMT'
            ],
            [
                '1923',
                'only',
                '-',
                'Apr',
                'Sun>=16',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'BST'
            ],
            [
                '1923',
                '1924',
                '-',
                'Sep',
                'Sun>=16',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'GMT'
            ],
            [
                '1924',
                'only',
                '-',
                'Apr',
                'Sun>=9',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'BST'
            ],
            [
                '1925',
                '1926',
                '-',
                'Apr',
                'Sun>=16',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'BST'
            ],
            [
                '1925',
                '1938',
                '-',
                'Oct',
                'Sun>=2',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'GMT'
            ],
            [
                '1927',
                'only',
                '-',
                'Apr',
                'Sun>=9',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'BST'
            ],
            [
                '1928',
                '1929',
                '-',
                'Apr',
                'Sun>=16',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'BST'
            ],
            [
                '1930',
                'only',
                '-',
                'Apr',
                'Sun>=9',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'BST'
            ],
            [
                '1931',
                '1932',
                '-',
                'Apr',
                'Sun>=16',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'BST'
            ],
            [
                '1933',
                'only',
                '-',
                'Apr',
                'Sun>=9',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'BST'
            ],
            [
                '1934',
                'only',
                '-',
                'Apr',
                'Sun>=16',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'BST'
            ],
            [
                '1935',
                'only',
                '-',
                'Apr',
                'Sun>=9',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'BST'
            ],
            [
                '1936',
                '1937',
                '-',
                'Apr',
                'Sun>=16',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'BST'
            ],
            [
                '1938',
                'only',
                '-',
                'Apr',
                'Sun>=9',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'BST'
            ],
            [
                '1939',
                'only',
                '-',
                'Apr',
                'Sun>=16',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'BST'
            ],
            [
                '1939',
                'only',
                '-',
                'Nov',
                'Sun>=16',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'GMT'
            ],
            [
                '1940',
                'only',
                '-',
                'Feb',
                'Sun>=23',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'BST'
            ],
            [
                '1941',
                'only',
                '-',
                'May',
                'Sun>=2',
                [
                    '1',
                    '0',
                    '0',
                    's'
                ],
                '120',
                'BDST'
            ],
            [
                '1941',
                '1943',
                '-',
                'Aug',
                'Sun>=9',
                [
                    '1',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'BST'
            ],
            [
                '1942',
                '1944',
                '-',
                'Apr',
                'Sun>=2',
                [
                    '1',
                    '0',
                    '0',
                    's'
                ],
                '120',
                'BDST'
            ],
            [
                '1944',
                'only',
                '-',
                'Sep',
                'Sun>=16',
                [
                    '1',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'BST'
            ],
            [
                '1945',
                'only',
                '-',
                'Apr',
                'Mon>=2',
                [
                    '1',
                    '0',
                    '0',
                    's'
                ],
                '120',
                'BDST'
            ],
            [
                '1945',
                'only',
                '-',
                'Jul',
                'Sun>=9',
                [
                    '1',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'BST'
            ],
            [
                '1945',
                '1946',
                '-',
                'Oct',
                'Sun>=2',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'GMT'
            ],
            [
                '1946',
                'only',
                '-',
                'Apr',
                'Sun>=9',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'BST'
            ],
            [
                '1947',
                'only',
                '-',
                'Mar',
                '16',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'BST'
            ],
            [
                '1947',
                'only',
                '-',
                'Apr',
                '13',
                [
                    '1',
                    '0',
                    '0',
                    's'
                ],
                '120',
                'BDST'
            ],
            [
                '1947',
                'only',
                '-',
                'Aug',
                '10',
                [
                    '1',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'BST'
            ],
            [
                '1947',
                'only',
                '-',
                'Nov',
                '2',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'GMT'
            ],
            [
                '1948',
                'only',
                '-',
                'Mar',
                '14',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'BST'
            ],
            [
                '1948',
                'only',
                '-',
                'Oct',
                '31',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'GMT'
            ],
            [
                '1949',
                'only',
                '-',
                'Apr',
                '3',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'BST'
            ],
            [
                '1949',
                'only',
                '-',
                'Oct',
                '30',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'GMT'
            ],
            [
                '1950',
                '1952',
                '-',
                'Apr',
                'Sun>=14',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'BST'
            ],
            [
                '1950',
                '1952',
                '-',
                'Oct',
                'Sun>=21',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'GMT'
            ],
            [
                '1953',
                'only',
                '-',
                'Apr',
                'Sun>=16',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'BST'
            ],
            [
                '1953',
                '1960',
                '-',
                'Oct',
                'Sun>=2',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'GMT'
            ],
            [
                '1954',
                'only',
                '-',
                'Apr',
                'Sun>=9',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'BST'
            ],
            [
                '1955',
                '1956',
                '-',
                'Apr',
                'Sun>=16',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'BST'
            ],
            [
                '1957',
                'only',
                '-',
                'Apr',
                'Sun>=9',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'BST'
            ],
            [
                '1958',
                '1959',
                '-',
                'Apr',
                'Sun>=16',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'BST'
            ],
            [
                '1960',
                'only',
                '-',
                'Apr',
                'Sun>=9',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'BST'
            ],
            [
                '1961',
                '1963',
                '-',
                'Mar',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'BST'
            ],
            [
                '1961',
                '1968',
                '-',
                'Oct',
                'Sun>=23',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'GMT'
            ],
            [
                '1964',
                '1967',
                '-',
                'Mar',
                'Sun>=19',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'BST'
            ],
            [
                '1968',
                'only',
                '-',
                'Feb',
                '18',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'BST'
            ],
            [
                '1972',
                '1980',
                '-',
                'Mar',
                'Sun>=16',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'BST'
            ],
            [
                '1972',
                '1980',
                '-',
                'Oct',
                'Sun>=23',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'GMT'
            ],
            [
                '1981',
                '1995',
                '-',
                'Mar',
                'lastSun',
                [
                    '1',
                    '0',
                    '0',
                    'u'
                ],
                '60',
                'BST'
            ],
            [
                '1981',
                '1989',
                '-',
                'Oct',
                'Sun>=23',
                [
                    '1',
                    '0',
                    '0',
                    'u'
                ],
                '0',
                'GMT'
            ],
            [
                '1990',
                '1995',
                '-',
                'Oct',
                'Sun>=22',
                [
                    '1',
                    '0',
                    '0',
                    'u'
                ],
                '0',
                'GMT'
            ]
        ],
        'Eire': [
            [
                '1971',
                'only',
                '-',
                'Oct',
                '31',
                [
                    '2',
                    '0',
                    '0',
                    'u'
                ],
                '-60',
                '-'
            ],
            [
                '1972',
                '1980',
                '-',
                'Mar',
                'Sun>=16',
                [
                    '2',
                    '0',
                    '0',
                    'u'
                ],
                '0',
                '-'
            ],
            [
                '1972',
                '1980',
                '-',
                'Oct',
                'Sun>=23',
                [
                    '2',
                    '0',
                    '0',
                    'u'
                ],
                '-60',
                '-'
            ],
            [
                '1981',
                'max',
                '-',
                'Mar',
                'lastSun',
                [
                    '1',
                    '0',
                    '0',
                    'u'
                ],
                '0',
                '-'
            ],
            [
                '1981',
                '1989',
                '-',
                'Oct',
                'Sun>=23',
                [
                    '1',
                    '0',
                    '0',
                    'u'
                ],
                '-60',
                '-'
            ],
            [
                '1990',
                '1995',
                '-',
                'Oct',
                'Sun>=22',
                [
                    '1',
                    '0',
                    '0',
                    'u'
                ],
                '-60',
                '-'
            ],
            [
                '1996',
                'max',
                '-',
                'Oct',
                'lastSun',
                [
                    '1',
                    '0',
                    '0',
                    'u'
                ],
                '-60',
                '-'
            ]
        ],
        'EU': [
            [
                '1977',
                '1980',
                '-',
                'Apr',
                'Sun>=1',
                [
                    '1',
                    '0',
                    '0',
                    'u'
                ],
                '60',
                'S'
            ],
            [
                '1977',
                'only',
                '-',
                'Sep',
                'lastSun',
                [
                    '1',
                    '0',
                    '0',
                    'u'
                ],
                '0',
                '-'
            ],
            [
                '1978',
                'only',
                '-',
                'Oct',
                '1',
                [
                    '1',
                    '0',
                    '0',
                    'u'
                ],
                '0',
                '-'
            ],
            [
                '1979',
                '1995',
                '-',
                'Sep',
                'lastSun',
                [
                    '1',
                    '0',
                    '0',
                    'u'
                ],
                '0',
                '-'
            ],
            [
                '1981',
                'max',
                '-',
                'Mar',
                'lastSun',
                [
                    '1',
                    '0',
                    '0',
                    'u'
                ],
                '60',
                'S'
            ],
            [
                '1996',
                'max',
                '-',
                'Oct',
                'lastSun',
                [
                    '1',
                    '0',
                    '0',
                    'u'
                ],
                '0',
                '-'
            ]
        ],
        'W-Eur': [
            [
                '1977',
                '1980',
                '-',
                'Apr',
                'Sun>=1',
                [
                    '1',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1977',
                'only',
                '-',
                'Sep',
                'lastSun',
                [
                    '1',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1978',
                'only',
                '-',
                'Oct',
                '1',
                [
                    '1',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1979',
                '1995',
                '-',
                'Sep',
                'lastSun',
                [
                    '1',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1981',
                'max',
                '-',
                'Mar',
                'lastSun',
                [
                    '1',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1996',
                'max',
                '-',
                'Oct',
                'lastSun',
                [
                    '1',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ]
        ],
        'C-Eur': [
            [
                '1916',
                'only',
                '-',
                'Apr',
                '30',
                [
                    '23',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1916',
                'only',
                '-',
                'Oct',
                '1',
                [
                    '1',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1917',
                '1918',
                '-',
                'Apr',
                'Mon>=15',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1917',
                '1918',
                '-',
                'Sep',
                'Mon>=15',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1940',
                'only',
                '-',
                'Apr',
                '1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1942',
                'only',
                '-',
                'Nov',
                '2',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1943',
                'only',
                '-',
                'Mar',
                '29',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1943',
                'only',
                '-',
                'Oct',
                '4',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1944',
                '1945',
                '-',
                'Apr',
                'Mon>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1944',
                'only',
                '-',
                'Oct',
                '2',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1945',
                'only',
                '-',
                'Sep',
                '16',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1977',
                '1980',
                '-',
                'Apr',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1977',
                'only',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1978',
                'only',
                '-',
                'Oct',
                '1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1979',
                '1995',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1981',
                'max',
                '-',
                'Mar',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1996',
                'max',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ]
        ],
        'E-Eur': [
            [
                '1977',
                '1980',
                '-',
                'Apr',
                'Sun>=1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1977',
                'only',
                '-',
                'Sep',
                'lastSun',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1978',
                'only',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1979',
                '1995',
                '-',
                'Sep',
                'lastSun',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1981',
                'max',
                '-',
                'Mar',
                'lastSun',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1996',
                'max',
                '-',
                'Oct',
                'lastSun',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ]
        ],
        'Russia': [
            [
                '1917',
                'only',
                '-',
                'Jul',
                '1',
                [
                    '23',
                    '0',
                    '0'
                ],
                '60',
                'MST',
                ''
            ],
            [
                '1917',
                'only',
                '-',
                'Dec',
                '28',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'MMT',
                ''
            ],
            [
                '1918',
                'only',
                '-',
                'May',
                '31',
                [
                    '22',
                    '0',
                    '0'
                ],
                '120',
                'MDST',
                ''
            ],
            [
                '1918',
                'only',
                '-',
                'Sep',
                '16',
                [
                    '1',
                    '0',
                    '0'
                ],
                '60',
                'MST'
            ],
            [
                '1919',
                'only',
                '-',
                'May',
                '31',
                [
                    '23',
                    '0',
                    '0'
                ],
                '120',
                'MDST'
            ],
            [
                '1919',
                'only',
                '-',
                'Jul',
                '1',
                [
                    '0',
                    '0',
                    '0',
                    'u'
                ],
                '60',
                'MSD'
            ],
            [
                '1919',
                'only',
                '-',
                'Aug',
                '16',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'MSK'
            ],
            [
                '1921',
                'only',
                '-',
                'Feb',
                '14',
                [
                    '23',
                    '0',
                    '0'
                ],
                '60',
                'MSD'
            ],
            [
                '1921',
                'only',
                '-',
                'Mar',
                '20',
                [
                    '23',
                    '0',
                    '0'
                ],
                '120',
                '+05'
            ],
            [
                '1921',
                'only',
                '-',
                'Sep',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'MSD'
            ],
            [
                '1921',
                'only',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1981',
                '1984',
                '-',
                'Apr',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1981',
                '1983',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1984',
                '1995',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1985',
                '2010',
                '-',
                'Mar',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1996',
                '2010',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ]
        ],
        'Albania': [
            [
                '1940',
                'only',
                '-',
                'Jun',
                '16',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1942',
                'only',
                '-',
                'Nov',
                '2',
                [
                    '3',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1943',
                'only',
                '-',
                'Mar',
                '29',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1943',
                'only',
                '-',
                'Apr',
                '10',
                [
                    '3',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1974',
                'only',
                '-',
                'May',
                '4',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1974',
                'only',
                '-',
                'Oct',
                '2',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1975',
                'only',
                '-',
                'May',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1975',
                'only',
                '-',
                'Oct',
                '2',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1976',
                'only',
                '-',
                'May',
                '2',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1976',
                'only',
                '-',
                'Oct',
                '3',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1977',
                'only',
                '-',
                'May',
                '8',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1977',
                'only',
                '-',
                'Oct',
                '2',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1978',
                'only',
                '-',
                'May',
                '6',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1978',
                'only',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1979',
                'only',
                '-',
                'May',
                '5',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1979',
                'only',
                '-',
                'Sep',
                '30',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1980',
                'only',
                '-',
                'May',
                '3',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1980',
                'only',
                '-',
                'Oct',
                '4',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1981',
                'only',
                '-',
                'Apr',
                '26',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1981',
                'only',
                '-',
                'Sep',
                '27',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1982',
                'only',
                '-',
                'May',
                '2',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1982',
                'only',
                '-',
                'Oct',
                '3',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1983',
                'only',
                '-',
                'Apr',
                '18',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1983',
                'only',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1984',
                'only',
                '-',
                'Apr',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ]
        ],
        'Austria': [
            [
                '1920',
                'only',
                '-',
                'Apr',
                '5',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1920',
                'only',
                '-',
                'Sep',
                '13',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1946',
                'only',
                '-',
                'Apr',
                '14',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1946',
                'only',
                '-',
                'Oct',
                '7',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1947',
                '1948',
                '-',
                'Oct',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1947',
                'only',
                '-',
                'Apr',
                '6',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1948',
                'only',
                '-',
                'Apr',
                '18',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1980',
                'only',
                '-',
                'Apr',
                '6',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1980',
                'only',
                '-',
                'Sep',
                '28',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ]
        ],
        'Belgium': [
            [
                '1918',
                'only',
                '-',
                'Mar',
                '9',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1918',
                '1919',
                '-',
                'Oct',
                'Sat>=1',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1919',
                'only',
                '-',
                'Mar',
                '1',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1920',
                'only',
                '-',
                'Feb',
                '14',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1920',
                'only',
                '-',
                'Oct',
                '23',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1921',
                'only',
                '-',
                'Mar',
                '14',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1921',
                'only',
                '-',
                'Oct',
                '25',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1922',
                'only',
                '-',
                'Mar',
                '25',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1922',
                '1927',
                '-',
                'Oct',
                'Sat>=1',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1923',
                'only',
                '-',
                'Apr',
                '21',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1924',
                'only',
                '-',
                'Mar',
                '29',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1925',
                'only',
                '-',
                'Apr',
                '4',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1926',
                'only',
                '-',
                'Apr',
                '17',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1927',
                'only',
                '-',
                'Apr',
                '9',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1928',
                'only',
                '-',
                'Apr',
                '14',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1928',
                '1938',
                '-',
                'Oct',
                'Sun>=2',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1929',
                'only',
                '-',
                'Apr',
                '21',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1930',
                'only',
                '-',
                'Apr',
                '13',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1931',
                'only',
                '-',
                'Apr',
                '19',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1932',
                'only',
                '-',
                'Apr',
                '3',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1933',
                'only',
                '-',
                'Mar',
                '26',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1934',
                'only',
                '-',
                'Apr',
                '8',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1935',
                'only',
                '-',
                'Mar',
                '31',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1936',
                'only',
                '-',
                'Apr',
                '19',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1937',
                'only',
                '-',
                'Apr',
                '4',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1938',
                'only',
                '-',
                'Mar',
                '27',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1939',
                'only',
                '-',
                'Apr',
                '16',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1939',
                'only',
                '-',
                'Nov',
                '19',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1940',
                'only',
                '-',
                'Feb',
                '25',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1944',
                'only',
                '-',
                'Sep',
                '17',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1945',
                'only',
                '-',
                'Apr',
                '2',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1945',
                'only',
                '-',
                'Sep',
                '16',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1946',
                'only',
                '-',
                'May',
                '19',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1946',
                'only',
                '-',
                'Oct',
                '7',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ]
        ],
        'Bulg': [
            [
                '1979',
                'only',
                '-',
                'Mar',
                '31',
                [
                    '23',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1979',
                'only',
                '-',
                'Oct',
                '1',
                [
                    '1',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1980',
                '1982',
                '-',
                'Apr',
                'Sat>=1',
                [
                    '23',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1980',
                'only',
                '-',
                'Sep',
                '29',
                [
                    '1',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1981',
                'only',
                '-',
                'Sep',
                '27',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ]
        ],
        'Czech': [
            [
                '1945',
                'only',
                '-',
                'Apr',
                'Mon>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1945',
                'only',
                '-',
                'Oct',
                '1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1946',
                'only',
                '-',
                'May',
                '6',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1946',
                '1949',
                '-',
                'Oct',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1947',
                '1948',
                '-',
                'Apr',
                'Sun>=15',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1949',
                'only',
                '-',
                'Apr',
                '9',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ]
        ],
        'Denmark': [
            [
                '1916',
                'only',
                '-',
                'May',
                '14',
                [
                    '23',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1916',
                'only',
                '-',
                'Sep',
                '30',
                [
                    '23',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1940',
                'only',
                '-',
                'May',
                '15',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1945',
                'only',
                '-',
                'Apr',
                '2',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1945',
                'only',
                '-',
                'Aug',
                '15',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1946',
                'only',
                '-',
                'May',
                '1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1946',
                'only',
                '-',
                'Sep',
                '1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1947',
                'only',
                '-',
                'May',
                '4',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1947',
                'only',
                '-',
                'Aug',
                '10',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1948',
                'only',
                '-',
                'May',
                '9',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1948',
                'only',
                '-',
                'Aug',
                '8',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ]
        ],
        'Thule': [
            [
                '1991',
                '1992',
                '-',
                'Mar',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1991',
                '1992',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1993',
                '2006',
                '-',
                'Apr',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1993',
                '2006',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '2007',
                'max',
                '-',
                'Mar',
                'Sun>=8',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '2007',
                'max',
                '-',
                'Nov',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ]
        ],
        'Finland': [
            [
                '1942',
                'only',
                '-',
                'Apr',
                '2',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1942',
                'only',
                '-',
                'Oct',
                '4',
                [
                    '1',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1981',
                '1982',
                '-',
                'Mar',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1981',
                '1982',
                '-',
                'Sep',
                'lastSun',
                [
                    '3',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ]
        ],
        'France': [
            [
                '1916',
                'only',
                '-',
                'Jun',
                '14',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1916',
                '1919',
                '-',
                'Oct',
                'Sun>=1',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1917',
                'only',
                '-',
                'Mar',
                '24',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1918',
                'only',
                '-',
                'Mar',
                '9',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1919',
                'only',
                '-',
                'Mar',
                '1',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1920',
                'only',
                '-',
                'Feb',
                '14',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1920',
                'only',
                '-',
                'Oct',
                '23',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1921',
                'only',
                '-',
                'Mar',
                '14',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1921',
                'only',
                '-',
                'Oct',
                '25',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1922',
                'only',
                '-',
                'Mar',
                '25',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1922',
                '1938',
                '-',
                'Oct',
                'Sat>=1',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1923',
                'only',
                '-',
                'May',
                '26',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1924',
                'only',
                '-',
                'Mar',
                '29',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1925',
                'only',
                '-',
                'Apr',
                '4',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1926',
                'only',
                '-',
                'Apr',
                '17',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1927',
                'only',
                '-',
                'Apr',
                '9',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1928',
                'only',
                '-',
                'Apr',
                '14',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1929',
                'only',
                '-',
                'Apr',
                '20',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1930',
                'only',
                '-',
                'Apr',
                '12',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1931',
                'only',
                '-',
                'Apr',
                '18',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1932',
                'only',
                '-',
                'Apr',
                '2',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1933',
                'only',
                '-',
                'Mar',
                '25',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1934',
                'only',
                '-',
                'Apr',
                '7',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1935',
                'only',
                '-',
                'Mar',
                '30',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1936',
                'only',
                '-',
                'Apr',
                '18',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1937',
                'only',
                '-',
                'Apr',
                '3',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1938',
                'only',
                '-',
                'Mar',
                '26',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1939',
                'only',
                '-',
                'Apr',
                '15',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1939',
                'only',
                '-',
                'Nov',
                '18',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1940',
                'only',
                '-',
                'Feb',
                '25',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1941',
                'only',
                '-',
                'May',
                '5',
                [
                    '0',
                    '0',
                    '0'
                ],
                '120',
                'M',
                ''
            ],
            [
                '1941',
                'only',
                '-',
                'Oct',
                '6',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1942',
                'only',
                '-',
                'Mar',
                '9',
                [
                    '0',
                    '0',
                    '0'
                ],
                '120',
                'M'
            ],
            [
                '1942',
                'only',
                '-',
                'Nov',
                '2',
                [
                    '3',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1943',
                'only',
                '-',
                'Mar',
                '29',
                [
                    '2',
                    '0',
                    '0'
                ],
                '120',
                'M'
            ],
            [
                '1943',
                'only',
                '-',
                'Oct',
                '4',
                [
                    '3',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1944',
                'only',
                '-',
                'Apr',
                '3',
                [
                    '2',
                    '0',
                    '0'
                ],
                '120',
                'M'
            ],
            [
                '1944',
                'only',
                '-',
                'Oct',
                '8',
                [
                    '1',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1945',
                'only',
                '-',
                'Apr',
                '2',
                [
                    '2',
                    '0',
                    '0'
                ],
                '120',
                'M'
            ],
            [
                '1945',
                'only',
                '-',
                'Sep',
                '16',
                [
                    '3',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1976',
                'only',
                '-',
                'Mar',
                '28',
                [
                    '1',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1976',
                'only',
                '-',
                'Sep',
                '26',
                [
                    '1',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ]
        ],
        'Germany': [
            [
                '1946',
                'only',
                '-',
                'Apr',
                '14',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1946',
                'only',
                '-',
                'Oct',
                '7',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1947',
                '1949',
                '-',
                'Oct',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1947',
                'only',
                '-',
                'Apr',
                '6',
                [
                    '3',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1947',
                'only',
                '-',
                'May',
                '11',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '120',
                'M'
            ],
            [
                '1947',
                'only',
                '-',
                'Jun',
                '29',
                [
                    '3',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1948',
                'only',
                '-',
                'Apr',
                '18',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1949',
                'only',
                '-',
                'Apr',
                '10',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ]
        ],
        'SovietZone': [
            [
                '1945',
                'only',
                '-',
                'May',
                '24',
                [
                    '2',
                    '0',
                    '0'
                ],
                '120',
                'M',
                ''
            ],
            [
                '1945',
                'only',
                '-',
                'Sep',
                '24',
                [
                    '3',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1945',
                'only',
                '-',
                'Nov',
                '18',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ]
        ],
        'Greece': [
            [
                '1932',
                'only',
                '-',
                'Jul',
                '7',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1932',
                'only',
                '-',
                'Sep',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1941',
                'only',
                '-',
                'Apr',
                '7',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1942',
                'only',
                '-',
                'Nov',
                '2',
                [
                    '3',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1943',
                'only',
                '-',
                'Mar',
                '30',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1943',
                'only',
                '-',
                'Oct',
                '4',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1952',
                'only',
                '-',
                'Jul',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1952',
                'only',
                '-',
                'Nov',
                '2',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1975',
                'only',
                '-',
                'Apr',
                '12',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1975',
                'only',
                '-',
                'Nov',
                '26',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1976',
                'only',
                '-',
                'Apr',
                '11',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1976',
                'only',
                '-',
                'Oct',
                '10',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1977',
                '1978',
                '-',
                'Apr',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1977',
                'only',
                '-',
                'Sep',
                '26',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1978',
                'only',
                '-',
                'Sep',
                '24',
                [
                    '4',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1979',
                'only',
                '-',
                'Apr',
                '1',
                [
                    '9',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1979',
                'only',
                '-',
                'Sep',
                '29',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1980',
                'only',
                '-',
                'Apr',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1980',
                'only',
                '-',
                'Sep',
                '28',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ]
        ],
        'Hungary': [
            [
                '1918',
                'only',
                '-',
                'Apr',
                '1',
                [
                    '3',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1918',
                'only',
                '-',
                'Sep',
                '16',
                [
                    '3',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1919',
                'only',
                '-',
                'Apr',
                '15',
                [
                    '3',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1919',
                'only',
                '-',
                'Nov',
                '24',
                [
                    '3',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1945',
                'only',
                '-',
                'May',
                '1',
                [
                    '23',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1945',
                'only',
                '-',
                'Nov',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1946',
                'only',
                '-',
                'Mar',
                '31',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1946',
                '1949',
                '-',
                'Oct',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1947',
                '1949',
                '-',
                'Apr',
                'Sun>=4',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1950',
                'only',
                '-',
                'Apr',
                '17',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1950',
                'only',
                '-',
                'Oct',
                '23',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1954',
                '1955',
                '-',
                'May',
                '23',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1954',
                '1955',
                '-',
                'Oct',
                '3',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1956',
                'only',
                '-',
                'Jun',
                'Sun>=1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1956',
                'only',
                '-',
                'Sep',
                'lastSun',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1957',
                'only',
                '-',
                'Jun',
                'Sun>=1',
                [
                    '1',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1957',
                'only',
                '-',
                'Sep',
                'lastSun',
                [
                    '3',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1980',
                'only',
                '-',
                'Apr',
                '6',
                [
                    '1',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ]
        ],
        'Iceland': [
            [
                '1917',
                '1919',
                '-',
                'Feb',
                '19',
                [
                    '23',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1917',
                'only',
                '-',
                'Oct',
                '21',
                [
                    '1',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1918',
                '1919',
                '-',
                'Nov',
                '16',
                [
                    '1',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1921',
                'only',
                '-',
                'Mar',
                '19',
                [
                    '23',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1921',
                'only',
                '-',
                'Jun',
                '23',
                [
                    '1',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1939',
                'only',
                '-',
                'Apr',
                '29',
                [
                    '23',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1939',
                'only',
                '-',
                'Oct',
                '29',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1940',
                'only',
                '-',
                'Feb',
                '25',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1940',
                '1941',
                '-',
                'Nov',
                'Sun>=2',
                [
                    '1',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1941',
                '1942',
                '-',
                'Mar',
                'Sun>=2',
                [
                    '1',
                    '0',
                    '0',
                    's'
                ],
                '60',
                '-'
            ],
            [
                '1943',
                '1946',
                '-',
                'Mar',
                'Sun>=1',
                [
                    '1',
                    '0',
                    '0',
                    's'
                ],
                '60',
                '-'
            ],
            [
                '1942',
                '1948',
                '-',
                'Oct',
                'Sun>=22',
                [
                    '1',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1947',
                '1967',
                '-',
                'Apr',
                'Sun>=1',
                [
                    '1',
                    '0',
                    '0',
                    's'
                ],
                '60',
                '-'
            ],
            [
                '1949',
                'only',
                '-',
                'Oct',
                '30',
                [
                    '1',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1950',
                '1966',
                '-',
                'Oct',
                'Sun>=22',
                [
                    '1',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1967',
                'only',
                '-',
                'Oct',
                '29',
                [
                    '1',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ]
        ],
        'Italy': [
            [
                '1916',
                'only',
                '-',
                'Jun',
                '3',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1916',
                '1917',
                '-',
                'Sep',
                '30',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1917',
                'only',
                '-',
                'Mar',
                '31',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1918',
                'only',
                '-',
                'Mar',
                '9',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1918',
                'only',
                '-',
                'Oct',
                '6',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1919',
                'only',
                '-',
                'Mar',
                '1',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1919',
                'only',
                '-',
                'Oct',
                '4',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1920',
                'only',
                '-',
                'Mar',
                '20',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1920',
                'only',
                '-',
                'Sep',
                '18',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1940',
                'only',
                '-',
                'Jun',
                '14',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1942',
                'only',
                '-',
                'Nov',
                '2',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1943',
                'only',
                '-',
                'Mar',
                '29',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1943',
                'only',
                '-',
                'Oct',
                '4',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1944',
                'only',
                '-',
                'Apr',
                '2',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1944',
                'only',
                '-',
                'Sep',
                '17',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1945',
                'only',
                '-',
                'Apr',
                '2',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1945',
                'only',
                '-',
                'Sep',
                '15',
                [
                    '1',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1946',
                'only',
                '-',
                'Mar',
                '17',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1946',
                'only',
                '-',
                'Oct',
                '6',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1947',
                'only',
                '-',
                'Mar',
                '16',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1947',
                'only',
                '-',
                'Oct',
                '5',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1948',
                'only',
                '-',
                'Feb',
                '29',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1948',
                'only',
                '-',
                'Oct',
                '3',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1966',
                '1968',
                '-',
                'May',
                'Sun>=22',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1966',
                'only',
                '-',
                'Sep',
                '24',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1967',
                '1969',
                '-',
                'Sep',
                'Sun>=22',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1969',
                'only',
                '-',
                'Jun',
                '1',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1970',
                'only',
                '-',
                'May',
                '31',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1970',
                'only',
                '-',
                'Sep',
                'lastSun',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1971',
                '1972',
                '-',
                'May',
                'Sun>=22',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1971',
                'only',
                '-',
                'Sep',
                'lastSun',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1972',
                'only',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1973',
                'only',
                '-',
                'Jun',
                '3',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1973',
                '1974',
                '-',
                'Sep',
                'lastSun',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1974',
                'only',
                '-',
                'May',
                '26',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1975',
                'only',
                '-',
                'Jun',
                '1',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1975',
                '1977',
                '-',
                'Sep',
                'lastSun',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1976',
                'only',
                '-',
                'May',
                '30',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1977',
                '1979',
                '-',
                'May',
                'Sun>=22',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1978',
                'only',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1979',
                'only',
                '-',
                'Sep',
                '30',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ]
        ],
        'Latvia': [
            [
                '1989',
                '1996',
                '-',
                'Mar',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1989',
                '1996',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ]
        ],
        'Lux': [
            [
                '1916',
                'only',
                '-',
                'May',
                '14',
                [
                    '23',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1916',
                'only',
                '-',
                'Oct',
                '1',
                [
                    '1',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1917',
                'only',
                '-',
                'Apr',
                '28',
                [
                    '23',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1917',
                'only',
                '-',
                'Sep',
                '17',
                [
                    '1',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1918',
                'only',
                '-',
                'Apr',
                'Mon>=15',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1918',
                'only',
                '-',
                'Sep',
                'Mon>=15',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1919',
                'only',
                '-',
                'Mar',
                '1',
                [
                    '23',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1919',
                'only',
                '-',
                'Oct',
                '5',
                [
                    '3',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1920',
                'only',
                '-',
                'Feb',
                '14',
                [
                    '23',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1920',
                'only',
                '-',
                'Oct',
                '24',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1921',
                'only',
                '-',
                'Mar',
                '14',
                [
                    '23',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1921',
                'only',
                '-',
                'Oct',
                '26',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1922',
                'only',
                '-',
                'Mar',
                '25',
                [
                    '23',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1922',
                'only',
                '-',
                'Oct',
                'Sun>=2',
                [
                    '1',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1923',
                'only',
                '-',
                'Apr',
                '21',
                [
                    '23',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1923',
                'only',
                '-',
                'Oct',
                'Sun>=2',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1924',
                'only',
                '-',
                'Mar',
                '29',
                [
                    '23',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1924',
                '1928',
                '-',
                'Oct',
                'Sun>=2',
                [
                    '1',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1925',
                'only',
                '-',
                'Apr',
                '5',
                [
                    '23',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1926',
                'only',
                '-',
                'Apr',
                '17',
                [
                    '23',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1927',
                'only',
                '-',
                'Apr',
                '9',
                [
                    '23',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1928',
                'only',
                '-',
                'Apr',
                '14',
                [
                    '23',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1929',
                'only',
                '-',
                'Apr',
                '20',
                [
                    '23',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ]
        ],
        'Malta': [
            [
                '1973',
                'only',
                '-',
                'Mar',
                '31',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1973',
                'only',
                '-',
                'Sep',
                '29',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1974',
                'only',
                '-',
                'Apr',
                '21',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1974',
                'only',
                '-',
                'Sep',
                '16',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1975',
                '1979',
                '-',
                'Apr',
                'Sun>=15',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1975',
                '1980',
                '-',
                'Sep',
                'Sun>=15',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1980',
                'only',
                '-',
                'Mar',
                '31',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ]
        ],
        'Moldova': [
            [
                '1997',
                'max',
                '-',
                'Mar',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1997',
                'max',
                '-',
                'Oct',
                'lastSun',
                [
                    '3',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ]
        ],
        'Neth': [
            [
                '1916',
                'only',
                '-',
                'May',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'NST',
                ''
            ],
            [
                '1916',
                'only',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'AMT',
                ''
            ],
            [
                '1917',
                'only',
                '-',
                'Apr',
                '16',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'NST'
            ],
            [
                '1917',
                'only',
                '-',
                'Sep',
                '17',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'AMT'
            ],
            [
                '1918',
                '1921',
                '-',
                'Apr',
                'Mon>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'NST'
            ],
            [
                '1918',
                '1921',
                '-',
                'Sep',
                'lastMon',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'AMT'
            ],
            [
                '1922',
                'only',
                '-',
                'Mar',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'NST'
            ],
            [
                '1922',
                '1936',
                '-',
                'Oct',
                'Sun>=2',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'AMT'
            ],
            [
                '1923',
                'only',
                '-',
                'Jun',
                'Fri>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'NST'
            ],
            [
                '1924',
                'only',
                '-',
                'Mar',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'NST'
            ],
            [
                '1925',
                'only',
                '-',
                'Jun',
                'Fri>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'NST'
            ],
            [
                '1926',
                '1931',
                '-',
                'May',
                '15',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'NST'
            ],
            [
                '1932',
                'only',
                '-',
                'May',
                '22',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'NST'
            ],
            [
                '1933',
                '1936',
                '-',
                'May',
                '15',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'NST'
            ],
            [
                '1937',
                'only',
                '-',
                'May',
                '22',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'NST'
            ],
            [
                '1937',
                'only',
                '-',
                'Jul',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1937',
                '1939',
                '-',
                'Oct',
                'Sun>=2',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1938',
                '1939',
                '-',
                'May',
                '15',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1945',
                'only',
                '-',
                'Apr',
                '2',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1945',
                'only',
                '-',
                'Sep',
                '16',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ]
        ],
        'Norway': [
            [
                '1916',
                'only',
                '-',
                'May',
                '22',
                [
                    '1',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1916',
                'only',
                '-',
                'Sep',
                '30',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1945',
                'only',
                '-',
                'Apr',
                '2',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1945',
                'only',
                '-',
                'Oct',
                '1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1959',
                '1964',
                '-',
                'Mar',
                'Sun>=15',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1959',
                '1965',
                '-',
                'Sep',
                'Sun>=15',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1965',
                'only',
                '-',
                'Apr',
                '25',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ]
        ],
        'Poland': [
            [
                '1918',
                '1919',
                '-',
                'Sep',
                '16',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1919',
                'only',
                '-',
                'Apr',
                '15',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1944',
                'only',
                '-',
                'Apr',
                '3',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1944',
                'only',
                '-',
                'Oct',
                '4',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1945',
                'only',
                '-',
                'Apr',
                '29',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1945',
                'only',
                '-',
                'Nov',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1946',
                'only',
                '-',
                'Apr',
                '14',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1946',
                'only',
                '-',
                'Oct',
                '7',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1947',
                'only',
                '-',
                'May',
                '4',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1947',
                '1949',
                '-',
                'Oct',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1948',
                'only',
                '-',
                'Apr',
                '18',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1949',
                'only',
                '-',
                'Apr',
                '10',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1957',
                'only',
                '-',
                'Jun',
                '2',
                [
                    '1',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1957',
                '1958',
                '-',
                'Sep',
                'lastSun',
                [
                    '1',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1958',
                'only',
                '-',
                'Mar',
                '30',
                [
                    '1',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1959',
                'only',
                '-',
                'May',
                '31',
                [
                    '1',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1959',
                '1961',
                '-',
                'Oct',
                'Sun>=1',
                [
                    '1',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1960',
                'only',
                '-',
                'Apr',
                '3',
                [
                    '1',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1961',
                '1964',
                '-',
                'May',
                'lastSun',
                [
                    '1',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1962',
                '1964',
                '-',
                'Sep',
                'lastSun',
                [
                    '1',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ]
        ],
        'Port': [
            [
                '1916',
                'only',
                '-',
                'Jun',
                '17',
                [
                    '23',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1916',
                'only',
                '-',
                'Nov',
                '1',
                [
                    '1',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1917',
                'only',
                '-',
                'Feb',
                '28',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1917',
                '1921',
                '-',
                'Oct',
                '14',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1918',
                'only',
                '-',
                'Mar',
                '1',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1919',
                'only',
                '-',
                'Feb',
                '28',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1920',
                'only',
                '-',
                'Feb',
                '29',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1921',
                'only',
                '-',
                'Feb',
                '28',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1924',
                'only',
                '-',
                'Apr',
                '16',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1924',
                'only',
                '-',
                'Oct',
                '14',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1926',
                'only',
                '-',
                'Apr',
                '17',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1926',
                '1929',
                '-',
                'Oct',
                'Sat>=1',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1927',
                'only',
                '-',
                'Apr',
                '9',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1928',
                'only',
                '-',
                'Apr',
                '14',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1929',
                'only',
                '-',
                'Apr',
                '20',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1931',
                'only',
                '-',
                'Apr',
                '18',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1931',
                '1932',
                '-',
                'Oct',
                'Sat>=1',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1932',
                'only',
                '-',
                'Apr',
                '2',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1934',
                'only',
                '-',
                'Apr',
                '7',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1934',
                '1938',
                '-',
                'Oct',
                'Sat>=1',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1935',
                'only',
                '-',
                'Mar',
                '30',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1936',
                'only',
                '-',
                'Apr',
                '18',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1937',
                'only',
                '-',
                'Apr',
                '3',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1938',
                'only',
                '-',
                'Mar',
                '26',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1939',
                'only',
                '-',
                'Apr',
                '15',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1939',
                'only',
                '-',
                'Nov',
                '18',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1940',
                'only',
                '-',
                'Feb',
                '24',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1940',
                '1941',
                '-',
                'Oct',
                '5',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1941',
                'only',
                '-',
                'Apr',
                '5',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1942',
                '1945',
                '-',
                'Mar',
                'Sat>=8',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1942',
                'only',
                '-',
                'Apr',
                '25',
                [
                    '22',
                    '0',
                    '0',
                    's'
                ],
                '120',
                'M',
                ''
            ],
            [
                '1942',
                'only',
                '-',
                'Aug',
                '15',
                [
                    '22',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1942',
                '1945',
                '-',
                'Oct',
                'Sat>=24',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1943',
                'only',
                '-',
                'Apr',
                '17',
                [
                    '22',
                    '0',
                    '0',
                    's'
                ],
                '120',
                'M'
            ],
            [
                '1943',
                '1945',
                '-',
                'Aug',
                'Sat>=25',
                [
                    '22',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1944',
                '1945',
                '-',
                'Apr',
                'Sat>=21',
                [
                    '22',
                    '0',
                    '0',
                    's'
                ],
                '120',
                'M'
            ],
            [
                '1946',
                'only',
                '-',
                'Apr',
                'Sat>=1',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1946',
                'only',
                '-',
                'Oct',
                'Sat>=1',
                [
                    '23',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1947',
                '1949',
                '-',
                'Apr',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1947',
                '1949',
                '-',
                'Oct',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1951',
                '1965',
                '-',
                'Apr',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1951',
                '1965',
                '-',
                'Oct',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1977',
                'only',
                '-',
                'Mar',
                '27',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1977',
                'only',
                '-',
                'Sep',
                '25',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1978',
                '1979',
                '-',
                'Apr',
                'Sun>=1',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1978',
                'only',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1979',
                '1982',
                '-',
                'Sep',
                'lastSun',
                [
                    '1',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1980',
                'only',
                '-',
                'Mar',
                'lastSun',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1981',
                '1982',
                '-',
                'Mar',
                'lastSun',
                [
                    '1',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1983',
                'only',
                '-',
                'Mar',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ]
        ],
        'Romania': [
            [
                '1932',
                'only',
                '-',
                'May',
                '21',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1932',
                '1939',
                '-',
                'Oct',
                'Sun>=1',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1933',
                '1939',
                '-',
                'Apr',
                'Sun>=2',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1979',
                'only',
                '-',
                'May',
                '27',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1979',
                'only',
                '-',
                'Sep',
                'lastSun',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1980',
                'only',
                '-',
                'Apr',
                '5',
                [
                    '23',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1980',
                'only',
                '-',
                'Sep',
                'lastSun',
                [
                    '1',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1991',
                '1993',
                '-',
                'Mar',
                'lastSun',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1991',
                '1993',
                '-',
                'Sep',
                'lastSun',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ]
        ],
        'Spain': [
            [
                '1918',
                'only',
                '-',
                'Apr',
                '15',
                [
                    '23',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1918',
                '1919',
                '-',
                'Oct',
                '6',
                [
                    '24',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1919',
                'only',
                '-',
                'Apr',
                '6',
                [
                    '23',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1924',
                'only',
                '-',
                'Apr',
                '16',
                [
                    '23',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1924',
                'only',
                '-',
                'Oct',
                '4',
                [
                    '24',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1926',
                'only',
                '-',
                'Apr',
                '17',
                [
                    '23',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1926',
                '1929',
                '-',
                'Oct',
                'Sat>=1',
                [
                    '24',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1927',
                'only',
                '-',
                'Apr',
                '9',
                [
                    '23',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1928',
                'only',
                '-',
                'Apr',
                '15',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1929',
                'only',
                '-',
                'Apr',
                '20',
                [
                    '23',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1937',
                'only',
                '-',
                'Jun',
                '16',
                [
                    '23',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1937',
                'only',
                '-',
                'Oct',
                '2',
                [
                    '24',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1938',
                'only',
                '-',
                'Apr',
                '2',
                [
                    '23',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1938',
                'only',
                '-',
                'Apr',
                '30',
                [
                    '23',
                    '0',
                    '0'
                ],
                '120',
                'M'
            ],
            [
                '1938',
                'only',
                '-',
                'Oct',
                '2',
                [
                    '24',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1939',
                'only',
                '-',
                'Oct',
                '7',
                [
                    '24',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1942',
                'only',
                '-',
                'May',
                '2',
                [
                    '23',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1942',
                'only',
                '-',
                'Sep',
                '1',
                [
                    '1',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1943',
                '1946',
                '-',
                'Apr',
                'Sat>=13',
                [
                    '23',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1943',
                '1944',
                '-',
                'Oct',
                'Sun>=1',
                [
                    '1',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1945',
                '1946',
                '-',
                'Sep',
                'lastSun',
                [
                    '1',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1949',
                'only',
                '-',
                'Apr',
                '30',
                [
                    '23',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1949',
                'only',
                '-',
                'Oct',
                '2',
                [
                    '1',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1974',
                '1975',
                '-',
                'Apr',
                'Sat>=12',
                [
                    '23',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1974',
                '1975',
                '-',
                'Oct',
                'Sun>=1',
                [
                    '1',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1976',
                'only',
                '-',
                'Mar',
                '27',
                [
                    '23',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1976',
                '1977',
                '-',
                'Sep',
                'lastSun',
                [
                    '1',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1977',
                'only',
                '-',
                'Apr',
                '2',
                [
                    '23',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1978',
                'only',
                '-',
                'Apr',
                '2',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1978',
                'only',
                '-',
                'Oct',
                '1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ]
        ],
        'SpainAfrica': [
            [
                '1967',
                'only',
                '-',
                'Jun',
                '3',
                [
                    '12',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1967',
                'only',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1974',
                'only',
                '-',
                'Jun',
                '24',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1974',
                'only',
                '-',
                'Sep',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1976',
                '1977',
                '-',
                'May',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1976',
                'only',
                '-',
                'Aug',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1977',
                'only',
                '-',
                'Sep',
                '28',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1978',
                'only',
                '-',
                'Jun',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1978',
                'only',
                '-',
                'Aug',
                '4',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ]
        ],
        'Swiss': [
            [
                '1941',
                '1942',
                '-',
                'May',
                'Mon>=1',
                [
                    '1',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1941',
                '1942',
                '-',
                'Oct',
                'Mon>=1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ]
        ],
        'Turkey': [
            [
                '1916',
                'only',
                '-',
                'May',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1916',
                'only',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1920',
                'only',
                '-',
                'Mar',
                '28',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1920',
                'only',
                '-',
                'Oct',
                '25',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1921',
                'only',
                '-',
                'Apr',
                '3',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1921',
                'only',
                '-',
                'Oct',
                '3',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1922',
                'only',
                '-',
                'Mar',
                '26',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1922',
                'only',
                '-',
                'Oct',
                '8',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1924',
                'only',
                '-',
                'May',
                '13',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1924',
                '1925',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1925',
                'only',
                '-',
                'May',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1940',
                'only',
                '-',
                'Jul',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1940',
                'only',
                '-',
                'Oct',
                '6',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1940',
                'only',
                '-',
                'Dec',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1941',
                'only',
                '-',
                'Sep',
                '21',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1942',
                'only',
                '-',
                'Apr',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1945',
                'only',
                '-',
                'Oct',
                '8',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1946',
                'only',
                '-',
                'Jun',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1946',
                'only',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1947',
                '1948',
                '-',
                'Apr',
                'Sun>=16',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1947',
                '1951',
                '-',
                'Oct',
                'Sun>=2',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1949',
                'only',
                '-',
                'Apr',
                '10',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1950',
                'only',
                '-',
                'Apr',
                '16',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1951',
                'only',
                '-',
                'Apr',
                '22',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1962',
                'only',
                '-',
                'Jul',
                '15',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1963',
                'only',
                '-',
                'Oct',
                '30',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1964',
                'only',
                '-',
                'May',
                '15',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1964',
                'only',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1973',
                'only',
                '-',
                'Jun',
                '3',
                [
                    '1',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1973',
                '1976',
                '-',
                'Oct',
                'Sun>=31',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1974',
                'only',
                '-',
                'Mar',
                '31',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1975',
                'only',
                '-',
                'Mar',
                '22',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1976',
                'only',
                '-',
                'Mar',
                '21',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1977',
                '1978',
                '-',
                'Apr',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1977',
                '1978',
                '-',
                'Oct',
                'Sun>=15',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1978',
                'only',
                '-',
                'Jun',
                '29',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1983',
                'only',
                '-',
                'Jul',
                '31',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'S'
            ],
            [
                '1983',
                'only',
                '-',
                'Oct',
                '2',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1985',
                'only',
                '-',
                'Apr',
                '20',
                [
                    '1',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1985',
                'only',
                '-',
                'Sep',
                '28',
                [
                    '1',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1986',
                '1993',
                '-',
                'Mar',
                'lastSun',
                [
                    '1',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1986',
                '1995',
                '-',
                'Sep',
                'lastSun',
                [
                    '1',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ],
            [
                '1994',
                'only',
                '-',
                'Mar',
                '20',
                [
                    '1',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1995',
                '2006',
                '-',
                'Mar',
                'lastSun',
                [
                    '1',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'S'
            ],
            [
                '1996',
                '2006',
                '-',
                'Oct',
                'lastSun',
                [
                    '1',
                    '0',
                    '0',
                    's'
                ],
                '0',
                '-'
            ]
        ],
        'US': [
            [
                '1918',
                '1919',
                '-',
                'Mar',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1918',
                '1919',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1942',
                'only',
                '-',
                'Feb',
                '9',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'W',
                ''
            ],
            [
                '1945',
                'only',
                '-',
                'Aug',
                '14',
                [
                    '23',
                    '0',
                    '0',
                    'u'
                ],
                '60',
                'P',
                ''
            ],
            [
                '1945',
                'only',
                '-',
                'Sep',
                '30',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1967',
                '2006',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1967',
                '1973',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1974',
                'only',
                '-',
                'Jan',
                '6',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1975',
                'only',
                '-',
                'Feb',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1976',
                '1986',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1987',
                '2006',
                '-',
                'Apr',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '2007',
                'max',
                '-',
                'Mar',
                'Sun>=8',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '2007',
                'max',
                '-',
                'Nov',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ]
        ],
        'NYC': [
            [
                '1920',
                'only',
                '-',
                'Mar',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1920',
                'only',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1921',
                '1966',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1921',
                '1954',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1955',
                '1966',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ]
        ],
        'Chicago': [
            [
                '1920',
                'only',
                '-',
                'Jun',
                '13',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1920',
                '1921',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1921',
                'only',
                '-',
                'Mar',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1922',
                '1966',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1922',
                '1954',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1955',
                '1966',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ]
        ],
        'Denver': [
            [
                '1920',
                '1921',
                '-',
                'Mar',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1920',
                'only',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1921',
                'only',
                '-',
                'May',
                '22',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1965',
                '1966',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1965',
                '1966',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ]
        ],
        'CA': [
            [
                '1948',
                'only',
                '-',
                'Mar',
                '14',
                [
                    '2',
                    '1',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1949',
                'only',
                '-',
                'Jan',
                '1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1950',
                '1966',
                '-',
                'Apr',
                'lastSun',
                [
                    '1',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1950',
                '1961',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1962',
                '1966',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ]
        ],
        'Indianapolis': [
            [
                '1941',
                'only',
                '-',
                'Jun',
                '22',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1941',
                '1954',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1946',
                '1954',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ]
        ],
        'Marengo': [
            [
                '1951',
                'only',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1951',
                'only',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1954',
                '1960',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1954',
                '1960',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ]
        ],
        'Vincennes': [
            [
                '1946',
                'only',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1946',
                'only',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1953',
                '1954',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1953',
                '1959',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1955',
                'only',
                '-',
                'May',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1956',
                '1963',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1960',
                'only',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1961',
                'only',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1962',
                '1963',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ]
        ],
        'Perry': [
            [
                '1955',
                'only',
                '-',
                'May',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1955',
                '1960',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1956',
                '1963',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1961',
                '1963',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ]
        ],
        'Pike': [
            [
                '1955',
                'only',
                '-',
                'May',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1955',
                '1960',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1956',
                '1964',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1961',
                '1964',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ]
        ],
        'Starke': [
            [
                '1947',
                '1961',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1947',
                '1954',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1955',
                '1956',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1957',
                '1958',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1959',
                '1961',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ]
        ],
        'Pulaski': [
            [
                '1946',
                '1960',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1946',
                '1954',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1955',
                '1956',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1957',
                '1960',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ]
        ],
        'Louisville': [
            [
                '1921',
                'only',
                '-',
                'May',
                '1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1921',
                'only',
                '-',
                'Sep',
                '1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1941',
                'only',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1941',
                'only',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1946',
                'only',
                '-',
                'Apr',
                'lastSun',
                [
                    '0',
                    '1',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1946',
                'only',
                '-',
                'Jun',
                '2',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1950',
                '1961',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1950',
                '1955',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1956',
                '1961',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ]
        ],
        'Detroit': [
            [
                '1948',
                'only',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1948',
                'only',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ]
        ],
        'Menominee': [
            [
                '1946',
                'only',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1946',
                'only',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1966',
                'only',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1966',
                'only',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ]
        ],
        'Canada': [
            [
                '1918',
                'only',
                '-',
                'Apr',
                '14',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1918',
                'only',
                '-',
                'Oct',
                '27',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1942',
                'only',
                '-',
                'Feb',
                '9',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'W',
                ''
            ],
            [
                '1945',
                'only',
                '-',
                'Aug',
                '14',
                [
                    '23',
                    '0',
                    '0',
                    'u'
                ],
                '60',
                'P',
                ''
            ],
            [
                '1945',
                'only',
                '-',
                'Sep',
                '30',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1974',
                '1986',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1974',
                '2006',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1987',
                '2006',
                '-',
                'Apr',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '2007',
                'max',
                '-',
                'Mar',
                'Sun>=8',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '2007',
                'max',
                '-',
                'Nov',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ]
        ],
        'StJohns': [
            [
                '1917',
                'only',
                '-',
                'Apr',
                '8',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1917',
                'only',
                '-',
                'Sep',
                '17',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1919',
                'only',
                '-',
                'May',
                '5',
                [
                    '23',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1919',
                'only',
                '-',
                'Aug',
                '12',
                [
                    '23',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1920',
                '1935',
                '-',
                'May',
                'Sun>=1',
                [
                    '23',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1920',
                '1935',
                '-',
                'Oct',
                'lastSun',
                [
                    '23',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1936',
                '1941',
                '-',
                'May',
                'Mon>=9',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1936',
                '1941',
                '-',
                'Oct',
                'Mon>=2',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1946',
                '1950',
                '-',
                'May',
                'Sun>=8',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1946',
                '1950',
                '-',
                'Oct',
                'Sun>=2',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1951',
                '1986',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1951',
                '1959',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1960',
                '1986',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1987',
                'only',
                '-',
                'Apr',
                'Sun>=1',
                [
                    '0',
                    '1',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1987',
                '2006',
                '-',
                'Oct',
                'lastSun',
                [
                    '0',
                    '1',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1988',
                'only',
                '-',
                'Apr',
                'Sun>=1',
                [
                    '0',
                    '1',
                    '0'
                ],
                '120',
                'DD'
            ],
            [
                '1989',
                '2006',
                '-',
                'Apr',
                'Sun>=1',
                [
                    '0',
                    '1',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '2007',
                '2011',
                '-',
                'Mar',
                'Sun>=8',
                [
                    '0',
                    '1',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '2007',
                '2010',
                '-',
                'Nov',
                'Sun>=1',
                [
                    '0',
                    '1',
                    '0'
                ],
                '0',
                'S'
            ]
        ],
        'Halifax': [
            [
                '1916',
                'only',
                '-',
                'Apr',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1916',
                'only',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1920',
                'only',
                '-',
                'May',
                '9',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1920',
                'only',
                '-',
                'Aug',
                '29',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1921',
                'only',
                '-',
                'May',
                '6',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1921',
                '1922',
                '-',
                'Sep',
                '5',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1922',
                'only',
                '-',
                'Apr',
                '30',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1923',
                '1925',
                '-',
                'May',
                'Sun>=1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1923',
                'only',
                '-',
                'Sep',
                '4',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1924',
                'only',
                '-',
                'Sep',
                '15',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1925',
                'only',
                '-',
                'Sep',
                '28',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1926',
                'only',
                '-',
                'May',
                '16',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1926',
                'only',
                '-',
                'Sep',
                '13',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1927',
                'only',
                '-',
                'May',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1927',
                'only',
                '-',
                'Sep',
                '26',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1928',
                '1931',
                '-',
                'May',
                'Sun>=8',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1928',
                'only',
                '-',
                'Sep',
                '9',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1929',
                'only',
                '-',
                'Sep',
                '3',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1930',
                'only',
                '-',
                'Sep',
                '15',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1931',
                '1932',
                '-',
                'Sep',
                'Mon>=24',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1932',
                'only',
                '-',
                'May',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1933',
                'only',
                '-',
                'Apr',
                '30',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1933',
                'only',
                '-',
                'Oct',
                '2',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1934',
                'only',
                '-',
                'May',
                '20',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1934',
                'only',
                '-',
                'Sep',
                '16',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1935',
                'only',
                '-',
                'Jun',
                '2',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1935',
                'only',
                '-',
                'Sep',
                '30',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1936',
                'only',
                '-',
                'Jun',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1936',
                'only',
                '-',
                'Sep',
                '14',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1937',
                '1938',
                '-',
                'May',
                'Sun>=1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1937',
                '1941',
                '-',
                'Sep',
                'Mon>=24',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1939',
                'only',
                '-',
                'May',
                '28',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1940',
                '1941',
                '-',
                'May',
                'Sun>=1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1946',
                '1949',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1946',
                '1949',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1951',
                '1954',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1951',
                '1954',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1956',
                '1959',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1956',
                '1959',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1962',
                '1973',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1962',
                '1973',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ]
        ],
        'Moncton': [
            [
                '1933',
                '1935',
                '-',
                'Jun',
                'Sun>=8',
                [
                    '1',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1933',
                '1935',
                '-',
                'Sep',
                'Sun>=8',
                [
                    '1',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1936',
                '1938',
                '-',
                'Jun',
                'Sun>=1',
                [
                    '1',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1936',
                '1938',
                '-',
                'Sep',
                'Sun>=1',
                [
                    '1',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1939',
                'only',
                '-',
                'May',
                '27',
                [
                    '1',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1939',
                '1941',
                '-',
                'Sep',
                'Sat>=21',
                [
                    '1',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1940',
                'only',
                '-',
                'May',
                '19',
                [
                    '1',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1941',
                'only',
                '-',
                'May',
                '4',
                [
                    '1',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1946',
                '1972',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1946',
                '1956',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1957',
                '1972',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1993',
                '2006',
                '-',
                'Apr',
                'Sun>=1',
                [
                    '0',
                    '1',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1993',
                '2006',
                '-',
                'Oct',
                'lastSun',
                [
                    '0',
                    '1',
                    '0'
                ],
                '0',
                'S'
            ]
        ],
        'Toronto': [
            [
                '1919',
                'only',
                '-',
                'Mar',
                '30',
                [
                    '23',
                    '30',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1919',
                'only',
                '-',
                'Oct',
                '26',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1920',
                'only',
                '-',
                'May',
                '2',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1920',
                'only',
                '-',
                'Sep',
                '26',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1921',
                'only',
                '-',
                'May',
                '15',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1921',
                'only',
                '-',
                'Sep',
                '15',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1922',
                '1923',
                '-',
                'May',
                'Sun>=8',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1922',
                '1926',
                '-',
                'Sep',
                'Sun>=15',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1924',
                '1927',
                '-',
                'May',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1927',
                '1937',
                '-',
                'Sep',
                'Sun>=25',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1928',
                '1937',
                '-',
                'Apr',
                'Sun>=25',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1938',
                '1940',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1938',
                '1939',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1945',
                '1946',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1946',
                'only',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1947',
                '1949',
                '-',
                'Apr',
                'lastSun',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1947',
                '1948',
                '-',
                'Sep',
                'lastSun',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1949',
                'only',
                '-',
                'Nov',
                'lastSun',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1950',
                '1973',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1950',
                'only',
                '-',
                'Nov',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1951',
                '1956',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1957',
                '1973',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ]
        ],
        'Winn': [
            [
                '1916',
                'only',
                '-',
                'Apr',
                '23',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1916',
                'only',
                '-',
                'Sep',
                '17',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1918',
                'only',
                '-',
                'Apr',
                '14',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1918',
                'only',
                '-',
                'Oct',
                '27',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1937',
                'only',
                '-',
                'May',
                '16',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1937',
                'only',
                '-',
                'Sep',
                '26',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1942',
                'only',
                '-',
                'Feb',
                '9',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'W',
                ''
            ],
            [
                '1945',
                'only',
                '-',
                'Aug',
                '14',
                [
                    '23',
                    '0',
                    '0',
                    'u'
                ],
                '60',
                'P',
                ''
            ],
            [
                '1945',
                'only',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1946',
                'only',
                '-',
                'May',
                '12',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1946',
                'only',
                '-',
                'Oct',
                '13',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1947',
                '1949',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1947',
                '1949',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1950',
                'only',
                '-',
                'May',
                '1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1950',
                'only',
                '-',
                'Sep',
                '30',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1951',
                '1960',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1951',
                '1958',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1959',
                'only',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1960',
                'only',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1963',
                'only',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1963',
                'only',
                '-',
                'Sep',
                '22',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1966',
                '1986',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '1966',
                '2005',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '1987',
                '2005',
                '-',
                'Apr',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ]
        ],
        'Regina': [
            [
                '1918',
                'only',
                '-',
                'Apr',
                '14',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1918',
                'only',
                '-',
                'Oct',
                '27',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1930',
                '1934',
                '-',
                'May',
                'Sun>=1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1930',
                '1934',
                '-',
                'Oct',
                'Sun>=1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1937',
                '1941',
                '-',
                'Apr',
                'Sun>=8',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1937',
                'only',
                '-',
                'Oct',
                'Sun>=8',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1938',
                'only',
                '-',
                'Oct',
                'Sun>=1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1939',
                '1941',
                '-',
                'Oct',
                'Sun>=8',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1942',
                'only',
                '-',
                'Feb',
                '9',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'W',
                ''
            ],
            [
                '1945',
                'only',
                '-',
                'Aug',
                '14',
                [
                    '23',
                    '0',
                    '0',
                    'u'
                ],
                '60',
                'P',
                ''
            ],
            [
                '1945',
                'only',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1946',
                'only',
                '-',
                'Apr',
                'Sun>=8',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1946',
                'only',
                '-',
                'Oct',
                'Sun>=8',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1947',
                '1957',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1947',
                '1957',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1959',
                'only',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1959',
                'only',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ]
        ],
        'Swift': [
            [
                '1957',
                'only',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1957',
                'only',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1959',
                '1961',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1959',
                'only',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1960',
                '1961',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ]
        ],
        'Edm': [
            [
                '1918',
                '1919',
                '-',
                'Apr',
                'Sun>=8',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1918',
                'only',
                '-',
                'Oct',
                '27',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1919',
                'only',
                '-',
                'May',
                '27',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1920',
                '1923',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1920',
                'only',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1921',
                '1923',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1942',
                'only',
                '-',
                'Feb',
                '9',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'W',
                ''
            ],
            [
                '1945',
                'only',
                '-',
                'Aug',
                '14',
                [
                    '23',
                    '0',
                    '0',
                    'u'
                ],
                '60',
                'P',
                ''
            ],
            [
                '1945',
                'only',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1947',
                'only',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1947',
                'only',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1972',
                '1986',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1972',
                '2006',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ]
        ],
        'Vanc': [
            [
                '1918',
                'only',
                '-',
                'Apr',
                '14',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1918',
                'only',
                '-',
                'Oct',
                '27',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1942',
                'only',
                '-',
                'Feb',
                '9',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'W',
                ''
            ],
            [
                '1945',
                'only',
                '-',
                'Aug',
                '14',
                [
                    '23',
                    '0',
                    '0',
                    'u'
                ],
                '60',
                'P',
                ''
            ],
            [
                '1945',
                'only',
                '-',
                'Sep',
                '30',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1946',
                '1986',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1946',
                'only',
                '-',
                'Sep',
                '29',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1947',
                '1961',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1962',
                '2006',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ]
        ],
        'NT_YK': [
            [
                '1918',
                'only',
                '-',
                'Apr',
                '14',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1918',
                'only',
                '-',
                'Oct',
                '27',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1919',
                'only',
                '-',
                'May',
                '25',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1919',
                'only',
                '-',
                'Nov',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1942',
                'only',
                '-',
                'Feb',
                '9',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'W',
                ''
            ],
            [
                '1945',
                'only',
                '-',
                'Aug',
                '14',
                [
                    '23',
                    '0',
                    '0',
                    'u'
                ],
                '60',
                'P',
                ''
            ],
            [
                '1945',
                'only',
                '-',
                'Sep',
                '30',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1965',
                'only',
                '-',
                'Apr',
                'lastSun',
                [
                    '0',
                    '0',
                    '0'
                ],
                '120',
                'DD'
            ],
            [
                '1965',
                'only',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1980',
                '1986',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1980',
                '2006',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1987',
                '2006',
                '-',
                'Apr',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ]
        ],
        'Mexico': [
            [
                '1939',
                'only',
                '-',
                'Feb',
                '5',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1939',
                'only',
                '-',
                'Jun',
                '25',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1940',
                'only',
                '-',
                'Dec',
                '9',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1941',
                'only',
                '-',
                'Apr',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1943',
                'only',
                '-',
                'Dec',
                '16',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'W',
                ''
            ],
            [
                '1944',
                'only',
                '-',
                'May',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1950',
                'only',
                '-',
                'Feb',
                '12',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1950',
                'only',
                '-',
                'Jul',
                '30',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1996',
                '2000',
                '-',
                'Apr',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1996',
                '2000',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '2001',
                'only',
                '-',
                'May',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '2001',
                'only',
                '-',
                'Sep',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '2002',
                'max',
                '-',
                'Apr',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '2002',
                'max',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ]
        ],
        'Bahamas': [
            [
                '1964',
                '1975',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1964',
                '1975',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ]
        ],
        'Barb': [
            [
                '1977',
                'only',
                '-',
                'Jun',
                '12',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1977',
                '1978',
                '-',
                'Oct',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1978',
                '1980',
                '-',
                'Apr',
                'Sun>=15',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1979',
                'only',
                '-',
                'Sep',
                '30',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1980',
                'only',
                '-',
                'Sep',
                '25',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ]
        ],
        'Belize': [
            [
                '1918',
                '1942',
                '-',
                'Oct',
                'Sun>=2',
                [
                    '0',
                    '0',
                    '0'
                ],
                '30',
                '-0530'
            ],
            [
                '1919',
                '1943',
                '-',
                'Feb',
                'Sun>=9',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'CST'
            ],
            [
                '1973',
                'only',
                '-',
                'Dec',
                '5',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'CDT'
            ],
            [
                '1974',
                'only',
                '-',
                'Feb',
                '9',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'CST'
            ],
            [
                '1982',
                'only',
                '-',
                'Dec',
                '18',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'CDT'
            ],
            [
                '1983',
                'only',
                '-',
                'Feb',
                '12',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'CST'
            ]
        ],
        'CR': [
            [
                '1979',
                '1980',
                '-',
                'Feb',
                'lastSun',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1979',
                '1980',
                '-',
                'Jun',
                'Sun>=1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1991',
                '1992',
                '-',
                'Jan',
                'Sat>=15',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1991',
                'only',
                '-',
                'Jul',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1992',
                'only',
                '-',
                'Mar',
                '15',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ]
        ],
        'Cuba': [
            [
                '1928',
                'only',
                '-',
                'Jun',
                '10',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1928',
                'only',
                '-',
                'Oct',
                '10',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1940',
                '1942',
                '-',
                'Jun',
                'Sun>=1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1940',
                '1942',
                '-',
                'Sep',
                'Sun>=1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1945',
                '1946',
                '-',
                'Jun',
                'Sun>=1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1945',
                '1946',
                '-',
                'Sep',
                'Sun>=1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1965',
                'only',
                '-',
                'Jun',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1965',
                'only',
                '-',
                'Sep',
                '30',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1966',
                'only',
                '-',
                'May',
                '29',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1966',
                'only',
                '-',
                'Oct',
                '2',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1967',
                'only',
                '-',
                'Apr',
                '8',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1967',
                '1968',
                '-',
                'Sep',
                'Sun>=8',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1968',
                'only',
                '-',
                'Apr',
                '14',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1969',
                '1977',
                '-',
                'Apr',
                'lastSun',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1969',
                '1971',
                '-',
                'Oct',
                'lastSun',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1972',
                '1974',
                '-',
                'Oct',
                '8',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1975',
                '1977',
                '-',
                'Oct',
                'lastSun',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1978',
                'only',
                '-',
                'May',
                '7',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1978',
                '1990',
                '-',
                'Oct',
                'Sun>=8',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1979',
                '1980',
                '-',
                'Mar',
                'Sun>=15',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1981',
                '1985',
                '-',
                'May',
                'Sun>=5',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1986',
                '1989',
                '-',
                'Mar',
                'Sun>=14',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1990',
                '1997',
                '-',
                'Apr',
                'Sun>=1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1991',
                '1995',
                '-',
                'Oct',
                'Sun>=8',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '1996',
                'only',
                '-',
                'Oct',
                '6',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '1997',
                'only',
                '-',
                'Oct',
                '12',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '1998',
                '1999',
                '-',
                'Mar',
                'lastSun',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '1998',
                '2003',
                '-',
                'Oct',
                'lastSun',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '2000',
                '2003',
                '-',
                'Apr',
                'Sun>=1',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '2004',
                'only',
                '-',
                'Mar',
                'lastSun',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '2006',
                '2010',
                '-',
                'Oct',
                'lastSun',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '2007',
                'only',
                '-',
                'Mar',
                'Sun>=8',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '2008',
                'only',
                '-',
                'Mar',
                'Sun>=15',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '2009',
                '2010',
                '-',
                'Mar',
                'Sun>=8',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '2011',
                'only',
                '-',
                'Mar',
                'Sun>=15',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '2011',
                'only',
                '-',
                'Nov',
                '13',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '2012',
                'only',
                '-',
                'Apr',
                '1',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '2012',
                'max',
                '-',
                'Nov',
                'Sun>=1',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '2013',
                'max',
                '-',
                'Mar',
                'Sun>=8',
                [
                    '0',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ]
        ],
        'DR': [
            [
                '1966',
                'only',
                '-',
                'Oct',
                '30',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'EDT'
            ],
            [
                '1967',
                'only',
                '-',
                'Feb',
                '28',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'EST'
            ],
            [
                '1969',
                '1973',
                '-',
                'Oct',
                'lastSun',
                [
                    '0',
                    '0',
                    '0'
                ],
                '30',
                '-0430'
            ],
            [
                '1970',
                'only',
                '-',
                'Feb',
                '21',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'EST'
            ],
            [
                '1971',
                'only',
                '-',
                'Jan',
                '20',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'EST'
            ],
            [
                '1972',
                '1974',
                '-',
                'Jan',
                '21',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'EST'
            ]
        ],
        'Salv': [
            [
                '1987',
                '1988',
                '-',
                'May',
                'Sun>=1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1987',
                '1988',
                '-',
                'Sep',
                'lastSun',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ]
        ],
        'Guat': [
            [
                '1973',
                'only',
                '-',
                'Nov',
                '25',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1974',
                'only',
                '-',
                'Feb',
                '24',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1983',
                'only',
                '-',
                'May',
                '21',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1983',
                'only',
                '-',
                'Sep',
                '22',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1991',
                'only',
                '-',
                'Mar',
                '23',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1991',
                'only',
                '-',
                'Sep',
                '7',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '2006',
                'only',
                '-',
                'Apr',
                '30',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '2006',
                'only',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ]
        ],
        'Haiti': [
            [
                '1983',
                'only',
                '-',
                'May',
                '8',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1984',
                '1987',
                '-',
                'Apr',
                'lastSun',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1983',
                '1987',
                '-',
                'Oct',
                'lastSun',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1988',
                '1997',
                '-',
                'Apr',
                'Sun>=1',
                [
                    '1',
                    '0',
                    '0',
                    's'
                ],
                '60',
                'D'
            ],
            [
                '1988',
                '1997',
                '-',
                'Oct',
                'lastSun',
                [
                    '1',
                    '0',
                    '0',
                    's'
                ],
                '0',
                'S'
            ],
            [
                '2005',
                '2006',
                '-',
                'Apr',
                'Sun>=1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '2005',
                '2006',
                '-',
                'Oct',
                'lastSun',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '2012',
                '2015',
                '-',
                'Mar',
                'Sun>=8',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '2012',
                '2015',
                '-',
                'Nov',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '2017',
                'max',
                '-',
                'Mar',
                'Sun>=8',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '2017',
                'max',
                '-',
                'Nov',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ]
        ],
        'Hond': [
            [
                '1987',
                '1988',
                '-',
                'May',
                'Sun>=1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1987',
                '1988',
                '-',
                'Sep',
                'lastSun',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '2006',
                'only',
                '-',
                'May',
                'Sun>=1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '2006',
                'only',
                '-',
                'Aug',
                'Mon>=1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ]
        ],
        'Nic': [
            [
                '1979',
                '1980',
                '-',
                'Mar',
                'Sun>=16',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1979',
                '1980',
                '-',
                'Jun',
                'Mon>=23',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '2005',
                'only',
                '-',
                'Apr',
                '10',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '2005',
                'only',
                '-',
                'Oct',
                'Sun>=1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '2006',
                'only',
                '-',
                'Apr',
                '30',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '2006',
                'only',
                '-',
                'Oct',
                'Sun>=1',
                [
                    '1',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ]
        ],
        'Arg': [
            [
                '1930',
                'only',
                '-',
                'Dec',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1931',
                'only',
                '-',
                'Apr',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1931',
                'only',
                '-',
                'Oct',
                '15',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1932',
                '1940',
                '-',
                'Mar',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1932',
                '1939',
                '-',
                'Nov',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1940',
                'only',
                '-',
                'Jul',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1941',
                'only',
                '-',
                'Jun',
                '15',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1941',
                'only',
                '-',
                'Oct',
                '15',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1943',
                'only',
                '-',
                'Aug',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1943',
                'only',
                '-',
                'Oct',
                '15',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1946',
                'only',
                '-',
                'Mar',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1946',
                'only',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1963',
                'only',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1963',
                'only',
                '-',
                'Dec',
                '15',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1964',
                '1966',
                '-',
                'Mar',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1964',
                '1966',
                '-',
                'Oct',
                '15',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1967',
                'only',
                '-',
                'Apr',
                '2',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1967',
                '1968',
                '-',
                'Oct',
                'Sun>=1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1968',
                '1969',
                '-',
                'Apr',
                'Sun>=1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1974',
                'only',
                '-',
                'Jan',
                '23',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1974',
                'only',
                '-',
                'May',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1988',
                'only',
                '-',
                'Dec',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1989',
                '1993',
                '-',
                'Mar',
                'Sun>=1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1989',
                '1992',
                '-',
                'Oct',
                'Sun>=15',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1999',
                'only',
                '-',
                'Oct',
                'Sun>=1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2000',
                'only',
                '-',
                'Mar',
                '3',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2007',
                'only',
                '-',
                'Dec',
                '30',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2008',
                '2009',
                '-',
                'Mar',
                'Sun>=15',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2008',
                'only',
                '-',
                'Oct',
                'Sun>=15',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ]
        ],
        'SanLuis': [
            [
                '2008',
                '2009',
                '-',
                'Mar',
                'Sun>=8',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2007',
                '2008',
                '-',
                'Oct',
                'Sun>=8',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ]
        ],
        'Brazil': [
            [
                '1931',
                'only',
                '-',
                'Oct',
                '3',
                [
                    '11',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1932',
                '1933',
                '-',
                'Apr',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1932',
                'only',
                '-',
                'Oct',
                '3',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1949',
                '1952',
                '-',
                'Dec',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1950',
                'only',
                '-',
                'Apr',
                '16',
                [
                    '1',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1951',
                '1952',
                '-',
                'Apr',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1953',
                'only',
                '-',
                'Mar',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1963',
                'only',
                '-',
                'Dec',
                '9',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1964',
                'only',
                '-',
                'Mar',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1965',
                'only',
                '-',
                'Jan',
                '31',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1965',
                'only',
                '-',
                'Mar',
                '31',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1965',
                'only',
                '-',
                'Dec',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1966',
                '1968',
                '-',
                'Mar',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1966',
                '1967',
                '-',
                'Nov',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1985',
                'only',
                '-',
                'Nov',
                '2',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1986',
                'only',
                '-',
                'Mar',
                '15',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1986',
                'only',
                '-',
                'Oct',
                '25',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1987',
                'only',
                '-',
                'Feb',
                '14',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1987',
                'only',
                '-',
                'Oct',
                '25',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1988',
                'only',
                '-',
                'Feb',
                '7',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1988',
                'only',
                '-',
                'Oct',
                '16',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1989',
                'only',
                '-',
                'Jan',
                '29',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1989',
                'only',
                '-',
                'Oct',
                '15',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1990',
                'only',
                '-',
                'Feb',
                '11',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1990',
                'only',
                '-',
                'Oct',
                '21',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1991',
                'only',
                '-',
                'Feb',
                '17',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1991',
                'only',
                '-',
                'Oct',
                '20',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1992',
                'only',
                '-',
                'Feb',
                '9',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1992',
                'only',
                '-',
                'Oct',
                '25',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1993',
                'only',
                '-',
                'Jan',
                '31',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1993',
                '1995',
                '-',
                'Oct',
                'Sun>=11',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1994',
                '1995',
                '-',
                'Feb',
                'Sun>=15',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1996',
                'only',
                '-',
                'Feb',
                '11',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1996',
                'only',
                '-',
                'Oct',
                '6',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1997',
                'only',
                '-',
                'Feb',
                '16',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1997',
                'only',
                '-',
                'Oct',
                '6',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1998',
                'only',
                '-',
                'Mar',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1998',
                'only',
                '-',
                'Oct',
                '11',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1999',
                'only',
                '-',
                'Feb',
                '21',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1999',
                'only',
                '-',
                'Oct',
                '3',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2000',
                'only',
                '-',
                'Feb',
                '27',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2000',
                '2001',
                '-',
                'Oct',
                'Sun>=8',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2001',
                '2006',
                '-',
                'Feb',
                'Sun>=15',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2002',
                'only',
                '-',
                'Nov',
                '3',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2003',
                'only',
                '-',
                'Oct',
                '19',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2004',
                'only',
                '-',
                'Nov',
                '2',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2005',
                'only',
                '-',
                'Oct',
                '16',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2006',
                'only',
                '-',
                'Nov',
                '5',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2007',
                'only',
                '-',
                'Feb',
                '25',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2007',
                'only',
                '-',
                'Oct',
                'Sun>=8',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2008',
                '2017',
                '-',
                'Oct',
                'Sun>=15',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2008',
                '2011',
                '-',
                'Feb',
                'Sun>=15',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2012',
                'only',
                '-',
                'Feb',
                'Sun>=22',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2013',
                '2014',
                '-',
                'Feb',
                'Sun>=15',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2015',
                'only',
                '-',
                'Feb',
                'Sun>=22',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2016',
                '2019',
                '-',
                'Feb',
                'Sun>=15',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2018',
                'only',
                '-',
                'Nov',
                'Sun>=1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ]
        ],
        'Chile': [
            [
                '1927',
                '1931',
                '-',
                'Sep',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1928',
                '1932',
                '-',
                'Apr',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1968',
                'only',
                '-',
                'Nov',
                '3',
                [
                    '4',
                    '0',
                    '0',
                    'u'
                ],
                '60',
                '-'
            ],
            [
                '1969',
                'only',
                '-',
                'Mar',
                '30',
                [
                    '3',
                    '0',
                    '0',
                    'u'
                ],
                '0',
                '-'
            ],
            [
                '1969',
                'only',
                '-',
                'Nov',
                '23',
                [
                    '4',
                    '0',
                    '0',
                    'u'
                ],
                '60',
                '-'
            ],
            [
                '1970',
                'only',
                '-',
                'Mar',
                '29',
                [
                    '3',
                    '0',
                    '0',
                    'u'
                ],
                '0',
                '-'
            ],
            [
                '1971',
                'only',
                '-',
                'Mar',
                '14',
                [
                    '3',
                    '0',
                    '0',
                    'u'
                ],
                '0',
                '-'
            ],
            [
                '1970',
                '1972',
                '-',
                'Oct',
                'Sun>=9',
                [
                    '4',
                    '0',
                    '0',
                    'u'
                ],
                '60',
                '-'
            ],
            [
                '1972',
                '1986',
                '-',
                'Mar',
                'Sun>=9',
                [
                    '3',
                    '0',
                    '0',
                    'u'
                ],
                '0',
                '-'
            ],
            [
                '1973',
                'only',
                '-',
                'Sep',
                '30',
                [
                    '4',
                    '0',
                    '0',
                    'u'
                ],
                '60',
                '-'
            ],
            [
                '1974',
                '1987',
                '-',
                'Oct',
                'Sun>=9',
                [
                    '4',
                    '0',
                    '0',
                    'u'
                ],
                '60',
                '-'
            ],
            [
                '1987',
                'only',
                '-',
                'Apr',
                '12',
                [
                    '3',
                    '0',
                    '0',
                    'u'
                ],
                '0',
                '-'
            ],
            [
                '1988',
                '1990',
                '-',
                'Mar',
                'Sun>=9',
                [
                    '3',
                    '0',
                    '0',
                    'u'
                ],
                '0',
                '-'
            ],
            [
                '1988',
                '1989',
                '-',
                'Oct',
                'Sun>=9',
                [
                    '4',
                    '0',
                    '0',
                    'u'
                ],
                '60',
                '-'
            ],
            [
                '1990',
                'only',
                '-',
                'Sep',
                '16',
                [
                    '4',
                    '0',
                    '0',
                    'u'
                ],
                '60',
                '-'
            ],
            [
                '1991',
                '1996',
                '-',
                'Mar',
                'Sun>=9',
                [
                    '3',
                    '0',
                    '0',
                    'u'
                ],
                '0',
                '-'
            ],
            [
                '1991',
                '1997',
                '-',
                'Oct',
                'Sun>=9',
                [
                    '4',
                    '0',
                    '0',
                    'u'
                ],
                '60',
                '-'
            ],
            [
                '1997',
                'only',
                '-',
                'Mar',
                '30',
                [
                    '3',
                    '0',
                    '0',
                    'u'
                ],
                '0',
                '-'
            ],
            [
                '1998',
                'only',
                '-',
                'Mar',
                'Sun>=9',
                [
                    '3',
                    '0',
                    '0',
                    'u'
                ],
                '0',
                '-'
            ],
            [
                '1998',
                'only',
                '-',
                'Sep',
                '27',
                [
                    '4',
                    '0',
                    '0',
                    'u'
                ],
                '60',
                '-'
            ],
            [
                '1999',
                'only',
                '-',
                'Apr',
                '4',
                [
                    '3',
                    '0',
                    '0',
                    'u'
                ],
                '0',
                '-'
            ],
            [
                '1999',
                '2010',
                '-',
                'Oct',
                'Sun>=9',
                [
                    '4',
                    '0',
                    '0',
                    'u'
                ],
                '60',
                '-'
            ],
            [
                '2000',
                '2007',
                '-',
                'Mar',
                'Sun>=9',
                [
                    '3',
                    '0',
                    '0',
                    'u'
                ],
                '0',
                '-'
            ],
            [
                '2008',
                'only',
                '-',
                'Mar',
                '30',
                [
                    '3',
                    '0',
                    '0',
                    'u'
                ],
                '0',
                '-'
            ],
            [
                '2009',
                'only',
                '-',
                'Mar',
                'Sun>=9',
                [
                    '3',
                    '0',
                    '0',
                    'u'
                ],
                '0',
                '-'
            ],
            [
                '2010',
                'only',
                '-',
                'Apr',
                'Sun>=1',
                [
                    '3',
                    '0',
                    '0',
                    'u'
                ],
                '0',
                '-'
            ],
            [
                '2011',
                'only',
                '-',
                'May',
                'Sun>=2',
                [
                    '3',
                    '0',
                    '0',
                    'u'
                ],
                '0',
                '-'
            ],
            [
                '2011',
                'only',
                '-',
                'Aug',
                'Sun>=16',
                [
                    '4',
                    '0',
                    '0',
                    'u'
                ],
                '60',
                '-'
            ],
            [
                '2012',
                '2014',
                '-',
                'Apr',
                'Sun>=23',
                [
                    '3',
                    '0',
                    '0',
                    'u'
                ],
                '0',
                '-'
            ],
            [
                '2012',
                '2014',
                '-',
                'Sep',
                'Sun>=2',
                [
                    '4',
                    '0',
                    '0',
                    'u'
                ],
                '60',
                '-'
            ],
            [
                '2016',
                '2018',
                '-',
                'May',
                'Sun>=9',
                [
                    '3',
                    '0',
                    '0',
                    'u'
                ],
                '0',
                '-'
            ],
            [
                '2016',
                '2018',
                '-',
                'Aug',
                'Sun>=9',
                [
                    '4',
                    '0',
                    '0',
                    'u'
                ],
                '60',
                '-'
            ],
            [
                '2019',
                'max',
                '-',
                'Apr',
                'Sun>=2',
                [
                    '3',
                    '0',
                    '0',
                    'u'
                ],
                '0',
                '-'
            ],
            [
                '2019',
                'max',
                '-',
                'Sep',
                'Sun>=2',
                [
                    '4',
                    '0',
                    '0',
                    'u'
                ],
                '60',
                '-'
            ]
        ],
        'CO': [
            [
                '1992',
                'only',
                '-',
                'May',
                '3',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1993',
                'only',
                '-',
                'Apr',
                '4',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ]
        ],
        'Ecuador': [
            [
                '1992',
                'only',
                '-',
                'Nov',
                '28',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1993',
                'only',
                '-',
                'Feb',
                '5',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ]
        ],
        'Falk': [
            [
                '1937',
                '1938',
                '-',
                'Sep',
                'lastSun',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1938',
                '1942',
                '-',
                'Mar',
                'Sun>=19',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1939',
                'only',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1940',
                '1942',
                '-',
                'Sep',
                'lastSun',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1943',
                'only',
                '-',
                'Jan',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1983',
                'only',
                '-',
                'Sep',
                'lastSun',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1984',
                '1985',
                '-',
                'Apr',
                'lastSun',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1984',
                'only',
                '-',
                'Sep',
                '16',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1985',
                '2000',
                '-',
                'Sep',
                'Sun>=9',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1986',
                '2000',
                '-',
                'Apr',
                'Sun>=16',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2001',
                '2010',
                '-',
                'Apr',
                'Sun>=15',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2001',
                '2010',
                '-',
                'Sep',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ]
        ],
        'Para': [
            [
                '1975',
                '1988',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1975',
                '1978',
                '-',
                'Mar',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1979',
                '1991',
                '-',
                'Apr',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1989',
                'only',
                '-',
                'Oct',
                '22',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1990',
                'only',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1991',
                'only',
                '-',
                'Oct',
                '6',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1992',
                'only',
                '-',
                'Mar',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1992',
                'only',
                '-',
                'Oct',
                '5',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1993',
                'only',
                '-',
                'Mar',
                '31',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1993',
                '1995',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1994',
                '1995',
                '-',
                'Feb',
                'lastSun',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1996',
                'only',
                '-',
                'Mar',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1996',
                '2001',
                '-',
                'Oct',
                'Sun>=1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1997',
                'only',
                '-',
                'Feb',
                'lastSun',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1998',
                '2001',
                '-',
                'Mar',
                'Sun>=1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2002',
                '2004',
                '-',
                'Apr',
                'Sun>=1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2002',
                '2003',
                '-',
                'Sep',
                'Sun>=1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2004',
                '2009',
                '-',
                'Oct',
                'Sun>=15',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2005',
                '2009',
                '-',
                'Mar',
                'Sun>=8',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2010',
                'max',
                '-',
                'Oct',
                'Sun>=1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2010',
                '2012',
                '-',
                'Apr',
                'Sun>=8',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2013',
                'max',
                '-',
                'Mar',
                'Sun>=22',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ]
        ],
        'Peru': [
            [
                '1938',
                'only',
                '-',
                'Jan',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1938',
                'only',
                '-',
                'Apr',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1938',
                '1939',
                '-',
                'Sep',
                'lastSun',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1939',
                '1940',
                '-',
                'Mar',
                'Sun>=24',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1986',
                '1987',
                '-',
                'Jan',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1986',
                '1987',
                '-',
                'Apr',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1990',
                'only',
                '-',
                'Jan',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1990',
                'only',
                '-',
                'Apr',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1994',
                'only',
                '-',
                'Jan',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1994',
                'only',
                '-',
                'Apr',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ]
        ],
        'Uruguay': [
            [
                '1923',
                '1925',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '30',
                '-'
            ],
            [
                '1924',
                '1926',
                '-',
                'Apr',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1933',
                '1938',
                '-',
                'Oct',
                'lastSun',
                [
                    '0',
                    '0',
                    '0'
                ],
                '30',
                '-'
            ],
            [
                '1934',
                '1941',
                '-',
                'Mar',
                'lastSat',
                [
                    '24',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1939',
                'only',
                '-',
                'Oct',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '30',
                '-'
            ],
            [
                '1940',
                'only',
                '-',
                'Oct',
                '27',
                [
                    '0',
                    '0',
                    '0'
                ],
                '30',
                '-'
            ],
            [
                '1941',
                'only',
                '-',
                'Aug',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '30',
                '-'
            ],
            [
                '1942',
                'only',
                '-',
                'Dec',
                '14',
                [
                    '0',
                    '0',
                    '0'
                ],
                '30',
                '-'
            ],
            [
                '1943',
                'only',
                '-',
                'Mar',
                '14',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1959',
                'only',
                '-',
                'May',
                '24',
                [
                    '0',
                    '0',
                    '0'
                ],
                '30',
                '-'
            ],
            [
                '1959',
                'only',
                '-',
                'Nov',
                '15',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1960',
                'only',
                '-',
                'Jan',
                '17',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1960',
                'only',
                '-',
                'Mar',
                '6',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1965',
                'only',
                '-',
                'Apr',
                '4',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1965',
                'only',
                '-',
                'Sep',
                '26',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1968',
                'only',
                '-',
                'May',
                '27',
                [
                    '0',
                    '0',
                    '0'
                ],
                '30',
                '-'
            ],
            [
                '1968',
                'only',
                '-',
                'Dec',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1970',
                'only',
                '-',
                'Apr',
                '25',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1970',
                'only',
                '-',
                'Jun',
                '14',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1972',
                'only',
                '-',
                'Apr',
                '23',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1972',
                'only',
                '-',
                'Jul',
                '16',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1974',
                'only',
                '-',
                'Jan',
                '13',
                [
                    '0',
                    '0',
                    '0'
                ],
                '90',
                '-'
            ],
            [
                '1974',
                'only',
                '-',
                'Mar',
                '10',
                [
                    '0',
                    '0',
                    '0'
                ],
                '30',
                '-'
            ],
            [
                '1974',
                'only',
                '-',
                'Sep',
                '1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1974',
                'only',
                '-',
                'Dec',
                '22',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1975',
                'only',
                '-',
                'Mar',
                '30',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1976',
                'only',
                '-',
                'Dec',
                '19',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1977',
                'only',
                '-',
                'Mar',
                '6',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1977',
                'only',
                '-',
                'Dec',
                '4',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1978',
                '1979',
                '-',
                'Mar',
                'Sun>=1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1978',
                'only',
                '-',
                'Dec',
                '17',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1979',
                'only',
                '-',
                'Apr',
                '29',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1980',
                'only',
                '-',
                'Mar',
                '16',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1987',
                'only',
                '-',
                'Dec',
                '14',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1988',
                'only',
                '-',
                'Feb',
                '28',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1988',
                'only',
                '-',
                'Dec',
                '11',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1989',
                'only',
                '-',
                'Mar',
                '5',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1989',
                'only',
                '-',
                'Oct',
                '29',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1990',
                'only',
                '-',
                'Feb',
                '25',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1990',
                '1991',
                '-',
                'Oct',
                'Sun>=21',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1991',
                '1992',
                '-',
                'Mar',
                'Sun>=1',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '1992',
                'only',
                '-',
                'Oct',
                '18',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '1993',
                'only',
                '-',
                'Feb',
                '28',
                [
                    '0',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2004',
                'only',
                '-',
                'Sep',
                '19',
                [
                    '0',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2005',
                'only',
                '-',
                'Mar',
                '27',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2005',
                'only',
                '-',
                'Oct',
                '9',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ],
            [
                '2006',
                '2015',
                '-',
                'Mar',
                'Sun>=8',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                '-'
            ],
            [
                '2006',
                '2014',
                '-',
                'Oct',
                'Sun>=1',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                '-'
            ]
        ],
        'SystemV': [
            [
                'NaN',
                '1973',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                'NaN',
                '1973',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1974',
                'only',
                '-',
                'Jan',
                '6',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1974',
                'only',
                '-',
                'Nov',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1975',
                'only',
                '-',
                'Feb',
                '23',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1975',
                'only',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ],
            [
                '1976',
                'max',
                '-',
                'Apr',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '60',
                'D'
            ],
            [
                '1976',
                'max',
                '-',
                'Oct',
                'lastSun',
                [
                    '2',
                    '0',
                    '0'
                ],
                '0',
                'S'
            ]
        ]
    };
    kendo.timezone.zones_titles = [
        {
            'name': '(GMT) Casablanca',
            'other_zone': 'Morocco Standard Time'
        },
        {
            'name': '(GMT) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London',
            'other_zone': 'GMT Standard Time'
        },
        {
            'name': '(GMT) Monrovia, Reykjavik',
            'other_zone': 'Greenwich Standard Time'
        },
        {
            'name': '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
            'other_zone': 'W. Europe Standard Time'
        },
        {
            'name': '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
            'other_zone': 'Central Europe Standard Time'
        },
        {
            'name': '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris',
            'other_zone': 'Romance Standard Time'
        },
        {
            'name': '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
            'other_zone': 'Central European Standard Time'
        },
        {
            'name': '(GMT+01:00) West Central Africa',
            'other_zone': 'W. Central Africa Standard Time'
        },
        {
            'name': '(GMT+02:00) Amman',
            'other_zone': 'Jordan Standard Time'
        },
        {
            'name': '(GMT+02:00) Athens, Bucharest, Istanbul',
            'other_zone': 'GTB Standard Time'
        },
        {
            'name': '(GMT+02:00) Beirut',
            'other_zone': 'Middle East Standard Time'
        },
        {
            'name': '(GMT+02:00) Cairo',
            'other_zone': 'Egypt Standard Time'
        },
        {
            'name': '(GMT+02:00) Harare, Pretoria',
            'other_zone': 'South Africa Standard Time'
        },
        {
            'name': '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
            'other_zone': 'FLE Standard Time'
        },
        {
            'name': '(GMT+02:00) Jerusalem',
            'other_zone': 'Israel Standard Time'
        },
        {
            'name': '(GMT+02:00) Minsk',
            'other_zone': 'E. Europe Standard Time'
        },
        {
            'name': '(GMT+02:00) Windhoek',
            'other_zone': 'Namibia Standard Time'
        },
        {
            'name': '(GMT+03:00) Baghdad',
            'other_zone': 'Arabic Standard Time'
        },
        {
            'name': '(GMT+03:00) Kuwait, Riyadh',
            'other_zone': 'Arab Standard Time'
        },
        {
            'name': '(GMT+03:00) Moscow, St. Petersburg, Volgograd',
            'other_zone': 'Russian Standard Time'
        },
        {
            'name': '(GMT+03:00) Nairobi',
            'other_zone': 'E. Africa Standard Time'
        },
        {
            'name': '(GMT+03:00) Tbilisi',
            'other_zone': 'Georgian Standard Time'
        },
        {
            'name': '(GMT+03:30) Tehran',
            'other_zone': 'Iran Standard Time'
        },
        {
            'name': '(GMT+04:00) Abu Dhabi, Muscat',
            'other_zone': 'Arabian Standard Time'
        },
        {
            'name': '(GMT+04:00) Baku',
            'other_zone': 'Azerbaijan Standard Time'
        },
        {
            'name': '(GMT+04:00) Port Louis',
            'other_zone': 'Mauritius Standard Time'
        },
        {
            'name': '(GMT+04:00) Yerevan',
            'other_zone': 'Caucasus Standard Time'
        },
        {
            'name': '(GMT+04:30) Kabul',
            'other_zone': 'Afghanistan Standard Time'
        },
        {
            'name': '(GMT+05:00) Ekaterinburg',
            'other_zone': 'Ekaterinburg Standard Time'
        },
        {
            'name': '(GMT+05:00) Islamabad, Karachi',
            'other_zone': 'Pakistan Standard Time'
        },
        {
            'name': '(GMT+05:00) Tashkent',
            'other_zone': 'West Asia Standard Time'
        },
        {
            'name': '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi',
            'other_zone': 'India Standard Time'
        },
        {
            'name': '(GMT+05:30) Sri Jayawardenepura',
            'other_zone': 'Sri Lanka Standard Time'
        },
        {
            'name': '(GMT+05:45) Kathmandu',
            'other_zone': 'Nepal Standard Time'
        },
        {
            'name': '(GMT+06:00) Almaty, Novosibirsk',
            'other_zone': 'N. Central Asia Standard Time'
        },
        {
            'name': '(GMT+06:00) Astana, Dhaka',
            'other_zone': 'Central Asia Standard Time'
        },
        {
            'name': '(GMT+06:30) Yangon (Rangoon)',
            'other_zone': 'Myanmar Standard Time'
        },
        {
            'name': '(GMT+07:00) Bangkok, Hanoi, Jakarta',
            'other_zone': 'SE Asia Standard Time'
        },
        {
            'name': '(GMT+07:00) Krasnoyarsk',
            'other_zone': 'North Asia Standard Time'
        },
        {
            'name': '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
            'other_zone': 'China Standard Time'
        },
        {
            'name': '(GMT+08:00) Irkutsk, Ulaan Bataar',
            'other_zone': 'North Asia East Standard Time'
        },
        {
            'name': '(GMT+08:00) Kuala Lumpur, Singapore',
            'other_zone': 'Singapore Standard Time'
        },
        {
            'name': '(GMT+08:00) Perth',
            'other_zone': 'W. Australia Standard Time'
        },
        {
            'name': '(GMT+08:00) Taipei',
            'other_zone': 'Taipei Standard Time'
        },
        {
            'name': '(GMT+09:00) Osaka, Sapporo, Tokyo',
            'other_zone': 'Tokyo Standard Time'
        },
        {
            'name': '(GMT+09:00) Seoul',
            'other_zone': 'Korea Standard Time'
        },
        {
            'name': '(GMT+09:00) Yakutsk',
            'other_zone': 'Yakutsk Standard Time'
        },
        {
            'name': '(GMT+09:30) Adelaide',
            'other_zone': 'Cen. Australia Standard Time'
        },
        {
            'name': '(GMT+09:30) Darwin',
            'other_zone': 'AUS Central Standard Time'
        },
        {
            'name': '(GMT+10:00) Brisbane',
            'other_zone': 'E. Australia Standard Time'
        },
        {
            'name': '(GMT+10:00) Canberra, Melbourne, Sydney',
            'other_zone': 'AUS Eastern Standard Time'
        },
        {
            'name': '(GMT+10:00) Guam, Port Moresby',
            'other_zone': 'West Pacific Standard Time'
        },
        {
            'name': '(GMT+10:00) Hobart',
            'other_zone': 'Tasmania Standard Time'
        },
        {
            'name': '(GMT+10:00) Vladivostok',
            'other_zone': 'Vladivostok Standard Time'
        },
        {
            'name': '(GMT+11:00) Magadan, Solomon Is., New Caledonia',
            'other_zone': 'Central Pacific Standard Time'
        },
        {
            'name': '(GMT+12:00) Auckland, Wellington',
            'other_zone': 'New Zealand Standard Time'
        },
        {
            'name': '(GMT+12:00) Fiji, Kamchatka, Marshall Is.',
            'other_zone': 'Fiji Standard Time'
        },
        {
            'name': '(GMT+13:00) Nuku\'alofa',
            'other_zone': 'Tonga Standard Time'
        },
        {
            'name': '(GMT-01:00) Azores',
            'other_zone': 'Azores Standard Time'
        },
        {
            'name': '(GMT-01:00) Cape Verde Is.',
            'other_zone': 'Cape Verde Standard Time'
        },
        {
            'name': '(GMT-03:00) Brasilia',
            'other_zone': 'E. South America Standard Time'
        },
        {
            'name': '(GMT-03:00) Buenos Aires',
            'other_zone': 'Argentina Standard Time'
        },
        {
            'name': '(GMT-03:00) Georgetown',
            'other_zone': 'SA Eastern Standard Time'
        },
        {
            'name': '(GMT-03:00) Greenland',
            'other_zone': 'Greenland Standard Time'
        },
        {
            'name': '(GMT-03:00) Montevideo',
            'other_zone': 'Montevideo Standard Time'
        },
        {
            'name': '(GMT-03:30) Newfoundland',
            'other_zone': 'Newfoundland Standard Time'
        },
        {
            'name': '(GMT-04:00) Atlantic Time (Canada)',
            'other_zone': 'Atlantic Standard Time'
        },
        {
            'name': '(GMT-04:00) La Paz',
            'other_zone': 'SA Western Standard Time'
        },
        {
            'name': '(GMT-04:00) Manaus',
            'other_zone': 'Central Brazilian Standard Time'
        },
        {
            'name': '(GMT-04:00) Santiago',
            'other_zone': 'Pacific SA Standard Time'
        },
        {
            'name': '(GMT-04:30) Caracas',
            'other_zone': 'Venezuela Standard Time'
        },
        {
            'name': '(GMT-05:00) Bogota, Lima, Quito, Rio Branco',
            'other_zone': 'SA Pacific Standard Time'
        },
        {
            'name': '(GMT-05:00) Eastern Time (US & Canada)',
            'other_zone': 'Eastern Standard Time'
        },
        {
            'name': '(GMT-05:00) Indiana (East)',
            'other_zone': 'US Eastern Standard Time'
        },
        {
            'name': '(GMT-06:00) Central America',
            'other_zone': 'Central America Standard Time'
        },
        {
            'name': '(GMT-06:00) Central Time (US & Canada)',
            'other_zone': 'Central Standard Time'
        },
        {
            'name': '(GMT-06:00) Guadalajara, Mexico City, Monterrey',
            'other_zone': 'Central Standard Time (Mexico)'
        },
        {
            'name': '(GMT-06:00) Saskatchewan',
            'other_zone': 'Canada Central Standard Time'
        },
        {
            'name': '(GMT-07:00) Arizona',
            'other_zone': 'US Mountain Standard Time'
        },
        {
            'name': '(GMT-07:00) Chihuahua, La Paz, Mazatlan',
            'other_zone': 'Mountain Standard Time (Mexico)'
        },
        {
            'name': '(GMT-07:00) Mountain Time (US & Canada)',
            'other_zone': 'Mountain Standard Time'
        },
        {
            'name': '(GMT-08:00) Pacific Time (US & Canada)',
            'other_zone': 'Pacific Standard Time'
        },
        {
            'name': '(GMT-08:00) Tijuana, Baja California',
            'other_zone': 'Pacific Standard Time (Mexico)'
        },
        {
            'name': '(GMT-09:00) Alaska',
            'other_zone': 'Alaskan Standard Time'
        },
        {
            'name': '(GMT-10:00) Hawaii',
            'other_zone': 'Hawaiian Standard Time'
        },
        {
            'name': '(GMT-11:00) Midway Island, Samoa',
            'other_zone': 'Samoa Standard Time'
        },
        {
            'name': '(GMT-12:00) International Date Line West',
            'other_zone': 'Dateline Standard Time'
        }
    ];
    kendo.timezone.windows_zones = [
        {
            'other_zone': 'Dateline Standard Time',
            'zone': 'Etc/GMT+12',
            'territory': 'GMT+12'
        },
        {
            'other_zone': 'UTC-11',
            'zone': 'Etc/GMT+11',
            'territory': 'GMT+11'
        },
        {
            'other_zone': 'UTC-11',
            'zone': 'Pacific/Pago_Pago',
            'territory': 'Pago Pago'
        },
        {
            'other_zone': 'UTC-11',
            'zone': 'Pacific/Niue',
            'territory': 'Niue'
        },
        {
            'other_zone': 'UTC-11',
            'zone': 'Pacific/Midway',
            'territory': 'Midway'
        },
        {
            'other_zone': 'Hawaiian Standard Time',
            'zone': 'Pacific/Honolulu',
            'territory': 'Honolulu'
        },
        {
            'other_zone': 'Hawaiian Standard Time',
            'zone': 'Pacific/Rarotonga',
            'territory': 'Rarotonga'
        },
        {
            'other_zone': 'Hawaiian Standard Time',
            'zone': 'Pacific/Tahiti',
            'territory': 'Tahiti'
        },
        {
            'other_zone': 'Hawaiian Standard Time',
            'zone': 'Pacific/Johnston',
            'territory': 'Johnston'
        },
        {
            'other_zone': 'Hawaiian Standard Time',
            'zone': 'Etc/GMT+10',
            'territory': 'GMT+10'
        },
        {
            'other_zone': 'Alaskan Standard Time',
            'zone': 'America/Anchorage',
            'territory': 'Anchorage'
        },
        {
            'other_zone': 'Alaskan Standard Time',
            'zone': 'America/Juneau',
            'territory': 'Juneau'
        },
        {
            'other_zone': 'Alaskan Standard Time',
            'zone': 'America/Nome',
            'territory': 'Nome'
        },
        {
            'other_zone': 'Alaskan Standard Time',
            'zone': 'America/Sitka',
            'territory': 'Sitka'
        },
        {
            'other_zone': 'Alaskan Standard Time',
            'zone': 'America/Yakutat',
            'territory': 'Yakutat'
        },
        {
            'other_zone': 'Pacific Standard Time (Mexico)',
            'zone': 'America/Santa_Isabel',
            'territory': 'Santa Isabel'
        },
        {
            'other_zone': 'Pacific Standard Time',
            'zone': 'America/Los_Angeles',
            'territory': 'Los Angeles'
        },
        {
            'other_zone': 'Pacific Standard Time',
            'zone': 'America/Vancouver',
            'territory': 'Vancouver'
        },
        {
            'other_zone': 'Pacific Standard Time',
            'zone': 'America/Dawson',
            'territory': 'Dawson'
        },
        {
            'other_zone': 'Pacific Standard Time',
            'zone': 'America/Whitehorse',
            'territory': 'Whitehorse'
        },
        {
            'other_zone': 'Pacific Standard Time',
            'zone': 'America/Tijuana',
            'territory': 'Tijuana'
        },
        {
            'other_zone': 'US Mountain Standard Time',
            'zone': 'America/Phoenix',
            'territory': 'Phoenix'
        },
        {
            'other_zone': 'US Mountain Standard Time',
            'zone': 'America/Dawson_Creek',
            'territory': 'Dawson Creek'
        },
        {
            'other_zone': 'US Mountain Standard Time',
            'zone': 'America/Creston',
            'territory': 'Creston'
        },
        {
            'other_zone': 'US Mountain Standard Time',
            'zone': 'America/Hermosillo',
            'territory': 'Hermosillo'
        },
        {
            'other_zone': 'US Mountain Standard Time',
            'zone': 'Etc/GMT+7',
            'territory': 'GMT+7'
        },
        {
            'other_zone': 'Mountain Standard Time (Mexico)',
            'zone': 'America/Chihuahua',
            'territory': 'Chihuahua'
        },
        {
            'other_zone': 'Mountain Standard Time (Mexico)',
            'zone': 'America/Mazatlan',
            'territory': 'Mazatlan'
        },
        {
            'other_zone': 'Mountain Standard Time',
            'zone': 'America/Denver',
            'territory': 'Denver'
        },
        {
            'other_zone': 'Mountain Standard Time',
            'zone': 'America/Edmonton',
            'territory': 'Edmonton'
        },
        {
            'other_zone': 'Mountain Standard Time',
            'zone': 'America/Cambridge_Bay',
            'territory': 'Cambridge Bay'
        },
        {
            'other_zone': 'Mountain Standard Time',
            'zone': 'America/Inuvik',
            'territory': 'Inuvik'
        },
        {
            'other_zone': 'Mountain Standard Time',
            'zone': 'America/Yellowknife',
            'territory': 'Yellowknife'
        },
        {
            'other_zone': 'Mountain Standard Time',
            'zone': 'America/Ojinaga',
            'territory': 'Ojinaga'
        },
        {
            'other_zone': 'Mountain Standard Time',
            'zone': 'America/Boise',
            'territory': 'Boise'
        },
        {
            'other_zone': 'Mountain Standard Time',
            'zone': 'America/Shiprock',
            'territory': 'Shiprock'
        },
        {
            'other_zone': 'Central America Standard Time',
            'zone': 'America/Guatemala',
            'territory': 'Guatemala'
        },
        {
            'other_zone': 'Central America Standard Time',
            'zone': 'America/Belize',
            'territory': 'Belize'
        },
        {
            'other_zone': 'Central America Standard Time',
            'zone': 'America/Costa_Rica',
            'territory': 'Costa Rica'
        },
        {
            'other_zone': 'Central America Standard Time',
            'zone': 'Pacific/Galapagos',
            'territory': 'Galapagos'
        },
        {
            'other_zone': 'Central America Standard Time',
            'zone': 'America/Tegucigalpa',
            'territory': 'Tegucigalpa'
        },
        {
            'other_zone': 'Central America Standard Time',
            'zone': 'America/Managua',
            'territory': 'Managua'
        },
        {
            'other_zone': 'Central America Standard Time',
            'zone': 'America/El_Salvador',
            'territory': 'El Salvador'
        },
        {
            'other_zone': 'Central America Standard Time',
            'zone': 'Etc/GMT+6',
            'territory': 'GMT+6'
        },
        {
            'other_zone': 'Central Standard Time',
            'zone': 'America/Chicago',
            'territory': 'Chicago'
        },
        {
            'other_zone': 'Central Standard Time',
            'zone': 'America/Winnipeg',
            'territory': 'Winnipeg'
        },
        {
            'other_zone': 'Central Standard Time',
            'zone': 'America/Rainy_River',
            'territory': 'Rainy River'
        },
        {
            'other_zone': 'Central Standard Time',
            'zone': 'America/Rankin_Inlet',
            'territory': 'Rankin Inlet'
        },
        {
            'other_zone': 'Central Standard Time',
            'zone': 'America/Resolute',
            'territory': 'Resolute'
        },
        {
            'other_zone': 'Central Standard Time',
            'zone': 'America/Matamoros',
            'territory': 'Matamoros'
        },
        {
            'other_zone': 'Central Standard Time',
            'zone': 'America/Indiana/Knox',
            'territory': 'Indiana'
        },
        {
            'other_zone': 'Central Standard Time',
            'zone': 'America/Indiana/Tell_City',
            'territory': 'Indiana'
        },
        {
            'other_zone': 'Central Standard Time',
            'zone': 'America/Menominee',
            'territory': 'Menominee'
        },
        {
            'other_zone': 'Central Standard Time',
            'zone': 'America/North_Dakota/Beulah',
            'territory': 'North Dakota'
        },
        {
            'other_zone': 'Central Standard Time',
            'zone': 'America/North_Dakota/Center',
            'territory': 'North Dakota'
        },
        {
            'other_zone': 'Central Standard Time',
            'zone': 'America/North_Dakota/New_Salem',
            'territory': 'North Dakota'
        },
        {
            'other_zone': 'Central Standard Time (Mexico)',
            'zone': 'America/Mexico_City',
            'territory': 'Mexico City'
        },
        {
            'other_zone': 'Central Standard Time (Mexico)',
            'zone': 'America/Bahia_Banderas',
            'territory': 'Bahia Banderas'
        },
        {
            'other_zone': 'Central Standard Time (Mexico)',
            'zone': 'America/Cancun',
            'territory': 'Cancun'
        },
        {
            'other_zone': 'Central Standard Time (Mexico)',
            'zone': 'America/Merida',
            'territory': 'Merida'
        },
        {
            'other_zone': 'Central Standard Time (Mexico)',
            'zone': 'America/Monterrey',
            'territory': 'Monterrey'
        },
        {
            'other_zone': 'Canada Central Standard Time',
            'zone': 'America/Regina',
            'territory': 'Regina'
        },
        {
            'other_zone': 'Canada Central Standard Time',
            'zone': 'America/Swift_Current',
            'territory': 'Swift Current'
        },
        {
            'other_zone': 'SA Pacific Standard Time',
            'zone': 'America/Bogota',
            'territory': 'Bogota'
        },
        {
            'other_zone': 'SA Pacific Standard Time',
            'zone': 'America/Coral_Harbour',
            'territory': 'Coral Harbour'
        },
        {
            'other_zone': 'SA Pacific Standard Time',
            'zone': 'America/Guayaquil',
            'territory': 'Guayaquil'
        },
        {
            'other_zone': 'SA Pacific Standard Time',
            'zone': 'America/Port-au-Prince',
            'territory': 'Port-au-Prince'
        },
        {
            'other_zone': 'SA Pacific Standard Time',
            'zone': 'America/Jamaica',
            'territory': 'Jamaica'
        },
        {
            'other_zone': 'SA Pacific Standard Time',
            'zone': 'America/Cayman',
            'territory': 'Cayman'
        },
        {
            'other_zone': 'SA Pacific Standard Time',
            'zone': 'America/Panama',
            'territory': 'Panama'
        },
        {
            'other_zone': 'SA Pacific Standard Time',
            'zone': 'America/Lima',
            'territory': 'Lima'
        },
        {
            'other_zone': 'SA Pacific Standard Time',
            'zone': 'Etc/GMT+5',
            'territory': 'GMT+5'
        },
        {
            'other_zone': 'Eastern Standard Time',
            'zone': 'America/New_York',
            'territory': 'New York'
        },
        {
            'other_zone': 'Eastern Standard Time',
            'zone': 'America/Nassau',
            'territory': 'Nassau'
        },
        {
            'other_zone': 'Eastern Standard Time',
            'zone': 'America/Toronto',
            'territory': 'Toronto'
        },
        {
            'other_zone': 'Eastern Standard Time',
            'zone': 'America/Iqaluit',
            'territory': 'Iqaluit'
        },
        {
            'other_zone': 'Eastern Standard Time',
            'zone': 'America/Montreal',
            'territory': 'Montreal'
        },
        {
            'other_zone': 'Eastern Standard Time',
            'zone': 'America/Nipigon',
            'territory': 'Nipigon'
        },
        {
            'other_zone': 'Eastern Standard Time',
            'zone': 'America/Pangnirtung',
            'territory': 'Pangnirtung'
        },
        {
            'other_zone': 'Eastern Standard Time',
            'zone': 'America/Thunder_Bay',
            'territory': 'Thunder Bay'
        },
        {
            'other_zone': 'Eastern Standard Time',
            'zone': 'America/Grand_Turk',
            'territory': 'Grand Turk'
        },
        {
            'other_zone': 'Eastern Standard Time',
            'zone': 'America/Detroit',
            'territory': 'Detroit'
        },
        {
            'other_zone': 'Eastern Standard Time',
            'zone': 'America/Indiana/Petersburg',
            'territory': 'Indiana'
        },
        {
            'other_zone': 'Eastern Standard Time',
            'zone': 'America/Indiana/Vincennes',
            'territory': 'Indiana'
        },
        {
            'other_zone': 'Eastern Standard Time',
            'zone': 'America/Indiana/Winamac',
            'territory': 'Indiana'
        },
        {
            'other_zone': 'Eastern Standard Time',
            'zone': 'America/Kentucky/Monticello',
            'territory': 'Kentucky'
        },
        {
            'other_zone': 'Eastern Standard Time',
            'zone': 'America/Louisville',
            'territory': 'Louisville'
        },
        {
            'other_zone': 'US Eastern Standard Time',
            'zone': 'America/Indianapolis',
            'territory': 'Indianapolis'
        },
        {
            'other_zone': 'US Eastern Standard Time',
            'zone': 'America/Indiana/Marengo',
            'territory': 'Indiana'
        },
        {
            'other_zone': 'US Eastern Standard Time',
            'zone': 'America/Indiana/Vevay',
            'territory': 'Indiana'
        },
        {
            'other_zone': 'Venezuela Standard Time',
            'zone': 'America/Caracas',
            'territory': 'Caracas'
        },
        {
            'other_zone': 'Paraguay Standard Time',
            'zone': 'America/Asuncion',
            'territory': 'Asuncion'
        },
        {
            'other_zone': 'Atlantic Standard Time',
            'zone': 'America/Halifax',
            'territory': 'Halifax'
        },
        {
            'other_zone': 'Atlantic Standard Time',
            'zone': 'Atlantic/Bermuda',
            'territory': 'Bermuda'
        },
        {
            'other_zone': 'Atlantic Standard Time',
            'zone': 'America/Glace_Bay',
            'territory': 'Glace Bay'
        },
        {
            'other_zone': 'Atlantic Standard Time',
            'zone': 'America/Goose_Bay',
            'territory': 'Goose Bay'
        },
        {
            'other_zone': 'Atlantic Standard Time',
            'zone': 'America/Moncton',
            'territory': 'Moncton'
        },
        {
            'other_zone': 'Atlantic Standard Time',
            'zone': 'America/Thule',
            'territory': 'Thule'
        },
        {
            'other_zone': 'Central Brazilian Standard Time',
            'zone': 'America/Cuiaba',
            'territory': 'Cuiaba'
        },
        {
            'other_zone': 'Central Brazilian Standard Time',
            'zone': 'America/Campo_Grande',
            'territory': 'Campo Grande'
        },
        {
            'other_zone': 'SA Western Standard Time',
            'zone': 'America/La_Paz',
            'territory': 'La Paz'
        },
        {
            'other_zone': 'SA Western Standard Time',
            'zone': 'America/Antigua',
            'territory': 'Antigua'
        },
        {
            'other_zone': 'SA Western Standard Time',
            'zone': 'America/Anguilla',
            'territory': 'Anguilla'
        },
        {
            'other_zone': 'SA Western Standard Time',
            'zone': 'America/Aruba',
            'territory': 'Aruba'
        },
        {
            'other_zone': 'SA Western Standard Time',
            'zone': 'America/Barbados',
            'territory': 'Barbados'
        },
        {
            'other_zone': 'SA Western Standard Time',
            'zone': 'America/St_Barthelemy',
            'territory': 'St Barthelemy'
        },
        {
            'other_zone': 'SA Western Standard Time',
            'zone': 'America/Kralendijk',
            'territory': 'Kralendijk'
        },
        {
            'other_zone': 'SA Western Standard Time',
            'zone': 'America/Manaus',
            'territory': 'Manaus'
        },
        {
            'other_zone': 'SA Western Standard Time',
            'zone': 'America/Boa_Vista',
            'territory': 'Boa Vista'
        },
        {
            'other_zone': 'SA Western Standard Time',
            'zone': 'America/Eirunepe',
            'territory': 'Eirunepe'
        },
        {
            'other_zone': 'SA Western Standard Time',
            'zone': 'America/Porto_Velho',
            'territory': 'Porto Velho'
        },
        {
            'other_zone': 'SA Western Standard Time',
            'zone': 'America/Rio_Branco',
            'territory': 'Rio Branco'
        },
        {
            'other_zone': 'SA Western Standard Time',
            'zone': 'America/Blanc-Sablon',
            'territory': 'Blanc-Sablon'
        },
        {
            'other_zone': 'SA Western Standard Time',
            'zone': 'America/Curacao',
            'territory': 'Curacao'
        },
        {
            'other_zone': 'SA Western Standard Time',
            'zone': 'America/Dominica',
            'territory': 'Dominica'
        },
        {
            'other_zone': 'SA Western Standard Time',
            'zone': 'America/Santo_Domingo',
            'territory': 'Santo Domingo'
        },
        {
            'other_zone': 'SA Western Standard Time',
            'zone': 'America/Grenada',
            'territory': 'Grenada'
        },
        {
            'other_zone': 'SA Western Standard Time',
            'zone': 'America/Guadeloupe',
            'territory': 'Guadeloupe'
        },
        {
            'other_zone': 'SA Western Standard Time',
            'zone': 'America/Guyana',
            'territory': 'Guyana'
        },
        {
            'other_zone': 'SA Western Standard Time',
            'zone': 'America/St_Kitts',
            'territory': 'St Kitts'
        },
        {
            'other_zone': 'SA Western Standard Time',
            'zone': 'America/St_Lucia',
            'territory': 'St Lucia'
        },
        {
            'other_zone': 'SA Western Standard Time',
            'zone': 'America/Marigot',
            'territory': 'Marigot'
        },
        {
            'other_zone': 'SA Western Standard Time',
            'zone': 'America/Martinique',
            'territory': 'Martinique'
        },
        {
            'other_zone': 'SA Western Standard Time',
            'zone': 'America/Montserrat',
            'territory': 'Montserrat'
        },
        {
            'other_zone': 'SA Western Standard Time',
            'zone': 'America/Puerto_Rico',
            'territory': 'Puerto Rico'
        },
        {
            'other_zone': 'SA Western Standard Time',
            'zone': 'America/Lower_Princes',
            'territory': 'Lower Princes'
        },
        {
            'other_zone': 'SA Western Standard Time',
            'zone': 'America/Port_of_Spain',
            'territory': 'Port of Spain'
        },
        {
            'other_zone': 'SA Western Standard Time',
            'zone': 'America/St_Vincent',
            'territory': 'St Vincent'
        },
        {
            'other_zone': 'SA Western Standard Time',
            'zone': 'America/Tortola',
            'territory': 'Tortola'
        },
        {
            'other_zone': 'SA Western Standard Time',
            'zone': 'America/St_Thomas',
            'territory': 'St Thomas'
        },
        {
            'other_zone': 'SA Western Standard Time',
            'zone': 'Etc/GMT+4',
            'territory': 'GMT+4'
        },
        {
            'other_zone': 'Pacific SA Standard Time',
            'zone': 'America/Santiago',
            'territory': 'Santiago'
        },
        {
            'other_zone': 'Pacific SA Standard Time',
            'zone': 'Antarctica/Palmer',
            'territory': 'Palmer'
        },
        {
            'other_zone': 'Newfoundland Standard Time',
            'zone': 'America/St_Johns',
            'territory': 'St Johns'
        },
        {
            'other_zone': 'E. South America Standard Time',
            'zone': 'America/Sao_Paulo',
            'territory': 'Sao Paulo'
        },
        {
            'other_zone': 'E. South America Standard Time',
            'zone': 'America/Araguaina',
            'territory': 'Araguaina'
        },
        {
            'other_zone': 'Argentina Standard Time',
            'zone': 'America/Buenos_Aires',
            'territory': 'Buenos Aires'
        },
        {
            'other_zone': 'Argentina Standard Time',
            'zone': 'America/Argentina/La_Rioja',
            'territory': 'Argentina'
        },
        {
            'other_zone': 'Argentina Standard Time',
            'zone': 'America/Argentina/Rio_Gallegos',
            'territory': 'Argentina'
        },
        {
            'other_zone': 'Argentina Standard Time',
            'zone': 'America/Argentina/Salta',
            'territory': 'Argentina'
        },
        {
            'other_zone': 'Argentina Standard Time',
            'zone': 'America/Argentina/San_Juan',
            'territory': 'Argentina'
        },
        {
            'other_zone': 'Argentina Standard Time',
            'zone': 'America/Argentina/San_Luis',
            'territory': 'Argentina'
        },
        {
            'other_zone': 'Argentina Standard Time',
            'zone': 'America/Argentina/Tucuman',
            'territory': 'Argentina'
        },
        {
            'other_zone': 'Argentina Standard Time',
            'zone': 'America/Argentina/Ushuaia',
            'territory': 'Argentina'
        },
        {
            'other_zone': 'Argentina Standard Time',
            'zone': 'America/Catamarca',
            'territory': 'Catamarca'
        },
        {
            'other_zone': 'Argentina Standard Time',
            'zone': 'America/Cordoba',
            'territory': 'Cordoba'
        },
        {
            'other_zone': 'Argentina Standard Time',
            'zone': 'America/Jujuy',
            'territory': 'Jujuy'
        },
        {
            'other_zone': 'Argentina Standard Time',
            'zone': 'America/Mendoza',
            'territory': 'Mendoza'
        },
        {
            'other_zone': 'SA Eastern Standard Time',
            'zone': 'America/Cayenne',
            'territory': 'Cayenne'
        },
        {
            'other_zone': 'SA Eastern Standard Time',
            'zone': 'Antarctica/Rothera',
            'territory': 'Rothera'
        },
        {
            'other_zone': 'SA Eastern Standard Time',
            'zone': 'America/Fortaleza',
            'territory': 'Fortaleza'
        },
        {
            'other_zone': 'SA Eastern Standard Time',
            'zone': 'America/Belem',
            'territory': 'Belem'
        },
        {
            'other_zone': 'SA Eastern Standard Time',
            'zone': 'America/Maceio',
            'territory': 'Maceio'
        },
        {
            'other_zone': 'SA Eastern Standard Time',
            'zone': 'America/Recife',
            'territory': 'Recife'
        },
        {
            'other_zone': 'SA Eastern Standard Time',
            'zone': 'America/Santarem',
            'territory': 'Santarem'
        },
        {
            'other_zone': 'SA Eastern Standard Time',
            'zone': 'Atlantic/Stanley',
            'territory': 'Stanley'
        },
        {
            'other_zone': 'SA Eastern Standard Time',
            'zone': 'America/Paramaribo',
            'territory': 'Paramaribo'
        },
        {
            'other_zone': 'SA Eastern Standard Time',
            'zone': 'Etc/GMT+3',
            'territory': 'GMT+3'
        },
        {
            'other_zone': 'Greenland Standard Time',
            'zone': 'America/Godthab',
            'territory': 'Godthab'
        },
        {
            'other_zone': 'Montevideo Standard Time',
            'zone': 'America/Montevideo',
            'territory': 'Montevideo'
        },
        {
            'other_zone': 'Bahia Standard Time',
            'zone': 'America/Bahia',
            'territory': 'Bahia'
        },
        {
            'other_zone': 'UTC-02',
            'zone': 'Etc/GMT+2',
            'territory': 'GMT+2'
        },
        {
            'other_zone': 'UTC-02',
            'zone': 'America/Noronha',
            'territory': 'Noronha'
        },
        {
            'other_zone': 'UTC-02',
            'zone': 'Atlantic/South_Georgia',
            'territory': 'South Georgia'
        },
        {
            'other_zone': 'Azores Standard Time',
            'zone': 'Atlantic/Azores',
            'territory': 'Azores'
        },
        {
            'other_zone': 'Azores Standard Time',
            'zone': 'America/Scoresbysund',
            'territory': 'Scoresbysund'
        },
        {
            'other_zone': 'Cape Verde Standard Time',
            'zone': 'Atlantic/Cape_Verde',
            'territory': 'Cape Verde'
        },
        {
            'other_zone': 'Cape Verde Standard Time',
            'zone': 'Etc/GMT+1',
            'territory': 'GMT+1'
        },
        {
            'other_zone': 'Morocco Standard Time',
            'zone': 'Africa/Casablanca',
            'territory': 'Casablanca'
        },
        {
            'other_zone': 'UTC',
            'zone': 'Etc/GMT',
            'territory': 'GMT'
        },
        {
            'other_zone': 'UTC',
            'zone': 'America/Danmarkshavn',
            'territory': 'Danmarkshavn'
        },
        {
            'other_zone': 'GMT Standard Time',
            'zone': 'Europe/London',
            'territory': 'London'
        },
        {
            'other_zone': 'GMT Standard Time',
            'zone': 'Atlantic/Canary',
            'territory': 'Canary'
        },
        {
            'other_zone': 'GMT Standard Time',
            'zone': 'Atlantic/Faeroe',
            'territory': 'Faeroe'
        },
        {
            'other_zone': 'GMT Standard Time',
            'zone': 'Europe/Guernsey',
            'territory': 'Guernsey'
        },
        {
            'other_zone': 'GMT Standard Time',
            'zone': 'Europe/Dublin',
            'territory': 'Dublin'
        },
        {
            'other_zone': 'GMT Standard Time',
            'zone': 'Europe/Isle_of_Man',
            'territory': 'Isle of Man'
        },
        {
            'other_zone': 'GMT Standard Time',
            'zone': 'Europe/Jersey',
            'territory': 'Jersey'
        },
        {
            'other_zone': 'GMT Standard Time',
            'zone': 'Europe/Lisbon',
            'territory': 'Lisbon'
        },
        {
            'other_zone': 'GMT Standard Time',
            'zone': 'Atlantic/Madeira',
            'territory': 'Madeira'
        },
        {
            'other_zone': 'Greenwich Standard Time',
            'zone': 'Atlantic/Reykjavik',
            'territory': 'Reykjavik'
        },
        {
            'other_zone': 'Greenwich Standard Time',
            'zone': 'Africa/Ouagadougou',
            'territory': 'Ouagadougou'
        },
        {
            'other_zone': 'Greenwich Standard Time',
            'zone': 'Africa/Abidjan',
            'territory': 'Abidjan'
        },
        {
            'other_zone': 'Greenwich Standard Time',
            'zone': 'Africa/El_Aaiun',
            'territory': 'El Aaiun'
        },
        {
            'other_zone': 'Greenwich Standard Time',
            'zone': 'Africa/Accra',
            'territory': 'Accra'
        },
        {
            'other_zone': 'Greenwich Standard Time',
            'zone': 'Africa/Banjul',
            'territory': 'Banjul'
        },
        {
            'other_zone': 'Greenwich Standard Time',
            'zone': 'Africa/Conakry',
            'territory': 'Conakry'
        },
        {
            'other_zone': 'Greenwich Standard Time',
            'zone': 'Africa/Bissau',
            'territory': 'Bissau'
        },
        {
            'other_zone': 'Greenwich Standard Time',
            'zone': 'Africa/Monrovia',
            'territory': 'Monrovia'
        },
        {
            'other_zone': 'Greenwich Standard Time',
            'zone': 'Africa/Bamako',
            'territory': 'Bamako'
        },
        {
            'other_zone': 'Greenwich Standard Time',
            'zone': 'Africa/Nouakchott',
            'territory': 'Nouakchott'
        },
        {
            'other_zone': 'Greenwich Standard Time',
            'zone': 'Atlantic/St_Helena',
            'territory': 'St Helena'
        },
        {
            'other_zone': 'Greenwich Standard Time',
            'zone': 'Africa/Freetown',
            'territory': 'Freetown'
        },
        {
            'other_zone': 'Greenwich Standard Time',
            'zone': 'Africa/Dakar',
            'territory': 'Dakar'
        },
        {
            'other_zone': 'Greenwich Standard Time',
            'zone': 'Africa/Sao_Tome',
            'territory': 'Sao Tome'
        },
        {
            'other_zone': 'Greenwich Standard Time',
            'zone': 'Africa/Lome',
            'territory': 'Lome'
        },
        {
            'other_zone': 'W. Europe Standard Time',
            'zone': 'Europe/Berlin',
            'territory': 'Berlin'
        },
        {
            'other_zone': 'W. Europe Standard Time',
            'zone': 'Europe/Andorra',
            'territory': 'Andorra'
        },
        {
            'other_zone': 'W. Europe Standard Time',
            'zone': 'Europe/Vienna',
            'territory': 'Vienna'
        },
        {
            'other_zone': 'W. Europe Standard Time',
            'zone': 'Europe/Zurich',
            'territory': 'Zurich'
        },
        {
            'other_zone': 'W. Europe Standard Time',
            'zone': 'Europe/Busingen',
            'territory': 'Busingen'
        },
        {
            'other_zone': 'W. Europe Standard Time',
            'zone': 'Europe/Gibraltar',
            'territory': 'Gibraltar'
        },
        {
            'other_zone': 'W. Europe Standard Time',
            'zone': 'Europe/Rome',
            'territory': 'Rome'
        },
        {
            'other_zone': 'W. Europe Standard Time',
            'zone': 'Europe/Vaduz',
            'territory': 'Vaduz'
        },
        {
            'other_zone': 'W. Europe Standard Time',
            'zone': 'Europe/Luxembourg',
            'territory': 'Luxembourg'
        },
        {
            'other_zone': 'W. Europe Standard Time',
            'zone': 'Africa/Tripoli',
            'territory': 'Tripoli'
        },
        {
            'other_zone': 'W. Europe Standard Time',
            'zone': 'Europe/Monaco',
            'territory': 'Monaco'
        },
        {
            'other_zone': 'W. Europe Standard Time',
            'zone': 'Europe/Malta',
            'territory': 'Malta'
        },
        {
            'other_zone': 'W. Europe Standard Time',
            'zone': 'Europe/Amsterdam',
            'territory': 'Amsterdam'
        },
        {
            'other_zone': 'W. Europe Standard Time',
            'zone': 'Europe/Oslo',
            'territory': 'Oslo'
        },
        {
            'other_zone': 'W. Europe Standard Time',
            'zone': 'Europe/Stockholm',
            'territory': 'Stockholm'
        },
        {
            'other_zone': 'W. Europe Standard Time',
            'zone': 'Arctic/Longyearbyen',
            'territory': 'Longyearbyen'
        },
        {
            'other_zone': 'W. Europe Standard Time',
            'zone': 'Europe/San_Marino',
            'territory': 'San Marino'
        },
        {
            'other_zone': 'W. Europe Standard Time',
            'zone': 'Europe/Vatican',
            'territory': 'Vatican'
        },
        {
            'other_zone': 'Central Europe Standard Time',
            'zone': 'Europe/Budapest',
            'territory': 'Budapest'
        },
        {
            'other_zone': 'Central Europe Standard Time',
            'zone': 'Europe/Tirane',
            'territory': 'Tirane'
        },
        {
            'other_zone': 'Central Europe Standard Time',
            'zone': 'Europe/Prague',
            'territory': 'Prague'
        },
        {
            'other_zone': 'Central Europe Standard Time',
            'zone': 'Europe/Podgorica',
            'territory': 'Podgorica'
        },
        {
            'other_zone': 'Central Europe Standard Time',
            'zone': 'Europe/Belgrade',
            'territory': 'Belgrade'
        },
        {
            'other_zone': 'Central Europe Standard Time',
            'zone': 'Europe/Ljubljana',
            'territory': 'Ljubljana'
        },
        {
            'other_zone': 'Central Europe Standard Time',
            'zone': 'Europe/Bratislava',
            'territory': 'Bratislava'
        },
        {
            'other_zone': 'Romance Standard Time',
            'zone': 'Europe/Paris',
            'territory': 'Paris'
        },
        {
            'other_zone': 'Romance Standard Time',
            'zone': 'Europe/Brussels',
            'territory': 'Brussels'
        },
        {
            'other_zone': 'Romance Standard Time',
            'zone': 'Europe/Copenhagen',
            'territory': 'Copenhagen'
        },
        {
            'other_zone': 'Romance Standard Time',
            'zone': 'Europe/Madrid',
            'territory': 'Madrid'
        },
        {
            'other_zone': 'Romance Standard Time',
            'zone': 'Africa/Ceuta',
            'territory': 'Ceuta'
        },
        {
            'other_zone': 'Central European Standard Time',
            'zone': 'Europe/Warsaw',
            'territory': 'Warsaw'
        },
        {
            'other_zone': 'Central European Standard Time',
            'zone': 'Europe/Sarajevo',
            'territory': 'Sarajevo'
        },
        {
            'other_zone': 'Central European Standard Time',
            'zone': 'Europe/Zagreb',
            'territory': 'Zagreb'
        },
        {
            'other_zone': 'Central European Standard Time',
            'zone': 'Europe/Skopje',
            'territory': 'Skopje'
        },
        {
            'other_zone': 'W. Central Africa Standard Time',
            'zone': 'Africa/Lagos',
            'territory': 'Lagos'
        },
        {
            'other_zone': 'W. Central Africa Standard Time',
            'zone': 'Africa/Luanda',
            'territory': 'Luanda'
        },
        {
            'other_zone': 'W. Central Africa Standard Time',
            'zone': 'Africa/Porto-Novo',
            'territory': 'Porto-Novo'
        },
        {
            'other_zone': 'W. Central Africa Standard Time',
            'zone': 'Africa/Kinshasa',
            'territory': 'Kinshasa'
        },
        {
            'other_zone': 'W. Central Africa Standard Time',
            'zone': 'Africa/Bangui',
            'territory': 'Bangui'
        },
        {
            'other_zone': 'W. Central Africa Standard Time',
            'zone': 'Africa/Brazzaville',
            'territory': 'Brazzaville'
        },
        {
            'other_zone': 'W. Central Africa Standard Time',
            'zone': 'Africa/Douala',
            'territory': 'Douala'
        },
        {
            'other_zone': 'W. Central Africa Standard Time',
            'zone': 'Africa/Algiers',
            'territory': 'Algiers'
        },
        {
            'other_zone': 'W. Central Africa Standard Time',
            'zone': 'Africa/Libreville',
            'territory': 'Libreville'
        },
        {
            'other_zone': 'W. Central Africa Standard Time',
            'zone': 'Africa/Malabo',
            'territory': 'Malabo'
        },
        {
            'other_zone': 'W. Central Africa Standard Time',
            'zone': 'Africa/Niamey',
            'territory': 'Niamey'
        },
        {
            'other_zone': 'W. Central Africa Standard Time',
            'zone': 'Africa/Ndjamena',
            'territory': 'Ndjamena'
        },
        {
            'other_zone': 'W. Central Africa Standard Time',
            'zone': 'Africa/Tunis',
            'territory': 'Tunis'
        },
        {
            'other_zone': 'W. Central Africa Standard Time',
            'zone': 'Etc/GMT-1',
            'territory': 'GMT-1'
        },
        {
            'other_zone': 'Namibia Standard Time',
            'zone': 'Africa/Windhoek',
            'territory': 'Windhoek'
        },
        {
            'other_zone': 'GTB Standard Time',
            'zone': 'Europe/Bucharest',
            'territory': 'Bucharest'
        },
        {
            'other_zone': 'GTB Standard Time',
            'zone': 'Europe/Athens',
            'territory': 'Athens'
        },
        {
            'other_zone': 'GTB Standard Time',
            'zone': 'Europe/Chisinau',
            'territory': 'Chisinau'
        },
        {
            'other_zone': 'Middle East Standard Time',
            'zone': 'Asia/Beirut',
            'territory': 'Beirut'
        },
        {
            'other_zone': 'Egypt Standard Time',
            'zone': 'Africa/Cairo',
            'territory': 'Cairo'
        },
        {
            'other_zone': 'Egypt Standard Time',
            'zone': 'Asia/Gaza',
            'territory': 'Gaza'
        },
        {
            'other_zone': 'Egypt Standard Time',
            'zone': 'Asia/Hebron',
            'territory': 'Hebron'
        },
        {
            'other_zone': 'Syria Standard Time',
            'zone': 'Asia/Damascus',
            'territory': 'Damascus'
        },
        {
            'other_zone': 'E. Europe Standard Time',
            'zone': 'Asia/Nicosia',
            'territory': 'Nicosia'
        },
        {
            'other_zone': 'South Africa Standard Time',
            'zone': 'Africa/Johannesburg',
            'territory': 'Johannesburg'
        },
        {
            'other_zone': 'South Africa Standard Time',
            'zone': 'Africa/Bujumbura',
            'territory': 'Bujumbura'
        },
        {
            'other_zone': 'South Africa Standard Time',
            'zone': 'Africa/Gaborone',
            'territory': 'Gaborone'
        },
        {
            'other_zone': 'South Africa Standard Time',
            'zone': 'Africa/Lubumbashi',
            'territory': 'Lubumbashi'
        },
        {
            'other_zone': 'South Africa Standard Time',
            'zone': 'Africa/Maseru',
            'territory': 'Maseru'
        },
        {
            'other_zone': 'South Africa Standard Time',
            'zone': 'Africa/Blantyre',
            'territory': 'Blantyre'
        },
        {
            'other_zone': 'South Africa Standard Time',
            'zone': 'Africa/Maputo',
            'territory': 'Maputo'
        },
        {
            'other_zone': 'South Africa Standard Time',
            'zone': 'Africa/Kigali',
            'territory': 'Kigali'
        },
        {
            'other_zone': 'South Africa Standard Time',
            'zone': 'Africa/Mbabane',
            'territory': 'Mbabane'
        },
        {
            'other_zone': 'South Africa Standard Time',
            'zone': 'Africa/Lusaka',
            'territory': 'Lusaka'
        },
        {
            'other_zone': 'South Africa Standard Time',
            'zone': 'Africa/Harare',
            'territory': 'Harare'
        },
        {
            'other_zone': 'South Africa Standard Time',
            'zone': 'Etc/GMT-2',
            'territory': 'GMT-2'
        },
        {
            'other_zone': 'FLE Standard Time',
            'zone': 'Europe/Kiev',
            'territory': 'Kiev'
        },
        {
            'other_zone': 'FLE Standard Time',
            'zone': 'Europe/Mariehamn',
            'territory': 'Mariehamn'
        },
        {
            'other_zone': 'FLE Standard Time',
            'zone': 'Europe/Sofia',
            'territory': 'Sofia'
        },
        {
            'other_zone': 'FLE Standard Time',
            'zone': 'Europe/Tallinn',
            'territory': 'Tallinn'
        },
        {
            'other_zone': 'FLE Standard Time',
            'zone': 'Europe/Helsinki',
            'territory': 'Helsinki'
        },
        {
            'other_zone': 'FLE Standard Time',
            'zone': 'Europe/Vilnius',
            'territory': 'Vilnius'
        },
        {
            'other_zone': 'FLE Standard Time',
            'zone': 'Europe/Riga',
            'territory': 'Riga'
        },
        {
            'other_zone': 'FLE Standard Time',
            'zone': 'Europe/Simferopol',
            'territory': 'Simferopol'
        },
        {
            'other_zone': 'FLE Standard Time',
            'zone': 'Europe/Uzhgorod',
            'territory': 'Uzhgorod'
        },
        {
            'other_zone': 'FLE Standard Time',
            'zone': 'Europe/Zaporozhye',
            'territory': 'Zaporozhye'
        },
        {
            'other_zone': 'Turkey Standard Time',
            'zone': 'Europe/Istanbul',
            'territory': 'Istanbul'
        },
        {
            'other_zone': 'Israel Standard Time',
            'zone': 'Asia/Jerusalem',
            'territory': 'Jerusalem'
        },
        {
            'other_zone': 'Jordan Standard Time',
            'zone': 'Asia/Amman',
            'territory': 'Amman'
        },
        {
            'other_zone': 'Arabic Standard Time',
            'zone': 'Asia/Baghdad',
            'territory': 'Baghdad'
        },
        {
            'other_zone': 'Kaliningrad Standard Time',
            'zone': 'Europe/Kaliningrad',
            'territory': 'Kaliningrad'
        },
        {
            'other_zone': 'Kaliningrad Standard Time',
            'zone': 'Europe/Minsk',
            'territory': 'Minsk'
        },
        {
            'other_zone': 'Arab Standard Time',
            'zone': 'Asia/Riyadh',
            'territory': 'Riyadh'
        },
        {
            'other_zone': 'Arab Standard Time',
            'zone': 'Asia/Bahrain',
            'territory': 'Bahrain'
        },
        {
            'other_zone': 'Arab Standard Time',
            'zone': 'Asia/Kuwait',
            'territory': 'Kuwait'
        },
        {
            'other_zone': 'Arab Standard Time',
            'zone': 'Asia/Qatar',
            'territory': 'Qatar'
        },
        {
            'other_zone': 'Arab Standard Time',
            'zone': 'Asia/Aden',
            'territory': 'Aden'
        },
        {
            'other_zone': 'E. Africa Standard Time',
            'zone': 'Africa/Nairobi',
            'territory': 'Nairobi'
        },
        {
            'other_zone': 'E. Africa Standard Time',
            'zone': 'Antarctica/Syowa',
            'territory': 'Syowa'
        },
        {
            'other_zone': 'E. Africa Standard Time',
            'zone': 'Africa/Djibouti',
            'territory': 'Djibouti'
        },
        {
            'other_zone': 'E. Africa Standard Time',
            'zone': 'Africa/Asmera',
            'territory': 'Asmera'
        },
        {
            'other_zone': 'E. Africa Standard Time',
            'zone': 'Africa/Addis_Ababa',
            'territory': 'Addis Ababa'
        },
        {
            'other_zone': 'E. Africa Standard Time',
            'zone': 'Indian/Comoro',
            'territory': 'Comoro'
        },
        {
            'other_zone': 'E. Africa Standard Time',
            'zone': 'Indian/Antananarivo',
            'territory': 'Antananarivo'
        },
        {
            'other_zone': 'E. Africa Standard Time',
            'zone': 'Africa/Khartoum',
            'territory': 'Khartoum'
        },
        {
            'other_zone': 'E. Africa Standard Time',
            'zone': 'Africa/Mogadishu',
            'territory': 'Mogadishu'
        },
        {
            'other_zone': 'E. Africa Standard Time',
            'zone': 'Africa/Juba',
            'territory': 'Juba'
        },
        {
            'other_zone': 'E. Africa Standard Time',
            'zone': 'Africa/Dar_es_Salaam',
            'territory': 'Dar es Salaam'
        },
        {
            'other_zone': 'E. Africa Standard Time',
            'zone': 'Africa/Kampala',
            'territory': 'Kampala'
        },
        {
            'other_zone': 'E. Africa Standard Time',
            'zone': 'Indian/Mayotte',
            'territory': 'Mayotte'
        },
        {
            'other_zone': 'E. Africa Standard Time',
            'zone': 'Etc/GMT-3',
            'territory': 'GMT-3'
        },
        {
            'other_zone': 'Iran Standard Time',
            'zone': 'Asia/Tehran',
            'territory': 'Tehran'
        },
        {
            'other_zone': 'Arabian Standard Time',
            'zone': 'Asia/Dubai',
            'territory': 'Dubai'
        },
        {
            'other_zone': 'Arabian Standard Time',
            'zone': 'Asia/Muscat',
            'territory': 'Muscat'
        },
        {
            'other_zone': 'Arabian Standard Time',
            'zone': 'Etc/GMT-4',
            'territory': 'GMT-4'
        },
        {
            'other_zone': 'Azerbaijan Standard Time',
            'zone': 'Asia/Baku',
            'territory': 'Baku'
        },
        {
            'other_zone': 'Russian Standard Time',
            'zone': 'Europe/Moscow',
            'territory': 'Moscow'
        },
        {
            'other_zone': 'Russian Standard Time',
            'zone': 'Europe/Samara',
            'territory': 'Samara'
        },
        {
            'other_zone': 'Russian Standard Time',
            'zone': 'Europe/Volgograd',
            'territory': 'Volgograd'
        },
        {
            'other_zone': 'Mauritius Standard Time',
            'zone': 'Indian/Mauritius',
            'territory': 'Mauritius'
        },
        {
            'other_zone': 'Mauritius Standard Time',
            'zone': 'Indian/Reunion',
            'territory': 'Reunion'
        },
        {
            'other_zone': 'Mauritius Standard Time',
            'zone': 'Indian/Mahe',
            'territory': 'Mahe'
        },
        {
            'other_zone': 'Georgian Standard Time',
            'zone': 'Asia/Tbilisi',
            'territory': 'Tbilisi'
        },
        {
            'other_zone': 'Caucasus Standard Time',
            'zone': 'Asia/Yerevan',
            'territory': 'Yerevan'
        },
        {
            'other_zone': 'Afghanistan Standard Time',
            'zone': 'Asia/Kabul',
            'territory': 'Kabul'
        },
        {
            'other_zone': 'Pakistan Standard Time',
            'zone': 'Asia/Karachi',
            'territory': 'Karachi'
        },
        {
            'other_zone': 'West Asia Standard Time',
            'zone': 'Asia/Tashkent',
            'territory': 'Tashkent'
        },
        {
            'other_zone': 'West Asia Standard Time',
            'zone': 'Antarctica/Mawson',
            'territory': 'Mawson'
        },
        {
            'other_zone': 'West Asia Standard Time',
            'zone': 'Asia/Oral',
            'territory': 'Oral'
        },
        {
            'other_zone': 'West Asia Standard Time',
            'zone': 'Asia/Aqtau',
            'territory': 'Aqtau'
        },
        {
            'other_zone': 'West Asia Standard Time',
            'zone': 'Asia/Aqtobe',
            'territory': 'Aqtobe'
        },
        {
            'other_zone': 'West Asia Standard Time',
            'zone': 'Indian/Maldives',
            'territory': 'Maldives'
        },
        {
            'other_zone': 'West Asia Standard Time',
            'zone': 'Indian/Kerguelen',
            'territory': 'Kerguelen'
        },
        {
            'other_zone': 'West Asia Standard Time',
            'zone': 'Asia/Dushanbe',
            'territory': 'Dushanbe'
        },
        {
            'other_zone': 'West Asia Standard Time',
            'zone': 'Asia/Ashgabat',
            'territory': 'Ashgabat'
        },
        {
            'other_zone': 'West Asia Standard Time',
            'zone': 'Asia/Samarkand',
            'territory': 'Samarkand'
        },
        {
            'other_zone': 'West Asia Standard Time',
            'zone': 'Etc/GMT-5',
            'territory': 'GMT-5'
        },
        {
            'other_zone': 'India Standard Time',
            'zone': 'Asia/Calcutta',
            'territory': 'Calcutta'
        },
        {
            'other_zone': 'Sri Lanka Standard Time',
            'zone': 'Asia/Colombo',
            'territory': 'Colombo'
        },
        {
            'other_zone': 'Nepal Standard Time',
            'zone': 'Asia/Katmandu',
            'territory': 'Katmandu'
        },
        {
            'other_zone': 'Central Asia Standard Time',
            'zone': 'Asia/Almaty',
            'territory': 'Almaty'
        },
        {
            'other_zone': 'Central Asia Standard Time',
            'zone': 'Antarctica/Vostok',
            'territory': 'Vostok'
        },
        {
            'other_zone': 'Central Asia Standard Time',
            'zone': 'Indian/Chagos',
            'territory': 'Chagos'
        },
        {
            'other_zone': 'Central Asia Standard Time',
            'zone': 'Asia/Bishkek',
            'territory': 'Bishkek'
        },
        {
            'other_zone': 'Central Asia Standard Time',
            'zone': 'Asia/Qyzylorda',
            'territory': 'Qyzylorda'
        },
        {
            'other_zone': 'Central Asia Standard Time',
            'zone': 'Etc/GMT-6',
            'territory': 'GMT-6'
        },
        {
            'other_zone': 'Bangladesh Standard Time',
            'zone': 'Asia/Dhaka',
            'territory': 'Dhaka'
        },
        {
            'other_zone': 'Bangladesh Standard Time',
            'zone': 'Asia/Thimphu',
            'territory': 'Thimphu'
        },
        {
            'other_zone': 'Ekaterinburg Standard Time',
            'zone': 'Asia/Yekaterinburg',
            'territory': 'Yekaterinburg'
        },
        {
            'other_zone': 'Myanmar Standard Time',
            'zone': 'Asia/Rangoon',
            'territory': 'Rangoon'
        },
        {
            'other_zone': 'Myanmar Standard Time',
            'zone': 'Indian/Cocos',
            'territory': 'Cocos'
        },
        {
            'other_zone': 'SE Asia Standard Time',
            'zone': 'Asia/Bangkok',
            'territory': 'Bangkok'
        },
        {
            'other_zone': 'SE Asia Standard Time',
            'zone': 'Antarctica/Davis',
            'territory': 'Davis'
        },
        {
            'other_zone': 'SE Asia Standard Time',
            'zone': 'Indian/Christmas',
            'territory': 'Christmas'
        },
        {
            'other_zone': 'SE Asia Standard Time',
            'zone': 'Asia/Jakarta',
            'territory': 'Jakarta'
        },
        {
            'other_zone': 'SE Asia Standard Time',
            'zone': 'Asia/Pontianak',
            'territory': 'Pontianak'
        },
        {
            'other_zone': 'SE Asia Standard Time',
            'zone': 'Asia/Phnom_Penh',
            'territory': 'Phnom Penh'
        },
        {
            'other_zone': 'SE Asia Standard Time',
            'zone': 'Asia/Vientiane',
            'territory': 'Vientiane'
        },
        {
            'other_zone': 'SE Asia Standard Time',
            'zone': 'Asia/Hovd',
            'territory': 'Hovd'
        },
        {
            'other_zone': 'SE Asia Standard Time',
            'zone': 'Asia/Saigon',
            'territory': 'Saigon'
        },
        {
            'other_zone': 'SE Asia Standard Time',
            'zone': 'Etc/GMT-7',
            'territory': 'GMT-7'
        },
        {
            'other_zone': 'N. Central Asia Standard Time',
            'zone': 'Asia/Novosibirsk',
            'territory': 'Novosibirsk'
        },
        {
            'other_zone': 'N. Central Asia Standard Time',
            'zone': 'Asia/Novokuznetsk',
            'territory': 'Novokuznetsk'
        },
        {
            'other_zone': 'N. Central Asia Standard Time',
            'zone': 'Asia/Omsk',
            'territory': 'Omsk'
        },
        {
            'other_zone': 'China Standard Time',
            'zone': 'Asia/Shanghai',
            'territory': 'Shanghai'
        },
        {
            'other_zone': 'China Standard Time',
            'zone': 'Asia/Chongqing',
            'territory': 'Chongqing'
        },
        {
            'other_zone': 'China Standard Time',
            'zone': 'Asia/Harbin',
            'territory': 'Harbin'
        },
        {
            'other_zone': 'China Standard Time',
            'zone': 'Asia/Kashgar',
            'territory': 'Kashgar'
        },
        {
            'other_zone': 'China Standard Time',
            'zone': 'Asia/Urumqi',
            'territory': 'Urumqi'
        },
        {
            'other_zone': 'China Standard Time',
            'zone': 'Asia/Hong_Kong',
            'territory': 'Hong Kong'
        },
        {
            'other_zone': 'China Standard Time',
            'zone': 'Asia/Macau',
            'territory': 'Macau'
        },
        {
            'other_zone': 'North Asia Standard Time',
            'zone': 'Asia/Krasnoyarsk',
            'territory': 'Krasnoyarsk'
        },
        {
            'other_zone': 'Singapore Standard Time',
            'zone': 'Asia/Singapore',
            'territory': 'Singapore'
        },
        {
            'other_zone': 'Singapore Standard Time',
            'zone': 'Asia/Brunei',
            'territory': 'Brunei'
        },
        {
            'other_zone': 'Singapore Standard Time',
            'zone': 'Asia/Makassar',
            'territory': 'Makassar'
        },
        {
            'other_zone': 'Singapore Standard Time',
            'zone': 'Asia/Kuala_Lumpur',
            'territory': 'Kuala Lumpur'
        },
        {
            'other_zone': 'Singapore Standard Time',
            'zone': 'Asia/Kuching',
            'territory': 'Kuching'
        },
        {
            'other_zone': 'Singapore Standard Time',
            'zone': 'Asia/Manila',
            'territory': 'Manila'
        },
        {
            'other_zone': 'Singapore Standard Time',
            'zone': 'Etc/GMT-8',
            'territory': 'GMT-8'
        },
        {
            'other_zone': 'W. Australia Standard Time',
            'zone': 'Australia/Perth',
            'territory': 'Perth'
        },
        {
            'other_zone': 'W. Australia Standard Time',
            'zone': 'Antarctica/Casey',
            'territory': 'Casey'
        },
        {
            'other_zone': 'Taipei Standard Time',
            'zone': 'Asia/Taipei',
            'territory': 'Taipei'
        },
        {
            'other_zone': 'Ulaanbaatar Standard Time',
            'zone': 'Asia/Ulaanbaatar',
            'territory': 'Ulaanbaatar'
        },
        {
            'other_zone': 'Ulaanbaatar Standard Time',
            'zone': 'Asia/Choibalsan',
            'territory': 'Choibalsan'
        },
        {
            'other_zone': 'North Asia East Standard Time',
            'zone': 'Asia/Irkutsk',
            'territory': 'Irkutsk'
        },
        {
            'other_zone': 'Tokyo Standard Time',
            'zone': 'Asia/Tokyo',
            'territory': 'Tokyo'
        },
        {
            'other_zone': 'Tokyo Standard Time',
            'zone': 'Asia/Jayapura',
            'territory': 'Jayapura'
        },
        {
            'other_zone': 'Tokyo Standard Time',
            'zone': 'Pacific/Palau',
            'territory': 'Palau'
        },
        {
            'other_zone': 'Tokyo Standard Time',
            'zone': 'Asia/Dili',
            'territory': 'Dili'
        },
        {
            'other_zone': 'Tokyo Standard Time',
            'zone': 'Etc/GMT-9',
            'territory': 'GMT-9'
        },
        {
            'other_zone': 'Korea Standard Time',
            'zone': 'Asia/Seoul',
            'territory': 'Seoul'
        },
        {
            'other_zone': 'Korea Standard Time',
            'zone': 'Asia/Pyongyang',
            'territory': 'Pyongyang'
        },
        {
            'other_zone': 'Cen. Australia Standard Time',
            'zone': 'Australia/Adelaide',
            'territory': 'Adelaide'
        },
        {
            'other_zone': 'Cen. Australia Standard Time',
            'zone': 'Australia/Broken_Hill',
            'territory': 'Broken Hill'
        },
        {
            'other_zone': 'AUS Central Standard Time',
            'zone': 'Australia/Darwin',
            'territory': 'Darwin'
        },
        {
            'other_zone': 'E. Australia Standard Time',
            'zone': 'Australia/Brisbane',
            'territory': 'Brisbane'
        },
        {
            'other_zone': 'E. Australia Standard Time',
            'zone': 'Australia/Lindeman',
            'territory': 'Lindeman'
        },
        {
            'other_zone': 'AUS Eastern Standard Time',
            'zone': 'Australia/Sydney',
            'territory': 'Sydney'
        },
        {
            'other_zone': 'AUS Eastern Standard Time',
            'zone': 'Australia/Melbourne',
            'territory': 'Melbourne'
        },
        {
            'other_zone': 'West Pacific Standard Time',
            'zone': 'Pacific/Port_Moresby',
            'territory': 'Port Moresby'
        },
        {
            'other_zone': 'West Pacific Standard Time',
            'zone': 'Antarctica/DumontDUrville',
            'territory': 'DumontDUrville'
        },
        {
            'other_zone': 'West Pacific Standard Time',
            'zone': 'Pacific/Truk',
            'territory': 'Truk'
        },
        {
            'other_zone': 'West Pacific Standard Time',
            'zone': 'Pacific/Guam',
            'territory': 'Guam'
        },
        {
            'other_zone': 'West Pacific Standard Time',
            'zone': 'Pacific/Saipan',
            'territory': 'Saipan'
        },
        {
            'other_zone': 'West Pacific Standard Time',
            'zone': 'Etc/GMT-10',
            'territory': 'GMT-10'
        },
        {
            'other_zone': 'Tasmania Standard Time',
            'zone': 'Australia/Hobart',
            'territory': 'Hobart'
        },
        {
            'other_zone': 'Tasmania Standard Time',
            'zone': 'Australia/Currie',
            'territory': 'Currie'
        },
        {
            'other_zone': 'Yakutsk Standard Time',
            'zone': 'Asia/Yakutsk',
            'territory': 'Yakutsk'
        },
        {
            'other_zone': 'Yakutsk Standard Time',
            'zone': 'Asia/Khandyga',
            'territory': 'Khandyga'
        },
        {
            'other_zone': 'Central Pacific Standard Time',
            'zone': 'Pacific/Guadalcanal',
            'territory': 'Guadalcanal'
        },
        {
            'other_zone': 'Central Pacific Standard Time',
            'zone': 'Antarctica/Macquarie',
            'territory': 'Macquarie'
        },
        {
            'other_zone': 'Central Pacific Standard Time',
            'zone': 'Pacific/Ponape',
            'territory': 'Ponape'
        },
        {
            'other_zone': 'Central Pacific Standard Time',
            'zone': 'Pacific/Kosrae',
            'territory': 'Kosrae'
        },
        {
            'other_zone': 'Central Pacific Standard Time',
            'zone': 'Pacific/Noumea',
            'territory': 'Noumea'
        },
        {
            'other_zone': 'Central Pacific Standard Time',
            'zone': 'Pacific/Efate',
            'territory': 'Efate'
        },
        {
            'other_zone': 'Central Pacific Standard Time',
            'zone': 'Etc/GMT-11',
            'territory': 'GMT-11'
        },
        {
            'other_zone': 'Vladivostok Standard Time',
            'zone': 'Asia/Vladivostok',
            'territory': 'Vladivostok'
        },
        {
            'other_zone': 'Vladivostok Standard Time',
            'zone': 'Asia/Sakhalin',
            'territory': 'Sakhalin'
        },
        {
            'other_zone': 'Vladivostok Standard Time',
            'zone': 'Asia/Ust-Nera',
            'territory': 'Ust-Nera'
        },
        {
            'other_zone': 'New Zealand Standard Time',
            'zone': 'Pacific/Auckland',
            'territory': 'Auckland'
        },
        {
            'other_zone': 'New Zealand Standard Time',
            'zone': 'Antarctica/South_Pole',
            'territory': 'South Pole'
        },
        {
            'other_zone': 'New Zealand Standard Time',
            'zone': 'Antarctica/McMurdo',
            'territory': 'McMurdo'
        },
        {
            'other_zone': 'UTC+12',
            'zone': 'Etc/GMT-12',
            'territory': 'GMT-12'
        },
        {
            'other_zone': 'UTC+12',
            'zone': 'Pacific/Tarawa',
            'territory': 'Tarawa'
        },
        {
            'other_zone': 'UTC+12',
            'zone': 'Pacific/Majuro',
            'territory': 'Majuro'
        },
        {
            'other_zone': 'UTC+12',
            'zone': 'Pacific/Kwajalein',
            'territory': 'Kwajalein'
        },
        {
            'other_zone': 'UTC+12',
            'zone': 'Pacific/Nauru',
            'territory': 'Nauru'
        },
        {
            'other_zone': 'UTC+12',
            'zone': 'Pacific/Funafuti',
            'territory': 'Funafuti'
        },
        {
            'other_zone': 'UTC+12',
            'zone': 'Pacific/Wake',
            'territory': 'Wake'
        },
        {
            'other_zone': 'UTC+12',
            'zone': 'Pacific/Wallis',
            'territory': 'Wallis'
        },
        {
            'other_zone': 'Fiji Standard Time',
            'zone': 'Pacific/Fiji',
            'territory': 'Fiji'
        },
        {
            'other_zone': 'Magadan Standard Time',
            'zone': 'Asia/Magadan',
            'territory': 'Magadan'
        },
        {
            'other_zone': 'Magadan Standard Time',
            'zone': 'Asia/Anadyr',
            'territory': 'Anadyr'
        },
        {
            'other_zone': 'Magadan Standard Time',
            'zone': 'Asia/Kamchatka',
            'territory': 'Kamchatka'
        },
        {
            'other_zone': 'Tonga Standard Time',
            'zone': 'Pacific/Tongatapu',
            'territory': 'Tongatapu'
        },
        {
            'other_zone': 'Tonga Standard Time',
            'zone': 'Pacific/Enderbury',
            'territory': 'Enderbury'
        },
        {
            'other_zone': 'Tonga Standard Time',
            'zone': 'Pacific/Fakaofo',
            'territory': 'Fakaofo'
        },
        {
            'other_zone': 'Tonga Standard Time',
            'zone': 'Etc/GMT-13',
            'territory': 'GMT-13'
        },
        {
            'other_zone': 'Samoa Standard Time',
            'zone': 'Pacific/Apia',
            'territory': 'Apia'
        }
    ];
    return kendo;
}, typeof define == 'function' && define.amd ? define : function (a1, a2, a3) {
    (a3 || a2)();
}));